import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import validate from "helpers/validate";
import { useEffect, useState } from "react";
import Locale from "translations";

export default function Passenger({
	data,
	DataCheckAvailability,
	index,
	RoomIndex,
	setErrorsClients,
	setDataCheckAvailability,
	errorsClients,
	checkFormallErrors,
	HotelData,
	doneCheck,
	setDoneCheck,
}) {
	//
	const { marketPlace } = Locale;

	const groups = [
		{ name: "Dr.", id: "558" },
		{ name: "Madame", id: "1671" },
		{ name: "Mademoiselle", id: "74195" },
		{ name: "Messrs", id: "9234" },
		{ name: "Miss", id: "15134" },
		{ name: "Monsieur", id: "74185" },
		{ name: "Mr.", id: "147" },
		{ name: "Mrs", id: "149" },
		{ name: "Ms", id: "148" },
		{ name: "Sir", id: "1328" },
		{ name: "Sir/Madam", id: "3801" },
	];

	const groupsTBO = [
		{ name: "Mr.", id: "Mr" },
		{ name: "Ms", id: "Ms" },
		{ name: "Mrs", id: "Mrs" },
	];

	const types = [
		{ name: "Adult", id: "Adult" },
		{ name: "Child", id: "Child" },
	];
	const [startVaild, setstartVaild] = useState(false);

	const [clientData, setclientData] = useState({
		salutation: "",
		first_name: "",
		last_name: "",
	});

	useEffect(() => {
		let NewlistClient;
		if (
			DataCheckAvailability[RoomIndex].guests &&
			DataCheckAvailability[RoomIndex].guests.length > 0
		) {
			NewlistClient = [...DataCheckAvailability];
			// let x = NewlistClient[RoomIndex];
			// x.guests[index] = clientData;

			const x = NewlistClient[RoomIndex].guests.map((res, inde) => {
				return inde == index
					? {
							...res,
							...clientData,
					  }
					: res;
			});
			// NewlistClient[RoomIndex].guests = x;

			let xx = NewlistClient.map((res, INDESS) => {
				if (RoomIndex == INDESS) {
					return { ...res, guests: x };
				}
				return { ...res };
			});

			setDataCheckAvailability([...xx]);
			//
		}
	}, [clientData]);

	useEffect(() => {
		if (startVaild) {
			checkFormErrorsfileds();
			setDoneCheck(!doneCheck);
			// setclientData({ ...clientData });
		}
		setstartVaild(true);
	}, [checkFormallErrors]);

	/* check errors */
	const checkFormErrorsfileds = () => {
		let error = errorsClients;
		let newError = {
			...validate(
				{
					name: "salutation",
					value: clientData.salutation,
				},
				{
					required: true,
					serverError:false
				}
			),
			...validate(
				{
					name: "first_name",
					value: clientData.first_name,
				},
				{
					required: true,
					serverError:false

				}
			),
			...validate(
				{
					name: "last_name",
					value: clientData.last_name,
				},
				{
					required: true,
					serverError:false

				}
			),
		};
		// if (error?.[RoomIndex]?.[index]) {
		// 	error[RoomIndex][index] = {
		// 		...error[RoomIndex][index],
		// 		...newError,
		// 	};
		// } else if (Array.isArray(error)) {
		// 	// if(Array.isArray(error[RoomIndex]))

		// 	error?.[RoomIndex]?.splice(index, 0, newError);
		// }
		if (!Array.isArray(error[RoomIndex])) error[RoomIndex] = [];
		error[RoomIndex][index] = newError;

		setErrorsClients(error);
	};

	const setErrorsClientsfiled = (name, value, role) => {
		let error = errorsClients;
		if (!Array.isArray(error[RoomIndex])) error[RoomIndex] = [];

		error[RoomIndex][index] = {
			...error[RoomIndex][index],
			...validate({ name: name, value: value }, role),
		};
		setErrorsClients(error);
	};

	return (
		<>
			<div className="cartItem row align-items-start">
				<div className="col-md-2">
					<div className="form-group full-input">
						<SelectField
							label={marketPlace.messages.title}
							placeholder={marketPlace.messages.title}
							options={HotelData.gds == 5 ? groupsTBO : groups}
							value={clientData?.salutationData?.label}
							name="salutation"
							onChange={(e) => {
								setclientData({
									...clientData,
									salutationData: e,
									salutation: e.id,
								});
								setErrorsClientsfiled("salutation", e, {
									required: true,
								});
							}}
							onBlur={() => false}
							errors={errorsClients?.[RoomIndex]?.[index]?.salutation}
							color={
								errorsClients?.[RoomIndex]?.[index]?.salutation?.required || errorsClients?.[RoomIndex]?.[index]?.salutation?.serverError
									? "danger"
									: ""
							}
						/>
					</div>
				</div>

				<div className="col-md-10 px-4 ">
					<div className="row">
						{HotelData.gds == 5 ? (
							<div className="col-auto ">
								<div
									className="form-group full-input"
									style={{ width: "100px" }}
								>
									<SelectField
										label={marketPlace.messages.type}
										placeholder={marketPlace.messages.type}
										options={types}
										value={clientData?.type?.label}
										name="type"
										onChange={(e) => {
											setclientData({
												...clientData,
												type: e,
											});
										}}
										onBlur={() => false}
									/>
								</div>
							</div>
						) : (
							""
						)}
						<div className="col-6 col-auto ">
							<div className="form-group full-input">
								<TextField
									placeholder={marketPlace.messages.firstName}
									label={marketPlace.messages.firstName}
									value={clientData?.first_name}
									name="first_name"
									onChange={(e) => {
										setclientData({
											...clientData,
											first_name: e.target.value,
										});
										setErrorsClientsfiled("first_name", e.target.value, {
											required: true,
										});
									}}
									errors={errorsClients?.[RoomIndex]?.[index]?.first_name}
									color={
										errorsClients?.[RoomIndex]?.[index]?.first_name?.required || errorsClients?.[RoomIndex]?.[index]?.first_name?.serverError
											? "danger"
											: ""
									}
								/>
							</div>
						</div>
						<div className="col-6 col-auto ">
							<div className="form-group full-input">
								<TextField
									placeholder={marketPlace.messages.lastName}
									label={marketPlace.messages.lastName}
									value={clientData?.last_name}
									name="last_name"
									onChange={(e) => {
										setclientData({
											...clientData,
											last_name: e.target.value,
										});
										setErrorsClientsfiled("last_name", e.target.value, {
											required: true,
										});
									}}
									errors={errorsClients?.[RoomIndex]?.[index]?.last_name}
									color={
										errorsClients?.[RoomIndex]?.[index]?.last_name?.required || errorsClients?.[RoomIndex]?.[index]?.last_name?.serverError
											? "danger"
											: ""
									}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
