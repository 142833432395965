import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import libphonenumber from "google-libphonenumber";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import { useEffect, useRef, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { Spinner } from "reactstrap";
import { uploadFile } from "services/auth";
import { fetchCities } from "services/lookups";
import { fetchCompanyProfile, updateCompanyProfile } from "services/profile";
import Locale from "translations";
import imgIcon from "../../assets/images/customRegister/image-regular.svg";

export default function CompanyProfile(props) {
	const { companyDetails, companyAvatar, commons } = Locale;
	const { locale } = useSBSState();
	const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
	const [countries] = useFetchCountries();
	let history = useHistory();
	const URI = process.env.REACT_APP_FILEUPLOAD_URL;
	const [editing, setEditing] = useState(true);
	const [cities, setCities] = useState({
		cities: [],
		isCitiesLoaded: false,
	});
	const [companyProfileState, setCompanyProfileState] = useState({
		name: "",
		address: "",
		businessAddress: "",
		country: 0,
		city: 0,
		tempCity: 0,
		phone: "",
		secondPhone: "",
		avatar: "",
		description: "",
		countryPhoneCode: null,
		companyLogo: null,
		filePreview: null,
		countryCode: null,
	});
	const inputFileRef = useRef(null);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const validatePhone = (phone) => {
		try {
			return phoneUtil.isValidNumberForRegion(
				phoneUtil.parse(phone.toString(), companyProfileState.countryCode),
				companyProfileState.countryCode
			);
		} catch (err) {
			return false;
		}
	};
	const checkFormErrors = () => {
		setErrors({
			...errors,
			...validate(
				{ name: "address", value: companyProfileState.address },
				{ required: true, min: 3 }
			),
			...validate(
				{ name: "country", value: companyProfileState.country },
				{ required: true }
			),
			...validate(
				{ name: "city", value: companyProfileState.city },
				{ required: true }
			),
			...validate(
				{ name: "phone", value: validatePhone(companyProfileState.phone) },
				{ required: true, phone: true }
			),
		});
	};
	useEffect(() => {
		const Data = countries?.filter(
			(obj) => obj.id == companyProfileState.country?.value
		);
		setCompanyProfileState({
			...companyProfileState,
			countryPhoneCode: Data.length > 0 ? Data[0]["phone_code"] : "-",
			countryCode: Data.length > 0 ? Data[0]["code"] : null,
		});
	}, [countries, companyProfileState.country?.value]);

	const fetchCity = async (id) => {
		const cities = await fetchCities(id);
		setCities({ cities: cities, isCitiesLoaded: true });
	};

	useEffect(() => {
		async function fetchData() {
			if (countries.length !== 0) {
				const company = await fetchCompanyProfile();
				fetchCity(company.country?.id);
				setCompanyProfileState({
					...companyProfileState,
					name: company.name,
					businessAddress: company.business_email,
					phone: company.phone?.phone,
					address: company.address,
					secondPhone: company.extra_data?.phone
						? company.extra_data?.phone.phone
						: "",
					description: company.extra_data?.description,
					avatar: company.logo,
					country: { value: company.country?.id, label: company.country?.name },
					countryPhoneCode: company.phone?.phone_code,
					countryCode: company.country?.code,
					city: { value: company.city?.id, label: company.city?.name },
				});
				// setCity(company.city);
			}
		}
		fetchData();
	}, [countries]);
	const [isPreview, setIsPreview] = useState(true);
	useEffect(() => {
		async function fetchData() {
			if (companyProfileState.avatar && isPreview) {
				//const fetchImage = await fetchImages(companyProfileState.avatar);
				const fetchImage = `${URI}/fetch/${companyProfileState.avatar}`;

				if (fetchImage) {
					updateLogo(fetchImage);
					setCompanyProfileState({
						...companyProfileState,
						filePreview: fetchImage,
					});
				}
				setIsPreview(false);
			}
		}
		fetchData();
	}, [companyProfileState.avatar]);

	const updateLogo = (file) => {
		setCompanyProfileState({ ...companyProfileState, companyLogo: file });
	};

	const onFileChange = async (e) => {
		const fileType = e.target.files[0];
		const formData = new FormData();
		formData.append("file", fileType);
		formData.append("name", fileType.name);
		formData.append("bucket", "company");
		if (!fileType.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarExtensionValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else if (fileType.size > 500000) {
			store.addNotification({
				title: commons.somethingWentWrong,
				message: companyAvatar.companyAvatarSizeValidation,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		} else {
			const response = await uploadFile(formData);
			if (response.status === 200) {
				setCompanyProfileState({
					...companyProfileState,
					avatar: response.data.data.uuid,
				});
				//const fetchImage = await fetchImages(response.data.data.uuid);
				const fetchImage = `${URI}/fetch/${response.data.data.uuid}`;

				updateLogo(fetchImage);
				setCompanyProfileState({
					...companyProfileState,
					filePreview: fetchImage,
					avatarID: companyProfileState.avatarID
						? companyProfileState.avatarID
						: response.data.data.uuid,
					avatar: companyProfileState.avatarID
						? companyProfileState.avatarID
						: response.data.data.uuid,
				});
				setIsPreview(true);
			}
		}
	};

	const handleBtnClick = () => {
		inputFileRef.current.click();
	};
	useEffect(async () => {
		const data = {
			lang: locale,
			phone: `${companyProfileState.countryPhoneCode}${companyProfileState.phone}`,
			address: companyProfileState.address,
			avatar: companyProfileState.avatar,
			country_id: `${companyProfileState.country?.value}`,
			city_id:
				`${companyProfileState.city?.value}` ?? companyProfileState.tempCity,
			name: companyProfileState.name,
			extra_data: {
				description: companyProfileState.description,
				phone: companyProfileState.secondPhone
					? `${companyProfileState.countryPhoneCode}${companyProfileState.secondPhone}`
					: null,
			},
			name: companyProfileState.name,
			business_email: companyProfileState.businessAddress,
		};
		if (isFormValid(errors)) {
			const response = await updateCompanyProfile(data);
			if (response.status === 200) {
				setEditing(false);
				store.addNotification({
					title: commons.updatedSuccessfully,
					message: "Company profile updated Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/company-profile");
					},
				});
			}
		}
		setCompanyProfileState({
			...companyProfileState,
			spin: false,
		});
	}, [isErrorLoaded]);

	const updateProfile = () => {
		setCompanyProfileState({
			...companyProfileState,
			spin: true,
		});
		checkFormErrors();

		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	return (
		<>
			<div className="  bg-white   rounded py-3">
				<div className="row m-0 justify-content-center  align-items-center">
					<div className="col-md-3 col-12">
						<input
							type="file"
							ref={inputFileRef}
							onChange={onFileChange}
							className="d-none"
							accept="image/*"
						/>
						<div
							className="image-uploader"
							onClick={editing ? handleBtnClick : false}
						>
							<div className="image-uploader__outline position-relative bg-white img-center mx-auto mx-md-0">
								<div className="img-holder ">
									<img
										src={
											companyProfileState.filePreview
												? companyProfileState.filePreview
												: imgIcon
										}
										className={"img-fluid"}
										accept="image/*"
										alt='company profile'
									/>
								</div>

								<i className="fas fa-plus-circle image-uploader__plus-btn"></i>
							</div>
						</div>

						{/*
						<input
								type="file"
								ref={inputFileRef}
								onChange={onFileChange}
								className="d-none"
								accept="image/*"
							/>
						<div
							className="image-uploader"
							onClick={editing ? handleBtnClick : false}
						>

						
							<div className="image-uploader__outline position-relative">
								<img
									src={
										companyProfileState.filePreview
											? companyProfileState.filePreview
											: ImagePlaceholder
									}
									className="image-uploader__img"
									alt="logo placeholder"
									accept="image/*"
								/>
								{editing ? (
									<img
										src={plusIcon}
										className="image-uploader__plus-btn"
										alt="logo placeholder"
										accept="image/*"
									/>
								) : null}
							</div>
						</div> */}

						{/* <div className="current-package">
								<p>
									{companyDetails.currentPackage}:{" "}
									<span className="font-weight-bold">Light</span>
								</p>
							</div> */}
						{/* <div className="description">
								<h6 className="py-1 font-weight-bold text-uppercase">
									{companyDetails.description}
								</h6>
								<p>{companyProfileState.description}</p>
							</div> */}
					</div>
					<div className="col-md-5 px-4 mt-3">
						<div className="user-profile-details">
							<div className="d-flex justify-content-between">
								<div className="col-md-12">
									{/* <h6 className="font-weight-bold"></h6> */}
								</div>
							</div>
							<div className="row m-0">
								<div className="col-md-12">
									<div className="form-group ">
										<TextField
											type="text"
											label={companyDetails.companyName}
											placeholder={companyDetails.companyNamePlaceholder}
											value={companyProfileState.name}
											disabled={true}
											onChange={(e) => {
												setCompanyProfileState({
													...companyProfileState,
													name: e.target.value,
												});
											}}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group full-input">
										<TextField
											label={companyDetails.businesmail}
											placeholder={companyDetails.businessEmailPlaceholder}
											value={companyProfileState.businessAddress}
											disabled={false}
											onChange={(e) => {
												setCompanyProfileState({
													...companyProfileState,
													businessAddress: e.target.value,
												});
											}}
										/>
									</div>
								</div>
								<div className="col-md-12">
									<div className="form-group full-input">
										<TextField
											label={companyDetails.address}
											placeholder={companyDetails.addressPlaceholder}
											value={companyProfileState.address}
											disabled={!editing}
											name="address"
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(e.target, {
														required: true,
														min: 3,
													}),
												});
												setCompanyProfileState({
													...companyProfileState,
													address: e.target.value,
												});
											}}
											min={3}
											color={
												errors?.address?.required || errors?.address?.min
													? "danger"
													: ""
											}
											errors={errors?.address}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group full-input">
										<SelectField
											label={companyDetails.country}
											value={companyProfileState.country?.label}
											disabled={true}
											placeholder={companyDetails.countryPlaceholder}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(
														{ name: "country", value: e.value },
														{ required: true }
													),
												});
												setCompanyProfileState({
													...companyProfileState,
													country: e,
													city: null,
													phone: "",
													secondPhone: "",
												});
												fetchCity(e.value);
											}}
											onBlur={() =>
												setErrors({
													...errors,
													...validate(
														{
															name: "country",
															value: companyProfileState.country,
														},
														{ required: true }
													),
												})
											}
											options={countries}
											name="country"
											color={errors?.country?.required ? "danger" : ""}
											errors={errors?.country}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group full-input">
										<SelectField
											label={companyDetails.city}
											value={companyProfileState.city?.label}
											placeholder={companyDetails.cityPlaceholder}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(
														{ name: "city", value: e.value },
														{ required: true }
													),
												});
												setCompanyProfileState({
													...companyProfileState,
													city: e,
												});
											}}
											onBlur={() =>
												setErrors({
													...errors,
													...validate(
														{
															name: "city",
															value: companyProfileState.country,
														},
														{ required: true }
													),
												})
											}
											options={cities.cities}
											name="city"
											color={errors?.city?.required ? "danger" : ""}
											errors={errors?.city}
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group full-input">
										<TextField
											label={`${companyDetails.companyPhoneNumber} 1`}
											value={companyProfileState.phone}
											disabled={!editing}
											extraText={companyProfileState.countryPhoneCode ?? "---"}
											extraTextPosition={
												companyProfileState.country === 0 ? "" : "prepend"
											}
											placeholder={companyDetails.companyPhoneNumberPlaceholder}
											onChange={(e) => {
												setErrors({
													...errors,
													...validate(
														{
															name: "phone",
															value: validatePhone(e.target.value),
														},
														{
															required: true,
															phone: true,
														}
													),
												});
												setCompanyProfileState({
													...companyProfileState,
													phone: e.target.value,
													countryPhoneCode:
														companyProfileState.countryPhoneCode,
													countryCode: companyProfileState.countryCode,
												});
											}}
											color={
												errors?.phone?.required || errors?.phone?.phone
													? "danger"
													: ""
											}
											errors={errors?.phone}
											name="phone"
										/>
									</div>
								</div>
								<div className="col-md-6">
									<div className="form-group full-input">
										<TextField
											label={`${companyDetails.companyPhoneNumber} 2`}
											value={companyProfileState.secondPhone}
											disabled={!editing}
											extraText={companyProfileState.countryPhoneCode ?? "---"}
											extraTextPosition={
												companyProfileState.country === 0 ? "" : "prepend"
											}
											placeholder={companyDetails.companyPhoneNumberPlaceholder}
											onChange={(e) => {
												setCompanyProfileState({
													...companyProfileState,
													secondPhone: e.target.value,
												});
											}}
										/>
									</div>
								</div>

								{/* <div className="col-md-12">
									<div className="form-group full-input">
										<TextAreaField
											label={companyDetails.description}
											placeholder="Write Desciption"
											rows={5}
											value={companyProfileState.description}
											disabled={!editing}
											onChange={(e) => {
												setCompanyProfileState({
													...companyProfileState,
													description: e.target.value,
												});
											}}
										/>
									</div>
								</div> */}
							</div>
						</div>
						<div className="d-flex w-100 justify-content-end mt-3 ">
							<button
								className="btn bg-bk  mx-3"
								onClick={() => history.push("/company-profile")}
							>
								{companyDetails.cancel}
							</button>{" "}
							<button
								className="btn bg-nxt "
								onClick={updateProfile}
								disabled={companyProfileState.spin}
							>
								{companyProfileState.spin ? (
									<Spinner color="light" size="sm" />
								) : null}{" "}
								{companyDetails.updateProfileBtn}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
