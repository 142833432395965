import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Locale from "translations";

export default function PriceBreakdownModal({ isOpen, toggle ,details}) {
	const {packages, inventory} = Locale;
	return (
		<div>
			<Modal
				size="lg"
				isOpen={isOpen?.isOpen}
				toggle={toggle}
				className="recharge-Modal"
			>
				<ModalHeader
					className="align-items-center py-1  border-none"
					toggle={toggle}
				>
					{packages.priceBreakdown}
				</ModalHeader>
				<ModalBody>
					<div className="container">
							<table className="table">
								<thead>
									<tr style={{color:"#112E53"}}>
										<th style={{borderTop:' 1px solid #eff1f3',fontWeight:700}}>{packages.service}</th>
										<th style={{borderTop:' 1px solid #eff1f3',fontWeight:700}}>{inventory.messages.Price}</th>
									</tr>
								</thead>
								<tbody>
									{isOpen?.details?.length > 0 &&
										isOpen?.details?.map((item, index) => (
											<tr key={index}>
												<td  style={{borderTop:' 1px solid #eff1f3'}}>{item?.name}</td>
												<td  style={{borderTop:' 1px solid #eff1f3'}} >{item?.price} {details?.currency}</td>
											</tr>
										))}
								</tbody>
							</table>
					</div>
				</ModalBody>
			</Modal>
		</div>
	);
}
