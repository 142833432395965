import React, { useState } from 'react'
import { Collapse } from 'reactstrap';
import Locale from 'translations';
import { useSBSState } from 'context/global';
import moment from 'moment';

export default function FlightSummarySegmensts({ journyData }) {
  const { marketPlace } = Locale
  const { locale } = useSBSState();
  const [isopen, setisopen] = useState(false);

  function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(+totalMinutes / 60);
    const minutes = +totalMinutes % 60;
    return `${hours}h ${minutes}m`;
  }

  const segments = journyData?.segments;
  const departureSegment = segments?.[0];
  const arrivaleSegment = segments?.[segments?.length - 1];
  const departureTime = departureSegment?.departureTime;
  const arrivalTime = arrivaleSegment?.arrivalTime;

	const flightsNumbers = segments.reduce(
		(prev, current, index) =>
			prev + (index === 0 ? "" : " | ") + current.airline.code+ current.flightNum,
		""
	);
  
  return (
    <>
      <div className="flight_item my-3">
        <div className="head-toggel">
          <button
            className="btn-toggel btn"
            onClick={() => {
              setisopen(!isopen);
            }}
          >
            {isopen ? (
              <>
                {marketPlace.hide} <i class="fas fa-angle-up"></i>
              </>
            ) : (
              <>
                {marketPlace.ShowDetails}<i class="fas fa-angle-down"></i>
              </>
            )}
          </button>
        </div>
        {/* general journy data */}
        <div className="time-line">
          <div className="infoAir">
            <h4 className="takeoff">{departureTime}</h4>
            <p className="airport-name">
              <span className='blue'>{departureSegment?.departure?.code} </span>
              {" "}
              <span>{departureSegment?.departure?.names[locale]}</span>
            </p>
          </div>
          <div className="text-center">
            {locale === "ar"? 
            <i class="fas fa-plane-departure" style={{ color: "#1A61AF",transform:'rotateY(180deg)' }}></i>
            :
            <i class="fas fa-plane-departure" style={{ color: "#1A61AF" }}></i>
            }
            <p className="spent">
              {toHoursAndMinutes(journyData?.journeyTime)}
              {" "}
              <span className="blue">
                {journyData?.stops?.length > 0 ? journyData?.stops?.join(" | ") + " Stop" : "Non-stop"}
              </span>
            </p>
          </div>
          <div className="Landing">
            <h4 className="takeoff">{arrivalTime}</h4>
            <p className="airport-name">
              <span className='blue'>{arrivaleSegment?.arrival?.code} </span>
              {" "}
              <span>{arrivaleSegment?.arrival?.names[locale]}</span>
            </p>
          </div>
        </div>



        <Collapse isOpen={isopen}>
          {segments.map((segment, index) => {
            const stopTime = moment(
              `${segments[index + 1]?.departureDate} ${segments[index + 1]?.departureTime
              }`
            ).diff(`${segment?.arrivalDate} ${segment?.arrivalTime}`, "m");
            return (
							<div className="mx-4 collapse-box p-2">
								<div className="d-flex align-items-center px-1">
									<img
										className="img-company mr-2"
										src={segment?.airline?.logo}
										alt=""
									/>
									<div className="mx-1">
										<p className="takeoff">{segment.airline?.name[locale]}</p>
										<p style={{ fontSize: "14px" }}>
											{segment.airline?.code + segment.flightNum}
										</p>
									</div>
								</div>

								<div className="time-line">
									<div className="infoAir">
										<p className="airport-name">
											<span className="blue">{segment?.departure?.code}</span>{" "}
											<span>{segment?.departure?.names[locale]}</span>
										</p>
										<span className="all-date">
											{moment(segment?.departureDate).format("DD MMM YYYY")}{" "}
											{segment?.departureTime}{" "}
											{moment(segment?.departureDate).format("ddd")}
										</span>
									</div>

									<div className="text-center">
										<p className="spent">
											{toHoursAndMinutes(segment.flightTime)}
										</p>
									</div>

									{/* arrival airport */}
									<div className="Landing">
										<p className="airport-name">
											<span className="blue">{segment?.arrival?.code}</span>{" "}
											<span>{segment?.arrival?.names[locale]}</span>
										</p>
										<span className="all-date">
											{moment(segment?.arrivalDate).format("DD MMM YYYY")}{" "}
											{segment?.arrivalTime}{" "}
											{moment(segment?.arrivalDate).format("ddd")}
										</span>
									</div>
								</div>

								{segments?.length > 1 && index < segments?.length - 1 && (
									<div
										className={`mx-2 stop-box${locale === "ar" ? "-ar" : ""}`}
									>
										<p
											className="pl-2"
											style={{ display: "inline-block", width: "43%" }}
										>
											<span>Stop </span>
											<span className="blue">
												{segment?.arrival?.names[locale]}
											</span>
										</p>
										<span className="stop">{toHoursAndMinutes(stopTime)} </span>
									</div>
								)}
							</div>
						);
          })}
        </Collapse>

        <div className="px-2">
          <div className="footer-toogle">

            <div className="d-flex justify-content-start align-items-center">
              <img className="img-company" src={departureSegment.airline?.logo} alt="" />
              <div className='mx-1'>
                <p className="takeoff">
                  {departureSegment.airline.name?.[locale]}
                </p>
                <p style={{ fontSize: "14px" }}>
                  {flightsNumbers}
                </p>
              </div>
            </div>

            <div className="d-flex justify-content-start align-items-center ">
              {segments?.map((segment, index) => {
                return (
                  <p className="px-1" style={{ backgroundColor: "#EBEBEB", borderRadius: "2px", marginInlineEnd: "4px" }} key={segment.segmentId}>
                    {segment?.equipment}
                  </p>
                )
              })}
            </div>

            <div className="d-flex justify-content-end align-items-center ">
              <p>{departureSegment.cabinClass}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}