import Company from 'modules/market-place/PackageResponse/Details/shared/company';
import React from 'react'
import Locale from 'translations';

export default function PackageReservationSummary({ details, itineraryElementsIds }) {
  const { packages, marketPlace } = Locale;
  // const productPrice = details?.product_price;
  // const roomsAvailabilities = details?.product_room_price;
  // const roomAvailabilities = details?.roomAvailabilities;

  // let typeRooms = {
  //   1: "Single Room",
  //   2: "Double Room",
  //   3: "Treble Room",
  //   4: "Quad Room",
  //   5: "Quintuple Room",
  //   6: "Hexagonal Room",
  //   7: "Seven Room",
  //   8: "Eight Room"
  // };
  
  // const priceType = [
  //   { type: "adults_count", name: "Adult" },
  //   { type: "children_count", name: "Children" },
  //   { type: "infant_count", name: "Infant" },
  // ];

  const ExternalElements = () => {
    //To Return uniqe External Element
    let externalElementAirLines = details?.product_details?.filter(
      (element) => element?.item?.id === itineraryElementsIds?.external
    );
    let uniqueAirLinesIds = {}
    const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
      const airLineId = item?.itemable?.transporter?.id
      if (uniqueAirLinesIds[airLineId] !== undefined) {
        return false;
      }
      uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item }
      return item
    });
    if (uniqueExtrenalAirLines?.length > 0) {
      return uniqueExtrenalAirLines?.map(externalTransport => {
        return (
          <Company
            key={externalTransport.id}
            title={marketPlace.airlines}
            name={externalTransport?.itemable?.transporter?.name}
            logo={externalTransport?.itemable?.transporter?.logo}
          />
        );
      })
    } else {
      return null;
    }
  };



  return (
    <div>
      {/* <h6 className='terms-color font-weight-bold'>
        Summary
      </h6> */}
      {/* summary per room */}
      {/* <div className='bg-white my-2 p-2 shadow-sm rounded'>
        {roomAvailabilities?.map((room) => {
          console.log(room);
          const roomType = roomsAvailabilities?.find(
            (i) => i.room_type === room.room_type
          );
          return priceType.map((price) => {
            // debugger
            return room[price.type] > 0 ? (
              <>
                <div className="d-flex justify-content-between mb-1">
                  <span>
                    {`${room.adults_count}x ${price.name} `}
                    <span style={{ fontSize: "13px", color: "#0F3E5E" }}>
                      ({typeRooms[roomType?.room_type]})
                    </span>{" "}
                  </span>
                  <span>
                    {price.type === "adults_count"
                      ? +roomType?.adult_price * +room[price.type]
                      : price.type === "children_count"
                        ? +productPrice.price_child * +room[price.type]
                        : +productPrice.price_infant * +room[price.type]}
                    {` ${productPrice.currency_code}`}
                  </span>
                </div>
              </>
            ) : null;
          });
        })}
      </div> */}
      {/* price summary */}
      <h6 className='terms-color font-weight-bold'>
        {packages.priceSummary}
      </h6>
      <div className="bg-white my-2 p-2 shadow-sm rounded p-3">
        <div className="d-flex justify-content-between my-2">
          <span className='font-weight-bold'>{packages.basePrice}</span>
          <span>{`${details?.total_price} ${details.currency}`}</span>
        </div>

        <hr className="my-1" />
        <div className="d-flex justify-content-between my-1 terms-color">
          <span className='font-weight-bold'>{packages.totalPackagePrice}</span>
          <span>{`${details?.total_price} ${details.currency}`}</span>
        </div>
      </div>

      {ExternalElements()}
    </div>
  )
}
