import airline from " ../../assets/images/icons/airline.svg";
import attraction from " ../../assets/images/icons/Attraction.svg";
import bus from " ../../assets/images/icons/bus.svg";
import serviesGold from " ../../assets/images/icons/other-servies-gold.svg";
import internalGold from " ../../assets/images/icons/internal-gold.svg";

import serviesdis from " ../../assets/images/icons/other-servies-dis.svg";

import domesticFlight from " ../../assets/images/icons/domesticFlight.svg";
import hotel from " ../../assets/images/icons/hotel.svg";
import Passport from " ../../assets/images/icons/Passport.svg";

import Model from "components/model";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import moment from "moment";
import { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { Col } from "reactstrap";
import {
	cloneProduct,
	deleteProduct,
	unpublishedProduct,
} from "services/productbuilder";
import Locale from "../../../translations";
import { store } from "react-notifications-component";
import SharePackageModal from "./SharePackageModal";
import Switch from "react-switch";
import ShowForPermission from "helpers/showForPermission";

export default function ProductsTableRow({ product, fetchProducts }) {
	const { productsBuilder, productsList } = Locale;
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const dispatch = useDispatch();
	const dispatch = useProductsBuilderDispatch();
	const history = useHistory();
	const [modal, setModal] = useState({
		isOpen: false,
		type: "",
		title: "",
		active: false,
	});

	const unpublished = async () => {
		const res = await unpublishedProduct(product.product_uuid);
		if (res.status === 200) {
			setModal({
				isOpen: !modal.isOpen,
				type: "Unpublished",
				title: "Unpublished",
			});
			store.addNotification({
				title: "Info!",
				message: productsList.UnpublishedMessages,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const cloneProducts = async () => {
		const res = await cloneProduct(product.product_uuid);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: productsBuilder.Info,
				message: `${productsBuilder.clonesuccessfully}`,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
			fetchProducts();
		} else {
			store.addNotification({
				title: "Error!",
				message: "Error!",
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	};

	const toggle = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: "delete",
			title: productsBuilder.deleteProduct,
		});
	};

	const toggleUnpublished = () => {
		setModal({
			isOpen: !modal.isOpen,
			type: productsBuilder.Unpublished,
			title: productsBuilder.Unpublished,
		});
	};

	let id = product.product_uuid;
	const deleteProducts = async () => {
		setModal({ ...modal, active: true });
		const res = await deleteProduct(id);
		if (res.status === 200) {
			setModal({});
			fetchProducts();
		} else {
			setModal({ ...modal, active: false, isOpen: false });
		}
	};
	const editProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `/products-builder/create`,
			state: {
				id,
			},
		});
	};
	const showProducts = async () => {
		localStorage.setItem("uuid", product.product_uuid);
		//dispatch({ type: "saveBasicDetails", payload: { isEditMode: true } });
		history.push({
			pathname: `/products-builder/show`,
			state: {
				id,
			},
		});
	};
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const statusColor = () => {
		switch (product.product_status.id) {
			case 2:
				return "text-primary";
			case 1:
				return "text-warning";
			case 3:
				return "text-success";
			default:
				break;
		}
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	//
	const [shareModelOpen, setShareModelOpen] = useState(false);

	function toggleShareModel() {
		setShareModelOpen(!shareModelOpen);
	}
	return (
		<>
			<Model
				isOpen={modal.isOpen}
				toggle={toggle}
				type={modal.type}
				title={modal.title}
				submit={modal.type === "delete" ? deleteProducts : unpublished}
				disabled={modal.active}
			>
				{modal.type === "delete" ? (
					<Col className="p-3">{productsBuilder.deleteProductQoute}</Col>
				) : (
					<Col className="p-3">{productsBuilder.ProductUnpublished}</Col>
				)}
			</Model>

			{shareModelOpen ? (
				<SharePackageModal
					shareModelOpen={shareModelOpen}
					toggleShareModel={toggleShareModel}
					product_uuid={product.product_uuid}
					validation_date_from_at={product.validation_date_from_at}
					validation_date_to_at={product.validation_date_to_at}
					fetchProducts={fetchProducts}
					is_break_down={product.is_break_down}
				/>
			) : null}

			<tr className="tr-hover tr-trips-mangment" key={product.product_uuid}>
				<td scope="row">{product.id}</td>
				<td>{product.name}</td>
				<td>{product.product_type.name}</td>
				<td>
					<p className="text-success">
						{moment(product.validation_date_from_at).format("DD-MM-YYYY")}
					</p>
					<p className="text-danger">
						{moment(product.validation_date_to_at).format("DD-MM-YYYY")}
					</p>
				</td>
				<td>
					<p className="text-success">
						{product.departure_date_at
							? moment(product.departure_date_at).format("DD-MM-YYYY")
							: null}
					</p>
					<p className="text-danger">
						{product.return_date_at
							? moment(product.return_date_at).format("DD-MM-YYYY")
							: null}
					</p>
				</td>
				<td className="tb-traip-products">
					{product.product_items.external_transportation ? (
						<img src={airline} alt="" srcset="" />
					) : (
						""
					)}
					{product.product_items.internal_transportation ? (
						<img src={bus} alt="" srcset="" />
					) : (
						// <img src={internalGold} alt="" srcset="" />

						""
					)}
					{product.product_items.domestic ? (
						<img src={domesticFlight} alt="" srcset="" />
					) : (
						""
					)}
					{product.product_items.visa ? (
						<img src={Passport} alt="" srcset="" />
					) : (
						""
					)}
					{product.product_items.insurance ? (
						<i className="fas fa-shield-alt fa-fw"></i>
					) : (
						""
					)}
					{product.product_items.sightseeing ? (
						<img src={attraction} alt="" srcset="" />
					) : (
						""
					)}
					{product.product_items.hotel ? (
						<img src={hotel} alt="" srcset="" />
					) : (
						""
					)}
					{product.product_items.other_service ? (
						// <i className="fas fa-braille fa-fw"></i>
						<img src={serviesdis} alt="" srcset="" />
					) : (
						""
					)}
				</td>
				<td>
					{product.product_classification
						? product.product_classification.name
						: ""}
				</td>
				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td>
						{product.is_has_price ? (
							<i className="fas fa-check text-success"></i>
						) : (
							<Link
								onClick={() => {
									localStorage.setItem("uuid", product.product_uuid);
									dispatch({
										type: "saveBasicDetails",
										payload: { isEditMode: true },
									});
								}}
								to={{
									pathname: `/products-builder/create`,
									state: { tab: "pricing", id },
								}}
							>
								<span className="add-price-underline">
									{productsBuilder.addPrice}
								</span>
							</Link>
						)}
					</td>
					<td>
						{product.product_status.name === "غير منشورة" ||
						product.product_status.name === "unpublished" ? (
							<>
								<Switch
									height={24}
									width={45}
									handleDiameter={20}
									className="mx-2 align-middle"
									checked={false}
									onChange={toggleShareModel}
								/>
								<small className="">{product.product_status.name}</small>
							</>
						) : (
							<>
								<Switch
									height={24}
									width={45}
									handleDiameter={20}
									className="mx-2 align-middle"
									checked={true}
									onColor="00b437"
									onChange={() => toggleUnpublished()}
								/>
								<small>{product.product_status.name}</small>
							</>
						)}
						{/* 					<span className={statusColor()}>{product.product_status.name}</span>
						 */}{" "}
					</td>
				</ShowForPermission>
				<td>
					<p>{moment(product.created_at).format("DD-MM-YYYY")}</p>
				</td>

				{/* Action */}
				<ShowForPermission permission="Manage-Trip-Management-Module">
					<td className="actions">
						{/* <button className="btn-clear" onClick={() => {}}>
					<i className="far fa-eye text-primary"></i>
				</button> */}
						{!product?.has_reservation ? (
							<button
								className="edit-product"
								title="edit"
								onClick={editProducts}
							>
								<i className="fas fa-edit mx-1"></i>
							</button>
						) : (
							<button
								className="edit-product"
								title="show"
								onClick={showProducts}
							>
								<i className="fas fa-eye mx-1"></i>
							</button>
						)}

						{/* <button className="btn-clear" onClick={() => {}}>
					<i className="fas fa-clone text-primary"></i>
				</button>
		
				<button className="btn-clear" onClick={() => {}}>
					<i className="fas fa-users text-primary"></i>
				</button> */}
						<button
							className="btn-clear mx-1"
							title="clone"
							onClick={() => {
								cloneProducts();
							}}
						>
							<i className="fas fa-clone text-primary"></i>
						</button>
						<button className="btn-clear" title="delete" onClick={toggle}>
							<i className="fas fa-trash-alt text-danger"></i>
						</button>
						<button style={{ minWidth: "2rem" }} className="btn-clear"></button>
					</td>
				</ShowForPermission>
			</tr>
		</>
	);
}
