import Pagination from 'components/Pagination';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import moment from 'moment';
import React, { useEffect, useState } from 'react'
import { getCart, getFiltersLookup, searchTours } from 'services/tours';
import ToursCartModal from '../CartModal';
import { ShoppingCartIcon } from '../TourIcons';
import TourListItem from './TourListItem';
import ToursFilter from './ToursFilter';
import ToursSearchBar from './ToursSearchBar';
import ToursTopbar from './ToursTopbar';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { useSBSDispatch } from 'context/global';
import Locale from 'translations';
import ToursErrorModal from '../ToursErrorModal';



export default function ToursList() {
  const { commons } = Locale;
  const dispatch = useMarketplaceDispatch();
  const sbsDispatch = useSBSDispatch();

  const history = useHistory();
  const [cartModalOpen, setCartModalOpen] = useState(false);
  const { toursSearchResults, toursSearch, toursCart } = useMarketplaceState();
  const [filtersLookup, setfiltersLookup] = useState({})
  const [initialPrices, setInitialPrices] = useState({})
  const [page, setPage] = useState(1)
  const [errorModal, setErrorModal] = useState({ message: "", open: false });
  const [filters, setFilters] = useState({
    name: "",
    price: { from: 0, to: 1 },
    categories: [],
    duration: [],
    services: [],
    activity_for: [],
    sortBy: "alphabetical",
    sortType: "desc",
  });

  function handleCartModalOpen() {
    setCartModalOpen(!cartModalOpen);
  }

  function toggleErrorModal() {
    setErrorModal({ ...errorModal, open: !errorModal.open })
  }


  // get the tours results if not exist in market search context
  useEffect(() => {
    async function getToursResults() {
      let data = {
        date_from: moment(toursSearch?.date_from).format("YYYY-MM-DD"),
        date_to: moment(toursSearch?.date_to).format("YYYY-MM-DD"),
        city_code: toursSearch?.destination?.id,
        noOfAdults: toursSearch?.adults?.value,
        children: toursSearch?.childs_ages?.map(age => age?.value),
        filter: filters?.price?.from > 0 && filters?.price?.to > 0 ? { ...filters } : { ...filters, price: { from: initialPrices?.from, to: initialPrices?.to } },
      }
      data = {
        ...data,
        ...(toursSearch?.cacheKey) && { cacheKey: toursSearch?.cacheKey }
      }
      const toursRes = await searchTours(data, page || 1);
      if (toursRes?.status >= 200 && toursRes?.status < 300) {
        setInitialPrices({ from: toursRes?.data?.prices?.min?.toFixed(2), to: toursRes?.data?.prices?.max?.toFixed(2) })
        dispatch({
          type: "toursSearchResults",
          payload: toursRes?.data
        });
        if(!toursSearch?.cacheKey) {
          dispatch({
            type: "saveToursSearch",
            payload: { ...toursSearch, cacheKey: toursRes?.data?.cacheKey }
          });
        }
      } else if (toursRes?.status === 400) {
        setErrorModal({ open: true, message: toursRes?.data?.message });
      } else {
        setInitialPrices({ from: 0, to: 0 })
        dispatch({
          type: "toursSearchResults",
          payload: null
        });
      }
    }

    if (toursSearch) {
      if ((filters?.price?.to > filters?.price?.from) || toursSearch?.cacheKey) {
        getToursResults();
      }
    } else {
      setTimeout(() => {
        history.push('/market-search');
      }, 1500);
    }
  }, [page, filters]);



  // get filters lookups
  useEffect(() => {
    async function fetchFiltersLookup() {
      const filtersLookupRes =  await getFiltersLookup(toursSearch?.cacheKey);
      if (filtersLookupRes?.status >= 200 && filtersLookupRes?.status < 300) {
        setfiltersLookup(filtersLookupRes?.data?.lookups);
      } else {
        setErrorModal({ open: true, message: filtersLookupRes?.data?.message });
        // sbsDispatch({
        //   type: "setError",
        //   payload: true,
        //   message: {
        //     title: commons.somethingWentWrong,
        //     body: filtersLookupRes?.data?.message,
        //   },
        // });
      }
    }
    toursSearch?.cacheKey && fetchFiltersLookup();
  }, [toursSearch])

  function goTo(page) {
    setPage(page);
  };

  // get cart 
  async function getUpdatedCart() {
    const tourCartRes = await getCart(toursCart?.id);
    if (tourCartRes?.status >= 200 && tourCartRes?.status < 300) {
      dispatch({
        type: "toursCart",
        payload: tourCartRes?.data?.cart,
      });
    } else {
      dispatch({
        type: "toursCart",
        payload: null,
      })
    }
  }
  useEffect(() => {
    if (toursCart?.id) {
      getUpdatedCart();
    }
  }, []);


  return (
    <section className="tours-list">
      <ToursSearchBar />
      <div className="container">
        <div className="row mt-3">

          <div className="col-md-3">
            <ToursFilter
              filters={filters}
              setFilters={setFilters}
              filtersLookup={filtersLookup}
              initialPrices={initialPrices}
              setPage={setPage}
            />
          </div>

          <div className="col-md-9 mt-2">
            <div className="container">
              <ToursTopbar
                filters={filters}
                setFilters={setFilters}
                toursCount={toursSearchResults?.meta?.total}
              />
              {/* search results list  */}
              {toursSearchResults?.data?.map(tour => {
                return (
                  <TourListItem
                    key={tour?.code}
                    tourData={tour}
                  />
                )
              })}

              {/* paginagtion */}
              <div>
                <Pagination info={toursSearchResults?.meta} goTo={goTo} />
              </div>
            </div>

          </div>

        </div>
      </div>
      {/* cart */}
      <button className="btn tours-cart-btn" onClick={handleCartModalOpen}>
        <ShoppingCartIcon />
        {
          toursCart?.cartItems?.length > 0 ? <span className='tours-cart-btn-indicator'>{toursCart?.cartItems?.length}</span> : null
        }
      </button>

      <ToursCartModal handleCartModalOpen={handleCartModalOpen} cartModalOpen={cartModalOpen} />

      <ToursErrorModal
        isOpen={errorModal.open}
        toggleModal={toggleErrorModal}
        message={errorModal.message}
        withButton={true}
      />



    </section>
  )
}
