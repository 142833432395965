import React from "react";
import Locale from "translations";
import { useSBSState } from "context/global";
import { useHistory } from "react-router-dom";

export default function CardPackage({ packageDetails }) {
	const { marketPlace } = Locale;
	const { locale } = useSBSState();
	let history =useHistory()

	const FILEUPLOAD_URL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
	return (
		<div
			className="package-card"
			style={{
				backgroundImage: `linear-gradient( rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)), url(${FILEUPLOAD_URL}/${packageDetails.photo})`,
			}}
			onClick={()=>history.push(`/market-package/package-details/${packageDetails.product_uuid}`)}
		>
			<div className="layer-top"></div>
			<div className="package-name">
				<h1>{packageDetails.name}</h1>
				<div className="hotel-star-rate d-inline px-2 ">
            {[...Array(packageDetails.stars)].map((i) => (
              <i key={i} className="fas fa-star image-shadow" aria-hidden="true"></i>
            ))}
          </div>
				<div className="d-flex flex-row cols-p">
					<p>
						<i class="fas fa-map-marker-alt"></i>{" "}
						{packageDetails.city.name[locale]}
					</p>
					<p>
						<i class="far fa-moon"></i>{" "}
						{`${marketPlace.Nights}: ${packageDetails.nights}`}
					</p>
				</div>
				<p className="badge-pac">{packageDetails.product_type}</p>
			</div>
			<div className="package-price">
				<p>{marketPlace.startingFrom}</p>
				<div className="d-flex flex-row  align-items-end">
					<h1>
						{`${packageDetails.price} ${packageDetails.currency} /`}
					</h1>
					<span>{marketPlace.perPerson}</span>
				</div>
			</div>
		</div>
	);
}
