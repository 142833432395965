import React, { useEffect, useState } from "react";
import SearchBarPackages from "./searchBarPackages";
import CardPackage from "./cardPackage";
import SortFilterPackage from "./sortFilter";
import "react-multi-carousel/lib/styles.css";
import { fetchMarketPackagesSearch } from "services/marketplace";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";

export default function CityPackages() {
	const initialFilters = {
		packageType: null,
		packageMonth: null,
		city: null,
		pax: null,
		tripItems: [],
		roomTypes: [],
		noNights: null,
		stars: null,
		priceFrom: 0,
		priceTo: 0,
	};
	const [packages, setPackages] = useState([]);
	const [filters, setFilters] = useState(initialFilters);
	const { id } = useParams();

	const resetForm = () => {
		setFilters(initialFilters);
		getPackages({}, true);
	};

	const formatDate = () => {
		const packageMonth = filters?.packageMonth;
		if (!packageMonth) return {};

		const dateLength = packageMonth.split("-").length;
		let date_from = packageMonth;
		let date_to = packageMonth;
		if (dateLength === 2) {
			date_from = `${packageMonth}-1`;
			date_to = `${packageMonth}-${moment(packageMonth).daysInMonth()}`;
		}
		return {
			date_from,
			date_to,
		};
	};

	const formatfilter = () => {
		const trip_items = filters.tripItems.map((i) => i.id);
		const room_types = filters.roomTypes.map((i) => i.id);

		const data = {
			product_type_id: filters.packageType?.id,
			city_id: filters.city?.id,
			pax: filters.pax?.id,
			trip_items: filters.tripItems.length ? trip_items : null,
			room_types: filters.roomTypes.length ? room_types : null,
			nights: filters.noNights?.id,
			stars: filters.stars?.id,
			...formatDate(),
			...(filters.priceFrom && { "price[from]": filters.priceFrom }),
			...(filters.priceTo && { "price[to]": filters.priceTo }),
		};

		return data;
	};

	const getPackages = async (sort, reset) => {
		let data = formatfilter();
		if (sort || filters?.sort_by) {
			data = {
				...data,
				"sort[by]": sort?.sort_by ?? filters?.sort_by,
				"sort[type]": sort?.sort_type ?? filters?.sort_type,
			};
		}

		if (reset) {
			data = {};
		}

		const res = await fetchMarketPackagesSearch({ country_id: id, ...data });
		if (res.status === 200) {
			setPackages(res.data.data);
		}
	};

	const handleFilters = ({ name, value, multipleValues }) => {
		if (multipleValues) {
			setFilters((prev) => ({
				...prev,
				...multipleValues,
			}));
			return;
		}
		setFilters((prev) => ({
			...prev,
			[name]: value,
		}));
	};
	useEffect(() => {
		getPackages();
		return () => {};
	}, []);

	return (
		<div>
			<SearchBarPackages
				handleFilters={handleFilters}
				filters={filters}
				getPackages={getPackages}
				resetForm={resetForm}
			/>
			<div className="container">
				<div className="w-100  d-flex justify-content-end mt-4">
					<SortFilterPackage
						getPackages={getPackages}
						filters={filters}
						handleFilters={handleFilters}
					/>
				</div>
				<div className="packages-box">
					{packages.map((packageDetails) => (
						<CardPackage packageDetails={packageDetails} />
					))}
				</div>
			</div>
		</div>
	);
}
