import emailImg from "../../../assets/images/customRegister/mailbox.png";

import { useEffect, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { activeAccount } from "services/auth";
import Locale from "translations";
import { useSBSDispatch } from 'context/global';

function Activation() {
	const { login } = Locale;
	const { token, email } = useParams();
	const history = useHistory();
	const [show, setShow] = useState(false);
	const [counter, setCounter] = useState(null);
  const dispatch = useSBSDispatch();

	const [msg, setMsg] = useState(
		"Congratulations your account has been activated"
	);
	const activate = async () => {
		const response = await activeAccount({ email, token });
		if (response.status==200) {
			setCounter(10);
		// setMsg(response.data.message);
		setShow(true);
		}else if (response.status === 400 &&response.data.err_code === "AUTH-012") {
				
			dispatch({
				type: "updateUserInfoOtp",
				userInfoOtp: { ...response.data.data },
			});
				history.push("/auth/otp");

		}
		
	};

	useEffect(() => {
	
		if (show) {
			const timer =
				counter > 0
					? setInterval(() => setCounter(counter - 1), 1000)
					: history.push("/auth/login");
			return () => {
				clearInterval(timer);
			};
		}else{
			activate()
		}
	}, [show]);

	return (
		<div className="row bg-layout   align-items-center justify-content-center  m-0">
			<div className="col-md-6 mx-auto text-center py-4">
				<img
					src={emailImg}
					width="180"
					alt="email-verification"
					title="email-verification"
				/>
			
				
				{show ? (
					<>
					<p className="text-headline">{msg}</p>
				

					<Link to="/auth/login" className="btn bg-nxt mt-2 ">
						{counter} {login.backToLogin}
					</Link>
					</>
				) : (
					<p>
							<div className="lds-dual-ring"></div>
					</p>
				)}
			</div>
		</div>
	);
}

export default Activation;
