import { useSBSState } from "context/global";
import { useState } from "react";
import Locale from "translations";

import SelectField from "components/Form/SelectField/SelectField";
import {
	fetchCities,
	fetchTransferHotels,
	fetchTransferPorts,
} from "services/lookups";
export default function AutocompleteModel(props) {
	const INITIAL_DATA = {
		country: null,
		destination: null,
		hotel: null,
		terminal: null,
	};
	const { closeModal, onSubmit } = props;

	const { marketPlace, reservation, productElements, onlineVisa } = Locale;
	const [radioType, setRadioType] = useState("hotel");
	const [cities, setCities] = useState([]);
	const [hotels, setHotels] = useState([]);
	const [ports, setPorts] = useState([]);
	const [formData, setFormData] = useState(INITIAL_DATA);
	// const dispatch = useSBSDispatch();

	const { allCountries: countries } = useSBSState();

	const fetchCity = async (id) => {
		const cities = await fetchCities(id);
		setCities(cities);
	};

	const fetchHotels = async (id) => {
		const hotels = await fetchTransferHotels({ destination_id: id });
		const formatedHotes =
			Array.isArray(hotels) &&
			hotels?.map((hotel) => ({
				id: hotel.code,
				name: hotel.name,
			}));
		setHotels(formatedHotes);
	};
	const fetchPorts = async (id) => {
		const ports = await fetchTransferPorts({ country_id: id });

		setPorts(ports.data);
	};

	const handleChange = (e) => {
		const name = e.name;
		setFormData({
			...formData,
			[name]: e["value"], // countries[value],
		});
	};
	const accommodationType = radioType === "hotel";

	const submit = () => {
		const data = {
			value: accommodationType ? formData.hotel : formData.terminal,
			type: radioType,
		};

		onSubmit(data);
		closeModal();
	};

	return (
		<div className="payModal mx-5">
			<div className="col-md-12 d-flex justify-content-between">
				<div className="mx-1">
					<input
						className="mx-1"
						type="radio"
						name="hotel"
						id="hotelId"
						value="hotel"
						checked={accommodationType}
						onChange={(e) => {
							setRadioType(e.target.value);
							setFormData(INITIAL_DATA);
						}}
					/>
					<label className="text-center" htmlFor="hotelId">
						{reservation.messages.accommodation}
					</label>
				</div>
				<div className="mx-1">
					<input
						className="mx-1"
						type="radio"
						name="terminalOnly"
						id="terminalId"
						value="terminal"
						checked={radioType === "terminal"}
						onChange={(e) => {
							setRadioType(e.target.value);
							setFormData(INITIAL_DATA);
						}}
					/>
					<label className="text-center " htmlFor="terminalId">
						{reservation.messages.terminal}
					</label>
				</div>
			</div>

			<div className="col-md-12" style={{ position: "relative" }}>
				<SelectField
					name="country"
					label={productElements.country}
					placeholder={productElements.countryPlaceholder}
					options={countries}
					value={formData?.country?.label}
					onChange={(e) => {
						handleChange({ name: "country", value: e });
						accommodationType ? fetchCity(e.value) : fetchPorts(e.value);
					}}
				/>
			</div>

			{accommodationType ? (
				<>
					<div className="col-md-12" style={{ position: "relative" }}>
						<SelectField
							name="destination"
							label={onlineVisa.Destination}
							placeholder={onlineVisa.Destination}
							options={cities}
							disabled={!formData?.country}
							value={formData?.destination?.label}
							onChange={(e) => {
								handleChange({ name: "destination", value: e });
								fetchHotels(e.value);
							}}
						/>
					</div>
					<div className="col-md-12" style={{ position: "relative" }}>
						<SelectField
							name="hotel"
							label={productElements.hotel}
							placeholder={productElements.hotel}
							options={hotels}
							disabled={!formData?.destination}
							value={formData?.hotel?.label}
							onChange={(e) => {
								handleChange({ name: "hotel", value: e });
							}}
						/>
					</div>
				</>
			) : (
				<div className="col-md-12" style={{ position: "relative" }}>
					<SelectField
						name="terminal"
						label={reservation.messages.terminal}
						placeholder={reservation.messages.terminal}
						options={ports}
						disabled={!formData?.country}
						value={formData?.terminal?.label}
						onChange={(e) => {
							handleChange({ name: "terminal", value: e });
						}}
					/>
				</div>
			)}

			<div className="col-md-12">
				<div className="full-input search-button">
					<div className="full-input search-button">
						<button
							onClick={submit}
							disabled={!formData.hotel && !formData.terminal}
							className="btn w-100 bg-nxt mb-3 mt-3"
							type="button"
						>
							{marketPlace.messages.search}
						</button>
						{/* <Link className="form-control btn-primary text-center" to="/market-view">{marketPlace.messages.search}</Link> */}
					</div>
				</div>
			</div>
		</div>
	);
}
