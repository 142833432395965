import { useEffect, useRef, useState } from "react";
import { Modal } from "reactstrap";
import Locale from "translations";
import BannerInfo from "./BannerInfo";
import FlightItem from "./FlightItem";
import SideFilghtFilter from "./SideFilghFilter";
import SortFilghtFilter from "./SortFilter";
import SearchFilghtForm from "../../Search/FlightsForm/SearchFilghtForm";
import _ from "lodash";

import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import { store } from "react-notifications-component";
import { fetchFlights, getFlightsFiltersLookup } from "services/marketplace";
import { formatSearchData } from "../helpers/formatSearchData";
import Pagination from "components/Pagination";

export default function Outbound() {
	const { inventory, messages } = Locale;
	const { flightsSearchResults, flightsSearch } = useMarketplaceState();
	const previousValue = useRef({
		transferCount: [],
		stops: [],
		airLines: [],
		departureTime: {},
		arrivalTime: {},
		durationTime: {},
		sortBy: "price",
		sortType: "desc",
		page: 1,
	});

	const dispatch = useMarketplaceDispatch();
	const [isopenmodel, setisopenmodel] = useState(false);

	const [filtersLookup, setfiltersLookup] = useState({});


	const [filters, setFilters] = useState({
		transferCount: [],
		stops: [],
		airLines: [],
		departureTime: {},
		arrivalTime: {},
		durationTime: {},
		sortBy: "price",
		sortType: "desc",
		page: 1,
	});

	const modeltoggel = () => {
		setisopenmodel(!isopenmodel);
	};

	function goTo(page) {
		setFilters((prev) => ({ ...prev, page }));
	}

	const handleSearch = async (filters) => {
		let data = formatSearchData(flightsSearch, filters);
		const response = await fetchFlights(data, filters?.page);
		if (response.status === 200) {
			const data = response.data;

			if (response.data.data.length > 0) {
				dispatch({
					type: "flightsSearchResults",
					payload: data,
				});
			} else {
				store.addNotification({
					title: messages.noResults,
					message: messages.noSearchResults,
					type: "danger",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
				});
			}
		}
	};

	useEffect(() => {
		dispatch({
			type: "inboundFlightsSearchResults",
			payload: undefined,
		});
		if (flightsSearchResults === undefined) {
			handleSearch();
		}
	}, [flightsSearch]);

	useEffect(() => {
		if (flightsSearchResults && !_.isEqual(filters, previousValue.current)) {
			
			handleSearch(filters);
			previousValue.current = filters;
		}
	}, [filters]);

	useEffect(() => {
		async function fetchFiltersLookup() {
			const sideFilters = await getFlightsFiltersLookup(
				flightsSearch?.cacheKey
			);
			if (sideFilters?.status === 200) {
				setfiltersLookup(sideFilters?.data?.lookups);
			}
		}
		fetchFiltersLookup();
	}, [flightsSearch?.cacheKey]);

	return (
		<>
			<BannerInfo modeltoggel={modeltoggel} />
			<div className="container">
				<div className="row ">
					<div className="col-md-4 col-12">
						<SideFilghtFilter
							filtersLookup={filtersLookup}
							filters={filters}
							setFilters={setFilters}
						/>
					</div>
					<div className="col-md-8 col-12">
						<SortFilghtFilter
							title={inventory.messages.Outbound}
							filters={filters}
							setFilters={setFilters}
							flightCount={flightsSearchResults?.meta?.total}
						/>
						{flightsSearchResults?.data?.length > 0 &&
							flightsSearchResults?.data?.map((solution) =>
								solution.journeys.map((journey) => (
									<FlightItem
										journey={journey}
										solution={solution}
										searchResults={flightsSearchResults?.data}
									/>
								))
							)}
					</div>
				</div>
				<Pagination info={flightsSearchResults?.meta} goTo={goTo} />
			</div>
			<Modal isOpen={isopenmodel} toggle={modeltoggel}>
				<div className="d-flex align-items-center justify-content-between border-bottom mb-4 p-2 px-4 pt-0 w-100">
					<h3>Modify</h3>
					<div onClick={modeltoggel} className=" btn-x">
						<i class="fas fa-times"></i>
					</div>
				</div>
				<div className='flight-modify-container'>

				<SearchFilghtForm modify closeModal={modeltoggel} />
				</div>
			</Modal>
		</>
	);
}
