import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import Locale from "translations";
import Arrow from "assets/images/icon-arrow-horizontal.svg";
import walletImgSrc from "assets/images/inventory/walletSm.svg";
// import cancelIconSrc from 'assets/images/cancel-icon.svg';
import Pagination from "components/Pagination";
import { Link } from "react-router-dom";
import TourFilter from "./Filter";
import moment from "moment";
import { FetchToursListRequests } from "services/inventory";

export default function ToursRequests() {
	const { backOffice, inventory } = Locale;
	const [filters, setFilters] = useState({
		search: "",
		created_at: "",
		start_date: "",
		status: "",
	});
	const [toursList, setToursList] = useState([]);
	const [meta, setMeta] = useState();
	const [total, setTotal] = useState([]);
	const [searchStatus, setSearchStatus] = useState("");

	const statusLocale = (status, paymentStatus) => {
		switch (true) {
			case (status === "confirmed" && paymentStatus === "pending"):
				return inventory.messages.waitingForPayment;
			case (status === "confirmed" && paymentStatus === "confirmed"):
				return inventory.messages.confirmed;
			case (status === "canceled"):
				return inventory.messages.cancelled;
			case (status === "expired"):
				return inventory.messages.expired;
			default:
				return status;
		}
	};

	useEffect(() => {
		// replace it with useffect to get the transfer data from api
		async function fetchTours() {
			const res = await FetchToursListRequests({
				...filters,
				created_at: filters.created_at
					? filters.created_at.format("YYYY-MM-DD")
					: "",
				start_date: filters.start_date
					? filters.start_date.format("YYYY-MM-DD")
					: "",
				status: filters.status.title,
			});

			setToursList(res?.data?.data);
			setMeta(res?.data?.meta);
			setTotal(res?.data?.data?.length);
		}
		fetchTours();
	}, [searchStatus, filters?.page]);

	const goTo = (page) => {
		setFilters({ ...filters, page: page });
	};
	const search = () => {
		setSearchStatus(!searchStatus);
	};

	// const sumPax = (reservation) => {
	//   return reservation.reduce(function (accumulator, currentValue) {
	//     return accumulator + currentValue?.adults;
	//   }, 0);
	// }
	// const fetchToursDate = (reservation) => {
	//   let x = reservation?.map((item, index) => (item?.start_date?.slice(0, 10)));
	//   return x?.map(item => (<p>{item}</p>))
	// }
	// const fetchCountry = (reservation) => {
	//   let x = reservation?.map((item, index) => item?.details?.country?.name);
	//   return x?.map(item => (<p>{item}</p>))
	// }
	return (
		<div className="transfer-container bg-white">
			<div className="product-header-list p-4 inventory-header-totals">
				<h3 className="font-weight-bold text-capitalize">
					{inventory.messages.toursBookings} ({total})
				</h3>
			</div>
			<TourFilter filters={filters} setFilters={setFilters} search={search} />
			<div className="table-container">
				<Table
					striped
					className="table-update p-2 bg-white table table-striped"
					style={{ verticalAlign: "center" }}
				>
					<thead>
						<tr>
							<th>{inventory.messages.refNumber}</th>
							<th>{inventory.messages.leadName}</th>
							<th>{inventory.messages.confirmationDate}</th>
							<th>{inventory.messages.freeCancellationDate}</th>
							<th>{inventory.messages.autoCancellation}</th>
							<th>{inventory.messages.TotalPrice}</th>
							<th>{inventory.messages.actions}</th>
							<th>{inventory.messages.status}</th>
						</tr>
					</thead>
					<tbody>
						{/* transfer list */}
						{toursList?.length > 0 ? (
							toursList?.map((tourItem) => {
								return (
									<tr key={tourItem?.brn}>
										<td>{tourItem.brn}</td>
										<td>{tourItem?.leader_name}</td>
										<td>
											{moment(tourItem?.confirmation_date).format("DD-MM-YYYY")}
										</td>
										<td>
											{tourItem?.free_cancel_date
												? moment(tourItem?.free_cancel_date).format(
													"DD-MM-YYYY"
												)
												: "-"}
										</td>
										<td>
											{tourItem?.auto_cancel_date
												? moment(tourItem?.auto_cancel_date).format(
													"DD-MM-YYYY"
												)
												: "-"}
										</td>
										<td>
										{tourItem?.total_price} {tourItem?.currency}
										</td>
										{/* actions */}
										<td>
											{(tourItem?.payment_method === "pay_later" || tourItem?.payment_method === "pay later") &&
												tourItem.status !== "canceled" &&
												tourItem.status !== "expired" ? (
												<Link
													to={`/inventory/tour/reservation/${tourItem?.id}`}
													className="transfer-actions pay-now"
												>
													<img
														src={walletImgSrc}
														alt="wallet"
														className="mx-1"
													/>
													{inventory.messages.payNow}
												</Link>
											) : (
												"-"
											)}
										</td>
										{/* status */}
										<td>
											<p
												class={`text-white text-capitalize p-1 rounded text-center 
												${tourItem?.status === "confirmed" && tourItem?.payment_status === "confirmed" ? "bg-success"
													: tourItem?.status === "confirmed" && tourItem?.payment_status === "pending" ? " pending"
														: tourItem?.status === "canceled" || tourItem?.status === "expired" ? "bg-danger" : ""
													}`}
											>
												{statusLocale(tourItem?.status, tourItem?.payment_status)}
											</p>
										</td>
										{/* go to reservation detalis */}
										<td>
											<Link
												to={`/inventory/tour/reservation/${tourItem?.id}`}
												className="transfer-arrow"
											>
												<img src={Arrow} alt="arrow" />
											</Link>
										</td>
									</tr>
								);
							})
						) : (
							<tr>
								<td colSpan="10">
									<div className="product-build__product-no-data fullHeight">
										<i className="icx icx-eye-slash" aria-hidden="true"></i>
										<h4>{backOffice.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
			{toursList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}
