import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getApplicationHistory } from "services/productbuilder";

export default function History() {
	const [applicationHistory, setApplicationHistory] = useState([]);
	const { id } = useParams();

	useEffect(() => {
		async function getProductHistory() {
			const appHistory = await getApplicationHistory(id);
			if (appHistory?.status >= 200 && appHistory?.status < 300) {
				setApplicationHistory(appHistory?.data?.data);
			}
		}
		getProductHistory();
	}, []);
	console.log(applicationHistory);

	return (
		<>
			<div className=" px-4">
				{applicationHistory.length > 0
					? applicationHistory.map((res) => {
							return (
								<div className="mb-3">
									<div className="head">
										<p>
											<i class="fas fa-info-circle"></i>
											<span className='mx-1'>{res.action}</span>
										</p>
										<p>
											{" "}
											{moment(res.created_at).format("YYYY-MM-DD | hh:mm")}
										</p>
									</div>
									<span className="descrip">{res.user_name} {" "} {res.action}  {res.attribute} ( {res.value} )</span>
								</div>
							);
					  })
					: ""}

				{/* <div className='mb-3'>
                <div className="head">
                  <p>
                  <i class="fas fa-info-circle"></i> 
                     Delete Passport</p>
                  <p>10-Dec-17 | 20:00</p>
                </div>
                <span className='descrip'
                >Hesham Delete Passport (Test)
                </span>
              </div> */}
			</div>
		</>
	);
}
