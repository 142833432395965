import dateExpireImage from "assets/images/datePicker.svg";
import walletImage from "assets/images/newWallat.svg";
import SelectField from 'components/Form/SelectField/SelectField';
import { useMarketplaceDispatch } from "context/marketplace";
import validate, { isFormValid } from "helpers/validate";
import moment from "moment";
import { useEffect, useState } from 'react';
import { store } from 'react-notifications-component';
import { useHistory } from "react-router-dom";
import { TabPane } from "reactstrap";
import { paymentTransportation, transportationsLookups } from "services/transportation";
import Locale from "translations";
import NewPaymentAction from 'components/NewPaymentAction';
import { useSBSState } from 'context/global';


function TransportationSearch({ isMarketSearch = true, toggleModel }) {
	const { currency } = useSBSState();
	const dispatch = useMarketplaceDispatch();
	const history = useHistory();

	const { marketPlace, messages, onlineVisa, productElements, inventory } = Locale;
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [destinationLookups, setDestinationLookups] = useState([]);
	const [isSearchDone, setIsSearchDone] = useState(false);
	const [ModalPayment, setModalPayment] = useState(false);
	const [cycleTypes, setCycleTypes] = useState([]);
	const [residence, setResidence] = useState([]);
	const [vehicleTypes, setVehicleTypes] = useState([]);
	const [vehicleModels, setVehicleModels] = useState([]);
	const [seatsCount, setSeatsCount] = useState([]);
	const [transportationsData, setTransportationsData] = useState({
		destination: null,
		cycle_type: null,
		vehicle_type: null,
		seats: null,
		vehicle_model: null,
		vehicles_count: "",
		include_ground: 0,
		residence: null,
	});

	const [actionType, setActionType] = useState('');
	const [formData, setFormData] = useState({});
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK ? JSON.parse(process.env.REACT_APP_OTP_CHECK) : false


	const toggleModelPayment = () => {
		setActionType('')
		setFormData({})
		setModalPayment(!ModalPayment);
	};

	useEffect(() => {
		const fetchDestinations = async () => {
			const response = await transportationsLookups();
			if (response.status === 200) {
				const formatDestination = response?.data?.data.destinations.map((item) => ({
					id: item?.country_code,
					name: item?.country,
					...item,
				}));
				setDestinationLookups(formatDestination);
			}
		};
		fetchDestinations();
	}, []);



	const checkFormErrors = () => {
		let SubmitError = {};
		Object.keys(transportationsData).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: transportationsData[key], },
					{ required: key !== 'include_ground' && key !== 'residence' }
				),
			};
		});
		setErrors(() => SubmitError);
	};
	const submit = async () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function payTransportation(paymentType, currency, otp) {
		const data = {
			destination_id: transportationsData?.destination?.id,
			cycle_type_id: transportationsData?.cycle_type?.id,
			vehicle_type_id: transportationsData?.vehicle_type?.id,
			seats_count: transportationsData?.seats?.label,
			vehicle_model_id: transportationsData?.vehicle_model?.id,
			vehicle_count: transportationsData?.vehicles_count,
			include_ground: +transportationsData?.include_ground,
			currency: currency,
			payment_method: paymentType,
			otp: otp
		}

		const response = await paymentTransportation(data);
		if (response.status === 200) {
			history.push({ pathname: "/inventory/transportations" });
			dispatch({
				type: "onlineVisaSearch",
				payload: [],
			});
			toggleModelPayment();
		} else if (response?.status === 202) {
			setActionType(response?.data?.actionType)
			setFormData(response?.data?.data?.formData)

		} else if (response?.status === 202) {
			setActionType(response?.data?.actionType)
			setFormData(response?.data?.data?.formData)
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			// send request to api to get available ground
			const handleSearch = async () => {
				const results = residence?.filter(residence => residence?.cycle_type?.id === transportationsData?.cycle_type?.id
					&& residence?.vehicle_type?.id === transportationsData?.vehicle_type?.id
					&& residence?.vehicle_model?.id === transportationsData?.vehicle_model?.id
					&& +residence?.include_ground === +transportationsData?.include_ground)
				if (results.length > 0) {
					dispatch({ type: "transportationSearch", payload: transportationsData })
					if (!isSearchDone) {
						setTransportationsData({
							...transportationsData,
							residence: results[0],
						})
						setIsSearchDone(true);
					} else {
						setModalPayment(true);
					}
				} else {
					store.addNotification({
						title: messages.noResults,
						message: messages.noSearchResults,
						type: "info",
						insert: "top",
						container: "top-right",
						animationIn: ["animated", "fadeIn"],
						animationOut: ["animated", "fadeOut"],
						dismiss: { duration: 3000, onScreen: true, pauseOnHover: true, },
					});
				}
			}
			handleSearch();
		} else {
			setIsSearchDone(false);
			setModalPayment(false);
		}
	}, [isErrorLoaded]);

	function getUniqueListBy(arr, key) {
		return [...new Map(arr.map(item => [item[key], item])).values()]
	}
	function resetSearchResults() {
		setIsSearchDone(false);
	}

	function filterVehicleLookups(cycleId, vehicleTypeId, vehicleModelId) {
		const cycleTypes = transportationsData?.destination?.cycle_types;
		const residences = cycleTypes?.filter(cycleType => cycleType?.id === cycleId).map(({ residences }) => residences)[0];
		const vehicleTypesLookups = getUniqueListBy(residences?.map(({ vehicle_type }) => vehicle_type), 'id');
		const vehicleModelsLookups = vehicleTypeId && getUniqueListBy(
			residences?.filter(residence => residence?.vehicle_type?.id === vehicleTypeId).map(({ vehicle_model }) => vehicle_model), 'id'
		);
		const seatsCount = residences?.filter(residence => residence?.vehicle_model?.id === vehicleModelId).map(({ seats_count }) => ({ id: seats_count, name: seats_count }));
		setVehicleTypes(vehicleTypesLookups);
		vehicleTypeId && setVehicleModels(vehicleModelsLookups);
		vehicleModelId && setSeatsCount(seatsCount);
		vehicleModelId && console.log();
	}

	return (
		<>
			<TabPane tabId="1">
				<div className="col-md-10 HotelSearch m-auto pb-3">
					<div className={`row px-2 `}>
						<div className="col-md-6 col-12">
							<SelectField
								hasLabel={true}
								label={marketPlace.messages.destination}
								errors={errors?.destination}
								color={errors?.destination?.required ? "danger" : ""}
								options={destinationLookups}
								value={transportationsData?.destination?.label}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData,
										destination: e,
										cycle_type: null,
										vehicle_model: null,
										vehicle_type: null,
										vehicles_count: null,
										seats: null,
										residence: null,
									});
									setCycleTypes(getUniqueListBy(e.cycle_types, 'id'));
									resetSearchResults();
									setErrors({
										...errors,
										...validate(
											{ name: "destination", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* cycle type */}
						<div className="col-md-6 col-12 my-1">
							<SelectField
								hasLabel={true}
								label={productElements.cycleType}
								placeholder={productElements.cycleType}
								errors={errors?.cycle_type}
								color={errors?.cycle_type?.required ? "danger" : ""}
								options={cycleTypes}
								value={transportationsData?.cycle_type?.label || transportationsData?.cycle_type?.name}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData, cycle_type: e,
										vehicle_type: null,
										vehicle_model: null,
										seats: null,
									});
									filterVehicleLookups(e.id, null, null);
									setResidence(e.residences)
									resetSearchResults();
									setErrors({
										...errors,
										...validate(
											{ name: "cycle_type", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* vehicle_type */}
						<div className="col-md-6 col-12 my-1">
							<SelectField
								hasLabel={true}
								label={productElements.vehicleType}
								placeholder={productElements.vehicleType}
								errors={errors?.vehicle_type}
								color={errors?.vehicle_type?.required ? "danger" : ""}
								options={vehicleTypes}
								value={transportationsData?.vehicle_type?.label || transportationsData?.vehicle_type?.name}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData, vehicle_type: e,
										vehicle_model: null,
										seats: null,
									});
									filterVehicleLookups(transportationsData?.cycle_type?.id, e.id, null);
									resetSearchResults();
									setErrors({
										...errors,
										...validate(
											{ name: "vehicle_type", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* vehicles Model */}
						<div className="col-md-6 col-12 my-1">
							<SelectField
								hasLabel={true}
								label={productElements.model}
								placeholder={productElements.model}
								errors={errors?.vehicle_model}
								color={errors?.vehicle_model?.required ? "danger" : ""}
								options={vehicleModels}
								value={transportationsData?.vehicle_model?.label || transportationsData?.vehicle_model?.name}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData,
										vehicle_model: e,
										seats: null,
									});
									filterVehicleLookups(transportationsData?.cycle_type?.id, transportationsData?.vehicle_type?.id, e.id)
									resetSearchResults();
									setErrors({
										...errors,
										...validate(
											{ name: "vehicle_model", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>
						{/* seats */}
						<div className="col-md-6 col-12 my-1">
							<SelectField
								hasLabel={true}
								label={productElements.seats}
								placeholder={productElements.seats}
								errors={errors?.seats}
								color={errors?.seats?.required ? "danger" : ""}
								options={seatsCount}
								value={transportationsData?.seats?.label || transportationsData?.seats?.name}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData,
										seats: e,
									});
									resetSearchResults();
									setErrors({
										...errors,
										...validate(
											{ name: "seats", value: e.id },
											{ required: true, }
										),
									});
								}}
							/>
						</div>

						{/* vehicles_count */}
						<div className="col-md-6 col-12 my-1">
							<SelectField
								hasLabel={true}
								options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((vehiclesCount) => { return { id: vehiclesCount, name: vehiclesCount, label: vehiclesCount } })}
								placeholder={productElements.vehiclesCount}
								label={productElements.vehiclesCount}
								name="vehicles_count"
								value={transportationsData.vehicles_count}
								onChange={(e) => {
									setTransportationsData({
										...transportationsData,
										vehicles_count: e.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "vehicles_count", value: e.value },
											{ required: true, }
										),
									});
								}}
								errors={errors?.vehicles_count}
								color={errors?.vehicles_count?.required ? "danger" : ""}
							/>
						</div>
						<div className="col-md-6 col-12 my-1">
							<div className="custom-control custom-checkbox">
								<input
									className="custom-control-input"
									id="groundservice-checkbox"
									name="groundservice"
									type="checkbox"
									value={transportationsData?.include_ground}
									onChange={(e) => {
										setTransportationsData({
											...transportationsData,
											include_ground: e.target.checked,
										})
										resetSearchResults();
									}}

								/>
								<label
									className="custom-control-label pointer user-select-none"
									htmlFor="groundservice-checkbox"
								>
									include Ground Service
								</label>
							</div>
						</div>
						{/* expireDate: moment(new Date()).add(transportationsData?.residence?.expiration_days, "d"), */}

						{/* search result */}
						{isSearchDone ?
							<div className="col-md-10 d-flex justify-content-between align-items-center mt-3 mb-2 visa_search_result">
								<div className="d-flex align-items-center">
									<img src={dateExpireImage} alt="" width="28px" className="mr-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.requestExpireDate}
										<span>{moment(new Date()).add(transportationsData?.residence?.expiration_days, "d").format("DD-MM-YYYY")}</span>
									</p>
								</div>
								<div className="d-flex align-items-center">
									<img src={walletImage} alt="" width="28px" className="mx-3" />
									<p className="d-flex flex-column font-weight-normal txt-blue">
										{marketPlace.messages.totalPrice}
										{transportationsData?.include_ground ?
											<p className="font-weight-normal small" style={{ color: "#000" }}>(include Ground Service)</p>
											: null
										}
										<span>
											{transportationsData?.residence?.currency} {" "}
											{(transportationsData?.vehicles_count * transportationsData?.residence?.price).toFixed(2)}
										</span>
									</p>
								</div>
							</div>

							:
							null
						}

						<div className="col-12">
							<button className="btn w-100 bg-nxt mb-3 mt-3 px-0" type="button" onClick={submit}>
								<p className="d-flex align-items-center justify-content-center">
									{isSearchDone ? inventory.messages.pay : onlineVisa.search}
								</p>
							</button>
						</div>
					</div>
				</div>
			</TabPane>

			{ModalPayment && (
				<NewPaymentAction
					isOpen={ModalPayment}
					toggleModal={toggleModelPayment}
					OTP={OTP_CHECK}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={transportationsData?.vehicles_count * transportationsData?.residence?.price}
					currency={transportationsData?.residence?.currency}
					secondCurrency={currency}
					onPayment={payTransportation}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
		</>
	);
}

export default TransportationSearch;
