import { useEffect, useState } from "react";
import { TabPane } from "reactstrap";

import Locale from "translations";

import {
	changeRequestStatus,
	requestViewReservation,
	requestViewReservationB2b,
} from "services/productbuilder";
import { useHistory, useParams } from "react-router-dom";
import SummaryTab from "./SummaryTab";
import Listpax from "./paxDetails/ListPax";
import Listpayemnts from "./payments/ListPayments";
import GuestsBox from "./GuestsBox";
import ColLogs from "./CallLogs";
import History from "./History";
import ItineraryRequest from "./ItineraryRequest";
import PassangerModal from "./paxDetails/passangerModal";
import PaymentsModal from "./payments/paymentsModal";
import { store } from "react-notifications-component";
import { useSBSState } from 'context/global';

export default function B2brequestview() {
	const { payment, inventory } = Locale;
	const { allLookupsBuilder } = useSBSState();
	const history = useHistory();
	const { id } = useParams();
	const [activeTap, setActiveTap] = useState(1);
	const [activeTapSide, setActiveTapSide] = useState(1);
	const [reservationDetails, setReservationDetails] = useState(null);
	const [totalPrice, setTotalPrice] = useState();

	const reservationType = reservationDetails?.reservation_type;
	const [guests, setGuests] = useState(null);
	const [modalPass, setmodalPass] = useState({
		isOpen: false,
		title: "add Passport",
	});
	const [spasificPassenger, setSpasificPassenger] = useState({});
	const [Reload, setReload] = useState(false);
	const togglepass = (type = null, passanger) => {
		setmodalPass({ isOpen: !modalPass.isOpen, title: type });
		setSpasificPassenger(passanger);
	};
	const [modalPax, setmodalPax] = useState({
		isOpen: false,
		title: "add Payments",
	});
	const togglepax = () => {
		setmodalPax({ isOpen: !modalPax.isOpen });
	};

	async function getViewReservation() {
		// to can catch change Request id guest 
		setGuests(null)
		const viewReservationRes = await requestViewReservationB2b(id);
		if (viewReservationRes?.status >= 200 && viewReservationRes?.status < 300) {
			let adults = [];
			let children = [];
			const guestsList =
				viewReservationRes?.data?.data?.roomAvailabilities?.map((room) => {
					children = [...Array(+room?.children_count).keys()].map(() => {
						return {
							...room,
							type: { value: 2, name: "Child", id: 2 },
							price: room?.children_price,
						};
					});

					adults = [...Array(+room?.adults_count).keys()].map(() => {
						return {
							...room,
							type: { value: 1, name: "Adult", id: 1 },
							price: room?.adults_price,
						};
					});
					return [...adults, ...children];
				});
			setReservationDetails(viewReservationRes?.data?.data);
			setGuests(guestsList?.flat());
			setTotalPrice(viewReservationRes?.data?.data?.total_price);
		} else {
			// history.push('/products-builder/requests');
		}
	}

	useEffect(() => {
		if (id) {
			getViewReservation();
		} else {
			// history.push('/products-builder/requests');
		}
	}, [id, Reload]);

	console.log("reservationDetails1010",reservationDetails);
	async function changePackageStatus(status) {
		const Data = {
			reservation_num: reservationDetails?.reservation_num,
			status: status,
		};
		const res = await changeRequestStatus(Data);

		if (res?.status === 200 || res?.status === 201) {
			store.addNotification({
				title: "info!",
				message: res?.massage,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					pauseOnHover: true,
				},
			});
			getViewReservation();
		}
	}

	return (
		<div className="container">
			<div className="row">
				<div className="col-md-8 col-12">
					<div>
						<div className="requestTaps">
							<TabPane
								className={`tap ${activeTap === 1 && `active`}`}
								onClick={() => {
									setActiveTap(1);
								}}
								tabId={1}
							>
								{payment.messages.summardetails}
							</TabPane>
							<TabPane
								className={`tap ${activeTap === 2 && `active`}`}
								onClick={() => {
									setActiveTap(2);
								}}
								tabId={2}
							>
								{payment.messages.paxdetails}
							</TabPane>
							{reservationDetails?.reservation_from === "online" &&
							reservationDetails?.reservation_type === "auto" ? null:(
								<TabPane
									className={`tap ${activeTap === 3 && `active`}`}
									onClick={() => {
										setActiveTap(3);
									}}
									tabId={3}
								>
									{payment.messages.Payments}
								</TabPane>
							) }
						</div>
						{activeTap === 1 && reservationDetails && (
							<SummaryTab reservationDetails={reservationDetails} />
						)}
						{activeTap === 2 && (
							<Listpax
								togglepass={togglepass}
								setReload={setReload}
								Reload={Reload}
								reservationDetails={reservationDetails}
							/>
						)}
						{activeTap === 3 && (
							<Listpayemnts
								toggle={togglepax}
								setReload={setReload}
								Reload={Reload}
								reservationDetails={reservationDetails}
							/>
						)}
					</div>
					{reservationDetails ? (
						<ItineraryRequest reservationDetails={reservationDetails} />
					) : null}
					{guests && Object.keys(allLookupsBuilder).length > 0 ? (
						<GuestsBox
							reservationDetails={reservationDetails}
							guests={guests}
							setGuests={setGuests}
							activeTap={activeTap}
							setTotalPrice={setTotalPrice}
							getViewReservation={getViewReservation}
							allLookupsBuilder={allLookupsBuilder}
						/>
					) : null}
					{/* accept or reject application */}

					<div className="d-flex justify-content-between align-items-center my-4">
						<div style={{ fontSize: "18px" }}>
							<p>{inventory.messages.TotalPackagePrice}</p>
							<p style={{ color: "#10A711" }}>
								<strong style={{ fontWeight: "bold" }}>
									{reservationDetails?.total_price} {reservationDetails?.currency}
								</strong>
								{/* / {reservationDetails?.nights} {inventory.messages.nights} */}
							</p>
						</div>
						{reservationType === "manual" &&
						reservationDetails?.reservation_status === "pending" &&
						reservationDetails?.reservation_from === "online" ? (
							<div>
								<button
									className="btn btn-guests me-2"
									style={{ color: "#fff", backgroundColor: "#C30101" }}
									onClick={() => changePackageStatus("reject")}
									// disabled
								>
									{inventory.messages.reject}
								</button>
								<button
									style={{ color: "#fff", backgroundColor: "#027B30" }}
									className="btn btn-guests"
									onClick={() => changePackageStatus("accept")}
									// disabled
								>
									{inventory.messages.accept}
								</button>
							</div>
						) : null}
					</div>
				</div>
				{/* calls log and history tabs */}
				<div className="col-md-4 col-12 ">
					<div className="side-col">
						<div className="requestTaps">
							<TabPane
								className={`tap ${activeTapSide === 1 && `active`}`}
								onClick={() => {
									setActiveTapSide(1);
								}}
								tabId={1}
							>
								{inventory.messages.CallsLog}
							</TabPane>
							<TabPane
								className={`tap ${activeTapSide === 2 && `active`}`}
								onClick={() => {
									setActiveTapSide(2);
								}}
								tabId={2}
							>
								{inventory.messages.History}
							</TabPane>
						</div>
						{activeTapSide === 1 && reservationDetails && (
							<ColLogs
								reservationDetails={reservationDetails}
								getViewReservation={getViewReservation}
							/>
						)}
						{activeTapSide === 2 && <History />}
					</div>
				</div>
			</div>
			{/* add passport modal */}
			<PassangerModal
				modalPass={modalPass}
				toggle={togglepass}
				setReload={setReload}
				Reload={Reload}
				reservationDetails={reservationDetails}
				spasificPassenger={spasificPassenger}
			/>
			{/* add payments modal */}
			<PaymentsModal
				modal={modalPax}
				toggle={togglepax}
				setReload={setReload}
				Reload={Reload}
				reservationDetails={reservationDetails}
			/>
		</div>
	);
}
