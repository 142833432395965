import PasswordField from 'components/Form/PasswordField/PasswordField';
import TextField from 'components/Form/TextField/TextField';
import { useAuthDispatch, useAuthState } from 'context/auth';
import validate, { isFormValid } from 'helpers/validate';
import React, { useEffect, useRef, useState } from 'react'
import Locale from 'translations';
import imgIcon from "assets/images/customRegister/image-regular.svg";
import { store } from 'react-notifications-component';
import { emailAvailability, fetchImages, nameAvailability, uploadFile } from 'services/auth';
import { Spinner } from 'reactstrap';
import { fetchCities } from 'services/lookups';
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';


export default function SafaUserDetails({ setSpin, spin, safaCompanyDetails }) {
  const { userDetails, login, companyAvatar, commons, companyDetails } = Locale;
  const { locale } = useSBSState();
  const dispatch = useAuthDispatch();
  const safaRegister = useAuthState();
  const componyLogoRef = useRef(null);
  const { companyCountry, companyName, companyPhone } = safaCompanyDetails;
  console.log(safaCompanyDetails);

  const [userDetailsState, setUserDetailsState] = useState({
    name: safaRegister?.safaUserDetails?.name ?? "",
    companyName: safaRegister?.safaUserDetails?.companyName ?? companyName,
    companyPhone: safaRegister?.safaUserDetails?.companyPhone ?? companyPhone,
    email: safaRegister?.safaUserDetails?.email ?? "",
    password: safaRegister?.safaUserDetails?.password ?? "",
    passwordConfirmation: safaRegister?.safaUserDetails?.passwordConfirmation ?? "",
    country: safaRegister?.safaUserDetails?.country ?? companyCountry,
    city: safaRegister?.safaUserDetails?.city ?? null,
    compnayLogo: safaRegister?.safaUserDetails?.compnayLogo ?? null,
    compnayLogoPreview: safaRegister?.safaUserDetails?.compnayLogoPreview ?? null,
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [cities, setCities] = useState([])
  const isPasswordMatch = userDetailsState.passwordConfirmation === userDetailsState.password;
  const maxCompnayLogoSize = 500000 // 500 KB

  // upload company logo if pass validation
  async function onFilechange(e) {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    formData.append("name", file?.name);
    formData.append("bucket", "companiesLogo");
    if (!file.name.match(/.(jpg|jpeg|png|gif|svg)$/i)) {
      store.addNotification({
        title: commons.somethingWentWrong,
        message: companyAvatar.companyAvatarExtensionValidation,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    } else if (file.size > maxCompnayLogoSize) {
      store.addNotification({
        title: commons.somethingWentWrong,
        message: companyAvatar.companyAvatarSizeValidation,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    } else {
      const uploadFileRes = await uploadFile(formData);
      if (uploadFileRes.status === 200) {
        const fetchImage = await fetchImages(uploadFileRes.data.data.uuid);
        setUserDetailsState({
          ...userDetailsState,
          compnayLogo: uploadFileRes.data.data.uuid,
          compnayLogoPreview: fetchImage,
        });
        dispatch({ type: "Safa_User_Details", payload: { ...userDetailsState } })
      }
    }
  }
  // check form validation erros
  function checkFormErrors() {
    let submitError = {};
    Object.keys(userDetailsState).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: key !== "passwordConfirmation" ? userDetailsState[key] : isPasswordMatch },
          {
            required: key !== "compnayLogo" && key !== "compnayLogoPreview",
            email: key === "email",
            password: key === "password",
            min: key === "password" ? 8 : key === "name" || key === "companyName" ? 3 : "",
            confirm: key === "passwordConfirmation"
          }
        )
      }
    })
    setErrors(() => submitError)
  }

  // validate email and company name Availability
  async function checkEmailCompanyNameAvailability() {
    const emailData = { user_email: userDetailsState.email };
    const companyData = {
      lang: locale,
      name: userDetailsState?.companyName,
      country_id: userDetailsState?.country?.id,
    }
    const responses = await Promise.all([emailAvailability(emailData), nameAvailability(companyData)]);
    if (responses.every(response => response?.status === 200)) {
      dispatch({ type: "Safa_User_Details", payload: { ...userDetailsState } })
      dispatch({ type: "Safa_Progress", progress: 100, step: 3, lastStep: 2 });
    }
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      checkEmailCompanyNameAvailability();
    }
    return () => { }
  }, [isErrorLoaded]);


  // fetch city based on selected safa visa country
  useEffect(() => {
    const fetchCity = async (id) => {
      const cities = await fetchCities(id);
      setCities(cities);
    };
    if (companyCountry) {
      fetchCity(companyCountry?.id)
    }
  }, [companyCountry])

  // save enterd data when click on back
  function back() {
    dispatch({ type: "Safa_User_Details", payload: { ...userDetailsState } })
    dispatch({ type: "Safa_Progress", progress: 0, step: 1, lastStep: 1 });
  }



  return (
    <div>
      <div className="row">
        <h2 className='text-title text-orange my-2'>{userDetails.mainTitle}</h2>

        {/* full name */}
        <div className='col-12'>
          <TextField
            type="text"
            label={userDetails.fullName}
            placeholder={userDetails.fullNamePlaceholder}
            value={userDetailsState.name}
            name="name"
            id="safa_register_fullname"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "name", value: e.target.value },
                  { required: true, min: 3 }
                ),
              });
              setUserDetailsState({
                ...userDetailsState,
                name: e.target.value,
              });
            }}
            min={3}
            color={errors?.name?.required || errors?.name?.min ? "danger" : ""}
            errors={errors?.name}
          />
        </div>
        {/* company name */}
        <div className='col-12'>
          <TextField
            type="text"
            label={companyDetails.companyName}
            placeholder={companyDetails.companyNamePlaceholder}
            value={userDetailsState.companyName}
            name="companyName"
            id="safa_register_companyname"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "companyName", value: e.target.value },
                  { required: true, min: 3 }),
              });
              setUserDetailsState({
                ...userDetailsState,
                companyName: e.target.value,
              });
            }}
            min={3}
            color={errors?.companyName?.required || errors?.companyName?.min ? "danger" : ""}
            errors={errors?.companyName}
          />
        </div>
        <div className='col-12'>
        <TextField
							label={userDetails.phoneNumber}
							value={userDetailsState.phone}
							name="companyPhone"
              min={3}
							placeholder={userDetails.phoneNumber}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: "companyPhone", value: e.target.value },
                    { required: true, min: 3 }),
                });
                setUserDetailsState({
                  ...userDetailsState,
                  companyPhone: e.target.value,
                });
							}}
							color={
								errors.companyPhone?.required || errors.companyPhone?.phone ? "danger" : ""
							}
							errors={errors.phone}
						/>
        </div>
        {/* email */}
        <div className='col-12'>
          <TextField
            label={login.emailAddress}
            placeholder={login.emailPlaceholder}
            value={userDetailsState.email}
            name="email"
            id="safa_register_email"
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "email", value: e.target.value },
                  { required: true, email: true }
                ),
              });
              setUserDetailsState({
                ...userDetailsState,
                email: e.target.value,
              });
            }}
            color={
              errors?.email?.required || errors?.email?.email
                ? "danger"
                : ""
            }
            errors={errors?.email}
          />
        </div>
        {/* password */}
        <div className='col-12'>
          <PasswordField
            placeholder={login.passwordPlaceholder}
            hasLabel={true}
            label={login.password}
            name="password"
            id="safa_register_password"
            value={userDetailsState.password}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "password", value: e.target.value },
                  { required: true, password: true, min: 8 }
                ),
              });
              setUserDetailsState({
                ...userDetailsState,
                password: e.target.value,
              });
            }}
            min={8}
            color={errors?.password?.required || errors?.password?.password || errors?.password?.min ? "danger" : ""}
            errors={errors?.password}
          />
        </div>
        {/* password confirmation */}
        <div className='col-12'>
          <PasswordField
            hasLabel={true}
            label={userDetails.repeatPassword}
            placeholder={userDetails.repeatPasswordPlaceholder}
            name="passwordConfirmation"
            id="safa_register_passwordConfirmation"
            value={userDetailsState.passwordConfirmation}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "passwordConfirmation", value: !isPasswordMatch },
                  { required: true, confirm: true }
                ),
              });
              setUserDetailsState({
                ...userDetailsState,
                passwordConfirmation: e.target.value,
              });
            }}
            min={8}
            color={
              errors?.passwordConfirmation?.required || errors?.passwordConfirmation?.confirm
                ? "danger"
                : ""
            }
            errors={errors?.passwordConfirmation}
          />
        </div>


        {/* Country */}
        <div className="col-md-6 pr-md-2 mt-2">
          <TextField
            label={companyDetails.country}
            value={companyCountry?.name}
            placeholder={companyDetails.countryPlaceholder}
            name="country"
            id="safa_register_country"
            disabled={true}
          />
        </div>

        {/* City */}
        <div className="col-md-6 pl-md-2 mt-2">
          <SelectField
            label={companyDetails.city}
            value={userDetailsState.city?.label}
            name="city"
            id="safa_register_city"
            options={cities}
            placeholder={companyDetails.cityPlaceholder}
            onChange={(e) => {
              setErrors({
                ...errors,
                ...validate(
                  { name: "city", value: e.value },
                  { required: true }
                ),
              });
              setUserDetailsState({
                ...userDetailsState,
                city: e,
              });
            }}
            onBlur={() =>
              setErrors({
                ...errors,
                ...validate(
                  { name: "city", value: userDetailsState.city },
                  { required: true }
                ),
              })
            }
            color={errors.city?.required ? "danger" : ""}
            errors={errors.city}
          />
        </div>
      </div>
      {/* company logo */}
      <div className='image_upload mt-3'>
        <h3 className='text-title text-orange my-2 new-fz'>{companyAvatar.title}</h3>
        <div className='d-flex align-items-center'>
          <div className=" my-2 sign-up-label">
            <input
              type="file"
              ref={componyLogoRef}
              onChange={onFilechange}
              className="d-none"
              accept="image/*"
            />
            <div className="image-uploader"
              onClick={() => componyLogoRef?.current?.click()}
            >
              <div className="image-uploader__outline position-relative bg-white img-center">
                <div className="img-holder ">
                  <img
                    src={
                      userDetailsState.compnayLogoPreview
                        ? userDetailsState.compnayLogoPreview
                        : imgIcon
                    }
                    className={`${userDetailsState.compnayLogo
                      ? "image-uploader__img"
                      : "img-fluid"
                      } `}
                    alt="logo placeholder"
                    accept="image/*"
                  />
                </div>

                <i className="fas fa-plus-circle image-uploader__plus-btn"></i>
              </div>
            </div>
          </div>
          <p className="text-bg-dark-blue mx-2 text-secondary">
            {companyAvatar.uploadLogoDesc}
          </p>
        </div>
      </div>
      {/* buttons */}
      <div className="d-flex align-items-center mt-3">
        <button className="btn bg-bk w-40 py-2" onClick={back}>
          {companyDetails.back}
        </button>
        <button
          className="btn bg-nxt w-60 mx-2 py-2 d-flex align-items-center justify-content-center"
          disabled={spin}
          onClick={submit}
        >
          {spin ? <Spinner color="light" size="sm" className='mx-2' /> : null}
          {userDetails.next}
        </button>
      </div>
    </div>
  )
}
