import walletImg from "assets/images/wallet/wallet2.svg";
import { useState } from "react";
import Locale from "translations";

function FlightBookingSummary({ reservation, togglePriceBreakdown }) {
	const { marketPlace, inventory } = Locale;

	const paxCount = reservation?.passengers?.reduce(
		(prev, current) => {
			if (current.psgType === "ADT") {
				prev.adults++;
			} else {
				prev.children++;
			}
			return prev;
		},
		{
			children: 0,
			adults: 0,
		}
	);
	const priceDetails = reservation?.price_details ?? {};
	const flightData = {
		...paxCount,
		...priceDetails,
		total_price: reservation?.total_price,
		currency: reservation?.currency,
		provider_currency: reservation?.provider_currency
	};

	const adultsChildrenCount = +flightData?.adults + +flightData?.children;
	// adults
	const fareForAllAdults = +flightData?.adtFare * +flightData?.adults || 0;
	const taxForAllAdults = +flightData?.adtTax * +flightData?.adults || 0;
	const adultsTotalPrice =
		fareForAllAdults + taxForAllAdults + +flightData?.tktFee || 0;
	// children
	const fareForAllChildren = +flightData?.chdFare * +flightData?.children || 0;
	const taxForAllChildren = +flightData?.chdTax * +flightData?.children || 0;
	const childrenTotalPrice =
		fareForAllChildren + taxForAllChildren + +flightData?.tktFee || 0;

	return (
		<>
			<div className="bg-white p-2 text-capitalize">
				<span
					className="text-right text-capitalize mb-2 pointer d-flex justify-content-end"
					onClick={togglePriceBreakdown}
					style={{
						color: "#D29D4D",
						fontSize: "small",
						fontWeight: "bold",
						display: "inline-block",
					}}
				>
					{marketPlace.Pricebreakdown}
				</span>
				{/* adults price summary */}
				<div className="adults-price-summary">
					<div className="d-flex justify-content-between price-color">
						<h5 className="bold">
							{flightData?.adults} X {inventory.messages.Adult}
						</h5>
						<h5 className="bold">
							{(fareForAllAdults + taxForAllAdults)?.toFixed(2)}{" "}
							{flightData?.currency}
						</h5>
					</div>
					<div className="d-flex justify-content-between ms-4 price-color">
						<div>
							<p>{inventory.messages.FarePerAdult}</p>
							<p>{inventory.messages.TaxesPerAdult}</p>
						</div>
						<div className='d-flex flex-column align-items-end'>
							<p>
								{flightData?.adtFare?.toFixed(2)} {flightData?.currency}
							</p>
							<p>
								{flightData?.adtTax?.toFixed(2)} {flightData?.currency}
							</p>
						</div>
					</div>
				</div>
				{/* child price summary */}
				{flightData?.children > 0 ? (
					<div className="children-price-summary my-2">
						<div className="d-flex justify-content-between price-color">
							<h5 className="bold">
								{flightData?.children} X {inventory.messages.Child}
							</h5>
							<h5 className="bold">
								{(fareForAllChildren + taxForAllChildren).toFixed(2)}{" "}
								{flightData?.currency}
							</h5>
						</div>
						<div className="d-flex justify-content-between ms-4 price-color">
							<div>
							<p>{inventory.messages.FarePerChild}</p>
							<p>{inventory.messages.TaxesPerChild}</p>
							</div>
							<div className='d-flex flex-column align-items-end'>
								<p>
									{flightData?.chdFare?.toFixed(2)} {flightData?.currency}
								</p>
								<p>
									{flightData?.chdTax?.toFixed(2)} {flightData?.currency}
								</p>
							</div>
						</div>
					</div>
				) : null}

				{/* tickets fees */}
				{/* <div className='d-flex justify-content-between price-color'>
          <h5 className='bold'>{adultsChildrenCount} X Ticketing Fee</h5>
          <h5 className='bold'>
            {(+flightData?.tktFee).toFixed(2)}
            {flightData?.currency}
          </h5>
        </div> */}
			</div>
			{/* total price and penalties */}
			<div className="d-flex mt-4 justify-content-end align-items-end">
				<div className="d-flex align-items-start">
					<img src={walletImg} alt="" />
					<div className="mx-2">
						<p style={{ fontSize: "14px", lineHeight: "1" }}>
							{marketPlace.total}
						</p>

						<span className="text-success font-weight-bold h5">
							{reservation?.currency == reservation?.provider_currency ? (
								<p
									style={{
										fontSize: "16px",
										color: "#28A745",
										fontWeight: "bold",
										lineHeight: "1",
									}}
								>
									{reservation?.total_price?.toFixed(2)} {reservation?.currency}{" "}
								</p>
							) : (
								<>
									<p
										style={{
											fontSize: "16px",
											color: "#28A745",
											fontWeight: "bold",
											lineHeight: "1",
										}}
									>
										{reservation?.total_price?.toFixed(2)}{" "}
										{reservation?.currency}{" "}
									</p>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
										{marketPlace.providerprice}
									</span>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
										{reservation?.provider_total_price}{" "}
										{reservation?.provider_currency}
									</span>
								</>
							)}
						</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default FlightBookingSummary;
