import PasswordField from 'components/Form/PasswordField/PasswordField';
import TextField from 'components/Form/TextField/TextField';
import { useAuthDispatch, useAuthState } from 'context/auth';
import validate, { isFormValid } from 'helpers/validate';
import React, { useEffect, useState } from 'react'
import { useHistory, Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { checkSafaVisaAccount } from 'services/auth';
import Locale from 'translations';

export default function SafaAccountDetails({ setSpin, spin, setSafaCompanyDetails }) {
  const { login, userDetails } = Locale;
  const dispatch = useAuthDispatch();
  const userSafaRegister = useAuthState();
  const history = useHistory();

  const [accountDetails, setAccountDetails] = useState({
    safa_key: userSafaRegister.safaAccountDetails?.safa_key ?? "",
    email: userSafaRegister.safaAccountDetails?.email ?? "",
    password: userSafaRegister?.safaAccountDetails?.password ?? "",
  });

  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const safaAccountConnectedCode = "AUTH-008";

  function checkFormErrors() {
    let submitError = {};
    Object.keys(accountDetails).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: accountDetails[key] },
          {
            required: true,
            email: key === "email",
          }
        )
      }
    })
    setErrors(() => submitError)
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }
  // login with safa key
  async function handleCheckSafaVisaAccount() {
    setSpin(true);
    const data = {
      safa_key: accountDetails?.safa_key,
      email: accountDetails?.email,
      password: accountDetails?.password,
    }
    const response = await checkSafaVisaAccount(data);
    if (response?.status === 200) {
      setSafaCompanyDetails({ companyCountry: response?.data?.country, companyName: response?.data?.company_name, companyPhone: response?.data?.company_phone });
      dispatch({ type: "Safa_Account_Details", payload: { ...accountDetails } })
      dispatch({ type: "Safa_Progress", progress: 50, step: 2, lastStep: 1 });
    } else if (response?.status === 400 && response?.data?.err_code === safaAccountConnectedCode) {
      history.push('/auth/login_safa');
    }
    setSpin(false);
  }


  useEffect(() => {
    if (isFormValid(errors)) {
      handleCheckSafaVisaAccount();
    }
    return () => { }
  }, [isErrorLoaded])


  // reset the progress bar to first step if no progress
  useEffect(() => {
    if (!userSafaRegister.progress) {
      dispatch({ type: "Progress", progress: 0, step: 1, lastStep: 1 });
    }
  }, []);


  return (
    <div className='login-with-safa mt-4'>
      <h2 className='text-title text-orange my-2'>{login.safaVisaAccountDetails}</h2>
      {/* safa key */}
      <TextField
        label={login.safa_key}
        placeholder={login.safaPlaceholder}
        name="safa_key"
        id="safa_key"
        value={accountDetails.safa_key}
        onChange={(e) => {
          setErrors({
            ...errors,
            ...validate(e.target, {
              required: true,
            }),
          });
          setAccountDetails({
            ...accountDetails,
            safa_key: e.target.value,
          });
        }}
        color={errors?.safa_key?.required ? "danger" : ""}
        errors={errors?.safa_key}
      />

      {/* username */}
      <TextField
        placeholder={login.usernamePlaceholder}
        label={login.username}
        value={accountDetails.email}
        name="safa_email"
        id="safa_email"
        onChange={(e) => {
          setErrors({
            ...errors,
            ...validate(e.target, {
              required: true,
              email: true,
            }),
          });
          setAccountDetails({
            ...accountDetails,
            email: e.target.value,
          });
        }}
        color={
          errors?.email?.required || errors?.email?.email
            ? "danger"
            : ""
        }
        errors={errors?.email}
      />

      {/* password */}
      <PasswordField
        placeholder={login.passwordPlaceholder}
        hasLabel={true}
        label={login.password}
        name="safa_password"
        id="safa_password"
        value={accountDetails.password}
        onChange={(e) => {
          setErrors({
            ...errors,
            ...validate(
              { name: "password", value: e.target.value },
              { required: true }
            ),
          });
          setAccountDetails({
            ...accountDetails,
            password: e.target.value,
          });
        }}
        color={errors?.password?.required ? "danger" : ""}
        errors={errors?.password}
      />


      <button
        className="btn bg-nxt w-100 my-3 d-flex align-items-center justify-content-center"
        disabled={spin}
        onClick={submit}
      >
        {spin ? <Spinner color="light" size="sm" className='mx-2' /> : null}
        {userDetails.next}
      </button>

      <Link to="/auth/login_safa" className="bg-back text-capitalize">
        <span className="icx icx-left-solid-arrow text-10"></span>{" "}
        {userDetails.backBtn}
      </Link>
    </div>
  )
}
