import moment from 'moment';
import { useEffect, useState } from "react";
import { requestsListReservationsB2b } from 'services/productbuilder';
import Locale from "translations";
import Filterrequestes from "./Filterrequestes";
import ListRequestes from './listRequestes';



function B2bRequestes() {
	const { payment } = Locale;
	const [requestsList, setRequestsList] = useState();
	const [filters, setFilters] = useState({
		trip_name: "",
		trip_type: "",
		departure_date: "",
		status: "",
	});
	const [meta, setMeta] = useState();
	// const [total, setTotal] = useState([]);

	async function getRequestsList(filters) {
		const params = {
			...filters,
			trip_type: filters?.trip_type?.id,
			departure_date:
				filters.departure_date !== ""
					? moment(filters.departure_date).format("YYYY-MM-DD")
					: "",
			status: filters?.status?.title,
		};
		const requestsListRes = await requestsListReservationsB2b(params);
		if (requestsListRes?.status >= 200 && requestsListRes?.status < 300) {
			setRequestsList(requestsListRes?.data?.data);
			setMeta(requestsListRes?.data?.meta);
			// setTotal();
		}
	}

	useEffect(() => {
		getRequestsList(filters);
	}, [filters]);

	const goTo = (page = 1) => {
		setFilters({ ...filters, page: page });
	};

	// add new offline application


	// get products list for new application



	return (
		<>
			<div className="container">
				<div className="d-flex justify-content-between align-items-center py-2 px-2 border bg-white">
					<p style={{ fontWeight: "bold" }} className="text-dark">{payment.messages.requests}({meta?.total})</p>
					
				</div>

				<Filterrequestes
					filters={filters}
					setFilters={setFilters}
					getRequestsList={getRequestsList}
				/>

				<ListRequestes requestsList={requestsList} meta={meta} goTo={goTo} />


			</div>
		</>
	);
}

export default B2bRequestes;
