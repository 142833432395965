import "@fortawesome/fontawesome-free/css/all.min.css";
import axios from "axios";
import ErrorModal from "components/ErrorModal";
import { useSBSDispatch, useSBSState } from "context/global";
import moment from "moment";
import "moment/locale/ar";
import { Suspense, useEffect } from "react";
import ReactNotification from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import { BrowserRouter as Router } from "react-router-dom";
import "scss/style.scss";
import Locale from "translations";
import ErrorBoundary from "./components/Errors/ErrorBoundary/ErrorBoundary";
import Loader from "./components/Loader";
import AxiosConfiguration from "./helpers/axiosConfiguration";
import Routes from "./routes";
import preLoginRoutes from "preLoginRoutes";
import DashboardLayout from "layouts/DashboardLayout";
import { fetchAllCurrencies } from 'services/lookups';

export default function App(props) {
	const { locale, loading } = useSBSState();
	const { isAuth } = useSBSState();
	const dispatch = useSBSDispatch();

	// const allCountries = useFetchCountries();
	Locale.setLanguage(locale);
	document
		.getElementsByTagName("html")[0]
		.setAttribute("dir", locale === "ar" ? "rtl" : "ltr");
	moment.locale("en");
	// Axios Configurations

	useEffect(() => {
		if (isAuth) {
			async function get() {
				await axios
					.get(
						`${process.env.REACT_APP_LOOKUPS_ENDPOINT}/geolocation/countries`
					)
					.then((res) => {
						dispatch({
							type: "getCountries",
							payload: res.data.data,
						});
					});

				await fetchAllCurrencies().then((res) => {
					dispatch({
						type: "getCurrencies",
						payload: res,
					});
				});

				await axios
					.get(
						`${process.env.REACT_APP_PRODUCTS_BUILDER}/lookups_common_product_builder`
					)
					.then((res) => {
						dispatch({
							type: "getLookupsBuilder",
							payload: res.data.data,
						});
					})
					.catch((err) => err.response);
			}
			get();
		}
	}, [isAuth]);
	console.log(isAuth);
	AxiosConfiguration();
	return (
		<>
			{loading && <Loader />}
			<ErrorModal />
			<div
				style={{
					direction: locale === "ar" ? "rtl" : "ltr",
					textAlign: locale === "ar" ? "right" : "left",
					fontFamily: locale === "ar" ? "Cairo" : "Cairo",
				}}
			>
				<ReactNotification />
				<ErrorBoundary>
					{isAuth ? (
						<Router>
							<Suspense fallback={<Loader />}>
								<DashboardLayout>{Routes}</DashboardLayout>
							</Suspense>
						</Router>
					) : (
						<Router>
							<Suspense fallback={<Loader />}>{preLoginRoutes}</Suspense>
						</Router>
					)}
				</ErrorBoundary>
			</div>
		</>
	);
}
