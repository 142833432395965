import React from "react";
import { useSBSState } from 'context/global';
import { InternalTransportationIcon } from '../../Details/shared/PackageIcon';
import moment from 'moment';
import Locale from 'translations';

function InternalTransportation({ isMobile, element }) {
  //const t = useTranslation()
  const { locale } = useSBSState();
  const {backOffice} = Locale
  return (
    <>
      <div 
			className={"boxcollapse"}
			>
        <div 
				className={"closecollapse"}
				>
          <div
          className="collapseIcon"
          >
            <InternalTransportationIcon />
          </div>
          {/* {isMobile && (
            <p
              style={{
                color: "##0F3E5E",
                fontWeight: "bold",
                marginRight: "auto",
                paddingLeft: "5px",
              }}
            >
              {element?.item?.name}
            </p>
          )} */}
          {!isMobile && (
            <>
              <div 
							className={"info"}
							>
                 <p style={{ color: "#0F3E5E",fontWeight:"bold",marginBottom:"0.8em" }}>
                    {element?.item?.name}
                  </p>
                <div className="d-flex wrap w-100 justify-content-between">
                  <p style={{ color: "#0F3E5E" }}>
                    {`${backOffice.companyName}: ${element?.itemable?.transporter_name[locale]}`}{" "}
                  </p>
                <span style={{ color: "##2D2D2D" }} >
                  {moment(element?.created_at).format("DD/MM/YYYY")}
                </span>
                </div>
              </div>

              {/* <>
                    <div className={styles.stepsinfo}>
                        <div className={styles.point}></div>
                        <Typography className={styles.steps} component={"span"}>Stop</Typography>
                        <div className={styles.airplaneicon} >
                        <ItinirareyDirectPlaneIcon />
                        </div>
                    </div>
                    <div className='d-flex flex-row align-items-center'>
                        <div className={styles.info}>
                            <Typography sx={{ color: "#0F3E5E" }} component={"p"} >{element?.itemable?.transporter_name[locale]} </Typography>
                            <Typography sx={{ color: "#2D2D2D" }} component={"span"} >-</Typography>
                            <Typography sx={{ color: "##2D2D2D" }} component={"span"} >{moment(element?.updated_at).format("DD/MM/YYYY")}</Typography>
                        </div>
                        <div>
                            <IconButton onClick={handelcollapse}><Icon className='material-symbols-outlined'>expand_more</Icon></IconButton>
                        </div>
                    </div>
                    </> */}
            </>
          )}

          {/* {isMobile &&
                        <div>
                            <IconButton onClick={handelcollapse}><Icon className='material-symbols-outlined'>expand_more</Icon></IconButton>
                        </div>
                    } */}
        </div>
        {/* <Collapse in={opened}>
          <div className={styles.opendcollapse}>
            <div className={styles.airstep}>
              <div className={styles.imgscompany}>
                <Image src={img} />
                <Typography>
                  {element?.itemable?.transporter_name[locale]}
                </Typography>
              </div>
              <div className={styles.tripdetails}>
                <div>
                  <Typography sx={{ color: "#0F3E5E" }} component={"p"}>
                    {element?.itemable?.transporter_name[locale]}
                  </Typography>
                  <Typography sx={{ color: "#2D2D2D" }} component={"span"}>
                    {moment(element?.created_at).format("DD/MM/YYYY")}
                  </Typography>
                </div>
                <div>
                  <Typography component={"span"} className={styles.triptime}>
                    {" "}
                    -{" "}
                  </Typography>
                </div>
                <div>
                  <Typography sx={{ color: "#0F3E5E" }} component={"p"}>
                    {element?.itemable?.transporter_name[locale]}
                  </Typography>
                  <Typography sx={{ color: "#2D2D2D" }} component={"span"}>
                    {moment(element?.created_at).format("DD/MM/YYYY")}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </Collapse> */}
      </div>
    </>
  );
}

export default InternalTransportation;
