import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import validate, { isFormValid } from "helpers/validate";
import useFetchCountries from "hooks/useFetchCountries";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { addPassanger, editPassanger } from "services/productbuilder";
import Locale from "translations";
export default function PassangerModal({
	modalPass,
	toggle,
	setReload,
	Reload,
	reservationDetails,
	spasificPassenger,
}) {
	const { inventory } = Locale;
	const { locale } = useSBSState();
	const { id } = useParams();
	const nowDate=moment(new Date()).add(1, 'days');
	const reservation_num = reservationDetails?.reservation_num;
	const [countries] = useFetchCountries();
	const [passangerState, setPassangerState] = useState({
		first_name: "",
		last_name: "",
		gender: "",
		birth_date: undefined,
		nationality_id: "",
		country_id: "",
		passport_number: "",
		passport_expiry: moment(nowDate).add(6, "M"),
		visa_number: "",
		e_number: "",
		visa_status: "",
	});
	const keysNotRequired = ["visa_number", "visa_status", "e_number"];
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const genderLookups = [
		{
			id: 1,
			name: `${locale === "en" ? "Male" : "ذكر"}`,
		},
		{ id: 2, name: `${locale === "en" ? "Female" : "أنثي"}` },
	];
	//to view spasific passanger
	useEffect(() => {
		if (modalPass?.title === "edit" && spasificPassenger) {
			setPassangerState({
				id: spasificPassenger?.id,
				first_name: spasificPassenger?.first_name,
				last_name: spasificPassenger?.last_name,
				gender: {
					label: spasificPassenger?.gender === "M" ? "Male" : "Female",
					value: spasificPassenger?.gender === "M" ? "Male" : "Female",
				},
				birth_date: spasificPassenger?.birth_date,
				nationality_id: {
					label: spasificPassenger?.nationality?.name,
					value: spasificPassenger?.nationality?.id,
				},
				country_id: {
					label: spasificPassenger?.country?.name,
					value: spasificPassenger?.country?.id,
				},
				passport_number: spasificPassenger?.passport_number,
				passport_expiry: moment(spasificPassenger?.passport_expiry),
				visa_number: spasificPassenger?.visa_number,
				e_number: spasificPassenger?.e_number,
				visa_status: spasificPassenger?.visa_status,
			});
		}
	}, [spasificPassenger]);
	function checkFormErrors() {
		let SubmitError = {};
		Object.keys(passangerState).forEach((key) => {
			SubmitError = {
				...SubmitError,
				...validate(
					{ name: key, value: passangerState[key] },
					{ required: keysNotRequired.includes(key) ? false : true }
				),
			};
		});

		setErrors(() => SubmitError);
	}
	//to add-edit passanger
	useEffect(() => {
		if (isFormValid(errors)) {
			const addPassangerFn = async () => {
				let data = {
					reservation_num,
					traveler: {
						...passangerState,
						gender: passangerState?.gender?.label.charAt(0),
						nationality_id: passangerState?.nationality_id?.value.toString(),
						country_id: passangerState?.country_id?.value.toString(),
						birth_date: moment(passangerState?.birth_date).format("YYYY-MM-DD"),
						passport_expiry: moment(passangerState?.passport_expiry).format(
							"YYYY-MM-DD"
						),
					},
				};
				let dataEdit = {
					...passangerState,
					//id:+id,
					gender: passangerState?.gender?.label.charAt(0),
					nationality_id: passangerState?.nationality_id?.value.toString(),
					country_id: passangerState?.country_id?.value.toString(),
					birth_date: moment(passangerState?.birth_date).format("YYYY-MM-DD"),
					passport_expiry: moment(passangerState?.passport_expiry).format(
						"YYYY-MM-DD"
					),
				};
				if (modalPass?.title === "add") {
					const response = await addPassanger(data);
					if (response.status === 200 || response.status === 201) {
						store.addNotification({
							title: "info!",
							message: "Passanger added Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 1500,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								setReload((prev) => !prev);
								toggle();
								emptyStateFn();
							},
						});
					}
				} else {
					const response = await editPassanger(passangerState?.id, dataEdit);
					if (response.status === 200 || response.status === 201) {
						store.addNotification({
							title: "info!",
							message: "Passanger edited Successfully",
							type: "success",
							insert: "top",
							container: "top-right",
							animationIn: ["animated", "fadeIn"],
							animationOut: ["animated", "fadeOut"],
							dismiss: {
								duration: 1500,
								onScreen: true,
								pauseOnHover: true,
							},
							onRemoval: () => {
								setReload((prev) => !prev);
								toggle();
								emptyStateFn();
							},
						});
					}
				}
			};
			addPassangerFn();
		}
	}, [isErrorLoaded]);
	function submit() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}
	const emptyStateFn = () => {
		setPassangerState({
			first_name: "",
			last_name: "",
			gender: "",
			birth_date: undefined,
			nationality_id: "",
			country_id: "",
			passport_number: "",
			passport_expiry:  moment(nowDate).add(6, "M"),
			visa_number: "",
			e_number: "",
			visa_status: "",
		});
	};
	console.log(passangerState);
	return (
		<>
			<Modal
				size="lg"
				isOpen={modalPass.isOpen}
				toggle={toggle}
				hasFooter={false}
			>
				{/* <ModalHeader className="d-flex justify-content-between align-items-center" toggle={toggle}>
					{modalPass?.title === "add"
						? inventory.messages.AddPassport
						: "Edit Passport"}
											<i class="fas fa-times pointer" onClick={()=>{toggle();emptyStateFn()}}></i>

				</ModalHeader> */}
				<div className="p-3 border-bottom d-flex justify-content-between align-items-center">
					{modalPass?.title === "add"
						? inventory.messages.AddPassport
						: "Edit Passport"}
					<i
						class="fas fa-times pointer"
						onClick={() => {
							toggle();
							emptyStateFn();
						}}
					></i>
				</div>
				<ModalBody>
					<div className="container  py-1  row">
						<div className="col-6">
							<TextField
								label={inventory.messages.firstName}
								placeholder={inventory.messages.enterFirstName}
								type="text"
								name="first_name"
								value={passangerState.first_name}
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										first_name: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "first_name", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.first_name?.required ? "danger" : ""}
								errors={errors?.first_name}
							/>
						</div>
						<div className="col-6">
							<TextField
								label={inventory.messages.lastName}
								placeholder={inventory.messages.enterLastName}
								type="text"
								name="last_name"
								value={passangerState.last_name}
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										last_name: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "last_name", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.last_name?.required ? "danger" : ""}
								errors={errors?.last_name}
							/>
						</div>
						<div className="col-md-6">
							<SelectField
								label={inventory.messages.gender}
								placeholder={inventory.messages.select}
								options={genderLookups}
								value={passangerState.gender.label}
								name="gender"
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										gender: e,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "gender", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.gender?.required ? "danger" : ""}
								errors={errors?.gender}
							/>
						</div>
						<div className="col-6 ">
							<DatePickerField
								label={inventory.messages.BirthDate}
								placeholder={"DD/MM/YYYY"}
								date={
									passangerState.birth_date
										? moment(passangerState.birth_date)
										: ""
								}
								name="birth_date"
								hasYears={true}
								onChangeDate={(e) => {
									setPassangerState({ ...passangerState, birth_date: e });
									setErrors({
										...errors,
										...validate(
											{ name: "birth_date", value: e },
											{ required: true }
										),
									});
								}}
								isOutsideRange={(day) => {
									return !day.isBefore(moment(), "day");
								}}
								color={errors?.birth_date?.required ? "danger" : ""}
								errors={errors?.birth_date}
							/>
						</div>

						<div className="col-md-6">
							<SelectField
								label={inventory.messages.nationality}
								placeholder={inventory.messages.select}
								name="nationality_id"
								value={passangerState.nationality_id?.label}
								options={countries}
								onChange={(e) => {
									setPassangerState({ ...passangerState, nationality_id: e });
									setErrors({
										...errors,
										...validate(
											{ name: "nationality_id", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.nationality_id?.required ? "danger" : ""}
								errors={errors?.nationality_id}
							/>
						</div>
						<div className="col-md-6">
							<SelectField
								label={inventory.messages.country}
								placeholder={inventory.messages.select}
								name="country_id"
								value={passangerState.country_id?.label}
								options={countries}
								onChange={(e) => {
									setPassangerState({ ...passangerState, country_id: e });
									setErrors({
										...errors,
										...validate(
											{ name: "country_id", value: e },
											{ required: true }
										),
									});
								}}
								color={errors?.country_id?.required ? "danger" : ""}
								errors={errors?.country_id}
							/>
						</div>

						<div className="col-6">
							<TextField
								label={inventory.messages.PassportNo}
								placeholder={inventory.messages.EnterPassportNo}
								type="text"
								name="passport_number"
								value={passangerState.passport_number}
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										passport_number: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "passport_number", value: e.target.value },
											{ required: true }
										),
									});
								}}
								color={errors?.passport_number?.required ? "danger" : ""}
								errors={errors?.passport_number}
							/>
						</div>
						<div className="col-6 ">
							<DatePickerField
								label={inventory.messages.passportExpiryDate}
								placeholder={"DD/MM/YYYY"}
								date={
									passangerState.passport_expiry
										? moment(passangerState.passport_expiry)
										: ""
								}
								name="passport_expiry"
								hasYears={true}
								onChangeDate={(e) => {
									setPassangerState({ ...passangerState, passport_expiry: e });
									setErrors({
										...errors,
										...validate(
											{ name: "passport_expiry", value: e },
											{ required: true }
										),
									});
								}}
								isOutsideRange={(day) => {
									return !day.isAfter(moment(new Date()).add(6, "M"), "day");
								}}
								color={errors?.passport_expiry?.required ? "danger" : ""}
								errors={errors?.passport_expiry}
							/>
						</div>
						<div className="col-6">
							<TextField
								label={inventory.messages.VisaNo}
								placeholder={inventory.messages.EnterVisaNo}
								type="text"
								name="visa_number"
								value={passangerState.visa_number}
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										visa_number: e.target.value,
									});
									setErrors({
										...errors,
										...validate(
											{ name: "visa_number", value: e.target.value },
											{ required: false }
										),
									});
								}}
								color={errors?.visa_number?.required ? "danger" : ""}
								errors={errors?.visa_number}
							/>
						</div>
						<div className="col-6 d-flex  align-items-end pb-3 ">
							<div className="boxwaiting me-4 d-flex  align-items-center ">
								<input
									type="radio"
									name="visa_status"
									id="waiting"
									value="waiting"
									checked={
										passangerState?.visa_status == "waiting" ? true : false
									}
									onChange={(e) => {
										setPassangerState({
											...passangerState,
											visa_status: e.target.value,
										});
									}}
								/>
								<label htmlFor="waiting" className="waiting">
									{inventory.messages.waiting}
								</label>
							</div>
							<div className="boxAccepted d-flex  align-items-center ">
								<input
									type="radio"
									name="visa_status"
									id="Accepted"
									value="accepted"
									checked={
										passangerState?.visa_status == "accepted" ? true : false
									}
									onChange={(e) => {
										setPassangerState({
											...passangerState,
											visa_status: e.target.value,
										});
									}}
								/>
								<label htmlFor="Accepted" className="Accepted">
									{inventory.messages.accepted}
								</label>
							</div>
						</div>
						<div className="col-6">
							<TextField
								label={inventory.messages.enumber}
								type="text"
								placeholder={inventory.messages.EnterENumber}
								name="e_number"
								value={passangerState.e_number}
								onChange={(e) => {
									setPassangerState({
										...passangerState,
										e_number: e.target.value,
									});
									// setErrors({
									// 	...errors,
									// 	...validate(
									// 		{ name: "e_number", value: e.target.value },
									// 		{ required: true }
									// 	),
									// });
								}}
								// color={errors?.e_number?.required ? "danger" : ""}
								// errors={errors?.e_number}
							/>
						</div>
						<div className="col-12 d-flex justify-content-center align-items-center ">
							<button className="btn w-50  py-2 btn-model" onClick={submit}>
								{modalPass?.title == "add"
									? inventory.messages.AddPassport
									: "Edit Passport"}
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	);
}
