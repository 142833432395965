import Loader from "components/Loader";
import Pagination from "components/Pagination";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import ProductsTableRow from "modules/products-builder/components/ProductsTableRow";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { getAllProducts } from "services/productbuilder";
import Locale from "../../../translations";
import ProductFilter from "../components/ProductFilter";
import ShowForPermission from "helpers/showForPermission";

export default function Products(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	// const products = productBody.product;

	const { productsList, productsBuilder } = Locale;
	const [orderBy, setOrderBy] = useState(null);
	const [sortBy, setSortBy] = useState(null);
	const [products, setProducts] = useState([]);
	const [meta, setMeta] = useState({});

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	const setSorting = (order, sort) => {
		setOrderBy(order);
		setSortBy(sort);
	};

	async function fetchProducts(filters = null, page = 1) {
		const response = await getAllProducts(filters, page);
		if (response.status === 200) {
			setProducts(response.data.data);
			setMeta(response.data.meta);
		} else {
			store.addNotification({
				title: "Something Went Wrong!",
				message: response.data.message,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	const goTo = (page) => {
		fetchProducts(null, page);
	};

	useEffect(() => {
		fetchProducts();
	}, []);

	// ----------------------------------------------------------------------------------------------------
	// Mini Components UI
	// ----------------------------------------------------------------------------------------------------
	// Sort Arrow View
	const sortArrowView = (order) => {
		if (orderBy === order && sortBy === "asc") {
			return <i className="fas fa-sort-down px-1 pointer"></i>;
		} else if (orderBy === order && sortBy === "desc") {
			return <i className="fas fa-sort-up px-1 pointer"></i>;
		} else {
			return "";
		}
	};

	const tableRows =
		products?.length > 0 ? (
			products?.map((product, i) => (
				<ProductsTableRow
					key={i}
					fetchProducts={fetchProducts}
					product={product}
				/>
			))
		) : (
			<tr>
				<td colSpan="11">
					<div className="product-build__product-no-data">
						<i className="fas fa-info-circle fa-lg"></i>{" "}
						<h4>{productsBuilder.noResult}</h4>
					</div>
				</td>
			</tr>
		);

	const dispatch = useProductsBuilderDispatch();
	const history = useHistory();

	const editProducts = async () => {
		localStorage.setItem("uuid", undefined);
		dispatch({ type: "deleteProduct", payload: {} });
		dispatch({ type: "saveBasicDetails", payload: { isEditMode: false } });

		history.push({
			pathname: `/products-builder/create`,
		});
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	if (true) {
		return (
			<>
				{/* Products Filter */}
				<div className="mx-md-5" style={{ width: "90%", margin: "auto" }}>
					<div className="col-md-12 bg-white py-2 px-4">
						<div className="header-Filter">
							<div>
								<p className="title-Filter">
									{productsBuilder.tripManagement} ({products?.length})
								</p>
							</div>
							<div>
								<ShowForPermission permission="Manage-Trip-Management-Module">
									<button
										className="btn-Product-Filter bg-nxt"
										onClick={editProducts}
									>
										{productsBuilder.addNew}
									</button>
								</ShowForPermission>
							</div>
						</div>
					</div>

					<ProductFilter
						orderBy={orderBy}
						sortBy={sortBy}
						currentPage={meta.current_page}
						fetchProducts={fetchProducts}
					/>

					{/* Products Table */}
					<div className="product-builder-list table-responsive  p-0">
						<table striped className="table table-striped border">
							<thead className="th-trip-mang ">
								<tr>
									<th className="border-top-0">{productsList.id}</th>
									<th>{productsList.productName}</th>
									<th>{productsList.productType}</th>
									<th
									// onClick={() =>
									// 	setSorting(
									// 		"validation_date_from_at",
									// 		sortBy === "asc" ? "desc" : "asc"
									// 	)
									// }
									>
										{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span>
										{sortArrowView("validation_date_from_at")}{" "} */}
										{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span>	 */}
										{productsList.validation}
									</th>
									<th>
										{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span> */}
										{sortArrowView("departure_date_at")} {productsList.tripDate}
									</th>
									<th>{productsList.products}</th>
									<ShowForPermission permission="Manage-Trip-Management-Module">
										<th>
											{/* <span class="up-down">
											<i class="fas fa-sort"></i>
										</span>
										{sortArrowView("product_classification_id")}{" "} */}
											{productsList.productClass}
										</th>
										<th>{productsList.pricing}</th>
									</ShowForPermission>
									<th>{productsList.status}</th>
									<th
									// onClick={() =>
									// 	setSorting(
									// 		"created_at",
									// 		sortBy === "asc" ? "desc" : "asc"
									// 	)
									// }
									>
										{productsList.createdAt}
									</th>
									<ShowForPermission permission="Manage-Trip-Management-Module">
										<th>{productsList.actions}</th>
									</ShowForPermission>
								</tr>
							</thead>
							<tbody>{tableRows}</tbody>
						</table>
					</div>
					{/* Pagination */}
					{products.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
				</div>
			</>
		);
	} else {
		return <Loader />;
	}
}
