import React from "react";
import Locale from "translations";
import TourBookingDetailsCard from "./BookingDetailsCard";

export default function ToursBookingDetails({ bookingDetails, setBookingDetails, toggleReloadData }) {
	const { dashboard, inventory } = Locale;


	return (
		<div className="col-md-8 transfer-booking-details" key={bookingDetails?.brn}>
			<div className="transfer-booking-details-header">
				<h2>{dashboard.messages.reservation}</h2>
				<span>
					{inventory.messages.refNo}: {bookingDetails.brn}
				</span>
			</div>
			{/* map on cart items */}
			{bookingDetails?.reservations.map((reservationItem) => (
				<TourBookingDetailsCard
					key={reservationItem?.details?.id}
					reservationItem={reservationItem}
					setBookingDetails={setBookingDetails}
					paymentStatus={bookingDetails?.payment_status}
					bookingStatus={bookingDetails?.status}
					toggleReloadData={toggleReloadData}
				/>
			))}

		</div>
	);
}
