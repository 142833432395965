import React, { useEffect, useState } from 'react'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap'
import Locale from 'translations';
import PackageReservationDetails from './Details';
import PackageReservationTravelers from './Travelers';
import { fetchPackagesReservation } from 'services/inventory';
import { useParams, useHistory } from 'react-router-dom';

export default function PackageReservation() {
  const { inventory } = Locale;
  const [activeTab, setActiveTab] = useState("1");
  const [packageDetails, setPackageDetails] = useState();
  const { id } = useParams();
  const history = useHistory();

  async function getPackageReservationDetails() {
    const PackageRes = await fetchPackagesReservation(id);
    if (PackageRes?.status === 200) {
      setPackageDetails(PackageRes?.data?.data)
    }
  }

  useEffect(() => {
    if (id) {
      getPackageReservationDetails();
    } else {
      history.push('/inventory/packages')
    }
  }, [id])



  return (
    <div className='container p-md-0'>
      <Nav tabs className="text-capitalize bg-gray flight-booking-nav-tabs d-flex ">
        <NavItem className='w-auto'>
          <NavLink
            id="package-booking-details"
            className={activeTab === "1" ? "active" : ""}
            onClick={() => setActiveTab("1")}
          >
            <i className="fas fa-calendar-alt"></i>
            <span className='mx-1'>{inventory.messages.bookingDetails}</span>
          </NavLink>
        </NavItem>
        {/* tra */}
        <NavItem className='w-auto'>
          <NavLink
            id="package-booking-travelers"
            className={activeTab === "2" ? "active" : ""}
            onClick={() => setActiveTab("2")}
          >
            <i className="fas fa-users"></i>
            <span className='mx-1'>{inventory.messages.travelers}</span>
          </NavLink>
        </NavItem>
      </Nav>


      <TabContent activeTab={activeTab}>
        {activeTab === "1" ? packageDetails ? <PackageReservationDetails details={packageDetails} /> : null : null}
        {activeTab === "2" ? <PackageReservationTravelers details={packageDetails} /> : null}
      </TabContent>
    </div>
  )
}
