// menu steps
import Locale from "translations";
export function useSetpsList() {
  const { guide } = Locale;
  const menuSteps = [
    {
      element: '#sidenav-market-search',
      intro: guide.menu.marketSearch,
      position: 'right',
    },
    {
      element: '#sidenav-mybooking',
      intro: guide.menu.mybooking,
      position: 'right',
    },
    {
      element: '#sidenav-safaOffline',
      intro: guide.menu.safaOffline,
      position: 'right',
    },
    {
      element: '#sidenav-myteam',
      intro: guide.menu.myteam,
      position: 'right',
    },
    {
      element: '#sidenav-wallet',
      intro: guide.menu.wallet,
      position: 'right',
    },
    {
      element: '#sidenav-activity-log',
      intro: guide.menu.activityLog,
      position: 'right',
    },
    {
      element: '#sidenav-statistics',
      intro: guide.menu.statistics,
      position: 'right',
    },
  ];
  // hotels tabs (vactions , umrah, individual, groups)
  const hotelsTabs = [
    {
      element: '#hotel-vacations',
      intro: guide.hotelsTabs.vacations,
    },
    // {
    //   element: '#hotel-umrah',
    //   intro: guide.hotelsTabs.umrah,
    // },
    // vacvtions tabs (individual, groups)
    // {
    //   element: '#hotel-vacations-individual',
    //   intro: guide.hotelsTabs.vacationsIndividual,
    // },
    // {
    //   element: '#hotel-vacations-groups',
    //   intro: guide.hotelsTabs.vacationsGroups,
    // },
  ]
  // hotels vacations Individual Steps
  const vacationsIndividualSteps = [
    {
      element: '#hotel-vacations-individual-destination',
      intro: guide.vacations.destination,
    },
    {
      element: '#hotel-vacations-individual-checkin',
      intro: guide.vacations.checkin,
    },
    {
      element: '#hotel-vacations-individual-checkout',
      intro: guide.vacations.checkout,
    },
    {
      element: '#hotel-vacations-individual-rooms',
      intro: guide.vacations.rooms,
    },
    {
      element: '#hotel-vacations-individual-residence',
      intro: guide.vacations.residence,
    },
    {
      element: '#hotel-vacations-individual-nationality',
      intro: guide.vacations.nationality,
    },
    {
      element: '#hotel-vacations-individual-currency',
      intro: guide.vacations.currency,
    },
    {
      element: '#hotel-vacations-groups',
      intro: guide.hotelsTabs.vacationsGroups,
    },
  ];
  // hotels vacations Groups Steps
  const vacationsGroupsSteps = [
    // groups
    {
      element: '#hotel-vacations-groups-destination',
      intro: guide.vacations.destination,
    },
    {
      element: '#hotel-vacations-groups-hotelname',
      intro: guide.vacations.hotelName,
    },
    {
      element: '#hotel-vacations-groups-checkin',
      intro: guide.vacations.checkin,
    },
    {
      element: '#hotel-vacations-groups-checkout',
      intro: guide.vacations.checkout,
    },
    {
      element: '#hotel-vacations-groups-rooms',
      intro: guide.vacations.rooms,
    },
    {
      element: '#hotel-vacations-groups-meals',
      intro: guide.vacations.meals,
    },
    {
      element: '#hotel-vacations-groups-residence',
      intro: guide.vacations.residence,
    },
    {
      element: '#hotel-vacations-groups-nationality',
      intro: guide.vacations.nationality,
    },
    // {
    //   element: '#hotel-vacations-groups-currency',
    //   intro: guide.vacations.currency,
    // },
    {
      element: '#hotel-vacations-groups-remarks',
      intro: guide.vacations.remarks,
    },
  ];

  const umrahTab = [
    {
      element: '#hotel-umrah-offers',
      intro: guide.umrahTab.umrahOffers,
    },
    {
      element: '#hotel-umrah-plus',
      intro: guide.umrahTab.umrahPlus,
    },
    {
      element: '#hotel-umrah-groups',
      intro: guide.umrahTab.umrahGroups,
    },
  ]
  return {
    menuSteps,
    hotelsTabs,
    vacationsIndividualSteps,
    vacationsGroupsSteps,
    umrahTab
  }
}

