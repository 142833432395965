import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import validate from 'helpers/validate'
import React from 'react'
import Locale from 'translations'

function ContactInfo({ countries, contactInfoData, setContactInfoData, errors, setErrors }) {
	const { userDetails, teamManagement, backOffice } = Locale;

	return (
		<div className='conatctBox row m-0'>
			<div className="col-md-12">
				<TextField
					placeholder={teamManagement.fullNamePlaceholder}
					label={teamManagement.fullName}
					type={'text'}
					name="fullName"
					id="fullName"
					value={contactInfoData?.fullName}
					onChange={(e) => {
						setContactInfoData({ ...contactInfoData, fullname: e.target.value });
						setErrors({
							...errors,
							...validate(
								{ name: "fullname", value: e.target.value },
								{ required: true }
							),
						});
					}}
					errors={errors?.fullname}
					color={errors?.fullname?.required ? "danger" : ""}
				/>
			</div>
			<div className="col-md-6">
				<TextField
					type="text"
					label={backOffice.email}
					placeholder={backOffice.emailPlaceholder}
					name="email"
					id="email"
					value={contactInfoData?.email}
					onChange={(e) => {
						setContactInfoData({ ...contactInfoData, email: e.target.value });
						setErrors({
							...errors,
							...validate(
								{ name: "email", value: e.target.value },
								{ required: true, email: true }
							),
						});
					}}
					errors={errors?.email}
					color={errors?.email?.required || errors?.email?.email ? "danger" : ""}
				/>
			</div>
			<div className="col-md-6 phone">
				{/* phone code  */}
				<div className='phone_code'>
					<SelectField
						name="phonecode"
						id="phonecode"
						value={contactInfoData.phoneCode?.phone_code}
						onChange={(e) => {
							setContactInfoData({ ...contactInfoData, phoneCode: e });
							setErrors({
								...errors,
								...validate(
									{ name: "phoneCode", value: e },
									{ required: true, }
								),
							});
						}}
						options={countries}
						color={errors?.phoneCode?.required ? "danger" : ""}
					/>
				</div>
				<TextField
					label={userDetails.phoneNumber}
					placeholder={userDetails.phoneNumberPlaceholder}
					type="number"
					name="phone"
					id= "phone"
					value={contactInfoData?.phone}
					min={9}
					max={13}
					onWheel={e => e.currentTarget.blur()}
					onChange={(e) => {
						setContactInfoData({ ...contactInfoData, phone: e.target.value });
						setErrors({
							...errors,
							...validate(
								{ name: "phone", value: e.target.value },
								{ required: true, min: 9, max: 13 }
							),
						});
					}}
					errors={errors?.phone}
					color={errors?.phone?.required || errors?.phone?.min || errors?.phone?.max ? "danger" : ""}
				/>
			</div>
		</div>
	)
}

export default ContactInfo