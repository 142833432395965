import NewPaymentAction from 'components/NewPaymentAction';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import validate, { isFormValid } from 'helpers/validate';
import React, { useEffect, useLayoutEffect,  useState } from 'react'
import { useHistory } from 'react-router-dom';
import { bookTours } from 'services/tours';
import Locale from 'translations'
import TourPassengersDetails from './TourPassengersDetails'
import TourSummaryReservationItem from './TourSummaryReservationItem'

export default function TourSummaryReservation({ setIsSuccessfullPayment, setReservationRef, calcTotalCartPrice }) {
  const { dashboard, onlineVisa, marketPlace } = Locale;
  const { toursCart } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();
  const history = useHistory();
  const [isOpen, setIsOpen] = useState(false);
  const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [passengerTypeRadio, setPassengerTypeRadio] = useState('lead-passenger');
  const [reference, setReference] = useState("")
  const [acceptedTerms, setAcceptedTerms] = useState(false);
  const [passengesData, setPassengersData] = useState({
    leadAge: 30,
    leadName: "",
    leadSurname: "",
  });

  const canPayLater = toursCart?.canPayLater;
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

  function toggleModal() {
    setActionType('')
		setFormData({})
    setIsOpen(!isOpen)
  }
  function checkFormErrors() {
    let SubmitError = {};
    questionsAnswers?.forEach((questionsAnswer, index) => {
      Object.keys(questionsAnswer?.answers).forEach((key, INd) => {
        SubmitError = {
          ...SubmitError,
          ...validate(
            { name: [key + index + INd], value: questionsAnswer?.answers[key], },
            { 
              required: true, 
              email: key === "email", 
              min: key === "phonenumber" || key ==="phone" ? 9 : "",
              max: key === "phonenumber" || key === "phone" ? 15 : ""  
            }
          ),
        };
      });
    });
    if (passengerTypeRadio === "lead-passenger") {
      Object.keys(passengesData).forEach((key) => {
        SubmitError = {
          ...SubmitError,
          ...validate(
            { name: key, value: passengesData[key], },
            { required: true }
          ),
        };
      })
    } else if (passengerTypeRadio === "all-passengers") {
      passengesData?.forEach((passengers, index) => {
        passengers?.adults?.forEach((adult, adultIdx) => {
          Object.keys(adult).forEach((key, IND) => {
            if (key !== "type" && key !== "age" && key !== "lead") {
              SubmitError = {
                ...SubmitError,
                ...validate(
                  { name: [key + index + adultIdx], value: adult?.[key], },
                  { required: true }
                ),
              };
            }
          })
        })
        passengers?.childs?.forEach((child, childIdx) => {
          Object.keys(child).forEach((key, IND) => {
            if (key !== "type" && key !== "age") {
              SubmitError = {
                ...SubmitError,
                ...validate(
                  { name: ["child-" + key + index + childIdx], value: child?.[key], },
                  { required: true }
                ),
              };
            }
          })

        })
      })
    }

    SubmitError = {
      ...SubmitError,
      ...validate(
        { name: "agency-reference", value: reference, },
        { required: true }
      ),
    };

    setErrors(() => SubmitError);
  };


  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };
  

  async function onPayment(payment_method,currency,otp) {
    const answers = toursCart?.cartItems?.map((cartItem) => {
      return cartItem?.details?.modalities[0]?.questions?.map((question, index) => {
        return {
          question: question,
          answer: questionsAnswers?.filter(answer => answer?.id === cartItem?.id)?.[0]?.answers?.[question?.code?.toLowerCase()]
        }
      })
    });
    const bookData = {
      paymentMethod: payment_method,
      reference: reference,
      otp:otp,
      currency:currency,
      holder: {
        name: !Array.isArray(passengesData) ? passengesData?.leadName : passengesData?.[0]?.adults?.[0]?.name,
        surname: !Array.isArray(passengesData) ? passengesData?.leadSurname : passengesData?.[0]?.adults?.[0]?.surname,
      },
      cartId: toursCart?.id,
      tours:
        !Array.isArray(passengesData) ?
          toursCart?.cartItems?.map((cartItem, index) => {
            return {
              id: cartItem?.id,
              answers: answers[index],
              passengers: null,
            }
          })
          :
          passengesData?.map((cartItem, index) => {
            return {
              id: cartItem?.id,
              answers: answers[index],
              paxes: [...cartItem?.adults?.map(adult => adult), ...cartItem?.childs?.map(child => child)],
            }
          })
    }

    const bookRes = await bookTours(bookData)
    if (bookRes?.status === 200) {
      setIsSuccessfullPayment(true);
      setReservationRef(bookRes?.data?.tour?.brn);
      
      // reset tours search criteria, results and tours cart
      dispatch({
        type: "toursCart",
        payload: [],
      });
      dispatch({
        type: "toursSearchResults",
        payload: null
      });
      dispatch({
        type: "saveToursSearch",
        payload: null
      });
    }
    else if (bookRes?.status === 202){
      setActionType(bookRes?.data?.actionType)
      setFormData(bookRes?.data?.data?.formData)

    }
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      toggleModal();
    }
  }, [isErrorLoaded]);
  // if cart empty return to result page
  useEffect(() => {
    if (!toursCart || toursCart?.length === 0 || toursCart?.cartItems?.length === 0) {
      history.push('/tours-result')
    }
  }, [toursCart]);

  // scroll to top of the page
  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      <div className='tours-summary-header'>
        <h1>{dashboard.messages.reservation}</h1>
      </div>
      {/* reservations list */}
      <div className='mt-4'>
        <div className=''>
          {toursCart?.cartItems?.map((cartItem, index) => {
            return (
              <TourSummaryReservationItem
                key={cartItem?.id}
                index={index}
                tourData={cartItem}
                questionsAnswers={questionsAnswers}
                setQuestionsAnswers={setQuestionsAnswers}
                errors={errors}
                setErrors={setErrors}
                cartId={toursCart?.id}
              />
            )
          })}
        </div>

        <TourPassengersDetails
          passengerTypeRadio={passengerTypeRadio}
          setPassengerTypeRadio={setPassengerTypeRadio}
          passengesData={passengesData}
          setPassengersData={setPassengersData}
          errors={errors}
          setErrors={setErrors}
          reference={reference}
          setReference={setReference}
        />

        <div className='my-3 d-flex flex-wrap justify-content-between tours-checkout'>
          <div className='d-flex align-items-baseline'>
            <input
              type="checkbox"
              name="accept-terms"
              id="accept-terms"
              value={acceptedTerms}
              checked={acceptedTerms}
              onChange={() => {
                setAcceptedTerms(!acceptedTerms);
              }}
            />
            <label className='mx-2 pointer' htmlFor='accept-terms'>{marketPlace.messages.acceptedTerms}</label>
          </div>
          <button
            className='btn tour-checkout-btn'
            disabled={!acceptedTerms}
            onClick={() => {
              submit()
            }}
          >
            {onlineVisa.PROCEEDTOCHECKOUT}
          </button>
        </div>
        {isOpen ?
          <NewPaymentAction
            isOpen={isOpen}
            actionType={actionType}
            formData={formData}
            toggleModal={toggleModal}
            onPayment={onPayment}
            canPayLater={canPayLater}
            currency={calcTotalCartPrice?.provider_currency}
            secondCurrency={calcTotalCartPrice?.currency}
            secondPrice={calcTotalCartPrice?.total}
            Price={calcTotalCartPrice?.provider_price}
            OTP={OTP_CHECK}
            setActionType={setActionType}
            setFormData={setFormData}
          />
          :
          null
        }
      </div>
    </>
  )
}
