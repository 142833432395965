import React, { useState } from 'react';
import Locale from 'translations';
import { CheckMarkIcon, CrossDangerIcon, InfoIcon } from '../TourIcons';
import moment from 'moment';

export default function TourDetailsInfo({ tourData, selectedDate, selectedCategory }) {
  const { marketPlace, inventory } = Locale;
  const charsCountLimit = 60;
  const tourDescription = tourData?.description;
  const tourContent = tourData?.content;
  const [showFullDesc, setShowFullDesc] = useState(false);
  const tourDesc = !showFullDesc && tourDescription?.length > charsCountLimit
    ?
    tourDescription?.split(' ')?.slice(0, charsCountLimit)?.join(' ') + " ...."
    :
    tourDescription;

  function toggleShowFullDesc() {
    setShowFullDesc(!showFullDesc)
  }

  const operationDates = selectedCategory?.rate?.operationDates;
  const operationSelectedData = operationDates?.filter(operationDate => moment(selectedDate).format("YYYY-MM-DD") === operationDate?.from)?.[0];

  return (
    <div className='tour-details-info' key={'tours-info'}>
      <div className='d-flex flex-column' >
        {/* cautions */}
        {tourContent?.importantInfo?.map((info, index) => {
          return (
            <div className='tour-details-caution' key={`tour-info-${index}`}>
              <InfoIcon />
              <p className='mt-1' dangerouslySetInnerHTML={{ __html: info }} />
            </div>
          )
        })}

        {/* commnets and remarks */}
        {tourData?.modalities?.[0]?.comments?.map((comment, index) => {
          return (
            <div className='tour-details-caution' key={`tour-comment-${index}`}>
              <InfoIcon />
              <p className='mt-1' dangerouslySetInnerHTML={{ __html: comment?.text }} />
            </div>
          )
        })}


        {/* tour features */}
        <div>
          {tourContent?.featureGroups?.map((feature, index) => {
            return (
              <div className='d-flex flex-column my-2' key={`tour-feature-${index}`}>
                <h4 className='font-weight-bold'>
                  {feature?.groupCode === "UNKNOWN" ? "OTHERS" : feature?.groupCode}
                </h4>
                {/* included features */}
                {feature?.included?.map((featureIncluded, includeIdx) => {
                  return (
                    <span className="" key={`featureIncluded-${includeIdx}`}>
                      <CheckMarkIcon />
                      <span className='mx-1'>
                        {featureIncluded?.description}
                      </span>
                    </span>
                  )
                })}
                {/* excluded features */}
                {feature?.excluded?.map((featureExcluded, excludeIdx) => {
                  return (
                    <span className="" key={`featureExluded-${excludeIdx}`}>
                      <i><CrossDangerIcon /></i>
                      <span className='mx-1'>
                        {featureExcluded?.description}
                      </span>
                    </span>
                  )
                })}
              </div>
            )
          })}
        </div>

        {/* location */}
        <div className='tour-details-info-location my-2'>
          <div className='d-flex align-items-baseline'>
            <h4 className='font-weight-bold text-uppercase'>{marketPlace.location}</h4>
            <div className='mx-2'>
              {/* <i className="fas fa-map-marker-alt text-yellow mx-1"></i> */}
              {/* <span className='text-yellow'>Show on Map</span> */}
            </div>
          </div>
          {/* starting points */}
          <div className='mx-2'>
            <span className='font-weight-bold'>{marketPlace.startPoints}</span>
            {tourContent?.location?.startingPoints?.map((startPoint, startPointIdx) => {
              return (
                <div key={`location-start-${startPointIdx}`}>
                  <p>
                    {startPoint?.meetingPoint?.country?.name}
                  </p>
                  {/* pickup instructions */}
                  <div className='d-flex align-items-baseline px-1'>
                    {startPoint?.pickupInstructions?.map((pickup, pickupIdx) => {
                      return (
                        <div key={`start-pickup-${pickupIdx}`}>
                          <span style={{ textIndent: "-1px" }}>* </span>
                          <p className='px-1 mt-1' dangerouslySetInnerHTML={{ __html: pickup?.description }} />
                        </div>
                      )
                    })}
                  </div>
                  <p className='px-1 mt-1'>
                    {startPoint?.meetingPoint?.description}
                  </p>
                </div>
              )
            })}
          </div>
          {/* end points */}
          <div className='mt-2 mx-2'>
            <span className='font-weight-bold'>{marketPlace.endPoint}</span>
            {tourContent?.location?.endPoints?.map((endPoint, endPointIdx) => {
              return (
                <p key={`location-end-${endPointIdx}`}>
                  {endPoint?.description}
                </p>
              )
            })}
          </div>
        </div>

        {/* about tour */}
        <div className='tour-details-about mt-3'>
          <h4 className='font-weight-bold text-uppercase'>{marketPlace.aboutTour}</h4>
          <p className='mt-1' dangerouslySetInnerHTML={{ __html: tourDesc || "" }} />
          {tourDescription?.length >= charsCountLimit
            ?
            <span className='show-full-desc' onClick={toggleShowFullDesc}>
              {showFullDesc ? marketPlace.hideFullDescription : marketPlace.showFullDescription}

            </span>
            :
            null
          }
        </div>

        {/* cancelation policies */}
        {operationSelectedData?.cancellationPolicies?.length > 0 &&
          <div className='bg-white tour-details-caution mb-0 mt-3'>
            <span className='font-weight-bold h6'>{inventory.messages.cancellationFees}</span>
            <div className="col-md-7 col-12 p-0">
              {operationSelectedData?.cancellationPolicies?.map((cancellationPolicy, index) => {
                return (
                  <div className='d-flex flex-wrap justify-content-between my-2' key={index}>
                    <p>
                      <span> {marketPlace.From}</span>
                      <span className='mx-2'>
                        {moment(cancellationPolicy?.dateFrom).format("hh:mm A")} {" "}
                        {moment(cancellationPolicy?.dateFrom).format("DD/MM/YYYY")} {" "}
                      </span>
                    </p>
                    <span className='font-weight-bold price-danger'>{cancellationPolicy?.amount} {tourData?.currency}</span>
                  </div>
                )
              })}
            </div>
          </div>
        }

      </div>
    </div>

  )
}
