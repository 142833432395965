import TextField from 'components/Form/TextField/TextField'
import SelectField from "components/Form/SelectField/SelectField";
import React, { useMemo, useState } from 'react'
import Locale from 'translations'
import { Collapse } from 'reactstrap';
import validate from 'helpers/validate';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useSBSState } from 'context/global';
import moment from 'moment';

export default function PassengerForm({ index, passengerType, passanger, passengersData, setPassengersData, calenderLookups, countries, errors, setErrors }) {
  const { marketPlace, inventory, reservation } = Locale
  const {locale} = useSBSState();
  const [isOpen, setisOpen] = useState(index === 0 && passengerType === "adults" ? true : false)
  const toggle = () => {
    setisOpen(!isOpen)
  }
  const gendersLookup = [
    { id: 1, name: marketPlace.male },
    { id: 2, name: marketPlace.female },
  ];
  // const idTypeLookup = [
  //   { id: "P", name: inventory.messages.passport },
  //   { id: "N", name: reservation.messages.nationalId },
  //   { id: "O", name: marketPlace.other },
  // ]

  // id expiry lookup
  const idExpiryDateLookup = useMemo(()=> {
    let idExpiryYears = [];
    const expiryDay = passanger?.cardExpiredDate?.day?.label;
    const expiryMonth = passanger?.cardExpiredDate?.month?.label;
    const currentYear = moment().year();
    const selectedDate = expiryDay && expiryMonth && `${currentYear}-${expiryMonth}-${expiryDay}`;
    const isSelectedDateValid = moment().add(6, "months").isBefore(moment(selectedDate));
    let idExpiryStartDate = moment().add(isSelectedDateValid ? 0 : 1, "year");
    let idExpiryEndDate = moment(selectedDate).add(7, "years");
    let idExpiryDates = idExpiryStartDate.startOf("years");

    do {
      idExpiryYears.push(idExpiryDates.year());
    } while (idExpiryDates.add(1, "year") < idExpiryEndDate);
    idExpiryYears = idExpiryYears.map((month) => {
      return { id: month, name: String(month) };
    });
    return idExpiryYears;
  },[passanger?.cardExpiredDate?.day?.label, passanger?.cardExpiredDate?.month?.label]);

  function handlePassengerChange(value, valueName) {
    let allPassengers = { ...passengersData };
    let currentPassenger = allPassengers[passengerType][index];
    currentPassenger = { ...currentPassenger, [valueName]: value };
    allPassengers[passengerType][index] = currentPassenger;
    setPassengersData(allPassengers);
    setErrors({
      ...errors,
      ...validate(
        { name: [`${passengerType}-${valueName}-${index}`], value: value },
        { required: true }
      ),
    });
  }

  function handlePassengerDatesChange(value, valueName, type) {
    let allPassengers = { ...passengersData };
    let currentPassenger = allPassengers[passengerType][index];
    let date = { ...currentPassenger[valueName] }
    // reset card expire year if month or day changed
    if ((type === "month" || type === "day") && valueName === "cardExpiredDate") {
      currentPassenger = { ...currentPassenger, [valueName]: { ...date, [type]: value, year: ""  } };

    }else {
      currentPassenger = { ...currentPassenger, [valueName]: { ...date, [type]: value } };
    }
    allPassengers[passengerType][index] = currentPassenger;
    setPassengersData(allPassengers);
    setErrors({
      ...errors,
      ...validate(
        { name: [`${passengerType}-${valueName}-${type}-${index}`], value: value },
        { required: true }
      ),
    });
  }

  const renderPassengerValidation = (props) => {
    return (
      <Tooltip id={passanger?.id} {...props}>
        <ul style={{ width: "100%", textAlign: "inherit" }}>
          <li className='my-1'>1. {marketPlace.PleaseEnterPassengerNames}</li>
          <li className='my-1'>2. {marketPlace.UseEnglishPassengerNames}</li>
          <li className='my-1'>
            <span>3. {marketPlace.PassengerNamesAccordingToTheFollowingFormat}</span>
            <ul>
              <li className='my-1'>• {marketPlace.LastNameOfAdults}</li>
              <li className='my-1'>• {marketPlace.lastNameOfChildren}</li>
              <li className='my-1'>• {marketPlace.givenNameMustBe}</li>
              <li className='my-1'>• {marketPlace.lastNameMustBe}</li>
            </ul>
          </li>
          <li className='my-1'>4. {marketPlace.ifYouHaveOtherProblems}</li>
        </ul>
      </Tooltip>
    )
  };

  // TODO add hasError to form that has input not valid then add red border to form box


  return (
    <div className='form-box mt-2 radius' key={passanger?.id}>
      <div className='form-head'>
        <p className='bold'>
          {passengerType === "adults" ? inventory.messages.Adult : inventory.messages.Child} {index + 1} {" "}
          <OverlayTrigger
            placement="top"
            delay={{ show: 100, hide: 100 }}
            overlay={renderPassengerValidation}
            trigger={"click"}
            onEnter={(e) => {
              e.querySelector('.tooltip-inner').style.maxWidth = '600px'
              e.querySelector('.tooltip-inner').style.textAlign = locale === "ar" ? "right" : "left"
            }}
          >
            <i class="fas fa-info-circle" style={{ color: "#B7B7B7" }}></i>
          </OverlayTrigger>
        </p>
        <i class="fas fa-angle-down pointer" onClick={toggle}></i>
      </div>
      <Collapse
        isOpen={ isOpen}
        toggle={toggle}
      >
        <div className="row p-4">
          {/* first and middle name */}
          <div className="col-md-6">
            <TextField
              placeholder={marketPlace.FirstNameAndMiddleName}
              label={marketPlace.FirstNameAndMiddleName}
              type={'text'}
              value={passanger?.firstName}
              id= "firstName"
              name= "firstName"
              onChange={(e) => {
                handlePassengerChange(e.target.value, 'firstName');
              }}
              errors={errors?.[`${passengerType}-firstName-${index}`]}
              color={errors?.[`${passengerType}-firstName-${index}`]?.required ? "danger" : ""}
            />
          </div>
          {/* last name */}
          <div className="col-md-6">
            <TextField
              placeholder={marketPlace.LastName}
              label={marketPlace.LastName}
              type={'text'}
              id="LastName"
              name="LastName"
              value={passanger?.lastName}
              onChange={(e) => {
                handlePassengerChange(e.target.value, 'lastName');
              }}
              errors={errors?.[`${passengerType}-lastName-${index}`]}
              color={errors?.[`${passengerType}-lastName-${index}`]?.required ? "danger" : ""}
            />
          </div>
          {/* gender */}
          <div className="col-md-4 col-12">
            <SelectField
              label={marketPlace.gender}
              placeholder={marketPlace.select}
              value={passanger?.sex?.name}
              id="gender"
              name="gender"
              options={gendersLookup}
              onChange={(e) => {
                handlePassengerChange(e, 'sex');
              }}
              errors={errors?.[`${passengerType}-sex-${index}`]}
              color={errors?.[`${passengerType}-sex-${index}`]?.required ? "danger" : ""}
            />
          </div>
          {/* date of birth */}
          <div className="col-md-4 d-flex flex-column ">
            <label htmlFor="" className='m-0'>{marketPlace.Dateofbirth}</label>
            <div className='d-flex date-of-birth'>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Birth Day"
                  name="birthday"
                  id="birthday"
                  placeholder="DD"
                  value={passanger?.birthday?.day?.name}
                  options={calenderLookups?.days}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'birthday', 'day');
                  }}
                  errors={errors?.[`${passengerType}-birthday-day-${index}`]}
                  color={errors?.[`${passengerType}-birthday-day-${index}`]?.required ? "danger" : ""}
                />
              </div>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Birth Month"
                  name="birthmonth"
                  id="birthmonth"
                  placeholder="MM"
                  value={passanger?.birthday?.month?.name}
                  options={calenderLookups?.months}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'birthday', 'month')
                  }}
                  errors={errors?.[`${passengerType}-birthday-month-${index}`]}
                  color={errors?.[`${passengerType}-birthday-month-${index}`]?.required ? "danger" : ""}
                />
              </div>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Birth Year"
                  name="birthyear"
                  id="birthyear"
                  placeholder="YYYY"
                  value={passanger?.birthday?.year?.name}
                  options={passengerType === "adults" ? calenderLookups?.adultYears : calenderLookups?.childYears}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'birthday', 'year')
                  }}
                  errors={errors?.[`${passengerType}-birthday-year-${index}`]}
                  color={errors?.[`${passengerType}-birthday-year-${index}`]?.required ? "danger" : ""}
                />
              </div>
            </div>
          </div>
          {/* nationality */}
          <div className="col-md-4 col-12">
            <SelectField
              label={marketPlace.nationality}
              placeholder={marketPlace.select}
              name="nationality"
              id="nationality"
              value={passanger?.nationality?.name}
              options={countries}
              onChange={(e) => {
                handlePassengerChange(e, 'nationality')
              }}
              errors={errors?.[`${passengerType}-nationality-${index}`]}
              color={errors?.[`${passengerType}-nationality-${index}`]?.required ? "danger" : ""}
            />
          </div>
          {/* id Type */}
          {/* <div className="col-4">
            <SelectField
              label={marketPlace.idType}
              placeholder={marketPlace.passport}
              name="idType"
              id="idType"
              value={passanger?.idType?.name}
              options={idTypeLookup}
              onChange={(e) => {
                handlePassengerChange(e, 'idType')
              }}
              errors={errors?.[`${passengerType}-idType-${index}`]}
              color={errors?.[`${passengerType}-idType-${index}`]?.required ? "danger" : ""}
            />
          </div> */}
          {/* id number */}
          <div className="col-md-4">
            <TextField
              placeholder={inventory.messages.PassportNo}
              label={inventory.messages.PassportNo}
              name="idNumber"
              id="idNumber"
              value={passanger?.cardNum}
              onChange={(e) => {
                handlePassengerChange(e.target.value, 'cardNum')
              }}
              errors={errors?.[`${passengerType}-cardNum-${index}`]}
              color={errors?.[`${passengerType}-cardNum-${index}`]?.required ? "danger" : ""}
            />
          </div>
          {/* id expiry date */}
          <div className="col-md-4 d-flex flex-column ">
            <label htmlFor="" className='m-0'>{inventory.messages.passportExpiryDate}</label>
            <div className='d-flex date-of-birth'>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Expiry Day"
                  name="cardExpiredDate"
                  id="cardExpiredDate"
                  placeholder="DD"
                  value={passanger?.cardExpiredDate?.day?.name}
                  options={calenderLookups?.days}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'cardExpiredDate', 'day')
                  }}
                  errors={errors?.[`${passengerType}-cardExpiredDate-day-${index}`]}
                  color={errors?.[`${passengerType}-cardExpiredDate-day-${index}`]?.required ? "danger" : ""}
                />
              </div>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Expiry Month"
                  name="expirymonth"
                  id="expirymonth"
                  placeholder="MM"
                  value={passanger?.cardExpiredDate?.month?.name}
                  options={calenderLookups?.months}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'cardExpiredDate', 'month')
                  }}
                  errors={errors?.[`${passengerType}-cardExpiredDate-month-${index}`]}
                  color={errors?.[`${passengerType}-cardExpiredDate-month-${index}`]?.required ? "danger" : ""}
                />
              </div>
              <div className='col-md-4 p-0'>
                <SelectField
                  hasLabel={false}
                  label="Expiry Year"
                  name="expiryyear"
                  id="expiryyear"
                  placeholder="YYYY"
                  value={passanger?.cardExpiredDate?.year?.name}
                  options={idExpiryDateLookup}
                  onChange={(e) => {
                    handlePassengerDatesChange(e, 'cardExpiredDate', 'year')
                  }}
                  disabled={!passanger?.cardExpiredDate?.month?.name}
                  errors={errors?.[`${passengerType}-cardExpiredDate-year-${index}`]}
                  color={errors?.[`${passengerType}-cardExpiredDate-year-${index}`]?.required ? "danger" : ""}
                />
              </div>
            </div>
          </div>
        </div>
      </Collapse>
    </div>
  )
}