import reservationError from "assets/images/inventory/error.png";
import Group1068 from "assets/images/inventory/Group1068.svg";
import reservationSuccess from "assets/images/inventory/success.png";
import masterCardImg from "assets/images/wallet/masterCard.svg";

import bankTranfertImg from "assets/images/inventory/wallet_78921.svg";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import TextAreaField from "components/Form/TextAreaField/TextAreaField";
import TextField from "components/Form/TextField/TextField";
import { useSBSDispatch } from "context/global";
import createDOMPurify from "dompurify";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useLocation } from "react-router-dom";
import Switch from "react-switch";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
} from "reactstrap";
import { fetchLookups, getAllSpecialRequests } from "services/lookups";
import {
  cancelHotelBooking,
  cancelRoom,
  confirmBookingHotels,
  fetchHotelBrn,
  fetchTransportaionsBrn,
  fetchVoucher,
  sendOtp,
  updateBookingHotels,
} from "services/marketplace";
import {
  getCredit,
  getDebit,
  getListWallets,
  getPrebookListWallets,
} from "services/wallet";
import Locale from "translations";
import { VoucherPrinte } from "../../../../../assets/NewVoucherPrinte";
// import Filter from "./Filter";
import InventoryDataRow from "./PassengerDataRow";
import ShowForPermission from "helpers/showForPermission";
import AWSpay from "components/AWSpay";
import VerifyOTPModal from "components/VerifyOTPModal";

export default function PassengersTable({ details, setDetails }) {
  const { marketPlace, UmrahHolidays, teamManagement, commons, inventory } =
    Locale;
  const state = { tab: "hotel" };
  const [RoomsList, setRoomsList] = useState([]);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalPay, setModalPay] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [modalPassenger, setModalPassenger] = useState(false);

  const [listWallets, setListWallets] = useState([]);

  const [message, setMessage] = useState("");
  const [radio, setRadio] = useState();
  const togglePayModal = () => {
    setActionType("");
    setFormData({});
    setModalPay(!modalPay);
  };
  const toggleRejectModal = () => setRejectModal(!rejectModal);
  const toggleSuccessModal = () => setSuccessModal(!successModal);
  const toggleModalEdit = () => setModalEdit(!modalEdit);
  const toggleModalPassenger = () => setModalPassenger(!modalPassenger);

  // const [isOpen, setIsOpen] = useState(false);
  // const toggleButton = () => setIsOpen(!isOpen);
  const [selected, setSelected] = useState({});
  const [lookups, setLookups] = useState([]);
  // const { safaBalance, myBalance } = useSBSState();
  const [modal, setModal] = useState(false);
  const [modalTerms, setModalTerms] = useState(false);

  const [childrenArray, setchildrenArray] = useState([]);
  const [SpecialRequests, setSpecialRequests] = useState([]);
  const onlineVisaURL = process.env.REACT_APP_API_URL + "/v1/files/fetch/";
  // const [filter, setFilter] = useState({
  // 	search: "",
  // 	date: "",
  // 	page: 1,
  // });
  // const [searchStatus, setSearchStatus] = useState("");
  useEffect(() => {
    async function getSpecialRequests() {
      const SpecialRequests = await getAllSpecialRequests({
        lang: "en",
      });

      if (SpecialRequests?.status === 200) {
        setSpecialRequests(SpecialRequests.data);
      }
    }
    getSpecialRequests();
  }, []);
  const toggleModal = () => setModal(!modal);
  const toggleModalTerms = () => setModalTerms(!modalTerms);
  const [configData, setConfigData] = useState({});
  const [paymnetMethod, setPaymnetMethod] = useState();
  const [paymnet, setPaymnet] = useState({});
  const [paymnetSAR, setpaymnetSAR] = useState({});
  const [cancellationReason, setCancellationReason] = useState("");
  const { pathname } = useLocation();
  const [_, number] = pathname.split("/inventory/");
  const [modelupdated, setmodelupdated] = useState(false);

  const [actionType, setActionType] = useState("");
  const [formData, setFormData] = useState({});

  async function fetchData() {
    setDetails(null);
    const { data } =
      state.tab === "hotel"
        ? await fetchHotelBrn(number)
        : await fetchTransportaionsBrn(number);

    setDetails(data.data);
  }

  const dispatch = useSBSDispatch();

  async function fetchSafaWallet() {
    const res = await getCredit();

    dispatch({ type: "getSafaBalance", payload: res?.data?.data });
    const res1 = await getDebit();
    dispatch({ type: "getMyBalance", payload: res1?.data?.data[0] });
  }
  const DOMPurify = createDOMPurify(window);
  const [editState, setEditState] = useState({
    checkIn: details?.start_date
      ? moment(details.start_date)
      : moment(new Date()).add(1, "d"),
    checkOut: details?.end_date
      ? moment(details.end_date)
      : moment(new Date()).add(2, "d"),
    adult: +details.adults,
    child: +details.children,
    roomType: null,
  });
  const listB = [
    {
      id: 0,
      name: "No Preference",
    },
    {
      id: 1,
      name: "King Size",
    },
    {
      id: 2,
      name: "Queen Size",
    },
    {
      id: 3,
      name: "Twin",
    },
  ];
  useEffect(() => {
    setchildrenArray(selected.childrenAges);
    setEditState({
      ...editState,
      adult: selected?.adults,
      checkIn: selected?.from_date
        ? moment(selected.from_date)
        : moment(new Date()).add(1, "d"),
      checkOut: selected?.to_date
        ? moment(selected.to_date)
        : moment(new Date()).add(2, "d"),
      child: selected.children,
      childrenArray: selected.childrenAges,
      maxOccupancy: selected.maxOccupancy,
      adultOption: selected?.maxAdult
        ? [...Array(+selected?.maxAdult).keys()].map((i, index) => ({
            id: index + 1,
            name: index + 1,
          }))
        : [],
      childOption: selected?.maxChildren
        ? [...Array(+selected?.maxChildren).keys()].map((i, index) => ({
            id: index + 1,
            name: index + 1,
          }))
        : [],
      rateOption: selected?.room_rates
        ? selected?.room_rates.map((res) => ({
            ...res,
            name: res?.mealTitle + "   (" + res.room_total_price + ")    ",
            id: res.roomConfigurationId,
          }))
        : [],
      BeddingOption: listB,
      beddingPreferenceName: selected?.beddingPreference
        ? listB.filter((res) => +res.id == +selected?.beddingPreference)[0].name
        : "",
      mealOptionId: selected?.roomConfigurationId,
      mealOptionName: selected?.room_rates
        ? selected?.room_rates.filter(
            (res) => +res.mealOptionId == +selected?.mealOptionId
          )[0]?.mealTitle
        : "",
      allowsSpecialRequests: selected.allowsSpecialRequests
        ? selected.allowsSpecialRequests
        : true,
      specialRequests: selected?.specialRequests,
      beddingPreference: selected?.beddingPreference,
    });
  }, [selected]);
  const ONLINE_CURRENCY = process.env.REACT_APP_ONLINE_CURRENCY;
  const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
    ? JSON.parse(process.env.REACT_APP_OTP_CHECK)
    : false;
  const [otpModalOpen, setOtpModalOpen] = useState(false);

  useEffect(() => {
    async function fetchData() {
      const response = await fetchLookups();
      setLookups(response.room_types);
    }
    fetchData();
    setRoomsList(details.payload.rooms);
  }, []);

  const requsetEdit = async () => {
    const Data = {
      brn: details.brn,
      date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
      date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
      bookingCode: selected.bookingCode,
      mealOptionId: editState.mealOptionId?.toString(),
      provider_id: details.gds,
      number_of_adult: editState.adult,
      beddingPreference: editState.beddingPreference
        ? editState.beddingPreference.toString()
        : "0",
      children:
        editState.childrenArray && editState.childrenArray.length > 0
          ? editState.childrenArray
          : [],
      special_requests:
        editState.specialRequests && editState.specialRequests.length > 0
          ? editState.specialRequests
          : [],
    };
    let book;
    book = await updateBookingHotels(Data);
    if (book.status === 200) {
      setRejectModal(false);
      setModalEdit(false);
      setConfigData(book.data);
      setModalPay(true);
      async function fetchSafaWallet() {
        setListWallets([]);
        // to DO asdasd
        if (book.data.rate_types[0].paid_deduct_amount > 0) {
          const res = await getPrebookListWallets({
            currency: book.data.rate_types[0].currency + ",",
            price: book.data.rate_types[0].paid_deduct_amount,
          });

          if (res.status === 200) {
            const dataSAR = res?.data?.data?.filter(
              (i) => i.currency === book.data.rate_types[0].currency
            )[0];
            setPaymnet(dataSAR);
            setpaymnetSAR(dataSAR);
            setListWallets(res?.data?.data);
          }
        } else {
          const res = await getListWallets({
            currency: book.data.rate_types[0].currency,
          });

          if (res.status === 200) {
            setListWallets(res?.data?.data);
          }
        }
      }
      fetchSafaWallet();
    } else {
      if (book.status === 400) {
        setMessage(book.data.message);
      }
      setModalEdit(false);
      //toggleRejectModal();
    }
  };
  const cancelBooking = async () => {
    let cancel;
    const Data = {
      brn: details.brn,
      bookingCode: selected.bookingCode,
      cancellationReason: cancellationReason,
      // currency:paymnet.currency
    };

    if (details.gds == 6 || details.gds == 5) {
      cancel = await cancelHotelBooking(Data);
    } else {
      cancel = await cancelRoom(Data);
    }

    if (cancel.status === 200) {
      toggleModal();
      fetchData();
      fetchSafaWallet();
      store.addNotification({
        title: "Info!",
        message: `${commons.updatedSuccessfully}`,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  };

  const setNewChildrenQuantity = (e) => {
    let x = [...Array(+e).keys()].map((i) => ({
      age: 0,
    }));
    setEditState({ ...editState, childrenArray: x, child: e });
  };

  async function handleSendOtp() {
    const otpRes = await sendOtp();
    if (otpRes?.status === 200) {
      setOtpModalOpen(true);
      togglePayModal();
      store.addNotification({
        title: commons.updatedSuccessfully,
        message: marketPlace.messages.otpHasSent,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
    }
  }

  const payAfterEdit = async (data) => {
    let otp = data?.otp;
    const Data = {
      brn: details.brn,
      beddingPreference: editState.beddingPreference
        ? editState.beddingPreference.toString()
        : "0",
      date_from: moment(editState.checkIn).format("YYYY-MM-DD"),
      date_to: moment(editState.checkOut).format("YYYY-MM-DD"),
      bookingCode: selected.bookingCode,
      mealOptionId: editState.mealOptionId.toString(),
      provider_id: 4,
      number_of_adult: editState.adult,
      children:
        editState.childrenArray && editState.childrenArray.length > 0
          ? editState.childrenArray
          : [],
      special_requests:
        editState.specialRequests && editState.specialRequests.length > 0
          ? editState.specialRequests
          : [],

      confirmData: {
        new_price: configData.rate_types[0].new_price,
        allocation_details: configData.rate_types[0].allocation_details,
        amend_charge: configData.rate_types[0].amend_charge,
      },
      payment_method: radio,
      otp: otp,
    };
    let book;
    book = await confirmBookingHotels(Data);

    if (book.status === 200) {
      store.addNotification({
        title: "Info!",
        message: `${commons.updatedSuccessfully}`,
        type: "info",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 3000,
          onScreen: true,
          pauseOnHover: true,
        },
      });
      fetchSafaWallet();
      fetchData();
      togglePayModal();
      setmodelupdated(true);
    } else if (book?.status === 202) {
      setActionType(book?.data?.actionType);
      setFormData(book?.data?.data?.formData);
    }
  };

  const setValueSpecialRequests = (checked, value) => {
    let x =
      editState.specialRequests && editState.specialRequests.length > 0
        ? [...editState.specialRequests]
        : [];
    if (checked) {
      x.push({ req: value });
    } else {
      x = x.filter((res) => res.req != value);
    }
    setEditState({
      ...editState,
      specialRequests: x,
    });
  };

  const changeAge = (index, e) => {
    let x = editState.childrenArray;
    x[index] = { age: e.target.value };
    setEditState({ ...editState, childrenArray: x });
  };

  const voucherData = async (romeKey) => {
    const res = await fetchVoucher(details?.brn, romeKey);

    if (res.status === 200) {
      // dispatch({ type: "getVoucher", payload: res?.data?.data });
      const children_ages =
        res?.data?.data?.children_ages?.length > 0
          ? res?.data?.data?.children_ages?.map(
              (item) => item?.age + " years old  "
            )
          : null;

      let response = VoucherPrinte;
      response = response.replace(
        "[avatar]",
        res?.data?.data?.company?.avatar
          ? `
					<img
						src=${onlineVisaURL}${res?.data?.data?.company?.avatar}
						alt="hotal-logo"
						width={100}
						height={100}
					/>
				`
          : `
					<div class="letter-logo">${res?.data?.data?.company?.name[0]}</div>
				`
      );

      response = response.replace(
        "[hotel_phone]",
        // 		res?.data?.data?.hotel_phone === "" ||
        // 			res?.data?.data?.hotel_phone == undefined
        // 			? `<div class="row">
        // 			<div class="col-3">
        // 				<h5>${inventory.messages.telephone}:</h5>
        // 			</div>
        // 			<div class="col-9">
        // 				<p>-</p>
        // 			</div>
        // 		</div>`
        // 			: `
        // 		<div class="row">
        // 			<div class="col-3">
        // 				<h5>${inventory.messages.telephone}:</h5>
        // 			</div>
        // 			<div class="col-9">
        // 				<p>${res?.data?.data?.hotel_phone}</p>
        // 			</div>
        // 		</div>
        // `

        res?.data?.data?.hotel_phone === "" ||
          res?.data?.data?.hotel_phone == undefined
          ? `<div class="row">
					<div class="col-5">
						<h5>${inventory.messages.telephone}:</h5>
					</div>
					<div class="col-7">
						<p>${res?.data?.data?.phones
              ?.map((item, index) => {
                return item.phoneType === "PHONEBOOKING" ||
                  item.phoneType === "PHONEHOTEL"
                  ? `<span>${item?.phoneType}:${item?.phoneNumber}</span>  `
                  : "";
              })
              .join(" &nbsp; &nbsp;&nbsp;  ")}</p>
					</div>
				</div>`
          : `
						<div class="row">
							<div class="col-5">
								<h5>${inventory.messages.telephone}:</h5>
							</div>
							<div class="col-7">
								<p>${res?.data?.data?.hotel_phone}</p>
							</div>
						</div>
				`
      );
      response = response.replace(
        "[companyName]",
        res?.data?.data?.company?.name
      );
      response = response.replace("[bookingCode]", res?.data?.data?.brn);
      response = response.replace("[printedOn]", res?.data?.data?.printedOn);
      response = response.replace(
        "[confirmationNumber]",
        res?.data?.data?.confirmationNumber
      );
      response = response.replace(
        "[bookingStatus]",
        res?.data?.data?.bookingStatus
      );
      response = response.replace("[bookedBy]", res?.data?.data?.bookedBy);
      response = response.replace("[hotel_name]", res?.data?.data?.hotel_name);
      response = response.replace(
        "[hotel_address]",
        res?.data?.data?.hotel_address
      );
      response = response.replace(
        "[passengerName]",
        res?.data?.data?.passenger?.name
      );
      response = response.replace(
        "[passengerNationality]",
        res?.data?.data?.passenger?.nationality ?? "-"
      );
      response = response.replace(
        "[passengerCountry]",
        res?.data?.data?.passenger?.country ?? "-"
      );
      response = response.replace(
        "[passengerservice_type]",
        res?.data?.data?.passenger?.service_type
      );
      response = response.replace(
        "[passengercity]",
        res?.data?.data?.hotel_city ?? "-"
      );
      response = response.replace("[checkin]", res?.data?.data?.checkin);
      response = response.replace("[checkout]", res?.data?.data?.checkout);
      response = response.replace("[roomType]", res?.data?.data?.roomType);
      response = response.replace(
        "[roomCategory]",
        res?.data?.data?.roomCategory
      );
      response = response.replace(
        "[rateBasis]",
        res?.data?.data?.rateBasis ?? "-"
      );
      response = response.replace(
        "[additionalRequests]",
        res?.data?.data?.additionalRequests ?? "-"
      );
      response = response.replace(
        "[messages_bookingReference]",
        inventory?.messages?.bookingReference
      );
      response = response.replace(
        "[messages_printedOn]",
        inventory?.messages?.printedOn
      );
      response = response.replace(
        "[messages_confirmationNO]",
        inventory?.messages?.confirmationNO
      );
      response = response.replace(
        "[messages_bookingStatus]",
        inventory?.messages?.bookingStatus
      );
      response = response.replace(
        "[messages_bookedBy]",
        inventory?.messages?.bookedBy
      );
      response = response.replace(
        "[messages_referenceNumber]",
        inventory?.messages?.referenceNumber
      );
      response = response.replace(
        "[messages_serviceProviderDetails]",
        inventory?.messages?.serviceProviderDetails
      );
      response = response.replace(
        "[messages_hotelName]",
        inventory?.messages?.hotelName
      );
      response = response.replace(
        "[messages_address]",
        inventory?.messages?.address
      );
      response = response.replace(
        "[messages_passengerDetails]",
        inventory?.messages?.passengerDetails
      );
      response = response.replace(
        "[messages_passengerName]",
        inventory?.messages?.passengerName
      );
      response = response.replace(
        "[messages_passengerNationality]",
        inventory?.messages?.passengerNationality
      );
      response = response.replace(
        "[messages_CountryResidence]",
        inventory?.messages?.CountryResidence
      );
      response = response.replace(
        "[messages_serviceType]",
        inventory?.messages?.serviceType
      );
      response = response.replace("[messages_city]", inventory?.messages?.city);
      response = response.replace(
        "[messages_checkin]",
        inventory?.messages?.checkin
      );
      response = response.replace(
        "[messages_checkOut]",
        inventory?.messages?.checkOut
      );
      response = response.replace(
        "[messages_roomType]",
        inventory?.messages?.roomType
      );
      response = response.replace(
        "[messages_MealPlan]",
        inventory?.messages?.MealPlan
      );
      response = response.replace(
        "[messages_additionalRequests]",
        inventory?.messages?.additionalRequests
      );
      response = response.replace(
        "[messages_roomCategory]",
        inventory?.messages?.roomCategory
      );
      response = response.replace("[messages_accommodation]", "Accommodation");
      response = response.replace(
        "[accommodation_value]",
        res?.data?.data?.accommodation ?? "-"
      );
      response = response.replace(
        "[messages_rate]",
        inventory?.messages?.Category
      );
      response = response.replace(
        "[specific_text]",
        `Payable through ${
          res?.data?.data?.supplier?.name ?? "-"
        }, acting as agent for the service operating company, details of which can be provided upon request. VAT: ${
          res?.data?.data?.supplier?.vatNumber ?? "-"
        } Reference:${res?.data?.data?.reference_number} `
      );
      response = response.replace("[rate_comments]", "Rate Comments");
      response = response.replace(
        "[rate_comments_value]",
        res?.data?.data?.rateComments ?? "-"
      );

      response = response.replace(
        "[rate_comments_msg_value]",
        res?.data?.data?.rateComments
          ? `<tr>
			<td>	
			<div class="voucher container bg-white p-4">
			<div class="row mt-5">
			<div class="col-4">
				<h5>Rate Comments :</h5>
			</div>
			<div class="col-8">
				<p>${res?.data?.data?.rateComments}</p>
			</div>
		</div>
				</div>
			</div>
			


			</div>
		</div>
	</div>
</div>


</td>

</tr>`
          : ""
      );
      let stars = "";
      let rates =
        +res?.data?.data?.rating > 0
          ? [...Array(+res?.data?.data?.rating).keys()]
          : [];
      rates.forEach(
        (i) =>
          (stars += `<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					viewBox="0 0 24 24"
				>
					<path
						fill="gold"
						d="m5.825 22l1.625-7.025L2 10.25l7.2-.625L12 3l2.8 6.625l7.2.625l-5.45 4.725L18.175 22L12 18.275L5.825 22Z"
					/>
				</svg>`)
      );
      response = response.replace("[rate_value]", stars ? stars : "-");
      var popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
      popupWin.document.open();
      popupWin.document.write(`
					<html>
					<head>
					<title> print Voucher</title>
					<style>

	@page {
	
	size: A4;
	
	margin:15px;			
	border: 1px solid black;
	
	}
	
	

					</style>
					
					</head>
					<body onLoad="{()=>window.print()}">${response}</body>
					</html>`);
      popupWin.document.close();
      setTimeout(() => {
        popupWin.print();
      }, 100);
    }
  };

  const dawnloadVoucher = async (romeKey) => {
    //const response = await getInvoice(brn);
    const voucher = await fetchVoucher(details?.brn, romeKey);
    if (voucher.status === 200) {
      dispatch({ type: "getVoucher", payload: voucher?.data?.data });

      // history.push("/inventory-voucher");
    }

    //const response = await fetchVoucher(details?.brn, romeKey);

    if (true) {
      const children_ages =
        voucher?.children_ages?.length > 0
          ? voucher.children_ages?.map((item) => item?.age + "years old  ")
          : null;
      var mywindow = window.open("", "PRINT", "height=600,width=1000");
      mywindow.document.write("<html><head><title>" + "file name" + "</title>");
      mywindow.document.write("</head><body >");
      mywindow.document.write(`

			<div className="voucher container bg-white p-4">
				<div className="container-wrapper p-4">
					<div className="voucher-header d-flex  my-5">
						<div className="d-flex flex-column header-name">
							<div className="header-img  p-4 text-center">
								
								${
                  voucher?.company?.avatar ? (
                    <img
                      src={voucher?.company?.avatar}
                      alt="hotal-logo"
                      width={100}
                      height={100}
                    />
                  ) : (
                    <div className="letter-logo">
                      ${voucher?.company?.name[0]}
                    </div>
                  )
                }
								{/* <img
								src={HotelColored}
								alt="hotal-logo"
								width={100}
								height={100}
							/> */}
								{/* <div className="letter-logo">{voucher?.bookingStatus[0]}</div> */}
							</div>
							<h4>${voucher?.company?.name}</h4>
						</div>

						<div className="header-data">
							<div className="row">
								<div className="col-6">
									<h5>${inventory.messages.bookingReference} :</h5>
								</div>
								<div className="col-6">
									<p>${voucher?.bookingReference}</p>
								</div>
							</div>

			

							<div className="row">
								<div className="col-6">
									<h5>${inventory.messages.itineraryNumber} :</h5>
								</div>
								<div className="col-6">
									<p>${voucher?.itineraryNumber}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-6">
									<h5>${inventory.messages.bookingStatus} :</h5>
								</div>
								<div className="col-6 ">
									<p className="text-success">${voucher?.bookingStatus}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-6">
									<h5>${inventory.messages.bookedBy} :</h5>
								</div>
								<div className="col-6">
									<p>${voucher?.bookedBy}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-6">
									<h5>${inventory.messages.referenceNumber} :</h5>
								</div>
								<div className="col-6">
									<p>${voucher?.referenceNumber}</p>
								</div>
							</div>
						</div>
					</div>
					<hr />

					<div className="services my-5">
						<h3>{inventory.messages.serviceProviderDetails}</h3>
						<div className="service-data px-4 py-2">
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.hotelName} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.hotel_name}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.address} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.hotel_address}</p>
								</div>
							</div>

							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.telephone} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.hotel_phone}</p>
								</div>
							</div>
						</div>
					</div>
					<hr />

					<div className="passenger my-5">
						<h3>${inventory.messages.passengerDetails}</h3>
						<div className="passenger-data px-4 py-2">
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.passengerName} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.passenger?.name}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.passengerNationality} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.passenger?.nationality}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.CountryResidence} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.passenger?.country}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.serviceType} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.passenger?.service_type}</p>
								</div>
							</div>
							<div className="row">
								<div className="col-3">
									<h5>${inventory.messages.city} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.passenger?.city}</p>
								</div>
							</div>
							<div className="passenger-info my-4">
								<div className="row">
									<div className="mb-3 col-sm-4">
										<h6 className="">${inventory.messages.checkin}</h6>
										<p className="font-weight-bold">${voucher?.checkin}</p>
									</div>
									<div className="mb-3 col-sm-4">
										<h6>${inventory.messages.checkOut}</h6>
										<p className="font-weight-bold">${voucher?.checkout}</p>
									</div>
									<div className="mb-3 col-sm-4">
										<h6>${inventory.messages.roomType}</h6>
										<p className="font-weight-bold">${voucher?.roomType}</p>
									</div>

									<div className="mb-2 col-sm-8">
										<h6>${inventory.messages.roomCategory}</h6>
										<p className="font-weight-bold">${voucher?.roomCategory} ${children_ages}***</p>
									</div>
									<div className="mb-2 col-sm-4">
										<h6>${inventory.messages.rateBasis}</h6>
										<p className="font-weight-bold">${voucher?.rateBasis}</p>
									</div>
								</div>
							</div>
							<div className="row mb-5">
								<div className="col-3">
									<h5>${inventory.messages.additionalRequests} :</h5>
								</div>
								<div className="col-9">
									<p>${voucher?.additionalRequests}</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>;

			 `);
      mywindow.document.write("</body></html>");

      // mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      setTimeout(() => {
        mywindow.print();
      }, 3000);
      //mywindow.close();

      return true;
    }
  };
  const AllRooms =
    RoomsList?.length > 0 ? (
      RoomsList.map((productItem, index) => (
        <InventoryDataRow
          productItem={productItem}
          details={details}
          index={index}
          toggleModalEdit={toggleModalEdit}
          setSelected={setSelected}
          toggleModalTerms={toggleModalTerms}
          toggleModalPassenger={toggleModalPassenger}
          setCancellationReason={setCancellationReason}
          toggleModal={toggleModal}
          voucherData={voucherData}
          dawnloadVoucher={dawnloadVoucher}
        />
      ))
    ) : (
      <tr>
        <td colSpan="10">
          <div className="product-build__product-no-data fullHeight">
            <i className="icx icx-eye-slash" aria-hidden="true"></i>
            <h4>{"No Result"}</h4>
          </div>
        </td>
      </tr>
    );

  const [swichToggle, setswichToggle] = useState(true);
  const handleSwitch = () => {
    setswichToggle(!swichToggle);
  };
  const groups = [
    { name: "Dr.", id: "558" },
    { name: "Madame", id: "1671" },
    { name: "Mademoiselle", id: "74195" },
    { name: "Messrs", id: "9234" },
    { name: "Miss", id: "15134" },
    { name: "Monsieur", id: "74185" },
    { name: "Mr.", id: "147" },
    { name: "Mrs", id: "149" },
    { name: "Ms", id: "148" },
    { name: "Sir", id: "1328" },
    { name: "Sir/Madam", id: "3801" },
  ];
  console.log("test");

  return (
    <>
      <div className="table-container">
        {details.gds == 6 &&
        details.reservation_status != "canceled" &&
        details.payment_status != "pending" &&
        +details?.updated_price === 0 ? (
          <div className="d-flex justify-content-end mx-3">
            <ShowForPermission permission="Manage-Inventory-Hotels">
              <a
                href={() => false}
                onClick={() => {
                  toggleModal();
                  // setSelected({
                  // 	...productItem,
                  // 	room_price_currency:
                  // 		productItem?.room_rates[0]?.room_price_currency,
                  // });
                  setCancellationReason("");
                }}
                className="mx-1 px-1  align-items-center"
                style={{ background: "#FF6868", height: "26px", color: "#fff" }}
                id={`tooltip-100-cancel`}
              >
                <i className="fas fa-ban mx-1 custom-icon-size text-white"></i>
                {teamManagement.cancel}
              </a>
            </ShowForPermission>
          </div>
        ) : (
          ""
        )}
        <Table striped className="table-update p-2 bg-white  m-0">
          <thead>
            <tr>
              <th>{inventory.messages.roomNo}</th>
              <th>{inventory.messages.arrival}</th>
              <th>{inventory.messages.departure}</th>
              <th>{inventory.messages.passengerName}</th>
              <th>{UmrahHolidays.roomType}</th>

              <th>{UmrahHolidays.adult}</th>
              <th>{UmrahHolidays.child}</th>
              <th>{inventory.messages.boardBasis}</th>
              {/* <th>{UmrahHolidays.maxCapacity}</th> */}
              <th>{UmrahHolidays.Price}</th>
              <th>{UmrahHolidays.actions}</th>
            </tr>
          </thead>
          <tbody>{AllRooms}</tbody>
        </Table>
      </div>

      {/* Edit Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal
        size="lg"
        className="model-bg"
        isOpen={modalEdit}
        toggle={toggleModalEdit}
        centered
      >
        <ModalHeader className="align-items-center" toggle={toggleModalEdit}>
          {UmrahHolidays.EditReservation}
        </ModalHeader>
        <ModalBody className="mx-md-5 ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="row  m-0">
                  <div className="col-md-6 ">
                    <div className="mybooking-input-wrapper marketplace-date ">
                      <DatePickerField
                        label={UmrahHolidays.checkIn}
                        date={editState.checkIn}
                        onChangeDate={(filterDate) => {
                          setEditState({
                            ...editState,
                            checkIn: filterDate,
                            checkOut: moment(filterDate).add(1, "d"),
                          });
                        }}
                      />
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="mybooking-input-wrapper marketplace-date ">
                      <DatePickerField
                        label={UmrahHolidays.checkOut}
                        date={editState.checkOut}
                        onChangeDate={(filterDate) => {
                          setEditState({
                            ...editState,
                            checkOut: filterDate,
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <SelectField
                  label={marketPlace.messages.adult}
                  options={editState.adultOption}
                  value={editState.adult}
                  onChange={(e) => {
                    setEditState({
                      ...editState,
                      adult: e.id,
                    });
                  }}
                />
              </div>
              <div className="col-md-6">
                <SelectField
                  label={marketPlace.messages.child}
                  options={editState.childOption}
                  value={editState.child}
                  onChange={(e) => {
                    setNewChildrenQuantity(e.id);
                  }}
                />
              </div>
              {editState.childrenArray && editState.childrenArray.length > 0
                ? editState.childrenArray.map((res, index) => {
                    return (
                      <div className="col-6">
                        <TextField
                          value={res.age}
                          label={`${marketPlace.messages.child}  ${UmrahHolidays.Age}`}
                          onChange={(e) => changeAge(index, e)}
                        />
                      </div>
                    );
                  })
                : ""}
              <div className="col-md-12">
                <div className="row   m-0">
                  <div className="col-12 col-md-6">
                    <SelectField
                      label={UmrahHolidays.RateBasis}
                      options={editState.rateOption}
                      value={editState.mealOptionName}
                      onChange={(e) => {
                        setEditState({
                          ...editState,
                          mealOptionId: e.id,
                          mealOptionName: e.name,
                        });
                      }}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <SelectField
                      label={UmrahHolidays.BeddingPreference}
                      options={editState.BeddingOption}
                      value={editState.beddingPreferenceName}
                      onChange={(e) => {
                        setEditState({
                          ...editState,
                          beddingPreference: e.id,
                          beddingPreferenceName: e.name,
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex my-2 col-12">
                <p>{marketPlace.specialRequest}</p>
                <Switch
                  className="react-switch mx-3"
                  //disabled
                  checked={swichToggle}
                  height={25}
                  handleDiameter={25}
                  width={55}
                  borderRadius={4}
                  onChange={handleSwitch}
                  uncheckedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "#fff",
                        paddingRight: 2,
                      }}
                    >
                      No
                    </div>
                  }
                  checkedIcon={
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "100%",
                        fontSize: 15,
                        color: "#fff",
                        paddingRight: 2,
                      }}
                    >
                      Yes
                    </div>
                  }
                />
              </div>
              {/* 							<div className="col-md-12 text-ltr special-request-containt">
               */}{" "}
              {editState.allowsSpecialRequests && SpecialRequests && swichToggle
                ? SpecialRequests.map((res, index) => {
                    return (
                      <>
                        <div class="form-check col-md-6 px-3">
                          <input
                            class="form-check-input"
                            type="checkbox"
                            value={res.code}
                            checked={
                              editState.specialRequests &&
                              editState.specialRequests.length > 0
                                ? editState.specialRequests.filter(
                                    (reas) => reas.req == res.code
                                  ).length > 0
                                : null
                            }
                            onChange={(e) => {
                              setValueSpecialRequests(
                                e.target.checked,
                                res.code
                              );
                            }}
                            id={"flexCheckIndeterminate" + index}
                          />
                          <label
                            class="form-check-label"
                            for={`flexCheckIndeterminate` + index}
                          >
                            {res.text}
                          </label>
                        </div>
                      </>
                    );
                  })
                : ""}
              {/* 							</div>
               */}{" "}
              <div className="col-12 my-2">
                <button
                  className="btn bg-nxt w-100 text-uppercase"
                  onClick={requsetEdit}
                >
                  {UmrahHolidays.search}
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter> */}
        {/* <div className="col-12">
						<button
							className="btn bg-nxt w-100 text-uppercase"
							onClick={requsetEdit}
						>
							{UmrahHolidays.search}
						</button>
					</div> */}

        {/* 					<Button
						className="bg-gray-150 text-dark border-0 px-4"
						onClick={toggleModalEdit}
					>
						{UmrahHolidays.cancel}
					</Button>
					<Button
						// disabled={["account", "otp"].some((el) => el === null || el === "")}
						className="px-5 w-40"
						color="primary"
						onClick={() => {
							requsetEdit();
						}}
					>
						{UmrahHolidays.edit}
					</Button>{" "} */}
        {/* </ModalFooter> */}
      </Modal>

      {/* pay Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal size="lg" isOpen={modalPay} toggle={togglePayModal}>
        <ModalHeader className="align-items-center" toggle={togglePayModal}>
          {/* {title == "deposit" ? "Pay Deposit" : "Pay Full Amount"} */}
          {inventory.messages.EditReservation}
        </ModalHeader>

        {actionType == "form-redirect" ? (
          <AWSpay data={formData} />
        ) : (
          <>
            <ModalBody className="payModal booking-details-pay-modal">
              <div className="container ">
                <div className=" d-flex justify-content-center modal-pay-box">
                  <div className=" text-center payment-box p-3">
                    <p>{inventory.messages.totalNewPrice}</p>
                    <p className="font-weight-bold">
                      {configData &&
                      configData.rate_types &&
                      configData.rate_types.length > 0
                        ? configData?.rate_types[0]?.paid_new_price.toFixed(2)
                        : ""}{" "}
                      {configData.rate_types && configData.rate_types.length > 0
                        ? configData?.rate_types[0]?.currency
                        : null}
                    </p>
                  </div>
                  <div className=" text-center  payment-box p-3 mx-3">
                    <p>{inventory.messages.amendmentCharge}</p>
                    <p className="font-weight-bold">
                      {configData?.rate_types &&
                      configData?.rate_types.length > 0
                        ? configData?.rate_types[0]?.paid_amend_charge.toFixed(
                            2
                          )
                        : ""}{" "}
                      {configData.rate_types && configData.rate_types.length > 0
                        ? configData?.rate_types[0]?.currency
                        : null}
                    </p>
                  </div>
                  <div className=" text-center must-box p-3">
                    <p>{inventory.messages.mustPay}</p>
                    <p className="font-weight-bold">
                      {configData?.rate_types?.[0]?.paid_deduct_amount != 0 ? (
                        paymnet?.exchange_rate_price + paymnet?.currency
                      ) : (
                        <span>
                          {configData?.rate_types &&
                          configData?.rate_types.length > 0
                            ? (configData?.rate_types[0]?.paid_deduct_amount).toFixed(
                                2
                              )
                            : ""}

                          {/* {configData.rate_types && configData.rate_types.length > 0
												? configData?.rate_types[0]?.currency
												: null} */}
                        </span>
                      )}
                    </p>
                  </div>
                  {configData?.rate_types &&
                  configData?.rate_types[0]?.paid_refund_amount &&
                  configData?.rate_types[0]?.paid_refund_amount > 0 ? (
                    <div className=" text-center  payment-box p-3 mx-3">
                      <p>{inventory.messages.refundAmount}</p>
                      <p className="font-weight-bold">
                        {configData?.rate_types &&
                        configData?.rate_types.length > 0
                          ? configData?.rate_types[0]?.paid_refund_amount.toFixed(
                              2
                            )
                          : ""}{" "}
                        {configData.rate_types &&
                        configData.rate_types.length > 0
                          ? configData?.rate_types[0]?.currency
                          : null}
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="d-flex mt-3 box-radio-contener">
                  {configData?.rate_types &&
                  +configData?.rate_types[0].paid_refund_amount === 0 &&
                  configData.rate_types[0].currency == ONLINE_CURRENCY ? (
                    <div
                      className={`radio-control box-radio ${
                        radio === "online" ? "border-active" : ""
                      }`}
                    >
                      <input
                        className="mx-1"
                        type="radio"
                        name="RechargeRadios"
                        id="radios1"
                        value="online"
                        onChange={(e) => {
                          setRadio(e.target.value);
                          setPaymnet(paymnetSAR);
                        }}
                      />
                      {/* <label htmlFor="payRadios1">
									{payment.messages.creditCard}
								</label> */}
                      <img src={masterCardImg} alt="master card" />
                      <p>Pay From Credit Card</p>
                    </div>
                  ) : null}
                  {listWallets.map((res) => {
                    return (
                      <>
                        {res.type === "debit" &&
                        res?.currency == configData?.rate_types[0].currency ? (
                          <div
                            className={`radio-control box-radio mx-2 ${
                              radio === "debit" &&
                              res.currency == paymnet.currency
                                ? "border-active"
                                : ""
                            }`}
                          >
                            <input
                              className="mx-1"
                              type="radio"
                              name="RechargeRadios"
                              id="radios2"
                              value="debit"
                              onChange={(e) => {
                                setRadio(e.target.value);
                                setPaymnet(res);
                              }}
                            />
                            {/* <label htmlFor="payRadios2">
									{payment.messages.bankTranfer}
								</label> */}
                            <img src={bankTranfertImg} alt="master card" />
                            <p style={{ margin: "10px auto 0" }}>
                              Pay From My Wallet Balance
                            </p>
                            <p style={{ margin: "0 auto" }}>
                              {res.amount} {res.currency}
                            </p>
                          </div>
                        ) : (
                          <div
                            className={`radio-control box-radio ${
                              radio === "credit" ? "border-active" : ""
                            }`}
                          >
                            <input
                              className="mx-1"
                              type="radio"
                              name="RechargeRadios"
                              id="radios3"
                              value="credit"
                              onChange={(e) => {
                                setRadio(e.target.value);
                              }}
                            />
                            {/* <label htmlFor="payRadios2">
											{payment.messages.bankTranfer}
										</label> */}
                            <img src={Group1068} alt="master card" />
                            <p>Pay From Safa Wallet Balance</p>
                          </div>
                        )}
                      </>
                    );
                  })}
                </div>

                {/* 			<div className="wallet d-flex  justify-content-center ">
							<div className=" bg-transparent mt-3 mb-2 w-100 text-center mr-3">
								<p>total new price</p>
								<p className="font-weight-bold text-success">
									{configData?.rate_types && configData?.rate_types.length > 0
										? configData?.rate_types[0]?.new_price.toFixed(2)
										: ""}{" "}
									SAR
								</p>
							</div>
							<div className=" bg-transparent mt-3 mb-2 w-100 text-center mr-3">
								<p>amend charge</p>
								<p className="font-weight-bold text-success">
									{configData?.rate_types && configData?.rate_types.length > 0
										? configData?.rate_types[0]?.amend_charge.toFixed(2)
										: ""}{" "}
									SAR
								</p>
							</div>
							<div className=" bg-transparent mt-3 mb-2 w-100 text-center mr-3">
								<p>must pay</p>
								<p className="font-weight-bold text-success">
									{configData?.rate_types && configData?.rate_types.length > 0
										? configData?.rate_types[0]?.deduct_amount.toFixed(2)
										: ""}{" "}
									SAR
								</p>
							</div>{" "}
							{configData?.rate_types &&
							configData?.rate_types[0]?.refund_amount &&
							configData?.rate_types[0]?.refund_amount > 0 ? (
								<div className=" bg-transparent mt-3 mb-2 w-100 text-center mr-3">
									<p>refund amount</p>
									<p className="font-weight-bold text-success">
										{configData?.rate_types && configData?.rate_types.length > 0
											? configData?.rate_types[0]?.refund_amount.toFixed(2)
											: ""}{" "}
										SAR
									</p>
								</div>
							) : (
								""
							)}
						</div>
 */}
              </div>
            </ModalBody>
            <ModalFooter>
              <button
                className="btn bg-nxt w-100 btn-pay-disabled"
                onClick={() => {
                  OTP_CHECK && radio === "debit"
                    ? handleSendOtp()
                    : payAfterEdit();
                }}
                disabled={
                  !(
                    radio === "online" ||
                    radio === "debit" ||
                    radio === "credit"
                  )
                }
              >
                PAY
              </button>
            </ModalFooter>
          </>
        )}
      </Modal>

      {otpModalOpen && OTP_CHECK ? (
        <VerifyOTPModal
          isOpen={otpModalOpen}
          header={marketPlace.messages.verifyOTP}
          toggle={() => setOtpModalOpen(!otpModalOpen)}
          actionData={{}}
          onVerify={payAfterEdit}
        />
      ) : null}

      {/* reject Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal
        size="md"
        className="mt-10	"
        isOpen={rejectModal}
        toggle={toggleRejectModal}
      >
        <ModalHeader className="align-items-center" toggle={toggleRejectModal}>
          {inventory.messages.EditReservation}
        </ModalHeader>
        <ModalBody className="payModal">
          <div className="container ">
            <div class="text-center" role="alert">
              <img src={reservationError} alt="" />

              <p className="m-0 mt-3 h5 text-gray">
                {inventory.messages.paymentDeclined}
              </p>
              <p className="h5 text-gray">{inventory.messages.checkTryAgain}</p>
            </div>

            <div className="box">
              <p className="title-modal-sm">{message}</p>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* success Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal
        size="md"
        className="mt-10	"
        isOpen={successModal}
        toggle={toggleSuccessModal}
      >
        <ModalHeader className="align-items-center" toggle={toggleSuccessModal}>
          {inventory.messages.EditReservation}
        </ModalHeader>
        <ModalBody className="payModal">
          <div className="container ">
            <div class="text-center" role="alert">
              <img src={reservationSuccess} alt="" />
              <p className="m-0 mt-3 h5 text-gray">
                {inventory.messages.reservationSuccessfully}
              </p>
              <p className="h5 text-gray">{inventory.messages.completed}</p>
            </div>

            <div className="box">
              <p className="title-modal-sm">{message}</p>
            </div>
          </div>
        </ModalBody>
      </Modal>

      {/* cancalation Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal
        size="lg"
        isOpen={modal}
        toggle={toggleModal}
        className="modal-cancel-reservation "
      >
        <ModalHeader className="align-items-center" toggle={toggleModal}>
          {inventory.messages.cancelReservation}
        </ModalHeader>
        <ModalBody>
          <div className=" col-md-12">
            <div className="mx-3 py-2	 row ">
              {selected?.cancellation_rules?.map((res) => {
                return (
                  <>
                    {!res.hasOwnProperty("fromDate") ? (
                      <>
                        <p className="w-100 mt-2">
                          {inventory.messages.before} {res.toDate}
                        </p>
                        <div className="col-md-6">
                          {res.cancelCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p>{inventory.messages.noCancellationCharge}</p>
                            </div>
                          ) : res.cancelRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              <p>{inventory.messages.cancellationRestricted}</p>
                            </div>
                          ) : (
                            <div className="boxCancellation">
                              {inventory.messages.cancellationCharge}
                              <p>
                                {" "}
                                {res.cancelCharge} {details?.currency}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 ">
                          {res.amendCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p> {inventory.messages.noAmendCharge}</p>
                            </div>
                          ) : res.amendRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              {inventory.messages.amendRestricted}
                            </div>
                          ) : details.gds != 5 && details.gds != 6 ? (
                            <div className="boxCancellation">
                              <p>{inventory.messages.amendmentCharge}</p>
                              {res.amendCharge}
                              {details?.currency}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : !res.hasOwnProperty("toDate") &&
                      !res.hasOwnProperty("noShowPolicy") ? (
                      <>
                        <p className="w-100 mt-3">
                          {inventory.messages.after} {res.fromDate}
                        </p>
                        <div className="col-md-6">
                          {res.cancelCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p>{inventory.messages.noCancellationCharge}</p>
                            </div>
                          ) : res.cancelRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              <p>{inventory.messages.cancellationRestricted}</p>
                            </div>
                          ) : (
                            <div className="boxCancellation">
                              {inventory.messages.cancellationCharge}
                              <p>
                                {" "}
                                {res.cancelCharge} {details?.currency}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 ">
                          {res.amendCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p>{inventory.messages.noAmendCharge}</p>
                            </div>
                          ) : res.amendRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              <p> {inventory.messages.amendRestricted}</p>
                            </div>
                          ) : details.gds != 5 && details.gds != 6 ? (
                            <div className="boxCancellation">
                              <p> {inventory.messages.amendmentCharge}</p>
                              {res.amendCharge} {selected.room_price_currency}
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    ) : res.hasOwnProperty("noShowPolicy") ? (
                      <>
                        <p className="w-100 mt-3">
                          {" "}
                          {inventory.messages.after} {res.fromDate}
                        </p>

                        <div className="col-md-12">
                          <div className="boxCancellation bg-dangerNew ">
                            <p>{inventory.messages.noShowPolicyCharge}</p>
                            {res.charge} {selected.room_price_currency}
                          </div>
                        </div>
                      </>
                    ) : (
                      <>
                        <p className="w-100 mt-3">
                          {" "}
                          between {res.fromDate} and {res.toDate}
                        </p>
                        <div className="col-md-6">
                          {res.cancelCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p>{inventory.messages.noAmendCharge}</p>
                            </div>
                          ) : res.cancelRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              <p>{inventory.messages.cancellationRestricted}</p>{" "}
                            </div>
                          ) : (
                            <div className="boxCancellation">
                              <p>{inventory.messages.cancellationCharge}</p>
                              <p>
                                {" "}
                                {res.cancelCharge} {details?.currency}
                              </p>
                            </div>
                          )}
                        </div>
                        <div className="col-md-6 ">
                          {res.amendCharge == 0 ? (
                            <div className="boxCancellation bg-Ground">
                              <p>{inventory.messages.noCancellationCharge}</p>
                            </div>
                          ) : res.amendRestricted ? (
                            <div className="boxCancellation bg-dangerr">
                              <p>{inventory.messages.amendRestricted}</p>
                            </div>
                          ) : details.gds != 5 && details.gds != 6 ? (
                            <div className="boxCancellation">
                              <p>{inventory.messages.amendmentCharge}</p>
                              <p>
                                {" "}
                                {res.amendCharge} {details?.currency}
                              </p>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </>
                    )}
                  </>
                );
              })}
            </div>

            {/* 	) : null} */}
          </div>
          <div className="col-md-12">
            <TextAreaField
              label={inventory.messages.cancellationReason}
              placeholder={inventory.messages.cancellationReason}
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="px-5 w-30"
            color="danger"
            onClick={() => cancelBooking()}
            style={{ fontFamily: "sans-serif !important" }}
          >
            {inventory.messages.cancelReservation}
          </Button>{" "}
        </ModalFooter>
      </Modal>

      {/* terms Modal ---------------------------------------------------------------------------------------------------------------------- */}
      <Modal size="lg" isOpen={modalTerms} toggle={toggleModalTerms}>
        <ModalHeader className="align-items-center" toggle={toggleModalTerms}>
          {inventory.messages.cancellationPolicy} &{" "}
          {inventory.messages.termsAndConditions}
        </ModalHeader>
        <ModalBody className="mx-3">
          <div className="row">
            <h6 className="h5 font-weight-bold">
              {inventory.messages.cancellationPolicy}
            </h6>
            <div className="col-12">
              {typeof selected?.cancellation_rules === "string" ? (
                <div className="col-md-12 text-right">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(selected?.cancellation_rules),
                    }}
                  />
                </div>
              ) : (
                <div className=" row col-md-12">
                  {selected?.cancellation_rules?.map((res) => {
                    return (
                      <>
                        {!res.hasOwnProperty("fromDate") ? (
                          <>
                            <p className="w-100 mt-2">
                              {inventory.messages.before} {res.toDate}
                            </p>
                            <div className="col-md-6">
                              {res.cancelCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p>
                                    {inventory.messages.noCancellationCharge}
                                  </p>
                                </div>
                              ) : res.cancelRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  <p>
                                    {inventory.messages.cancellationRestricted}
                                  </p>
                                </div>
                              ) : (
                                <div className="boxCancellation">
                                  {inventory.messages.cancellationCharge}
                                  <p>
                                    {" "}
                                    {res.cancelCharge} {details?.currency}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 ">
                              {res.amendCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p> {inventory.messages.noAmendCharge}</p>
                                </div>
                              ) : res.amendRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  {inventory.messages.amendRestricted}
                                </div>
                              ) : details.gds != 5 && details.gds != 6 ? (
                                <div className="boxCancellation">
                                  <p>{inventory.messages.amendmentCharge}</p>
                                  {res.amendCharge}
                                  {details?.currency}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : !res.hasOwnProperty("toDate") &&
                          !res.hasOwnProperty("noShowPolicy") ? (
                          <>
                            <p className="w-100 mt-3">
                              {inventory.messages.after} {res.fromDate}
                            </p>
                            <div className="col-md-6">
                              {res.cancelCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p>
                                    {inventory.messages.noCancellationCharge}
                                  </p>
                                </div>
                              ) : res.cancelRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  <p>
                                    {inventory.messages.cancellationRestricted}
                                  </p>
                                </div>
                              ) : (
                                <div className="boxCancellation">
                                  {inventory.messages.cancellationCharge}
                                  <p>
                                    {" "}
                                    {res.cancelCharge} {details?.currency}
                                  </p>
                                </div>
                              )}
                            </div>
                            <div className="col-md-6 ">
                              {res.amendCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p>{inventory.messages.noAmendCharge}</p>
                                </div>
                              ) : res.amendRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  <p> {inventory.messages.amendRestricted}</p>
                                </div>
                              ) : details.gds != 5 && details.gds != 6 ? (
                                <div className="boxCancellation">
                                  <p>{inventory.messages.amendmentCharge}</p>
                                  {res.amendCharge}{" "}
                                  {selected?.room_rate?.room_price_currency}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        ) : res.hasOwnProperty("noShowPolicy") ? (
                          <>
                            <p className="w-100 mt-3">
                              {" "}
                              {inventory.messages.after} {res.fromDate}
                            </p>

                            <div className="col-md-12">
                              <div className="boxCancellation bg-dangerNew ">
                                <p>{inventory.messages.noShowPolicyCharge}</p>
                                {res.charge} {details?.currency}
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <p className="w-100 mt-3">
                              {" "}
                              between {res.fromDate} and {res.toDate}
                            </p>
                            <div className="col-md-6">
                              {res.cancelCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p>{inventory.messages.noAmendCharge}</p>
                                </div>
                              ) : res.cancelRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  <p>
                                    {inventory.messages.cancellationRestricted}
                                  </p>{" "}
                                </div>
                              ) : details.gds != 6 ? (
                                <div className="boxCancellation">
                                  <p>{inventory.messages.cancellationCharge}</p>
                                  <p>
                                    {" "}
                                    {res.cancelCharge} {details?.currency}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div className="col-md-6 ">
                              {res.amendCharge == 0 ? (
                                <div className="boxCancellation bg-Ground">
                                  <p>
                                    {inventory.messages.noCancellationCharge}
                                  </p>
                                </div>
                              ) : res.amendRestricted ? (
                                <div className="boxCancellation bg-dangerr">
                                  <p>{inventory.messages.amendRestricted}</p>
                                </div>
                              ) : details.gds != 5 && details.gds != 6 ? (
                                <div className="boxCancellation">
                                  <p>{inventory.messages.amendmentCharge}</p>
                                  <p>
                                    {" "}
                                    {res.amendCharge} {details?.currency}
                                  </p>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </>
                        )}
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
          {selected?.termsAndConditions != "" ? (
            <div className="row">
              <p className="h5 font-weight-bold">
                {inventory.messages.termsAndConditions}
              </p>
              <div className="col-md-12 text-right">
                <div
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(selected?.termsAndConditions),
                  }}
                  style={{ textAlign: "start" }}
                />
              </div>
            </div>
          ) : (
            ""
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="bg-gray-150 text-dark border-0 px-4"
            onClick={toggleModalTerms}
          >
            {inventory.messages.cancel}
          </Button>
        </ModalFooter>
      </Modal>

      {/* Passenger Modal */}
      <Modal
        size="md"
        className="mt-10	"
        isOpen={modalPassenger}
        toggle={toggleModalPassenger}
      >
        <ModalHeader
          className="align-items-center"
          toggle={toggleModalPassenger}
        >
          {/* {inventory.messages.passengers} */}
          Guest Details
        </ModalHeader>
        <ModalBody className="payModal">
          <div className="container ">
            <div className="cartItem row align-items-start">
              <div className="col-md-3">
                <div className="form-group full-input">
                  <SelectField
                    label={"salutation"}
                    placeholder={"Mrs"}
                    options={groups}
                    name="salutation"
                    onBlur={() => false}
                  />
                </div>
              </div>

              <div className="col-md-9 px-4">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group full-input">
                      <TextField
                        placeholder={"First Name"}
                        label={"First Name"}
                        name="first_name"
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group full-input">
                      <TextField
                        placeholder={"last Name"}
                        label={"last Name"}
                        name="last_name"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end">
              <button className="btn btn-yellow px-5 py-2">Save Change</button>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {/* {modelupdated? */}
      <Modal isOpen={modelupdated}>
        <div className="p-3 border-bottom d-flex justify-content-between align-items-center">
          {" "}
          <h5 className="error-model-title">Edit Reservation</h5>{" "}
          <i
            className="fas fa-times pointer"
            onClick={() => {
              setmodelupdated(!modelupdated);
            }}
          ></i>
        </div>

        <div className="d-flex justify-content-center align-items-center flex-column py-5 p-3">
          <img
            className="w-25"
            src={reservationSuccess}
            alt="Your reservation has been successfully completed"
          />
          <p>Your reservation has been successfully completed</p>
        </div>
      </Modal>
    </>
  );
}
