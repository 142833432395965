import DateTimePickerField from "components/Form/DateTimePickerField/DateTimePickerField";
import SelectField from "components/Form/SelectField/SelectField";
import validate from "helpers/validate";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { fetchFlightClasses } from "services/marketplace";
import Locale from "translations";

export default function JourneysSelection({
	errors,
	setErrors,
	journeysList,
	setJourneysList,
}) {
	const { inventory } = Locale;
	const [classOptions, setClassOptions] = useState([]);
	function handleJourneysSelect(index) {
		const journeysListClone = [...journeysList];
		const currentSelectedJourney = journeysListClone[index];
		journeysListClone[index] = {
			...currentSelectedJourney,
			selected: !currentSelectedJourney?.selected,
		};
		setJourneysList(journeysListClone);
	}

	useEffect(() => {
		const getClass = async () => {
			const res = await fetchFlightClasses();
			// debugger
			const filteredClass = res.filter((item) => item !== "Economy");
			const classes = filteredClass.map((res, index) => {
				return { id: res, name: res === "PremiumEconomy" ? "Premium" : res };
			});
			setClassOptions(classes);
		};
		getClass();
	}, []);

	return (
		<div className="mx-md-4 mx-1 w-100">
			<h2 className="my-2 font-weight-bold">
				{inventory.messages.SelectJourneyChange}
			</h2>
			<div className="w-100">
				{journeysList?.map((journey, index) => {
					const classIndex = classOptions.findIndex(
						(i) =>
							i.id?.toLocaleLowerCase() ===
							journey.cabinClass?.toLocaleLowerCase()
					);
					const filteredClass = classOptions.slice(classIndex + 1);
					return (
						<div className="row m-0">
							<div
								className={`flight-actions-passenger col-md-6 col-12 col-6 my-2 align-self-baseline ${
									journey?.selected ? "selected" : ""
								}`}
								key={index}
								onClick={() => handleJourneysSelect(index)}
							>
								<span className="col-md-3 col-11 mx-1">{`${journey?.departure?.code} - ${journey?.arrival?.code}`}</span>
								{/* <span className="col-6">
									Ticket No. {journey?.ticketNumber}
								</span> */}

								{journey?.selected ? (
									<span className="col-1 text-center check-mark">
										<i className="fas fa-check"></i>
									</span>
								) : null}
							</div>

							<div className="  col-md-3">
								<DateTimePickerField
									placeholder="DD/MM/YYY"
									hasLabel={false}
									date={journeysList[index]?.booking_date || ""}
									onChangeDate={(e) => {
										const journeyListClone = [...journeysList];
										journeyListClone[index] = {
											...journeyListClone[index],
											booking_date: e,
										};
										setJourneysList(journeyListClone);

										const journeyErrors = [...errors?.journey];
										journeyErrors[index] = validate(
											{ name: "booking_date", value: e },
											{ required: journey?.selected }
										);
										setErrors({
											...errors,
											journey: journeyErrors,
										});
									}}
									isOutsideRange={(day) => {
										return false;
									}}
									minDate={moment().add(1, "d")["_d"]}
								/>
							</div>

							<div className="col-md-3 col-12 p-0">
								<SelectField
									hasLabel={false}
									placeholder=""
									value={journeysList[index]?.class?.name}
									options={filteredClass}
									onChange={(e) => {
										const journeyListClone = [...journeysList];
										journeyListClone[index] = {
											...journeyListClone[index],
											class: e,
										};
										setJourneysList(journeyListClone);

										const journeyErrors = [...errors?.journey];
										journeyErrors[index] = validate(
											{ name: "class", value: e },
											{ required: journey?.selected }
										);
										setErrors({
											...errors,
											journey: journeyErrors,
										});
									}}
									errors={errors?.["journey"]?.[index]?.class}
									color={
										errors?.["journey"]?.[index]?.class?.required
											? "danger"
											: ""
									}
								/>
							</div>
						</div>
					);
				})}
			</div>
			{!journeysList.some((journey) => journey.selected) ? (
				<small className="text-danger">
					{inventory.messages.selectJourneyError}
				</small>
			) : null}
		</div>
	);
}
