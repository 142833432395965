// import { Collapse, Icon, IconButton, Rating, Typography } from '@mui/material'
// import Image from 'next/image'
import React, { useState } from 'react'
// import ItinirareyDirectPlaneIcon from '../../../../Icons/ItinirareyDirectPlaneIcon'
// import ItinirareyPlaneIcon from '../../../../Icons/ItinirareyPlaneIcon'
// import imagePlaceHolder from "../../../../assets/images/image-placeholder.jpg"
// import img from '../../../../assets/images/packageimg.png'
//import styles from "../../../../styles/packages/modals/packagedetails.module.css"
//import { useTranslation } from '../../../../hooks/useTranslation'
import moment from 'moment/moment'
// import ItinareyHotelIcon from '../../../../Icons/ItinareyHotelIcon'
import { Collapse } from "reactstrap";
import { ItinirareyDirectPlaneIcon, ItinirareyPlaneIcon } from '../Details/shared/PackageIcon';

function ExternalTransportation({ type, isMobile, element }) {
  //const t = useTranslation()
  const [opened, setopened] = useState(false)
  const handelcollapse = () => {
    setopened(!opened)
  }
  return (<>
    <div className={"boxcollapse"}>
      <div 
			className={"closecollapse"}
			>
        <div 
				className={`collapseIcon ${opened && "opencoll"}`}
				>
         
              <ItinirareyPlaneIcon />
           
        </div>
        {isMobile && <p style={{ color: "##0F3E5E", fontWeight: "bold", marginRight: "auto", paddingLeft: "5px" }}>{"External Transportation"}</p>}
        {!isMobile && <>
          <div 
					className={"infoPackage"} 
					>
            <div className='d-flex wrap'>
              <p style={{ color: "#0F3E5E" }}>{element?.itemable?.from_port?.name} </p>
              {type == 'hotel' && 
								<div className="hotel-star-rate d-inline px-2">
									{[...Array(4).keys()].map((i) => (
										<i key={i} className="fa fa-star" aria-hidden="true"></i>
									))}
								</div>
						
							}
            </div>
            <span style={{ color: "#2D2D2D" }}>{element?.itemable?.from_country?.name}</span>
            <span style={{ color: "##2D2D2D" }}>{moment(element?.itemable?.arrival_at).format("DD/MM/YYYY")}</span>
          </div>
          {type == 'transportations' &&
            <>
              <div 
							className="stepsinfo"
							>
                <div className={"point"}></div>
                <span
								 className={"stepsPackage"}
								 >{element?.itemable?.transits?.length > 0 ? `${element?.itemable?.transits?.length} Stop` : "Direct"} </span>
                <div 
								 className={"airplaneicon"}
								 >
                  <ItinirareyDirectPlaneIcon />
                </div>
              </div>
              <div className='d-flex flex-row align-items-center'>
                <div 
								className="infoPackage"
								>
                  <p style={{ color: "#0F3E5E" }} >{element?.itemable?.to_port?.name}</p>
                  <span style={{ color: "#2D2D2D" }} >{element?.itemable?.to_country?.name}</span>
                  <span style={{ color: "##2D2D2D" }} > {moment(element?.itemable?.departure_at).format("DD/MM/YYYY")} </span>
                </div>
                <div>
                  <button onClick={handelcollapse} className='btn '>
                    <i class="fas fa-chevron-down fa-1x text-grey"></i>
										</button>
                </div>
              </div>
            </>}
        </>}

        {/* {isMobile &&
          <div>
            <IconButton onClick={handelcollapse}><Icon className='material-symbols-outlined'>expand_more</Icon></IconButton>
          </div>
        } */}
      </div>
      {type == 'transportations' &&
        <Collapse
				isOpen={opened}
        >
          <div 
					className={"opendcollapse"}
					 >
            <div 
						className={"airstep"}
						>
              <div 
							className={"imgscompany"}
							>
                <img
                  src={element?.itemable?.transporter?.logo}
                  // width={img.width}
                  // height={img.height} alt={element?.itemable?.transporter?.name}
                  // onError={(e) => (e.currentTarget.src = imagePlaceHolder.src)}
                />
                <p>{element?.itemable?.transporter?.name}</p>
              </div>
              <div 
							 className={"tripdetails"}
							>
                <div>
                  <p style={{ color: "#0F3E5E" }}>{element?.itemable?.from_port?.name}</p>
                  <span style={{ color: "#2D2D2D" }}>{moment(element?.itemable?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
                </div>
                <div>
                  <span 
									className={"triptime"} 
									>- </span>
                </div>
                <div>
                  <p style={{ color: "#0F3E5E" }}>{element?.itemable?.to_port?.name}</p>
                  <span style={{ color: "#2D2D2D" }}>{moment(element?.itemable?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
                </div>
              </div>
            </div>

            {element?.itemable?.transits?.map((transit, index) => {
              return (
                <div
								 className={"airstep"} 
								 key={index}>
                  <p 
									 className={"transite"}
									>
                    Stop
                    <span className='d-inline-block mx-1' style={{ color: "#0372BC" }}>{transit?.city?.name}</span>
                  </p>
                  <div 
									// className={styles.imgscompany}
									>
                    <img
                      src={transit?.transporter?.logo}
                      // width={img.width}
                      // height={img.height} alt={transit?.transporter?.name}
                      // onError={(e) => (e.currentTarget.src = imagePlaceHolder.src)}
                    />
                    <p>{transit?.transporter?.name}</p>
                  </div>
                  <div 
									className={"tripdetails"}
									>
                    <div>
                      <p style={{ color: "#0F3E5E" }} >{transit?.departure_port?.name}</p>
                      <p stylr={{ color: "#2D2D2D" }} > {moment(transit?.departure_at).format("MMM Do YYYY, h:mm A ,ddd")}
                      </p>
                    </div>
                    <div>
                      <span 
											className={"triptime"} 
											>- </span>
                    </div>
                    <div>
                      <p style={{ color: "#0F3E5E" }}>{transit?.arrival_port?.name}</p>
                      <span style={{ color: "#2D2D2D" }}>{moment(transit?.arrival_at).format("MMM Do YYYY, h:mm A ,ddd")}</span>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </Collapse>}

    </div>

  </>
  )
}

export default ExternalTransportation