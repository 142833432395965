import React from "react";
import { useSBSState } from 'context/global';
import { InternalTransportationIcon } from 'modules/market-place/PackageResponse/Details/shared/PackageIcon';

export default function ItineraryInternalTransportation({ isMobile, element }) {
	const { locale } = useSBSState();
	return (
		<>
			<div className="boxcollapse">
				<div className="closecollapse">
					<div className="collapseIcon">
						<InternalTransportationIcon />
					</div>
					{isMobile && 
						<p
							style={{
								color: "##0F3E5E",
								fontWeight: "bold",
								marginRight: "auto",
								paddingLeft: "5px",
							}}
						>
							{element?.item?.name}
						</p>
					}
					{!isMobile && 
						<>
							<div className={"info"}>
								<div className="d-flex wrap">
									<p style={{ color: "#0F3E5E" }}>
										{element?.itemable?.transporter_name[locale]}{" "}
									</p>
								</div>

							</div>
						</>
					}
				</div>
			</div>
		</>
	);
}
