import React from 'react'
import Locale from 'translations';



function ItineraryBox({ children }) {
    const {packages} = Locale;

  return (
    <>
      <p style={{ color: "#0F3E5E",fontWeight:"bold" }}>{packages.packageItinerary}</p>
      <div 
      className={"itineraryBox"}
      >{children}</div>
    </>
  );
}

export default ItineraryBox