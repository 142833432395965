import React, { useState } from "react";
// import { useTranslation } from "../../../../hooks/useTranslation";
// import styles from "../../../../styles/umrah/modals/hotel.module.css";
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import ImageGallery from './ImageGallery';
import Locale from 'translations';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function HotelTab({ room, details }) {
  const { packages } = Locale;
  const [tabState, setTabState] = useState(0);
  // const t = useTranslation();
  const handleChange = (event, newValue) => {
    setTabState(newValue);
  };

  const roomImages =
    details?.itemable?.hotel?.images?.length > 0
      ? details?.itemable?.hotel?.images?.map((i) => ({
        src: i,
        alt: i,
      }))
      : [];
  const sxResponsive = {
    ".dialogHotelTabs_package": {
      background: "red !important",
    },
    ".MuiTabs-flexContainer": {
      "@media(min-width: 320px )and (max-width: 480px)": {
        display: "flex",
        paddingInline: "8px",
        gap: 2,
        flexWrap: "wrap",
      },
      "@media(min-width: 481px )and (max-width: 768px)": {
        display: "flex",
        justifyContent: "space-between",
      },
    },
    ".MuiTab-root": {
      "@media(min-width: 320px )and (max-width: 768px)": {
        padding: "0px",
        maxWidth: "auto",
        minWidth: "auto",
        fontSize: "12px",
        color: "#000",
        fontWeight: "bold",
      },
    },
    ".Mui-selected": {
      "@media(min-width: 320px )and (max-width: 768px)": {
        color: "#D7A04B",
      },
    },
    ".MuiDialog-paperScrollBody": {
      "@media(min-width: 320px )and (max-width: 425px)": {
        maxWidth: "calc(100% - 15px) !important",
        margin: "auto",
        width: "auto",
      },
    },
    ".MuiTabs-indicator.css-1nse1li-MuiTabs-indicator": {
      "@media(min-width: 320px )and (max-width: 480px)": {
        display: "none !important",
      },
    },
  };
  // check if hotel facilities is json casue somtimes the facilities is json and sometimes facilities is array
  function isJson(strObj) {
    try {
      JSON.parse(strObj)
    } catch (error) {
      return false
    }
    return true;
  }
  let hotelFacilities = details?.itemable?.hotel?.facilities;
  hotelFacilities = hotelFacilities && isJson(hotelFacilities) ? JSON.parse(hotelFacilities) : hotelFacilities;
  
  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
  return (
    <div className="mb-2 border-2 border-top ">
      <div className={"TabsBox"} style={{ px: 0, ...sxResponsive }}>

        <Nav tabs className="w-100">
						<NavItem>
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "1",
									}
								)}`}
								onClick={() => {
									toggle("1");
								}}
							>
								<div className="product-build-list-item">
									
									<span className="text mx-1">
                  {packages.gallery}
									</span>
								</div>
							</NavLink>
						</NavItem>
						<NavItem className="">
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "2",
									}
								)}`}
								onClick={() => {
									toggle("2");
								}}
							>
								<div className="product-build-list-item">
								

                <span className="text mx-1">{packages.aboutHotel}</span>
								</div>
							</NavLink>
						</NavItem>
            <NavItem className="">
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "3",
									}
								)}`}
								onClick={() => {
									toggle("3");
								}}
							>
								<div className="product-build-list-item">
								

                <span className="text mx-1">{packages.map}</span>
								</div>
							</NavLink>
						</NavItem>
            <NavItem className="">
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "4",
									}
								)}`}
								onClick={() => {
									toggle("4");
								}}
							>
								<div className="product-build-list-item">
								

                <span className="text mx-1">{packages.facilities}</span>
								</div>
							</NavLink>
						</NavItem>
            <NavItem className="">
							<NavLink
								className={` d-flex align-items-center justify-content-center border-0  p-0 ${classnames(
									{
										active: activeTab === "5",
									}
								)}`}
								onClick={() => {
									toggle("5");
								}}
							>
								<div className="product-build-list-item">
								

                <span className="text mx-1">{packages.policies}</span>
								</div>
							</NavLink>
						</NavItem>
					</Nav>
 
      </div>

      <TabContent activeTab={activeTab} >
					<TabPane tabId="1">
						{activeTab === "1" ? <ImageGallery images={roomImages} /> : null}
					</TabPane>

					<TabPane tabId="2">{activeTab === "2" ? <div>
          <dt>About Hotel</dt>
          <p className='mx-3'>{details?.itemable?.hotel?.description}</p>
          </div>: null}</TabPane>
          <TabPane tabId="3">
						{activeTab === "3" ?  <iframe
            style={{ width: "100%", height: "300px" }}
            src={`https://maps.google.com/?q=${details?.itemable?.hotel?.longitude},${details?.itemable?.hotel?.latitude}&output=embed`}
            title="hotel map"
          ></iframe> : null}
					</TabPane>
          <TabPane tabId="4">
						{activeTab === "4" ?  <div className='d-flex flex-wrap align-items-center'>
            {hotelFacilities?.map((facility, index) => {
              return (
                <p key={`facility-${index}`} className='mt-1 col-4'>
                  <i className="fas fa-check text-success"></i>
                  <span className='mx-1'> {facility}</span>
                </p>
              )
            })}
          </div> : null}
					</TabPane>
          <TabPane tabId="5">
						{activeTab === "5" ?<>
            <dt>policies</dt>
          <p className='mx-3'>{details?.itemable?.hotel?.terms_conditions}</p>
            </> : null}
					</TabPane>
				</TabContent>
    </div>
  );
}

export default HotelTab;
