import React, { useEffect, useState } from "react";
import iconNotification from "assets/images/customHeader/wallet.svg";
import disimg from "assets/images/disimg.svg";
import actimg from "assets/images/actimg.svg";
import visa from "assets/images/actVisa.svg";
import disVisa from "assets/images/grayVisa.svg";

import walletgray from "assets/images/walletgray.svg";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import SelectField from "components/Form/SelectField/SelectField";
import { getPrebookListWallets } from "services/wallet";
import AWSpay from "components/AWSpay";
import Locale from "translations";
import OTPInput from 'react-otp-input';
import { sendOtp } from 'services/marketplace';
import ShowForPermission from 'helpers/showForPermission';

function NewPaymentAction({
	currency,
	Price,
	secondCurrency,
	actionType,
	formData,
	canPayLater,
	isOpen,
	toggleModal,
	secondPrice,
	setFormData,
	shouldPayCurrency,
	setActionType,
	onPayment,
	OTP
}) {


	const { payment, inventory, marketPlace } = Locale;

	const [check, setcheck] = useState("");
	const [otpNumber, setOtpNumber] = useState("");
	const [listWallets, setListWallets] = useState([]);
	const [paymnet, setPaymnet] = useState({});
	const [paymnetOnline, setpaymnetOnline] = useState({});
	const [canPayOnline, setCanPayOnline] = useState(false);
	const [isDrop, setisDrop] = useState(false)
	const ONLINE_CURRENCY = process.env.REACT_APP_ONLINE_CURRENCY;
	// console.log(ONLINE_CURRENCY);

	useEffect(() => {
		async function fetchSafaWallet() {
			setListWallets([]);
			const walletRes = await getPrebookListWallets({
				currency: shouldPayCurrency ? shouldPayCurrency : secondCurrency
					? currency + "," + secondCurrency + "," + ONLINE_CURRENCY
					: currency + "," + ONLINE_CURRENCY,
				price: Price,
			});
			if (walletRes.status >= 200 && walletRes?.status < 300) {

				const data = walletRes?.data?.data?.filter(
					(i) => i.amount > 0
				);
				const dataOnline = walletRes?.data?.data?.filter(
					(i) => i.currency === ONLINE_CURRENCY
				)[0];
				const dataPro = walletRes?.data?.data?.filter(
					(i) => i.currency === currency
				)[0];
				// setPaymnet(dataPro);
				let Online = dataOnline ? dataOnline : dataPro
				setCanPayOnline(dataOnline ? dataOnline : false)
				setpaymnetOnline(Online);
				setcheck(dataOnline ? "online" : "debit");
				setPaymnet(Online);
				setListWallets(data);
			}
		}
		fetchSafaWallet();
		return () => { };
	}, []);

	const sendMassage = async () => {
		// 
		const res = await sendOtp()
		if (res?.status === 200 || res?.status === 201) {
			setActionType("Otp")
		}
	}



	// console.log(paymnet);


	return (
		<>
			<Modal className="mt-5 " isOpen={isOpen} toggle={toggleModal}>
				<ModalHeader
					className="align-items-center font-weight-bold hd-title-model"
					toggle={toggleModal}
				>
					{inventory.messages.ChoosePaymentMethod}
				</ModalHeader>
				<ModalBody>
					{actionType == "form-redirect" ? (
						<AWSpay data={formData} />
					) : actionType == "Otp" ? (
						<>
							<p className="text-center font-weight-bolder h4 mt-3">
								{inventory.messages.enterVerificationCode}
							</p>
							<div
								className="my-3 d-flex flex-column px-3"
								style={{ minHeight: "10vh", width: "100%" }}
							>
								<OTPInput
									className="w-100"
									value={otpNumber}
									onChange={setOtpNumber}
									numInputs={4}
									renderSeparator={<span className="mx-2">-</span>}
									inputStyle={"w-25 input-OTP"}
									renderInput={(props) => (
										<input style={{ width: "25%" }} {...props} />
									)}
								/>
								<a
									className="btn btn-link h4 mt-2"
									onClick={() => sendMassage()}
									href={() => false}
								>
									{inventory.messages.resendCode}
								</a>
							</div>
							<ModalFooter>
								<Button
									className=" border-0 px-4 "
									onClick={() => {
										toggleModal();
									}}
									color="danger"
								>
									{inventory.messages.cancel}
								</Button>

								<Button
									className="px-5 w-30 btn-pay-prim-disabled"
									disabled={
										!(
											check === "online" ||
											check === "debit" ||
											check === "credit" ||
											check === "pay_later"
										)
									}
									color="primary"
									onClick={() => {
										onPayment(
											check,
											paymnet?.currency,
											otpNumber,
											paymnet?.exchange_rate_price
										);
									}}
								>
									{inventory.messages.pay}
								</Button>
							</ModalFooter>
						</>
					) : (
						<div className="boxs-payment px-md-5">
							{/* <div className="d-flex align-self-end">
								<img className="mx-2" src={iconNotification} alt="" />
								<div>
									<h5 className="">{inventory.messages.Amount}</h5>
									<h3 style={{ fontWeight: "bold" }} className=" text-success">
										{Price} {currency}
									</h3>
								</div>
							</div> */}

							{canPayLater ? (
								<div
									className={`item-payment  pay-${
										check == "pay_later" ? "active" : ""
									} `}
								>
									<div className="d-flex align-items-center justify-content-between">
										<div className="raido-label">
											<input
												type="radio"
												onClick={() => {
													setcheck("pay_later");
													setPaymnet({
														currency: currency,
														price: Price,
														exchange_rate_price: Price,
													});
												}}
												className="check-payment"
												id="pay_later"
												name="check-payment"
											/>
											<label htmlFor="pay_later">
												{inventory.messages.ConfirmNowPayLater}{" "}
											</label>
										</div>
										<img
											src={check == "pay_later" ? actimg : disimg}
											alt=""
											srcset=""
										/>
									</div>
								</div>
							) : (
								""
							)}

<ShowForPermission permission="Manage-Online-Payment">
						
						{canPayOnline ? (
								<div
									className={`item-payment  pay-${
										check == "online" ? "active" : ""
									} `}
								>
									<div className="d-flex align-items-center justify-content-between">
										<div className="raido-label">
											<input
												type="radio"
												onClick={() => {
													setcheck("online");
													setPaymnet(paymnetOnline);
												}}
												className="check-payment"
												id="online"
												name="check-payment"
												checked={check === "online"}
											/>
											<label htmlFor="online">
												{inventory.messages.PayFromCreditCard}
											</label>
										</div>
										<img
											src={check == "online" ? visa : disVisa}
											alt=""
											srcset=""
										/>
									</div>
								</div>
							) : null}
</ShowForPermission>
							<div
								className={`item-payment  pay-${
									check == "debit" ? "active" : ""
								} `}
							>
								<div className="d-flex align-items-center justify-content-between">
									<div className="raido-label">
										<input
											type="radio"
											onClick={() => {
												setcheck("debit");
											}}
											className="check-payment"
											id="debit"
											name="check-payment"
										/>
										<label htmlFor="debit">
											{inventory.messages.PayFromMyWallet}
										</label>
									</div>
									<img
										width="25px"
										src={check == "debit" ? iconNotification : walletgray}
										alt=""
										srcset=""
									/>
								</div>
								{check == "debit" ? (
									<>
										{" "}
										<div className="mx-md-5 mt-1 fake-select">
											{paymnet?.currency ?
											<div role='button' onClick={()=>{
												setisDrop(!isDrop)
											}} className='d-flex justify-content-between w-100'>
											<p>
												{paymnet.currency}
														</p>
														<p className="amount">
															{paymnet.amount} {paymnet.currency}
														</p>
														<i class="fas fa-angle-down"></i>
														</div>
														:<>
											<p className="placholder">{marketPlace.selectWallet}</p>
											<i class="fas fa-angle-down"></i>
											</>

										}
										{isDrop && 
										<div className='dropdown-list'>
										{listWallets &&
											listWallets?.length > 0 &&
											listWallets.map((res, index) => {
												return (
													<div
														className={`mx-md-5 drop-select pay-${
															paymnet.currency == res.currency ? "active" : ""
														}`}
														role="button"
														onClick={() => {
															setPaymnet(res);
															setisDrop(!isDrop)

														}}
													>
														<p className="placholder d-flex">
															{/* {paymnet.currency == res.currency ? (
																<i class="fa fa-check mx-1 text-success"></i>
															) : null} */}
															{/* {paymnet.currency == res.currency ? (
																<div
																	className={`visa-radio-selected  	active me-2
																	`}
																></div>
															) : null} */}

															{res.currency}
														</p>
														<p className="amount">
															{res.amount} {res.currency}
														</p>
													</div>
												);
											})}
											</div>}
										</div>
									</>
								) : (
									""
								)}
							</div>

							<div className="d-flex justify-content-center">
								<img className="mx-2" src={iconNotification} alt="" />
								<div>
									<h5 className="">{inventory.messages.dueAmount}</h5>
									<h3 style={{ fontWeight: "bold" }} className=" text-success">
										{check == "online"
											? (
													paymnet?.exchange_rate_price +
													paymnet?.exchange_rate_price * 0.025
											  ).toFixed(2)
											: paymnet?.exchange_rate_price?.toFixed(2)}{" "}
										{paymnet?.currency}
									</h3>
									{check == "online"
										? inventory.messages.AllPaymentsMadeByCredit
										: ""}
								</div>
								{check == "pay_later" && secondCurrency && secondPrice ? (
									<>
										<div className="px-3"></div>
										<img className="mx-2" src={iconNotification} alt="" />
										<div className="">
											<h5 className="">{inventory.messages.Amount}</h5>
											<h3
												style={{ fontWeight: "bold" }}
												className=" text-success"
											>
												{secondPrice} {secondCurrency}
											</h3>
										</div>
									</>
								) : (
									""
								)}
							</div>
							<button
								className="newpay-btn btn"
								disabled={check == ""}
								onClick={() => {
									if (OTP && check == "debit") {
										sendMassage();
									} else {
										onPayment(
											check,
											paymnet?.currency,
											otpNumber,
											paymnet?.exchange_rate_price
										);
									}
								}}
							>
								{" "}
								{payment.messages.pay}
							</button>
						</div>
					)}
				</ModalBody>
			</Modal>
		</>
	);
}

export default NewPaymentAction;
