import internal from " ../../assets/images/icons/Insurance-gold.svg";
import Locale from 'translations';

export default function InternalTransportationsPreview(props) {
	const { productsBuilder } = Locale;

	return (
		<div className=" product-build__product-collpase itenary-box  d-flex justify-content-center align-items-center flex-row">
			<div className="img-circle-wrapper icon-pro-Itinerary ">
				<img src={internal} alt="" srcset="" />
				{/* 							<span className="icx icx-transport"></span>
				 */}{" "}
			</div>
			<div className="button-collapse">
				<div className="title-style-container">
					<div className="right-items-container ">
						<div className="product-content-items px-4 p-2">
							<h6 className="blue-txt-item">{productsBuilder.internalTransportations}</h6>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
