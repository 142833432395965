import React from "react";
import InternalTransportation from '../ProductElements/InternalTransportations/InternalTransportations';
import Insurance from '../ProductElements/Insurance';
import Hotel from "../ProductElements/Hotel.old";
import OtherService from '../ProductElements/Other';
import ExternalTransportations from "../ProductElements/ExternalTransportations";


function ItinareyPackage({ isMobile, details }) {
  const itineraryElements = () => {
		return details?.itinerary?.map((element, index) => {
			switch (element ? element.item?.name : "") {
				case "external transportation":
					return (
						<ExternalTransportations
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
							type={"transportations"}
						/>
					);
				case "internal transportation":
					return (
						<InternalTransportation
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				// case 3:
				// 	return (
				// 		<DomesticFlight
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);
				// case "visa":
				// 	return (
				// 		<Visa
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);
				case "insurance":
					return (
						<Insurance
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				case "hotel":
					return (
						<Hotel
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
              type={"hotel"}
						/>
					);
				// case 7:
				// 	return (
				// 		<AttractionLandmark
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);
				case "other service":
					return (
						<OtherService
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				default:
					break;
			}
		});
	};

  return <div className='mt-3'>{itineraryElements()}</div>;
}

export default ItinareyPackage;
