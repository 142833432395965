import { useAuthState } from "context/auth";
import { Progress } from "reactstrap";
import buildingIcon from "assets/images/customRegister/Icon awesome-building.svg";
import imageIcon from "assets/images/customRegister/Icon awesome-image.svg";
import userIcon from "assets/images/customRegister/Icon awesome-user-tie.svg";
import materialIcon from "assets/images/customRegister/Icon material-verified-user.svg";
import safaOfflineIcon from "assets/images/customRegister/safa-offline-icon.svg";

export default function RegistrationProgress({ registerType }) {
	const normalRegister = (registerType && registerType === "normal") || null;
	const progressBar = useAuthState();

	return (
		<div className="main-bar mt-5">
			<Progress
				className="main-bar__progress mx-3"
				color="progress-bar"
				value={progressBar.progress}
			/>

			<div className="main-bar__box">
				<div
					className={`main-bar__item ${progressBar.step > 1 ? "active" : ""
						} ${progressBar.step === 1 ? "current" : ""}`}
				>
					<img src={normalRegister ? userIcon : safaOfflineIcon} alt="" />
				</div>
				<div
					className={`main-bar__item ${progressBar.step > 2 ? "active" : ""
						} ${progressBar.step === 2 ? "current" : ""}`}
				>
					<img src={normalRegister ? buildingIcon : userIcon} alt="" />
				</div>
				<div
					className={`main-bar__item ${progressBar.step > 3 ? "active" : ""
						} ${progressBar.step === 3 ? "current" : ""}`}
				>
					<img src={normalRegister ? imageIcon : materialIcon} alt="" />
				</div>

				{normalRegister ?
					<div
						className={`main-bar__item ${progressBar.step > 4 ? "active" : ""
							} ${progressBar.step === 4 ? "current" : ""}`}
					>
						<img src={materialIcon} alt="" />
					</div>
					: null
				}
			</div>
		</div>
	);
}
