import noHotel from "assets/images/placeHoleder.png";
import { useEffect, useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import { store } from "react-notifications-component";
import { useHistory, useLocation } from "react-router-dom";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { fetchImages } from "services/auth";
import {
	fetchGroupReservationPDF,
	fetchGroupReservationVoucher,
	getInvoice,
	payFees,
	payGroupsFees,
} from "services/marketplace";
import Locale from "translations";
import SafaLogo from "assets/images/MarketInvoice/SafaLogo.png";
import RequestStatus from "./shared/requestStatus";
import PassengersTable from "./Reservation/Individuals/PassengersTable";
import CustomModal from "./Modal";
import Payment from "./Payment";
import PaymentDetails from "./Reservation/Groups/PaymentDetails";
import RoomDetails from "./Reservation/Groups/RoomDetails";
import TermsDetails from "./Reservation/Groups/TermsDetails";
import { InvoicePrint } from "components/Printing/InvoicePrint";
import NewPaymentAction from "components/NewPaymentAction";
import { TentativePDF } from "components/Printing/TentativePDF";
import { DefinitVoucher } from "components/Printing/DefinitVoucher";

export default function BookingDetails({
	details,
	setDetails,
	GroupInventory,
}) {
	const { inventory, marketPlace } = Locale;
	const translate = inventory.viewReservation;
	const [hotelInfo, setHotelInfo] = useState(details);
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const scroll = queryParams.get("scroll");
	const [Silders, setSilders] = useState([]);
	const [active, setActive] = useState(0);
	const [modalNote, setModalNote] = useState(false);
	const toggleModalNote = () => setModalNote(!modalNote);
	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});

	const [modalPay, setModalPay] = useState(false);
	const togglePayModal = () => setModalPay(!modalPay);
	const [modalPaymentDetails, setModalPaymentDetails] = useState(false);
	let status_definite = hotelInfo.status === "definite";
	let status_tentative = hotelInfo.status === "tentative";
	const togglePaymentDetailsModal = () =>
		setModalPaymentDetails(!modalPaymentDetails);

	const [modalPromocode, setModalPromocode] = useState({
		toggle: false,
		title: "promo Code",
	});

	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
		? JSON.parse(process.env.REACT_APP_OTP_CHECK)
		: false;

	const togglePromocodeModal = (titleModal = "Promo Code") =>
		setModalPromocode({
			toggle: !modalPromocode.toggle,
			title: titleModal,
		});
	let history = useHistory();

	useEffect(() => {
		let x =
			details?.hotel_data?.images && details?.hotel_data?.images?.length > 0
				? details.hotel_data.images.map((res, index) => {
						return {
							key: index + "uuid.v4(2)",
							content: (
								<img
									className="bg-white"
									src={res}
									alt={index + 1}
									onError={(e) => {
										e.target.onerror = null;
										e.target.src = noHotel;
									}}
								/>
							),
							onClick: () => setActive(index),
						};
				  })
				: null;
		setSilders(x);
	}, []);

	const [IdGroupPaymentRow, setIdGroupPaymentRow] = useState(null);
	const pay = async (type, currency, otp) => {
		
		if (!GroupInventory) {
			const res = await payFees(details.brn, {
				payment_method: type,
				currency: currency,
				otp: otp,
			});
			if (res.status === 200 || res.status === 201) {
				store.addNotification({
					title: "info!",
					message: "Payment Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 3000,
						onScreen: true,
						pauseOnHover: true,
					},
				});

				togglePayModal(null);
				window.location.reload();
			} else if (res?.status === 202) {
				setActionType(res?.data?.actionType);
				setFormData(res?.data?.data?.formData);
			}
		} else {
			const res = await payGroupsFees(IdGroupPaymentRow?.id, {
				payment_method: type,
				currency: currency,
			});
			if (res.status === 200 || res.status === 201) {
				store.addNotification({
					title: "info!",
					message: "Paid Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 2000,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						togglePayModal(null);
						window.location.reload();
					},
				});
			} else if (res?.status === 202) {
				setActionType(res?.data?.actionType);
				setFormData(res?.data?.data?.formData);
			}
		}
	};

	const [invoicesTrue, setinvoicesTrue] = useState({});
	const [avatar, setAvatar] = useState("print");

	useEffect(() => {
		const fetchCompanyImage = async () => {
			const res = await fetchImages(invoicesTrue?.company?.avatar);
			if (res) {
				setAvatar(res);
			} else {
				setAvatar("print");
			}
		};
		if (invoicesTrue?.company?.avatar) {
			fetchCompanyImage();
		}
	}, [invoicesTrue?.company?.avatar]);

	const daweloadInvoice = async (brn) => {
		//history.push(`/Print/invoice/${details.brn}`);

		const invoice = await getInvoice(brn);

		const allRooms =
			invoice?.rooms?.length > 0
				? invoice?.rooms.map((room, index) => {
						return `		<tr>
							<td
								class=${
									+index + 1 === +invoice.rooms.length
										? "last-td-border"
										: "remove-last-td-border"
								}
							>
								${+index === 0 ? invoice?.hotel_name : ""}
							</td>
							<td className="f-12">${room.name}</td>
							<td>
						
								${
									room?.passengers?.length > 0
										? room?.passengers.map((passenger) => {
												return `<p className="m-0 f-12">
												${passenger?.first_name} ${passenger?.last_name}
											</p>`;
										  })
										: "-"
								}
							</td>
							<td className="f-12">${invoice?.rooms?.length}</td>
							<td className="f-12">${room.adults}</td>
							<td className="f-12">${room.children}</td>
							<td className="f-12">${room.nights}</td>
							<td className="f-12">${room.from_date}</td>
							<td className="f-12">${room.to_date}</td>
							<td className="f-12">
								${room.paid_price ? room.paid_price : room.price} ${invoice?.currency}
							</td>
						</tr>`;
				  })
				: [];

		if (invoice) {
			let respos = InvoicePrint;

			respos = respos.replace("[allRooms]", allRooms);

			respos = respos.replace("[invoiceNumber]", invoice?.invoiceNumber);
			respos = respos.replace(
				"[confirmationNumber]",
				invoice?.confirmationNumber
			);
			respos = respos.replace("[company_name]", invoice?.company?.name);
			respos = respos.replace("[company_name_sec]", invoice?.company?.name);
			respos = respos.replace(
				"[company_address]",
				invoice?.company?.address ? invoice?.company?.address : "-"
			);

			respos = respos.replace("[company_phone]", invoice?.company?.phone);
			respos = respos.replace(
				"[remark]",
				invoice?.remark
					? `<div class="border-section">
				<div class="row justify-content-start align-items-center  p-4 ">
					<div class=" col-12 d-flex flex-column">
						<p class="font-weight-bold mb-1">Agent Remarks</p>
						<p class="m-0">${invoice?.remark}</p>
					</div>
				</div>
			</div>`
					: ""
			);

			respos = respos.replace("[total_price]", invoice?.total_price);

			respos = respos.replace("[invoiceDate]", invoice?.invoiceDate);
			respos = respos.replace("[currency]", invoice?.currency);

			respos = respos.replace("[SafaLogo]", SafaLogo);
			respos = respos.replace(
				"[company_image]",
				invoice?.data?.data?.companyImage
					? `<img src=${invoice?.data?.data?.companyImage} alt="hotal-logo" class="img-fluid w-50" />`
					: `<div class="company-logo-font letter-logo p-5 ">
										${invoice?.company?.name[0]}
									</div>`
			);

			var popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
			popupWin.document.open();
			popupWin.document.write(`
						<html>
						<head>
						<title> print Invoice</title>
						<style>

						</style>
						</head>
						<body onLoad="{()=>window.print()}">${respos}</body>
						</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	var sumPax = 0;
	const totalPax = () => {
		for (let i = 0; i < details.payload.rooms?.length; i++) {
			sumPax +=
				+details.payload.rooms[i].adults + +details.payload.rooms[i].children;
		}
		return sumPax;
	};

	useEffect(() => {
		if (scroll) {
			document.getElementById("scroll").scrollIntoView();
		}
	}, []);
	//downLoad Tentative PDF
	const dawnloadTentativePDF = async () => {
		const { data: PDF } = await fetchGroupReservationPDF(hotelInfo?.id);
		const allRemark =
			PDF?.data?.remarks?.length > 0
				? PDF?.data?.remarks?.map((remark, index) => {
						return `<span>${remark.content}</span>`;
				  })
				: "-";
		const allGuests =
			PDF?.data?.guests && PDF?.data?.guests?.length > 0
				? PDF?.data?.guests?.map((guest, index) => {
						return `<span>${guest.name}</span>`;
				  })
				: "-";

		const allRooms =
			PDF?.data?.rooms?.length > 0
				? PDF?.data?.rooms.map((room, index) => {
						return `<tr>
									<tr>
									<td class="td">${+room?.room_count}</td>									
									<td class="td">${room?.room_type?.name} Room</td>
									<td class="td">${room?.room_view?.name}</td>
									<td class="td">${
										PDF?.data?.meal_plan?.name
											? PDF?.data?.meal_plan?.name
											: "-"
									}</td>
								</tr>
									</tr>`;
				  })
				: [];
		const optionDate=PDF?.data?.payments?.length>0? PDF?.data?.payments.map((item,index)=>{
			if(item?.option_date){
				return `<div class="hero-section border-bottom-bold">
				<p>Option Date : Please Send Bank Transer by ${item?.option_date}</p>
			</div>`
			}else{
				return ""
			}
		
		}):""	
		if (PDF) {
			let respos = TentativePDF;
			respos = respos.replace("[allRooms]", allRooms);
			respos = respos.replace("[Date]", PDF?.data?.created_at);
			respos = respos.replace("[To]", PDF?.data?.date_to);
			respos = respos.replace("[From]", PDF?.data?.date_from);
			respos = respos.replace("[Attn]", "");
			respos = respos.replace("[Fax]", "");

			respos = respos.replace("[resNo]", hotelInfo?.id);
			respos = respos.replace("[arrival_date]", PDF?.data?.date_to);
			respos = respos.replace("[guest_name]", allGuests);
			respos = respos.replace("[hotel_name]", PDF?.data?.hotel_name);
			respos = respos.replace("[hotel_name1]", PDF?.data?.hotel_name);
			respos = respos.replace("[depart_date]", PDF?.data?.date_from);

			respos = respos.replace("[option_date]", optionDate);
			respos = respos.replace("[remarks]", allRemark);
			respos = respos.replace("[net_currency]", PDF?.data?.currency);
			respos = respos.replace("[net_currency1]", PDF?.data?.currency);
			respos = respos.replace("[net_amount]", PDF?.data?.total_sell_price);

			respos = respos.replace("[SafaLogo]", SafaLogo);
			respos = respos.replace("[status]", hotelInfo?.status);

			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
					<html>
					<head>
					<title> print Invoice</title>
					<style>

					</style>
					</head>
					<body onLoad="{()=>window.print()}">${respos}</body>
					</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};

	//download Voucher
	const dawnloadDefinitVoucher = async () => {
		const { data: voucher } = await fetchGroupReservationVoucher(hotelInfo?.id);
		const allRemark =
			voucher?.data?.remarks?.length > 0
				? voucher?.data?.remarks?.map((remark, index) => {
						return `<span>${remark.content}</span>`;
				  })
				: "-";
		const allGuests =
			voucher?.data?.guests && voucher?.data?.guests?.length > 0
				? voucher?.data?.guests?.map((guest, index) => {
						return `<span>${guest.name}</span>`;
				  })
				: "-";

		const allRooms =
			voucher?.data?.rooms?.length > 0
				? voucher?.data?.rooms.map((room, index) => {
						return `<div>
							<span class="mx-3">${room.room_type.name}  </span>
							<span>${room.room_view.name}</span>
								</div>`;
				  })
				: [];
		if (voucher) {
			;
			let respos = DefinitVoucher;
			console.log(respos);
			respos = respos.replace("[allRooms]", allRooms);

			respos = respos.replace(
				"[confirmation_number]",
				voucher?.data?.confirmation_number ?? " - "
			);
			respos = respos.replace("[hotel_name]", voucher?.data?.hotel_name);
			respos = respos.replace("[isuee_date]", voucher?.data?.created_at);
			respos = respos.replace("[staff_name]", voucher?.data?.accepted_by);
			respos = respos.replace(
				"[hotel_rsr]",
				voucher?.data?.confirmation_number ?? " - "
			);
			respos = respos.replace("[check_in]", voucher?.data?.date_from);
			respos = respos.replace("[check_out]", voucher?.data?.date_to);
			respos = respos.replace("[guest_name]", allGuests);
			respos = respos.replace("[remarks]", allRemark);

			respos = respos.replace("[SafaLogo]", SafaLogo);
			var params =
				"width=" +
				window.innerWidth +
				", height=" +
				window.innerHeight +
				", top=0, left=0" +
				",toolbar=no,scrollbars=no,status=no,menubar=no";
			//"top=0,left=0,height=80%,width=auto"
			var popupWin = window.open("", " ", params);
			popupWin.document.open();
			popupWin.document.write(`
				<html>
				<head>
				<title> print Invoice</title>
				<style>

				</style>
				</head>
				<body onLoad="{()=>window.print()}">${respos}</body>
				</html>`);
			popupWin.document.close();

			setTimeout(() => {
				popupWin.print();
			}, 100);
		}
	};
	return (
		<>
			<section className="marketplace-details  bg-white py-3">
				<div className="d-flex justify-content-between hotel-name px-4">
					<div className="d-flex">
						<h6 className="font-weight-bold dark-blue-color bg-white pt-3 text-headline">
							{/* Fairmont Makkah Clock Tower */}
							{/* {state.tab === "hotel" ? state.hotel : state.transport}  */}
							{/* {inventory.messages.viewHotel} :  */}
							{details?.hotel_name}
						</h6>
						{!GroupInventory && details.hotel_data.rating > 0 ? (
							<div className="hotel-star-rate d-inline mt-3 px-2">
								{[...Array(details.hotel_data.rating).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>
						) : !GroupInventory ? (
							<div className="hotel-star-rate d-inline mt-3 px-2">
								{[...Array(5)].map((i) => (
									<i key={i} className="far fa-star" aria-hidden="true"></i>
								))}
							</div>
						) : null}
					</div>
					{!GroupInventory && (
						<button
							className="mx-1 btn btn-white-border py-1"
							onClick={() =>
								history.push({
									pathname: "/Hotels/view-booking",
									state: { detail: details },
								})
							}
						>
							<a href={() => false} className="text-decor">
								{inventory.messages.viewHotel}
							</a>
						</button>
					)}
					{GroupInventory &&
						<div>
							{GroupInventory && (status_definite || status_tentative) && (
								<button
									className="btn btn-yellow "
									onClick={dawnloadTentativePDF}
								>
									Download PDF
								</button>
							)}
							{GroupInventory && status_definite && (
								<button
									className="btn btn-yellow mx-2"
									onClick={dawnloadDefinitVoucher}
								>
									Download Voucher
								</button>
							)}
						</div>
					}
				</div>
				<hr className="hr my-2" />
				{hotelInfo.gds == 3 ? (
					<div className="hotel-details">
						<RequestStatus
							modalPay={modalPay}
							setModalPay={setModalPay}
							togglePayModal={togglePayModal}
							togglePromocodeModal={togglePromocodeModal}
							modalPromocode={modalPromocode}
							hotelInfo={hotelInfo}
							title={inventory.messages.requestStatus}
							promoVoucher={details}
							status={[
								{
									status: `${
										details?.reservation_status === "pending" ||
										details?.reservation_status === "canceled" ||
										details?.reservation_status === "rejected" ||
										details?.reservation_status === "expired"
											? "inactive"
											: "active"
									}`,
									name: `${translate.ApprovedRequest}`,
								},
								{
									status: `${
										details?.payment_status === "pending" ||
										details?.reservation_status === "canceled"
											? "inactive"
											: "active"
									}`,
									name: `${
										details?.payment_type === "deposit" ||
										details?.payment_type == null
											? inventory.messages.PayFullAmount
											: inventory.messages.PayFullAmount
									}`,
								},

								{
									status: `${
										details?.payment_status === "confirmed" &&
										details?.reservation_status !== "canceled" &&
										(details?.payment_type === "full payment" ||
											details?.payment_type === "deposit" ||
											details?.payment_type == null) &&
										details?.payment_status === "confirmed" &&
										details?.reservation_status === "confirmed"
											? "active"
											: details?.reservation_status === "paid" &&
											  details?.reservation_type === "auto"
											? "active"
											: "inactive"
									}`,
									name: `${
										details?.payment_type === "full payment"
											? inventory.messages.VoucherIssuance
											: inventory.messages.VoucherIssuance
									}`,
								},
							]}
						/>
					</div>
				) : null}

				<div className="px-4 my-4 res-remove-padding">
					<div className="hotel-info p-3">
						<h3 className="header-hotel-info">
							{inventory.messages.bookingDetails}
						</h3>
						<div className="btns-booking d-flex justify-content-end">
							{!GroupInventory && (
								<button
									className=" btn btn-link"
									onClick={() => {
										toggleModalNote();
									}}
								>
									<i class="fas fa-eye mx-1"></i>
									{inventory.messages.bookingRemarks}
								</button>
							)}

							{location.pathname.includes("/inventory-group") ||
							(details.gds == 4 &&
								details.reservation_status === "approved" &&
								details.payment_status === "pending") ||
							(details.gds == 6 &&
								details.reservation_status === "approved" &&
								details.payment_status === "pending") ||
							details?.reservation_status === "canceled" ||
							details?.reservation_status === "expired" ? (
								""
							) : (
								<button
									className=" btn btn-yellow text-uppercase"
									onClick={() => daweloadInvoice(details.brn)}
								>
									<i class="fas fa-cloud-download-alt"></i>{" "}
									{inventory.messages.downloudInvoice}
								</button>
							)}
						</div>
						{!GroupInventory ? (
							<div className=" booking-data-container px-4 my-3 ">
								<div className="row ">
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											{/* <div className="service-data px-4 py-2"> */}
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.bookDate}</h5>
												</div>
												<div className="col-8">
													<p>{details.start_date}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.bookingService}</h5>
												</div>
												<div className="col-8">
													<p>{details?.hotel_name.substring(0, 43)}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.numberRoom}</h5>
												</div>
												<div className="col-8">
													<p>{details?.rooms_number}</p>
												</div>
											</div>

											{/* <div className="row">
											<div className="col-4">
												<h5>{inventory.messages.totalPax}</h5>
											</div>
											<div className="col-8">
												<p>
													{details.payload.rooms?.length > 0 &&
														details.payload.rooms?.map(
															(item, index) => item.adults + item.children
														)}
												</p>
											</div>
										</div> */}

											<div className="row">
												<div className="col-4">
													<h5 className="text-uppercase">
														{inventory.messages.status}
													</h5>
												</div>
												<div className="col-8">
													<p
														className={`${
															details?.reservation_status === "confirmed" ||
															details?.reservation_status === "approved" ||
															details?.reservation_status === "paid"
																? "text-success"
																: "text-danger"
														} font-weight-bold text-uppercase`}
													>
														{details?.reservation_status}
													</p>
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											{/* <div className="service-data px-4 py-2"> */}
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.totalPax}</h5>
												</div>
												<div className="col-8">
													<p>
														{/* {details.payload.rooms?.length > 0 &&
														details.payload.rooms?.map(
															(item, index) => +item.adults + +item.children
														)} */}

														{!GroupInventory && totalPax()}
														{/* {details.availablity} */}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.totalPrice}</h5>
												</div>
												<div className="col-8">
													<p className="w-100">
														{new Intl.NumberFormat('en').format(+details?.original_provider_price) }{" "}
														{details?.original_provider_currency}
														{+details?.total_price !=
														+details?.original_provider_price ? (
															<span
																className="mx-2 text-success	"
																style={{ float: "right" }}
															>
																{marketPlace.paidprice} {details?.total_price}{" "}
																{details?.currency}
															</span>
														) : (
															""
														)}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.leadPassenger}</h5>
												</div>
												<div className="col-8">
													<p>
														{/* {details?.payload?.rooms[0]?.passengers?.length >
													0 ? (
														<span>
															{
																details?.payload?.rooms[0]?.passengers[0]
																	.first_name
															}{" "}
															{
																details?.payload?.rooms[0]?.passengers[0]
																	.last_name
															}
														</span>
													) : (
														<span className="text-gray">No Passengers</span>
													)} */}
														_
													</p>
												</div>
											</div>

											{/* <div className="row row-inner">
											<div className="col-4">
												<h5>{inventory.messages.rateNotes}</h5>
											</div>
											<div className="col-8">
												<p>_</p>
											</div>
										</div> */}

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.payment}</h5>
												</div>
												<div className="col-8">
													<p
														className={`${
															details?.payment_status === "paid" ||
															details?.payment_status === "confirmed"
																? "text-success"
																: details?.payment_status === "pending"
																? "text-warning"
																: "text-danger"
														} font-weight-bold text-uppercase`}
													>
														{details?.payment_status}

														{(hotelInfo.gds == 6 &&
															details?.payment_status === "pending" &&
															hotelInfo.reservation_status != "expired") ||
														(hotelInfo.gds == 4 &&
															details?.payment_status === "pending" &&
															hotelInfo.reservation_status != "expired") ? (
															<a
																className="mx-3 btn btn-success"
																href={() => false}
																onClick={() => togglePayModal()}
															>
																{" "}
																pay{" "}
															</a>
														) : (
															""
														)}
													</p>
												</div>
											</div>
											{/* </div> */}
										</div>
									</div>
								</div>
							</div>
						) : (
							<div className=" booking-data-container px-4 my-3 ">
								<div className="row ">
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											<div className="row row-inner ">
												<div className="col-4">
													<h5>{inventory.messages.Status}</h5>
												</div>
												<div
													className={`col-8  ${
														details?.status === "canceled"
															? "bg-light-danger"
															: details?.status === "definite"
															? "bg-light-success"
															: "bg-light-warning"
													}`}
												>
													<p
														className={`font-weight-bolder ${
															details?.status === "canceled"
																? "text-danger"
																: details?.status === "definite"
																? "text-success"
																: "text-light-warning"
														}`}
													>
														{details?.status}
													</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.reference}</h5>
												</div>
												<div className="col-8">
													<p>{details?.id}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.destination}</h5>
												</div>
												<div className="col-8">
													<p>{details?.destination}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.checkin}</h5>
												</div>
												<div className="col-8">
													<p>{details?.date_from}</p>
												</div>
											</div>

											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.nights}</h5>
												</div>
												<div className="col-8">
													<p>{details?.nights}</p>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.nationality}</h5>
												</div>
												<div className="col-8">
													<p>{details?.nationality}</p>
												</div>
											</div>
										</div>
									</div>
									<div className="col-md-6 col-12">
										<div className="booking-data my-2">
											<div className="row ">
												<div className="col-4 bg-white">
													<h5 className="text-white">0</h5>
												</div>
												<div className="col-8">
													<p className="text-white">0</p>
												</div>
											</div>
											<div className="row">
												<div className="col-4">
													<h5>{inventory.messages.requestDate}</h5>
												</div>
												<div className="col-8">
													<p>{details?.created_at}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.hotelName}</h5>
												</div>
												<div className="col-8">
													<p>{details?.hotel_name}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.checkOut}</h5>
												</div>
												<div className="col-8">
													<p>{details?.date_to}</p>
												</div>
											</div>

											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.residence}</h5>
												</div>
												<div className="col-8">
													<p>{details?.residence}</p>
												</div>
											</div>
											<div className="row row-inner">
												<div className="col-4">
													<h5>{inventory.messages.currency}</h5>
												</div>
												<div className="col-8">
													<p>{details?.currency}</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
				{!GroupInventory ? (
					<div className="px-4 my-4 res-remove-padding">
						<div className="passenger ">
							<h3 className="header-hotel-info">
								{inventory.messages.passengerDetails}
							</h3>
							<div className="mt-4 invetory-table">
								<PassengersTable details={details} setDetails={setDetails} />
							</div>
						</div>
					</div>
				) : null}
				{/*** Start Room Details ***/}
				{GroupInventory ? <RoomDetails details={details} /> : null}
				{/*** End Room Details ***/}
				{/*** Start TERMS & CONDITIONS ***/}
				{GroupInventory ? <TermsDetails details={details} /> : null}
				{/*** End TERMS & CONDITIONS ***/}
				{/*** Start Payment ***/}
				<div id="scroll">
					{GroupInventory ? (
						<PaymentDetails
							modalPaymentDetails={modalPaymentDetails}
							setModalPaymentDetails={setModalPaymentDetails}
							togglePaymentDetailsModal={togglePaymentDetailsModal}
							details={details}
							setIdGroupPaymentRow={setIdGroupPaymentRow}
						/>
					) : null}
				</div>
				{/*** End Payment ***/}

				{/* Passenger Modal */}
				<Modal size="md" isOpen={modalNote} toggle={toggleModalNote}>
					<ModalHeader
						className="align-items-center font-weight-bold hd-title-model"
						toggle={toggleModalNote}
					>
						{inventory.messages.bookingRemarks}
					</ModalHeader>
					<ModalBody className="payModal">
						<div className="container my-3 ">
							<p className="p-remark">{details?.notes}</p>
						</div>
					</ModalBody>
				</Modal>
			</section>

			{modalPay && (
				<NewPaymentAction
					isOpen={modalPay}
					toggleModal={togglePayModal}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={Number(details?.total_price.replace(/[^0-9.-]+/g, ""))}
					currency={details?.currency}
					onPayment={pay}
					setActionType={setActionType}
					setFormData={setFormData}
					OTP={OTP_CHECK}
				/>
			)}

			{/******* payment Details*/}

			{modalPaymentDetails && (
				<NewPaymentAction
					isOpen={modalPaymentDetails}
					toggleModal={togglePaymentDetailsModal}
					canPayLater={false}
					formData={formData}
					actionType={actionType}
					Price={IdGroupPaymentRow?.amount}
					currency={
						details?.currency
							? details?.currency
							: details?.destination?.currency
					}
					shouldPayCurrency={	details?.currency
						? details?.currency
						: details?.destination?.currency}
					onPayment={pay}
					setActionType={setActionType}
					setFormData={setFormData}
					OTP={OTP_CHECK}
				/>
			)}
		</>
	);
}
