import React, { useEffect } from "react";
import Locale from "translations";
import TextField from "components/Form/TextField/TextField";
import { useMarketplaceState } from "context/marketplace";

export default function ContactInformation({
	values,
	handleChange,
	touched,
	errors,
	setPhoneCode,
	countries,
	userInfoExist,
	userInfo,
	setFieldValue,
}) {
	useEffect(() => {
		if (userInfoExist) {
			// setFieldValue("name", userInfo?.name);
			// setFieldValue("email", userInfo?.email);
			// setFieldValue("phone", userInfo?.phone);
			// setPhoneCode(userInfo?.phone_code);
		}
	}, [userInfoExist]);

	const { packages: t } = Locale;
	return (
		<div className="ContactInfo p-3">
			<div className="col-md-12">
				{/* full name */}
				<div className="w-100 mb-3">
					<label htmlFor="name">{t.fullName}*</label>

					<TextField
						hasLabel={false}
						type="text"
						id="name"
						name="name"
						size="small"
						placeholder={t.fullName}
						value={userInfo?.full_name}
						disabled={true}
					/>
				</div>

				<div className="row">
					<div className="col-md-6">
						<div className="w-100 mb-3">
							<label htmlFor="email">{t.emailAddress}*</label>

							<TextField
								hasLabel={false}
								id="email"
								name="email"
								size="small"
								placeholder={t.emailAddress}
								value={userInfo?.user_id}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-md-6">
						<label htmlFor="phone">{t.phoneNumber}*</label>
						<div className="w-100 mb-3">
							<TextField
								hasLabel={false}
								type="tel"
								id="phone"
								name="phone"
								size="small"
								placeholder={t.phoneNumber}
								value={`${userInfo?.phone?.phone_code}${userInfo?.phone?.phone}`}
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
