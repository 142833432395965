import React, { useEffect, useState } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import CountryCard from "./CountryCard";
import { fetchMarketPackagesCountries } from "services/marketplace";




export default function PackagesCountries() {

	const [countriesPackages, setcountriesPackages] = useState([]);
   const responsive = {
			superLargeDesktop: {
	
				breakpoint: { max: 4000, min: 3000 },
				items: 4,
			},
			desktop: {
				breakpoint: { max: 3000, min: 1024 },
				items: 3,
			},
			tablet: {
				breakpoint: { max: 1024, min: 464 },
				items: 2,
			},
			mobile: {
				breakpoint: { max: 464, min: 0 },
				items: 1,
			},
		};

		console.log(countriesPackages);

	const getCountries = async () => {
		const res = await fetchMarketPackagesCountries();
		if (res.status === 200) {
			setcountriesPackages(res.data.data);
		}
	};
	useEffect(() => {
		getCountries();
		return () => {};
	}, []);



	return (
		<>
			<Carousel
				 responsive={responsive}
				 itemClass={"pakage-slide"}
					slidesToSlide={1}
			  	keyBoardControl={true}
			>
				{countriesPackages.map((packages) => (
					<CountryCard packages={packages}/>
				))}
			</Carousel>
		</>
	);
}
