import { useProductsBuilderState } from "context/productsBuilder";
import AttractionLandmarkPreview from "modules/products-builder/components/ProductElements/AttractionLandmarkPreview";
import DomesticFlightPreview from "modules/products-builder/components/ProductElements/DomesticFlightPreview";
import ExternalTransportationsPreview from "modules/products-builder/components/ProductElements/ExternalTransportationsPreview";
import HotelPreview from "modules/products-builder/components/ProductElements/HotelPreview";
import InternalTransportationsPreview from "modules/products-builder/components/ProductElements/InternalTransportationsPreview";
import { useEffect, useState } from "react";
import { getIteneraryProduct } from "services/productbuilder";
import Locale from 'translations';

export default function ItineraryRequest({ reservationDetails }) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { productsBuilder } = Locale;
	const productBodyItems = reservationDetails?.product_details;
	// const ProductsBuilder = useProductsBuilderState();
	// const [productBodyItems, setProductBodyItems] = useState({});

	// useEffect(() => {
	// 	const fetchProducts = async () => {
	// 		if (ProductsBuilder.product_uuid) {
	// 			const response = await getIteneraryProduct(
	// 				ProductsBuilder.product_uuid
	// 			);

	// 			if (response.data.data && response.data.data.length > 0) {
	// 				setProductBodyItems(response.data);
	// 			}
	// 		}
	// 	};

	// 	fetchProducts();
	// }, []);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	// Return Correct Element
	const productElements =
		reservationDetails?.product_details?.map((element, index) => {
			switch (element ? element.item?.name : "") {
				case "hotel":
				case "فندق":
					return <HotelPreview key={`itinerary-${index}`} element={element} />;
				case "external transportation":
				case "تنقلات خارجيه":
					return (
						<ExternalTransportationsPreview
							key={`itinerary-${index}`}
							element={element}
						/>
					);
				case "domestic":
					return (
						<DomesticFlightPreview
							key={`itinerary-${index}`}
							element={element}
						/>
					);
				case "internal transportation":
				case "تنقل جوى داخلى":
					return (
						<InternalTransportationsPreview
							key={`itinerary-${index}`}
							element={element}
						/>
					);
				case "sightseeing":
				case "مزار سياحي":
					return (
						<AttractionLandmarkPreview
							key={`itinerary-${index}`}
							element={element}
						/>
					);
				default:
					break;
			}
		});

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<div className="p-3 builder-wrapper-content1 gbox">
			<p style={{ fontWeight: "bold" }}>Package Details</p>
			{/* Product Elements */}
			<div className=" product-build__itenary-content">
				{productBodyItems.productType <= 2 &&
					!productBodyItems.departureExternalType ? (
					<ExternalTransportationsPreview
						key={`itinerary-0`}
						element={{ fail: true, title: "Departure" }}
					/>
				) : null}

				{productElements}
				{productBodyItems.productType <= 2 &&
					!productBodyItems.madinahHotels ? (
					<HotelPreview
						key={`itinerary-${1}`}
						element={{ fail: true, title: "Madinah Hotels" }}
					/>
				) : null}

				{productBodyItems.productType <= 2 && !productBodyItems.makkahHotels ? (
					<HotelPreview
						key={`itinerary-${1}`}
						element={{ fail: true, title: "Makkah Hotels" }}
					/>
				) : null}

				{productBodyItems.productType <= 2 &&
					!productBodyItems.returnExternalType ? (
					<ExternalTransportationsPreview
						key={`itinerary-4`}
						element={{ fail: true, title: "Return" }}
					/>
				) : null}
			</div>
		</div>
	);
}
