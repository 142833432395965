import { useSBSState } from "context/global";
import { useMarketplaceDispatch } from "context/marketplace";

import React, { useEffect, useRef, useState } from "react";
import {
	useHistory,
	useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { Hotels } from "./shared/Hotels";
import Company from "./shared/company";
import TitlePackage from "./shared/Title";
import ItineraryBox from "./shared/Itinerary";
import CustomBox from "./shared/CustomBox";
import Guests from "./shared/Guests";
import data from "./shared/data.json";
import ExternalTransportations from "../ProductElements/ExternalTransportations";
import Insurance from "../ProductElements/Insurance";
import Hotel from "../ProductElements/Hotel.old";
import InternalTransportation from "../ProductElements/InternalTransportations/InternalTransportations";
import OtherService from "../ProductElements/Other";
import { fetchMarketPackagesDetails } from "services/marketplace";
import Sightseeing from "../ProductElements/Sightseeing";
import PriceBreakdownModal from "./shared/PriceBreakdownModal";
import Locale from 'translations';
const PackageDetails = () => {
	const priceEle = useRef(null);
	const { locale } = useSBSState();
	const { packages, productsBuilder, marketPlace, inventory } = Locale;

	const [edit, setedit] = useState(false);
	const scrolPrice = () => {
		priceEle.current.scrollIntoView();
	};
	const packagesDispatch = useMarketplaceDispatch();
	const isMobile = false;

	const [details, setDetails] = useState(data);
	const { uuid } = useParams();
	const history = useHistory();
	const [RoooomOpt, setRoooomOpt] = useState([]);
	const [adultState, setAdultState] = useState({ adult: 1, child: 0 });
	const [obj, setObj] = useState({ adult: [], child: [] });
	const imageUrl =  process.env.REACT_APP_API_URL + "/v1/files/fetch/";
	const [GuestsState, setGuestsState] = useState([]);
	const [GuestsChildrenState, setGuestsChildrenState] = useState("");

	const [isSaved, setIsSaved] = useState(false);
	const [SumItenaryTotal, setSumItenaryTotal] = useState(0)

	const [notSavedMsg, setNotSavedMsg] = useState("");
	const [isOpen, setIsOpen] = useState({ isOpen: false, details: "" });
	const toggle = (data) => {
		setIsOpen({ isOpen: !isOpen.isOpen, details: data });
	};
	const currencyCode = details?.product_price?.currency_code;
	const productDiscounts = details?.product_price?.discounts;
	useEffect(() => {
		async function FetchDetails() {
			const res = await fetchMarketPackagesDetails(uuid);
			setDetails(res?.data);
		}
		if (uuid) {
			FetchDetails();
		}
	}, [uuid]);

	useEffect(() => {
		if (adultState.adult || adultState.child) {
			setObj({
				adult: [...Array(adultState.adult).keys()],
				child: [...Array(adultState.child).keys()],
			});
		}
	}, [adultState]);
	let typeRooms = {
		1: "Single Room",
		2: "Double Room",
		3: "Triple Room",
		4: "Quad Room",
		5: "Quintuple Room",
		6: "Hexagonal Room",
		7: "Seven Room",
		8: "Eight Room",
	};
	const itineraryElementsIds = {
		external: 1,
		internal: 2,
		visa: 4,
		insurance: 5,
		hotel: 6,
		sightseeing: 7,
		otherServices: 8
	}
	const itineraryElements = () => {
		return details?.itinerary?.map((element, index) => {
			switch (element ? element.item?.id : "") {
				case 1:
					return (
						<ExternalTransportations
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
							type={"transportations"}
						/>
					);
				case 2:
					return (
						<InternalTransportation
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				// case 3:
				// 	return (
				// 		<DomesticFlight
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);
				// case "visa":
				// 	return (
				// 		<Visa
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);

				case 5:
					return (
						<Insurance
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				case 6:
					return (
						<Hotel
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
							type={"hotel"}
						/>
					);
				// case 7:
				// 	return (
				// 		<AttractionLandmark
				// 			key={element.id}
				// 			element={element}
				// 			index={index}
				// 			id={element.id}
				// 			isMobile={isMobile}
				// 		/>
				// 	);

				case 8:
					return (
						<OtherService
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				case 7:
					return (
						<Sightseeing
							key={element.id}
							element={element}
							index={index}
							id={element.id}
							isMobile={isMobile}
						/>
					);
				default:
					break;
			}
		});
	};

	const HotelElements = () => {
		return details?.product_items?.map((element, index) => {
			if (element?.item?.id === itineraryElementsIds.hotel) {
				return <Hotels details={element} key={index} />;
			}
			return null;
		});
	};
	const ExternalElements = () => {
		//To Return uniqe External Element
		let externalElementAirLines = details?.product_items?.filter(
			(element) => element?.item?.id === itineraryElementsIds.external
		);

		let uniqueAirLinesIds = {};

		const uniqueExtrenalAirLines = externalElementAirLines?.filter((item) => {
			const airLineId = item?.itemable?.transporter?.id;
			if (uniqueAirLinesIds[airLineId] === undefined) {
				uniqueAirLinesIds = { ...uniqueAirLinesIds, [airLineId]: item };
				return item;
			}
		});
		if (uniqueExtrenalAirLines?.length > 0) {
			return uniqueExtrenalAirLines?.map((externalTransport) => {
				return (
					<Company
						key={externalTransport.id}
						title={marketPlace.airlines}
						name={externalTransport?.itemable?.transporter?.name}
						logo={externalTransport?.itemable?.transporter?.logo}
					/>
				);
			});
		} else {
			return null;
		}
	};

	const checkout = () => {
		if (isSaved && isValidINput()) {
			packagesDispatch({
				type: "setPackagesGuests",
				payload: {
					guests: GuestsState,
					children: GuestsChildrenState,
					currency: details.currency,
				},
			});
			history.push(`/market-package/checkout-package/${uuid}`);
		} else {
			setNotSavedMsg(packages.PleaseSaveNumberPaxFirst);
		}
	};

	const typeRoomString = (type) => {
		switch (type) {
			case "Single Room":
				return 1;
			case "Double Room":
				return 2;
			case "Treble Room":
				return 3;
			case "Quad Room":
				return 4;
			case "Quintuple Room":
				return 5;
			case "Hexagonal Room":
				return 6;
			case "Seven Room":
				return 7;
			case "Eight Room":
				return 8;
			default:
				return 1;
		}
	};
	const availabilityFn = (type) => {
		let arr = obj?.adult?.filter((item) => typeRoomString(item?.name) == type);
		let x = arr?.length;
		return x;
	};

	const disabledBtn = () => {
		return details?.roomsAvailabilities?.map((item) => {
			let x = availabilityFn(item?.room_type);
			if (x > item?.room_remaining_availability) {
				return true;
			}
		});
	};

	function calcDiscountPrices(price) {
		const discountTypeId = productDiscounts?.[0]?.discount_type?.id;
		const discountAmount = productDiscounts?.[0]?.discount;
		const percentageDiscountId = 1;
		const fixedDiscount = 2;
		let priceAfterDiscount;
		if (discountTypeId === percentageDiscountId) {
			priceAfterDiscount = price - +price * (+discountAmount / 100);
		} else if (discountTypeId === fixedDiscount) {
			priceAfterDiscount = price !== 0 ? price - +discountAmount : "0.00";
		} else {
			priceAfterDiscount = price;
		}
		return priceAfterDiscount;
	}
	const isValidINput = () => {
		return (
			GuestsState?.length > 0 &&
			GuestsState?.every((item) => item.room_numbers != "")
			//GuestsChildrenState !== ""
		);
	};
	console.log(
		GuestsState?.filter((item) => item.room_numbers > item.room_availability)
			?.length == 0
	);

	useEffect(() => {
		let sum_total = 0;
		let sum_adults = GuestsState.reduce(
			(total, obj) => +obj?.room_numbers + total,
			0
		);

		let x= details?.itinerary?.map((element, index) => {
			if (element.item?.id !== 6 && element.item?.name!=="hotel") {
				 return  +element?.itemable?.price_adult * sum_adults;
			}
		});
		

		sum_total =x.filter((item=>item)).reduce(
			(total, obj) => obj!==undefined?+obj + total:0,
			0
		);
		setSumItenaryTotal(sum_total)
		console.log(x,sum_total);
	}, [GuestsState]);
console.log("sum_totalsum_total", SumItenaryTotal);
	return (
		<div className={`container`}>
			<div className={`row pt-5`}>
				<div className="col-lg-9  col-12">
					<TitlePackage details={details} />

					{isMobile && (
						<div className="d-flex gap-1 flex-wrap">
							<Company
								title={"travelAgent"}
								name={details?.company_name[locale]}
								logo={
									details?.company_avatar &&
									`${imageUrl}${details?.company_avatar}`
								}
							/>
							{ExternalElements()}
						</div>
					)}

					<ItineraryBox>
						{details?.product_items?.length > 0 && itineraryElements()}
					</ItineraryBox>

					{HotelElements()}

					<CustomBox title={packages.services}>
						<p className="d-flex align-items-center mb-2">
							{details?.product_items?.filter(
								(item) => item?.item?.id === 2
							)?.length > 0 ? (
								<i className="fas fa-check text-success"></i>
							) : (
								<i className="fas fa-times text-danger"></i>
							)}
							<span className="mx-1">{"internal Transportation"}</span>
						</p>
						<p className="d-flex align-items-center mb-2">
							{details?.product_items?.filter(
								(item) => item?.item?.id === 4
							)?.length > 0 ? (
								<i className="fas fa-check text-success"></i>
							) : (
								<i className="fas fa-times text-danger"></i>
							)}
							<span className="mx-1">{"visa"}</span>
						</p>
						<p className="d-flex align-items-center mb-2">
							{details?.product_items?.filter(
								(item) => item?.item?.id === 7
							)?.length > 0 ? (
								<i className="fas fa-check text-success"></i>
							) : (
								<i className="fas fa-times text-danger"></i>
							)}
							<span className="mx-1">{"sightseeing"}</span>
						</p>
						<p className="d-flex align-items-center mb-2">
							{details?.product_items?.filter(
								(item) => item?.item?.id === 8
							)?.length > 0 ? (
								<i className="fas fa-check text-success"></i>
							) : (
								<i className="fas fa-times text-danger"></i>
							)}
							<span className="mx-1">{"other services"}</span>
						</p>
					</CustomBox>
					<CustomBox title={packages.termsAndConditions}>
						<p>
							{details?.share_terms ?? (
								<p className="text-muted text-center">Not Found Data</p>
							)}
						</p>
					</CustomBox>
          <CustomBox title={productsBuilder.requiredDocuments}>
						{details?.share_required_documents ?? (
							<p className="text-muted text-center">Not Found Data</p>
						)}
					</CustomBox>
					<CustomBox title={marketPlace.messages.roomPrices}>
						<table
							striped
							className=" p-2 bg-white table table-striped table-hover"
						>
							<thead>
								<tr>
									<th rowSpan={"1"} width={"45%"}></th>
									<th
										align="center"
										className={"thstyle text-center"}
										width={"18%"}
									>
										{inventory.messages.Price}
									</th>
									<th align="center" className={"thstyle1"} width={"8%"}>
										{""}
									</th>
									<th align="center" className={"thstyle1"} width={"28%"}>
										{""}
									</th>
								</tr>
							</thead>
							<tbody>
								{details?.roomsAvailabilities?.length > 0 &&
									details?.roomsAvailabilities?.map((item) =>
										item?.room_remaining_availability != 0 ? (
											<tr key={item?.id}>
												<td component={"td"}>
													<p>
														{typeRooms[item?.room_type]}
													
													</p>
												</td>
												<td align="center">
													{item?.adult_price}
													{details.currency}
												</td>
												<td align="center">
													{/* {details?.product_price?.adjustment_price_child
														? details?.product_price?.adjustment_price_child
														: "0.00"}{" "}
													{currencyCode} */}
												</td>
												<td align="center">
													{/* {details?.product_price?.adjustment_price_infant
														? details?.product_price?.adjustment_price_infant
														: "0.00"}{" "}
													{currencyCode} */}
													<button
														className="btn btn-link font-weight-bold"
														style={{ color: "#D29D4D" }}
														onClick={() => toggle(item?.price_break_down)}
													>
														{packages.viewPriceBreakdown}
													</button>
												</td>
											</tr>
										) : null
									)}
							</tbody>
						</table>
					</CustomBox>
					<Guests
						edit={edit}
						setedit={setedit}
						setAdultState={setAdultState}
						adultState={adultState}
						setObj={setObj}
						obj={obj}
						setRoooomOpt={setRoooomOpt}
						setIsSaved={setIsSaved}
						calcDiscountPrices={calcDiscountPrices}
						currencyCode={currencyCode}
						productDiscounts={productDiscounts}
						setGuestsState={setGuestsState}
						GuestsState={GuestsState}
						details={details}
						setGuestsChildrenState={setGuestsChildrenState}
						GuestsChildrenState={GuestsChildrenState}
					/>
					<div className={"checkoutBox"}>
						<div ref={priceEle}>
							<p>{packages.totalPackagePrice}</p>
							<p style={{ color: "#10A711", display: "flex" }}>
								<p style={{ fontWeight: "bold" }} variant="span">
									{GuestsState?.reduce((total, obj) => +obj.price + total, 0)?.toFixed(2) }{" "}
									{details.currency}
								</p>{" "}
							</p>
						</div>
						<div className="d-flex flex-column">
							<span className="text-danger">
								{details?.roomsAvailabilities?.map((item) => {
									let x = availabilityFn(item?.room_type);
									return x > item?.room_remaining_availability ? (
										<span>
											*{typeRooms[item?.room_type]} available{" "}
											{item?.room_remaining_availability} Room, Not {x}*
										</span>
									) : (
										""
									);
								})}
							</span>

							<span className="text-danger">{!isSaved && notSavedMsg}</span>
							<span className="text-danger">
								{isSaved && !isValidINput() && "*All Fields Required "}
							</span>
						</div>
						<button
							className={"btn btncheckout"}
							onClick={() => {
								let condition =
									GuestsState?.filter(
										(item) => item.room_numbers > item.room_availability
									)?.length == 0;
								if (condition) {
									checkout();
								}
							}}
							disabled={disabledBtn()?.includes(true)}
						>
							{packages.completeCheckout}
						</button>
					</div>
				</div>
				{!isMobile && (
					<div className="col-lg-3 col-12">
						<>
							<Company
								title={packages.travelAgent}
								name={details?.company_name[locale]}
								logo={
									details?.company_avatar &&
									`${imageUrl}${details?.company_avatar}`
								}
							/>
							{ExternalElements()}
							<button
								className="btn w-100 py-2 bg-white"
								onClick={scrolPrice}
								style={{
									border: "1px solid rgba(215, 160, 75, 0.5)",
									color: "#D7A04B",
									fontSize: "17px",
								}}
							>
								{packages.viewPrice}
							</button>
						</>
					</div>
				)}

				<PriceBreakdownModal
					isOpen={isOpen}
					toggle={toggle}
					details={details}
				/>
			</div>
		</div>
	);
};

export default PackageDetails;
