import arrowRight from "assets/images/icons/arrow-right-icons.svg";
import visaToken from "assets/images/printvisa/visa.png";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import NumberField from "components/Form/NumberField/NumberField";
import TextField from "components/Form/TextField/TextField";
import { useSBSState } from "context/global";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
	downloadVisaDocuments,
	downloadVisaInsuranceDocuments,
	editRequestPassengers,
	sendRequestPassengers,
	entryStampPassengers,
	viewVisaOnline,
} from "services/VisaOnline";
import Locale from "translations";
import AddPassengers from "./components/AddPassengers";
import ListHeader from "./components/ListHeader";
import CustomModal from "./components/Modal";
import useShowPermission from "hooks/useShowPermission";
import axios from "axios";
import AddNewPassengerModal from "./components/AddNewPassenger";
import { ReactComponent as UploadIcon } from "assets/images/visa/upload-stamp.svg";
import { uploadFile } from "services/auth";

const converGender = (gender) => {
	if(gender == "أنثى" || gender == "أنثي" || gender == 2 || gender == "انثى" || gender == "انثي" ) return "female";
	if(gender == "ذكر"|| gender == 1 ) return "male";
	return gender?.toLowerCase()

}
export default function ListPassengers() {
	const { onlineVisaSearch, onlineVisaPassenger } = useMarketplaceState();
	let history = useHistory();
	const dispatch = useMarketplaceDispatch();
	const {
		onlineVisa,
		productsBuilder,
		success,
		visa,
		inventory,
		landing,
		reservation,
		commons,
	} = Locale;
	const [ModalAddGroupMembers, setModalAddGroupMembers] = useState(false);
	const { status, id } = useParams();
	const location = useLocation();
	const [ModalNewMember, setModalNewMember] = useState(false);
	const [searchVal, setSearchVal] = useState(null);
	const [searching, setSearching] = useState(false);
	const [passengersErrorsIndex, setPassengersErrorsIndex] = useState([]);
	const { locale, userInfo, role,serverErrors } = useSBSState();
	const [checkedIds, setCheckedIds] = useState(
		onlineVisaPassenger.map((item) =>
			item.id ? item.id : item.uniqueIdFrontend
		)
	);


	const [sendTravelersModal, setSendTravelersModal] = useState(false);
	const [passengersList, setPassengersList] = useState([]);
	const [serverPassengersList, setServerPassengersList] = useState([]);
	const [visaDates, setVisaDates] = useState({
		departure_date: null,
		return_date: null,
		rawdah_date: null,
	});

	// const [splitCount, setSplitCount] = useState(0);
	// const [modalSplit, setModalSplit] = useState(false);
	const [downloadList, setDownloadList] = useState([]);
	const [stampFiles, setStampFiles] = useState([]);
	const [invalidPassengerData, setInvalidPassengerData] = useState({
		isOpen: false,
		note: "",
	});
	const [rejectReason, setRejectReason] = useState({ isOpen: false, note: "" });
	const VISA_TYPE_UMRAH =
	Boolean(onlineVisaSearch.is_umrah)

		const pullIndexesFromServerErrors = (serverErrors)=>{
			let serverErrorsIndexes = []
			for (let index = 0; index < serverErrors.length; index++) {
				if(serverErrors[index]){
					serverErrorsIndexes.push(index)
				};
				
			}
			return serverErrorsIndexes
		}

		useEffect(() => {
			if (serverErrors) {
				const  errorsIndexes = pullIndexesFromServerErrors(serverErrors)
				setPassengersErrorsIndex(errorsIndexes);
			}
	
			return () => {};
		}, [serverErrors]);

	const inventoryPermission = useShowPermission({
		permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
	});
	const search = (value) => {
		setSearching(true);
		if (onlineVisaPassenger.length > 0 && value) {
			let filteredData = [];
			for (let index = 0; index < onlineVisaPassenger.length; index++) {
				for (const key in onlineVisaPassenger[index]) {
					if (
						onlineVisaPassenger[index][key]
							?.toString()
							.includes(value?.toLowerCase()) ||
						onlineVisaPassenger[index][key]
							?.toString()
							.includes(value?.toUpperCase())
					) {
						filteredData.push(onlineVisaPassenger[index]);
						break;
					}
				}
			}
			setPassengersList(filteredData);
			setSearching(false);
		} else {
			setPassengersList(onlineVisaPassenger);
			setSearching(false);
		}
	};

	const Clearsearch = () => {
		setPassengersList(onlineVisaPassenger);
		setSearchVal("");
	};

	const toggleModelInvalidData = (note) => {
		setInvalidPassengerData((prev) => ({
			isOpen: !prev.isOpen,
			note: note,
		}));
	};

	const toggleModelReject = (note) => {
		setRejectReason((prev) => ({
			isOpen: !prev.isOpen,
			note: note,
		}));
	};

	const toggleModelAddGroupMembers = () => {
		setModalAddGroupMembers(!ModalAddGroupMembers);
	};

	const toggleModelSendTravelers = () => {
		setSendTravelersModal(!sendTravelersModal);
	};

	// const toggleModelSplit = () => {
	// 	setModalSplit(!modalSplit);
	// };

	const toggleModelNewMember = () => {
		setModalNewMember(!ModalNewMember);
	};

	const addSelectedPassangers = (data) => {
		setPassengersList([...passengersList, ...data]);
		const residence_data = onlineVisaSearch?.residence_data;

		dispatch({
			type: "onlineVisaPassenger",
			payload: data.map((passenger) => {
				debugger
				return {
					...passenger,
					full_name_en: passenger?.FullName,
					full_name_ar: passenger?.AFullName,
					national_id: passenger?.national_id?.toString(),
					nationality_id: passenger?.nationality_id,
					issue_country: passenger?.nationality_id,
					first_name_ar: passenger?.AFirstName
						? passenger?.AFirstName
						: passenger?.AFullName?.split(" ")[0],

					father_name_ar: passenger?.AFatherName,

					grandfather_name_ar: passenger?.AGrandName,

					last_name_ar: passenger?.ALastName,

					first_name_en: passenger?.FirstName,

					father_name_en: passenger?.FatherName,

					grandfather_name_en: passenger?.GrandName,
						
					last_name_en: passenger?.LastName,

					...(residence_data && {
						residence_data: {
								residence_number:null,
								residence_photo:null,
								issue_date:null,
								expiry_date:null,
						},
					}),
				};
			}),
		});
		toggleModelAddGroupMembers();
	};

	const removePassanger = (id) => {
		let newFIlteredPassanger = onlineVisaPassenger.filter(
			(item) => id !== item.id && id !== item.uniqueIdFrontend
		);
		setPassengersList(newFIlteredPassanger);
		dispatch({
			type: "onlineVisaPassengerRemove",
			payload: newFIlteredPassanger,
		});
		let newCheckedIds = checkedIds.filter((item) => item != id);
		setCheckedIds(newCheckedIds);
		setSearchVal("");
		// const cloneIndex =  passengersErrorsIndex.filter(i => i !== index)
		setPassengersErrorsIndex([])
	};

	function handelCheck(passenger, checked) {
		if (checked) {
			setDownloadList([...downloadList, passenger.id]);
		} else {
			setDownloadList(downloadList.filter((item) => item != passenger.id));
		}
	}
	function handelCheckAll(checked) {
		if (checked) {
			const passengers = serverPassengersList?.filter(
				(passenger) => passenger?.visa_document || passenger?.insurance_document
			);
			setDownloadList(passengers.map((passenger) => passenger.id));
		} else {
			setDownloadList([]);
		}
	}

	function downLoadDocument(UUID, documentName) {
		const fetchFilesURL = process.env.REACT_APP_FILEUPLOAD_URL + "/fetch";
		const config = {
			fetchFilesURL,
			method: "GET",
			responseType: "blob",
		};
		axios.get(`${fetchFilesURL}/${UUID}`, config).then((res) => {
			const blob = new Blob([res.data], {
				type: res.headers["content-type"],
			});
			const objectUrl = window.URL.createObjectURL(blob);
			const anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`${documentName}-${moment().format("DD-MM-YYYY")}.${
					res.data.type.split("/")[1]
				}`
			);
			anchor.click();
		});
	}

	const EDIT_STATUS = status === "edit";
	const VIEW_STATUS = status === "view";

	const uploadStampFile = async (file) => {
		const formData = new FormData();
		formData.append("file", file);
		formData.append("name", file?.name);
		formData.append("bucket", "entryStamp");
		const response = await uploadFile(formData);
		if (response.data.data.uuid) {
			return response.data.data.uuid;
		}
	};
	const handleUploadStamp = async (e, index, passenger_id) => {
		const files = e.target.files;

		const uuid = await uploadStampFile(files[0]);
		if (uuid) {
			const data = {
				request_id: +id,
				passengers: [
					{
						passenger_id,
						entry_stamp: uuid,
					},
				],
			};
			const entryStamp = await entryStampPassengers(data);
			if (entryStamp.status === 200) {
				successfulRequest(entryStamp, false);
				const cloneStamp = [...stampFiles];
				cloneStamp[index] = true;
				setStampFiles(cloneStamp);
			}
		}
	};

	const allPassengers =
		passengersList?.length > 0 && status !== "edit" && status !== "view"
			? passengersList?.map((item, index) => {
					return (
						<tr key={item.id} className={passengersErrorsIndex.includes(index )  ? "border-danger":""}>
							<td className="d-flex align-items-center">
								<input
									type="checkbox"
									checked={downloadList.includes(item.id)}
									className="mx-1 insert-group-checkbox "
									onChange={(e) => {
										handelCheck(item, e.target.checked);
									}}
								/>
								{item.passport_number}
							</td>
							<td>
								{locale === "en"
									? item.name_en
										? item.name_en
										: item.full_name_en
									: item.name_ar
									? item.name_ar
									: item.full_name_ar}
							</td>
							<td>{item.gender}</td>
							<td>{item.birth_date}</td>
							<td>{item.age}</td>
							{/* <td>{item?.relationship[locale] ? item?.relationship[locale] : item?.relationship ? item?.relationship : ""}</td> */}
							<td>{item.national_id}</td>
							<td>{item.nationality}</td>
							<td>{item?.visa ? item?.visa : "-"}</td>
							{status !== "edit" && status !== "view" ? (
								<td>
									<i
										class="fas fa-trash-alt text-danger pointer"
										onClick={() => {
											removePassanger(
												item.id ? item.id : item.uniqueIdFrontend
											);
										}}
									></i>
								</td>
							) : null}
							<td>
								<span className="pointer">
									{EDIT_STATUS && (
										<img
											src={arrowRight}
											alt=""
											onClick={() => {
												dispatch({
													type: "onlineVisaEditPassenger",
													payload: { ...item, index },
												});
												history.push("/visa-requests/Edit/Passenger");
											}}
										/>
									)}
								</span>
							</td>
						</tr>
					);
			  })
			: [];

	const allPassengersEdit =
		(passengersList?.length > 0 && EDIT_STATUS) || VIEW_STATUS
			? passengersList?.map((item, index) => {
					const passangerStatus = item.status;
					const invalidStatus = passangerStatus === "invalid";
					const rejectStatus = passangerStatus === "rejected";
					const issuedStatus = passangerStatus === "issued";

					const entryStamp =
						onlineVisaSearch?.entry_stamp &&
						!item?.entry_stamp &&
						!stampFiles[index] &&
						issuedStatus;

					return (
						<tr key={item.id} className={passengersErrorsIndex.includes(index) ? "border-danger":""}>
							{VIEW_STATUS && (
								<td className="d-flex align-items-center">
									<input
										type="checkbox"
										checked={downloadList.includes(item.id)}
										disabled={!item?.visa_document && !item?.insurance_document}
										className="mx-1 insert-group-checkbox"
										onChange={(e) => {
											handelCheck(item, e.target.checked);
										}}
									/>
								</td>
							)}
							<td>{item.passport_number}</td>
							<td>
								{locale === "ar"
									? item.full_name_ar?.trim() === ""
										? item.full_name_en
										: item.full_name_ar
									: item.full_name_en}
							</td>
							<td>{item.gender?.[locale] || item.gender}</td>
							<td>{item.date_of_birth}</td>
							<td>
								{item.age
									? item.age
									: moment().diff(item.date_of_birth, "years")}
							</td>
							{/* <td>{item.relationship ? item.relationship[locale] : ""}</td> */}
							<td>{item.national_id}</td>
							<td>
								{item.nationality_name?.name?.[locale] || item.nationality}
							</td>
							<td>
								{item.passport_photo && item.passport_photo !== "" ? (
									<i className="fas fa-check text-success"></i>
								) : (
									<i className="fas fa-times text-danger"></i>
								)}
							</td>
							<td>{item?.visa_number ? item?.visa_number : "-"}</td>
							{/*  remove passanger */}
							{EDIT_STATUS && (
								<td>
									<i
										class="fas fa-trash-alt text-danger pointer"
										onClick={() =>
											removePassanger(item.id ? item.id : item.uniqueIdFrontend)
										}
									></i>
								</td>
							)}
							{/* show visa and insurance documents */}
							{VIEW_STATUS ? (
								<>
									<td className="text-center">
										{rejectStatus ? (
											<span className="border-0 bg-transparent d-flex shadow-none  align-items-center">
												{inventory.messages.Rejected}

												<i
													className="fas fa-eye text-danger pointer ms-1"
													onClick={() => toggleModelReject(item?.reject_note)}
												></i>
											</span>
										) : item.visa_document ? (
											<>
												<button
													className="border-0 bg-transparent d-flex shadow-none custom-color align-items-center"
													onClick={() =>
														downLoadDocument(
															item.visa_document,
															"visa-document"
														)
													}
												>
													<i className="fas fa-download pointer"></i>
													<span className="mx-1">
														{inventory.messages.download}
													</span>
												</button>
											</>
										) : (
											"-"
										)}
									</td>
									<td className="text-center">
										{item.insurance_document ? (
											<button
												className="border-0 bg-transparent d-flex shadow-none custom-color align-items-center"
												onClick={() =>
													downLoadDocument(
														item.insurance_document,
														"insurance-document"
													)
												}
											>
												<i className="fas fa-download pointer"></i>
												<span className="mx-1">
													{inventory.messages.download}
												</span>
											</button>
										) : (
											"-"
										)}
									</td>
									<td className="text-center">
										{invalidStatus ? (
											<>
												<span className="border-0 bg-transparent d-flex shadow-none  align-items-center">
													{inventory.messages.invalidData}

													<i
														className="fas fa-eye custom-color pointer"
														onClick={() =>
															toggleModelInvalidData(item?.reapply_note)
														}
													></i>
												</span>
												<span
													className="mx-1 pointer custom-color"
													style={{ textDecoration: "underline" }}
													onClick={() => {
														dispatch({
															type: "onlineVisaEditPassenger",
															payload: { ...item, index },
														});
														history.push({
															pathname: "/visa-requests/Edit/Passenger",
															state: { invalidData: invalidStatus },
														});
													}}
												>
													{inventory.messages.resend}
												</span>
											</>
										) : null}
										{entryStamp ? (
											<span
												style={{ textDecoration: "underline" }}
												className="pointer border-0 bg-transparent d-flex shadow-none pointer custom-color  align-items-center"
												role="button"
											>
												<label htmlFor="upload-stamp">
													<UploadIcon style={{ flex: "1 0 auto" }} />
													{inventory.messages.AttachStamp}
												</label>
												<input
													onChange={(e) => handleUploadStamp(e, index, item.id)}
													style={{ display: "none" }}
													type="file"
													id="upload-stamp"
													accept="image/*"
												/>
											</span>
										) : null}
									</td>
								</>
							) : null}

							{EDIT_STATUS && (
								<td>
									<span className="pointer">
										<img
											src={arrowRight}
											className="edit-passenger-arrow"
											alt="edit passenger"
											onClick={() => {
												dispatch({
													type: "onlineVisaEditPassenger",
													payload: { ...item, index },
												});
												history.push("/visa-requests/Edit/Passenger");
											}}
										/>
									</span>
								</td>
							)}
						</tr>
					);
			  })
			: [];

	const fetchVisaOnline = async (saveNewTravelers) => {
		if (location.state !== "editPassenger" || saveNewTravelers) {
			const response = await viewVisaOnline(id);
			if (response.status === 200 || response.status === 201) {
				let searchKeys = response.data.data;
				dispatch({
					type: "onlineVisaSearch",
					payload: {
						id,
						is_umrah:searchKeys?.is_umrah,
						residence_data: searchKeys?.residence_data,
						entry_stamp: searchKeys?.entry_stamp,
						destination: searchKeys?.destination_name,
						destination_id: searchKeys?.destination_id,
						pax: searchKeys?.pax,
						visaType: {
							id: searchKeys?.visa_type_id,
							name: searchKeys?.visa_type_name,
							currency_name: searchKeys?.currency,
							price: searchKeys.price_per_one,
						},
						residence: {
							value: searchKeys.residence_id,
							label: searchKeys.residence_name,
						},
						embassy: {
							value: searchKeys.embassy_id,
							label: searchKeys.embassy_name,
						},
						transportation_type: {
							value: searchKeys.transportation_type,
							label: searchKeys.transportation_type,

							currency: searchKeys?.currency,
							price: searchKeys.price_per_one,
						},
						nationalities: searchKeys?.nationalities?.map(
							(nationality) => nationality?.country_id
						),
						departure_date: searchKeys?.departure_date,
						return_date: searchKeys?.return_date,
						requestDate: searchKeys?.request_date,
						expirytDate: searchKeys?.expiration_date,
						status: searchKeys?.status,
						rawda_reservation_document: searchKeys?.rawda_reservation_document,
						all_nationalities: searchKeys?.all_nationalities,
						total: searchKeys?.total,
						currency: searchKeys?.currency,
					},
				});
				dispatch({
					type: "onlineVisaPassengerRemove",
					payload: searchKeys.passengers,
				});
				setPassengersList(searchKeys.passengers);
				setServerPassengersList(searchKeys.passengers);
				setCheckedIds(searchKeys?.passengers?.filter((item) => item !== id));
			}
		} else {
			setPassengersList(onlineVisaPassenger);
		}
	};
	// start edit
	useEffect(() => {
		if (EDIT_STATUS || VIEW_STATUS) {
			fetchVisaOnline(false);
		}
	}, [status]);

	function successfulRequest(response, redirect, successMsg) {
		store.addNotification({
			title: "",
			message: successMsg
				? successMsg
				: inventory.messages.requestUpdatedSuccessfully,
			type: "success",
			insert: "top",
			container: "top-right",
			animationIn: ["animated", "fadeIn"],
			animationOut: ["animated", "fadeOut"],
			dismiss: {
				duration: 5000,
				onScreen: true,
				pauseOnHover: true,
			},
		});
		if (redirect) {
			history.push(
				inventoryPermission ? "/inventory/visa-requests" : "/statistics"
			);
			dispatch({
				type: "onlineVisaPassengerRemove",
				payload: [],
			});
		}
	}

	// async function splitTravelers() {
	// 	const response = await splitRequestPassengers(id);
	// 	if (response.status === 200) {
	// 		successfulRequest(response, true);
	// 	}
	// }

	async function saveTravalers() {
		const data = {
			request_id: id,
			all_nationalities: onlineVisaSearch?.all_nationalities,
			passengers: passengersList.map((passenger) => {
				const residence_data = passenger.residence_data;
				debugger
				return {
					...passenger,
					national_id: passenger?.national_id,
					nationality_id: passenger?.nationality_id,

					country:
						passenger?.country?.[locale] ||
						passenger?.country ||
						passenger?.issue_country_label,
					issue_country: passenger?.PPIssueCountry || passenger?.issue_country,

					first_name_ar: passenger?.first_name_ar,
					father_name_ar: passenger?.father_name_ar,
					grandfather_name_ar: passenger?.grandfather_name_ar,
					last_name_ar: passenger?.last_name_ar,

					first_name_en: passenger?.first_name_en,
					father_name_en: passenger?.father_name_en,
					grandfather_name_en: passenger?.grandfather_name_en,
					last_name_en: passenger?.last_name_en,
					// last_name_en: passenger?.last_name_en && passenger?.last_name_en?.trim() !== "" ? passenger?.last_name_en : passenger?.full_name_en?.split(" ")[3],

					passport_photo: passenger?.passport_photo,
					gender: converGender(passenger?.gender?.en || passenger?.gender),
					title: passenger?.title?.en || passenger?.title,
					birth_country:
						passenger?.birth_country_name?.name?.[locale] ||
						passenger?.birth_country ||
						passenger?.issue_country_label,
					passport_type:
						passenger?.passport_type?.name || passenger?.passport_type,
					issue_place: passenger?.issue_place?.toString() || "",
					nationality:
						passenger?.nationality_name?.name?.en ||
						passenger?.nationality ||
						passenger?.issue_country_label,
						issue_date: passenger?.issue_date ? moment(passenger?.issue_date).format("YYYY-MM-DD"):passenger?.issue_date,
				expire_date: passenger?.expire_date ? moment(passenger?.expire_date).format("YYYY-MM-DD"):passenger?.expire_date,
					passport_number: passenger?.passport_number?.toString(),
					...(residence_data && {
						residence_data: {
							residence_number: residence_data?.residence_number,
							residence_photo: residence_data?.residence_photo,
							issue_date: residence_data?.issue_date
								? moment(residence_data?.issue_date).format("YYYY-MM-DD")
								: null,
							expiry_date: residence_data?.expiry_date
								? moment(residence_data?.expiry_date).format("YYYY-MM-DD")
								: null,
						},
					}),
					offline_company_id: passenger?.offline_company_id || null,
				};
			}),
		};
		debugger
		const response = await editRequestPassengers(data);
		if (response.status === 200) {
			successfulRequest(response, false);
			fetchVisaOnline(true);
		}
	}

	async function sendTravelers() {
		const data = {
			request_id: id,
			departure_date: moment(visaDates?.departure_date).format("YYYY-MM-DD"),
			return_date: moment(visaDates?.return_date).format("YYYY-MM-DD"),
			rawdah_date: moment(visaDates?.rawdah_date).format("YYYY-MM-DD"),
		};
		const response = await sendRequestPassengers(data);
		if (response.status === 200) {
			successfulRequest(response, true);
			setSendTravelersModal(false);
		}
	}

	async function downloadSelectedPassengerDocumnet(documentType) {
		const data = {
			visa_request_id: id,
			passenger_ids: downloadList,
		};

		const res =
			documentType === "visa"
				? await downloadVisaDocuments(data)
				: await downloadVisaInsuranceDocuments(data);
		if (res?.status === 200) {
			const blob = new Blob([res.data], {
				type: res.data.type,
			});
			const objectUrl = window.URL.createObjectURL(blob);
			var anchor = document.createElement("a");
			anchor.target = "_blank";
			anchor.href = objectUrl;
			anchor.setAttribute(
				"download",
				`${documentType}-${id}-${moment().format("DD-MM-YYYY")}`
			);
			anchor.click();
			successfulRequest(res, false, onlineVisa.documentsDownloadedSuccussfuly);
			setDownloadList([]);
		} else {
			store.addNotification({
				title: "",
				message:
					documentType === "visa"
						? onlineVisa.noVisaDocumentAvailable
						: documentType === "insurance"
						? onlineVisa.noInsuranceDocumentAvailable
						: commons.somethingWentWrong,
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}


	// redirect if the user changed the url
	useEffect(() => {
		if (status) {
			dispatch({ type: "redirectURL", payload: location.pathname });
		}
		if (onlineVisaSearch.status === "waiting" && role) {
			history.push(
				inventoryPermission
					? `/inventory/visa-requests/view/${id}`
					: "/statistics"
			);
		}
		return () => dispatch({ type: "redirectURL", payload: location.pathname });
	}, [role]);

	return (
		<>
			<div className="list-container">
				<div>
					<ListHeader />
				</div>

				<div className="mt-3 bg-white border rounded ">
					<header className=" p-3 border-bottom d-flex justify-content-between align-items-center">
						<p className="m-0 font-weight-bold">
							{onlineVisa.Travelers}({onlineVisaPassenger?.length}/{" "}
							{onlineVisaSearch?.pax}){/* download rawada */}
						</p>
						{/* download rawada */}
						{VISA_TYPE_UMRAH &&
						(onlineVisaSearch.status === "issued" ||
							onlineVisaSearch.status === "waiting") &&
						status !== "edit" ? (
							<div className="">
								<button
									className="btn bg-nxt m-1"
									disabled={!onlineVisaSearch?.rawda_reservation_document}
									onClick={() => {
										downLoadDocument(
											onlineVisaSearch?.rawda_reservation_document,
											"madinah-rawda"
										);
									}}
								>
									{onlineVisa.downloadMadinahRawda}
								</button>
							</div>
						) : null}
						{/* {status !== "view" ? (
							<button
								type="button"
								className="btn bg-nxt m-1"
								disabled={
									
								}
								onClick={() => {
									setModalSplit(true);
									setSplitCount(
										+onlineVisaSearch?.pax - serverPassengersList.length
									);
								}}
							>
								{onlineVisa.split}
							</button>
						) : null} */}
					</header>

					<div className="d-flex border-top px-3 align-items-center justify-content-between flex-wrap List-passengers-header">
						{EDIT_STATUS ? (
							<div className="col-md-4 col-12 p-0">
								<button
									className="btn bg-nxt m-1"
									disabled={
										passengersList.length === Number(onlineVisaSearch.pax)
									}
									onClick={toggleModelAddGroupMembers}
								>
									{onlineVisa.InsertFromGroups}
								</button>
								<button
									className="btn bg-nxt m-1"
									disabled={
										passengersList.length === Number(onlineVisaSearch.pax)
									}
									onClick={toggleModelNewMember}
								>
									{onlineVisa.AddNew}
								</button>
							</div>
						) : null}

						{/* download visa and insurance document */}
						{(onlineVisaSearch.status === "issued" ||
							onlineVisaSearch.status === "waiting") &&
						status !== "edit" ? (
							<div className="d-flex flex-wrap col-md-7 col-12">
								{/* download visa */}
								<div className="">
									<button
										className="btn bg-nxt m-1"
										disabled={downloadList.length === 0}
										onClick={() => downloadSelectedPassengerDocumnet("visa")}
									>
										{inventory.messages.downloadSelectedVisas}
									</button>
								</div>
								{/* download insurance */}
								<div className="">
									<button
										className="btn bg-nxt m-1"
										disabled={downloadList.length === 0}
										onClick={() =>
											downloadSelectedPassengerDocumnet("insurance")
										}
									>
										{inventory.messages.downloadSelectedInsurance}
									</button>
								</div>
							</div>
						) : null}

						{/* search in Travelers  */}
						<div
							className={`col-md-5 col-12 m-0 p-0 d-flex align-items-end py-2 justify-content-md-end`}
						>
							<div className=" mx-2 w-50">
								<TextField
									label={onlineVisa.search}
									placeholder={onlineVisa.search}
									value={searchVal}
									onChange={(e) => {
										search(e.target.value);
										setSearchVal(e.target.value);
									}}
								/>
							</div>

							<div className="clear text-secondary pointer my-2">
								<button
									className="btn bg-white px-4  onlineVisa-clear-bottom text-gray"
									onClick={() => Clearsearch()}
								>
									<i class="fas fa-undo mx-1"></i>
									{onlineVisa.clear}
								</button>
							</div>
						</div>
					</div>
				</div>
				{/* passengers list */}
				<div className="border table-container">
					<table class="table-update p-2  m-0 bg-white  table table-striped">
						<thead>
							<tr>
								{VIEW_STATUS && (
									<th>
										<input
											type="checkbox"
											checked={
												serverPassengersList?.filter(
													(passenger) =>
														passenger?.visa_document ||
														passenger?.insurance_document
												).length === downloadList.length &&
												downloadList.length > 0
											}
											disabled={serverPassengersList.every(
												(passenger) =>
													!passenger?.visa_document &&
													!passenger?.insurance_document
											)}
											onChange={(e) => {
												handelCheckAll(e.target.checked);
											}}
											className="mx-1 insert-group-checkbox "
										/>
									</th>
								)}
								<th>{onlineVisa.PassportNumber}</th>
								<th>{onlineVisa.Name}</th>
								<th>{onlineVisa.Gender}</th>
								<th>{onlineVisa.Birthday}</th>
								<th>{onlineVisa.Age}</th>
								{/* <th>{onlineVisa.Relationship}</th> */}
								<th>{onlineVisa.NationalId}</th>
								<th>{onlineVisa.Nationality}</th>
								<th>{reservation.messages.passportPhoto}</th>
								<th>{visa.Visa}</th>

								{VIEW_STATUS && (
									<>
										<th>{onlineVisa.visaDocument}</th>
										<th>{onlineVisa.insuranceDocument}</th>
										<th>{inventory.messages.tools}</th>
									</>
								)}
								{EDIT_STATUS && <th>{onlineVisa.actions}</th>}
							</tr>
						</thead>
						<tbody>
							{passengersList.length > 0 && !searching ? (
								EDIT_STATUS || VIEW_STATUS ? (
									allPassengersEdit
								) : (
									allPassengers
								)
							) : (
								<tr className="">
									<td
										colSpan={9}
										className="text-center online-visa-noResult  "
									>
										<p className="online-visa-noResult-tr">
											{searching ? "" : onlineVisa.noTravelersAdded}
											{searching && (
												<span
													className="spinner-border spinner-border-sm mx-2"
													role="status"
												/>
											)}
										</p>
									</td>
								</tr>
							)}
						</tbody>
					</table>
				</div>
				{/* footer */}
				<footer className=" d-flex  justify-content-between flex-wrap flex-md-row flex-column-reverse align-items-md-center py-4">
					<button
						type="button"
						className="btn btn-apply mt-2 px-3 py-2"
						onClick={() =>
							history.push(
								inventoryPermission
									? `/inventory/visa-requests/`
									: "/statistics"
							)
						}
					>
						{onlineVisa.backTobooking}
					</button>
					{status !== "view" ? (
						<div className="d-flex align-items-md-center align-self-end">
							<button
								type="button"
								class="btn  btn-green-300 mt-2 px-3 py-2 mx-2"
								disabled={
									serverPassengersList.length === 0 ||
									serverPassengersList.length !== passengersList.length
								}
								onClick={() => {
									setSendTravelersModal(true);
								}}
							>
								{onlineVisa.sendTravelers}
							</button>
							<button
								type="button"
								class="btn bg-nxt mt-2 px-3 py-2"
								onClick={saveTravalers}
							>
								{productsBuilder.save}
							</button>
						</div>
					) : null}
				</footer>
			</div>
			{/* InsertFromGroups */}
			<CustomModal
				modalIsOpen={ModalAddGroupMembers}
				header={onlineVisa.InsertFromGroups}
				toggle={toggleModelAddGroupMembers}
				size={userInfo?.is_connected_to_safa_visa ? "xl" : "md"}
				centered={false}
			>
				{userInfo?.is_connected_to_safa_visa ? (
					<AddPassengers
						checkedIds={checkedIds}
						setCheckedIds={setCheckedIds}
						addSelectedPassangers={addSelectedPassangers}
					/>
				) : (
					<>
						<div className="d-flex flex-column justify-content-center align-items-center">
							<div>
								<img src={visaToken} alt="" />
							</div>
							<p className="m-0 my-3">{onlineVisa.loginSafaVisaAccount}</p>
							<div className="text-center w-100">
								<button
									className="btn bg-nxt w-75"
									onClick={() => {
										history.push("/visa/login");
									}}
								>
									{landing.login}
								</button>
							</div>
						</div>
					</>
				)}
			</CustomModal>
			{/* split */}
			{/* <CustomModal
				modalIsOpen={modalSplit}
				header={onlineVisa.split}
				toggle={toggleModelSplit}
				size={"md"}
				centered={true}
			>
				<div className="d-flex flex-column justify-content-center align-items-center w-75 m-auto py-5">
					<NumberField
						type={"number"}
						hasLabel={true}
						readOnly={true}
						disabled={true}
						removeArrow={true}
						label={onlineVisa.count}
						placeholder={onlineVisa.enterCount}
						value={splitCount}
						name="split_count"
					/>
					<button
						type="button"
						className="btn bg-nxt m-1 w-100"
						onClick={splitTravelers}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal> */}

			{/* send travelers modal */}
			<CustomModal
				modalIsOpen={sendTravelersModal}
				header={onlineVisa.sendTravelers}
				toggle={toggleModelSendTravelers}
				size={"md"}
				centered={true}
			>
				<div className="d-flex flex-column justify-content-center align-items-center w-75 m-auto py-3">
					{serverPassengersList.length > 0 &&
					serverPassengersList.length !== +onlineVisaSearch?.pax ? (
						<div className="mrz-warning">
							<i class="fas fa-exclamation-triangle orange-color me-1"></i>
							<span>
								{onlineVisa.splitMessage}{" "}
								{+onlineVisaSearch?.pax - serverPassengersList.length}
							</span>
						</div>
					) : null}
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.departureDate}
							placeholder={onlineVisa.departureDate}
							id="departureDate"
							name="departureDate"
							date={visaDates.departure_date}
							onChangeDate={(departureDate) => {
								setVisaDates({
									...visaDates,
									departure_date: departureDate,
									return_date:
										moment(departureDate) >= moment(visaDates?.return_date)
											? null
											: visaDates?.return_date,
									rawdah_date:
										moment(departureDate) >= moment(visaDates?.rawdah_date)
											? null
											: visaDates?.rawdah_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(0, "d"), "day")
							}
						/>
					</div>
					<div className="col-12">
						<DatePickerField
							label={onlineVisa.returnDate}
							placeholder={onlineVisa.returnDate}
							id="returnDate"
							name="returnDate"
							date={visaDates.return_date}
							disabled={!visaDates.departure_date}
							onChangeDate={(returnDate) => {
								setVisaDates({
									...visaDates,
									return_date: returnDate,
									departure_date:
										moment(returnDate) <= moment(visaDates?.departure_date)
											? null
											: visaDates?.departure_date,
								});
							}}
							isOutsideRange={(day) =>
								!day.isAfter(moment(new Date()).add(1, "d"), "day") ||
								!day.isAfter(moment(visaDates.departure_date), "day")
							}
							initialVisibleMonth={() => moment(visaDates.departure_date)}
						/>
					</div>
					{/* check if  */}
					{VISA_TYPE_UMRAH ? (
						<div className="col-12">
							<DatePickerField
								label={`${onlineVisa.rawdahDate} (+/-)1 ${onlineVisa.day}`}
								placeholder={onlineVisa.rawdahDate}
								id="rawdahDate"
								name="rawdahDate"
								date={visaDates.rawdah_date}
								disabled={!visaDates.departure_date || !visaDates.return_date}
								onChangeDate={(date) => {
									setVisaDates({
										...visaDates,
										rawdah_date: date,
									});
								}}
								isOutsideRange={(day) =>
									day < visaDates.departure_date.startOf("day") ||
									day > visaDates.return_date.endOf("day")
								}
								initialVisibleMonth={() =>
									moment(visaDates.departure_date) || null
								}
							/>
						</div>
					) : null}

					<button
						type="button"
						className="btn bg-nxt m-1 w-100"
						disabled={!visaDates.departure_date || !visaDates.return_date}
						onClick={sendTravelers}
					>
						{success.confirm}
					</button>
				</div>
			</CustomModal>

			<CustomModal
				modalIsOpen={ModalNewMember}
				header={onlineVisa.AddNew}
				toggle={toggleModelNewMember}
				size={"md"}
				centered={true}
			>
				<AddNewPassengerModal checkedIds={checkedIds} />
				{/* <MRZ checkedIds={checkedIds} /> */}
			</CustomModal>

			<CustomModal
				modalIsOpen={invalidPassengerData.isOpen}
				header={inventory.messages.invalidData}
				toggle={() => toggleModelInvalidData()}
				size={"md"}
				centered={true}
			>
				{invalidPassengerData.note}
			</CustomModal>

			<CustomModal
				modalIsOpen={rejectReason.isOpen}
				header={inventory.messages.Rejected}
				toggle={() => toggleModelReject()}
				size={"md"}
				centered={true}
			>
				{rejectReason.note}
			</CustomModal>
		</>
	);
}
