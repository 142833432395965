import axios from "axios";
const authURL = process.env.REACT_APP_API_URL + "/v1/auth";
const URI = process.env.REACT_APP_FILEUPLOAD_URL;
const URL = process.env.REACT_APP_AUTH_API;
const permissionsURL = process.env.REACT_APP_API_URL + "/v1";
export const emailAvailability = async (data) => {
	return await axios
		.post(`${URL}/check_avaliable_email`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const changeNewPassword = async (data) => {
	return await axios
		.post(`${authURL}/update-password`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const nameAvailability = async (data) => {
	return await axios
		.post(`${URL}/check_avaliable_company_name`, data)
		.then((res) => res)
		.catch((err) => err);
};

export const activeAccount = async (data) => {
	return await axios
		.post(`${authURL}/active-account`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyEmail = async (data) => {
	return await axios
		.post(`${URL}/check_company_and_user_status`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const userLogin = async (data) => {
	return await axios
		.post(`${URL}/login`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const verifyOtp = async (data) => {
	return await axios
		.post(`${permissionsURL}/auth/verify-otp`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const resendMassage = async (url) => {
	return await axios
		.get(`${url}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const forgetPass = async (data) => {
	return await axios
		.post(`${URL}/forget-password`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const passReset = async (data) => {
	return await axios
		.post(`${URL}/forget-password/reset`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const accountRegister = async (data) => {
	return await axios
		.post(`${URL}/register`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const uploadFile = async (data) => {
	return await axios
		.post(
			`${URI}/upload`,
			data
			// , {
			// 	headers: {
			// 		"system-name": "Files_MS",
			// 		"system-secret": "secret",
			// 		"content-type": "multipart/form-data",
			// 	},
			// }
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchImages = async (id) => {
	return await axios
		.get(`${URI}/fetch/${id}`)
		.then((res) => res.config.url)
		.catch((err) => err.response);
};

export const getPermissions = async () => {
	return await axios
		.get(`${permissionsURL}/my-permissions`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const loginWithSafaKey = async(data) => {
	return await axios
		.post(`${authURL}/safa-key/login`, data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const checkSafaVisaAccount = async(data) => {
	return await axios
		.post(`${authURL}/safa-key/check-safa-visa-account`, data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const registerSafaVisaAccount = async(data) => {
	return await axios
		.post(`${authURL}/safa-key/register`, data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const connectSafaWithSbsAccount = async(data) => {
	return await axios
		.post(`${authURL}/safa-key/connect-and-login`, data)
		.then((res) => res)
		.catch((err) => err.response);
}
