import SafaLogo from "assets/images/dashboard/safaLogoFull.svg";
import safaLogoMinimal from "assets/images/dashboard/safaLogoSmall.svg";
  import newlogo from "assets/images/newlogo2.png";

import { useSBSState } from 'context/global';
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link, NavLink, useHistory, useLocation } from "react-router-dom";
import { Collapse } from "reactstrap";
import Locale from "translations";
import "../../scss/modules/Dashboard/_sidenav.scss";
import {
	AboutUsIcon,
	ActivityLogIcon,
	Arrow,
	ContactUsIcon,
	HotelFIightsIcon,
	MyBookingIcon,
	MyTeamIcon,
	SafaOfflineIcon,
	StatisticsIcon,
	TeamBranchesIcon,
	TeamGroupsIcon,
	TeamMemberIcon,
	TripsManagementIcon,
	WalletIcon,
	WalletRequestsIcon,
	WalletTransactionsIcon,
} from "./icons";
import useShowPermission from "hooks/useShowPermission";

export default function SideNav({ show, setShow }) {
	const {
		dashboard,
		marketPlace,
		inventory,
		payment,
		productsBuilder,
		teamManagement,
		newLand
	} = Locale;
	const { locale } = useSBSState();
	const location = useLocation();

	const history = useHistory();
	const { pathname } = useLocation();
	const marketPlacePrmission = useShowPermission({
		permission: [
			"View-Marketplace-Hotels",
			"Manage-Marketplace-Hotels",
			"View-Marketplace-Transfer",
			"Manage-Marketplace-Transfer",
			"View-Marketplace-Tours",
			"Manage-Marketplace-Tours",
			"View-Marketplace-Visa",
			"Manage-Marketplace-Visa",
			"View-Marketplace-Flights",
			"Manage-Marketplace-Flights",
		],
	});
	const [dashboardRoutes, setDashboardRoutes] = useState([
		// marketplace
		{
			id: "sidenav-market-search",
			icon: <HotelFIightsIcon />,
			text: `${dashboard.messages.hotelsFlights}`,
			ring: false,
			route: "/market-search/",
			permission: [
				"View-Marketplace-Hotels",
				"Manage-Marketplace-Hotels",
				"View-Marketplace-Transfer",
				"Manage-Marketplace-Transfer",
				"View-Marketplace-Tours",
				"Manage-Marketplace-Tours",
				"View-Marketplace-Visa",
				"Manage-Marketplace-Visa",
				"View-Marketplace-Flights",
				"Manage-Marketplace-Flights",
			],
			show: false,
		},
		// mybookings
		{
			id: "sidenav-mybooking",
			icon: <MyBookingIcon />,
			text: `${dashboard.messages.myBookings}`,
			ring: false,
			show: false,
			route: "/inventory/",
			permission: [
				"View-Inventory-Hotels",
				"Manage-Inventory-Hotels",
				"View-Inventory-Transfer",
				"Manage-Inventory-Transfer",
				"View-Inventory-Tours",
				"Manage-Inventory-Tours",
				"View-Inventory-Flights",
				"Manage-Marketplace-Flights",
				"View-Inventory-Visa",
				"Manage-Inventory-Visa",
				"View-Inventory-Flights", 
				"Manage-Inventory-Flights"
			],
			subNavs: [
				{
					id: "sidenav-mybooking-hotels",
					route: "/inventory/hotels",
					text: marketPlace.messages.hotels,
					icon: "",
					permission: ["View-Inventory-Hotels", "Manage-Inventory-Hotels"],
				},
				{
					id: "sidenav-mybooking-transfer",
					route: "/inventory/transfer",
					text: marketPlace.messages.transfer,
					icon: "",
					permission: ["View-Inventory-Transfer", "Manage-Inventory-Transfer"],
				},
				// {
				// 	id: "sidenav-mybooking-visa-requests",
				// 	route: "/inventory/visa-requests",
				// 	text: inventory.messages.visaRequests,
				// 	icon: "",
				// 	permission: ["View-Inventory-Visa", "Manage-Inventory-Visa"],
				// },
				{
					id: "sidenav-mybooking-tour",
					route: "/inventory/tour",
					text: marketPlace.messages.tours,
					icon: "",
					permission: ["View-Inventory-Tours", "Manage-Inventory-Tours"],
				},
				{
					id: "sidenav-mybooking-flights",
					route: "/inventory/flights",
					text: marketPlace.messages.flights,
					icon: "",
					permission: ["View-Inventory-Flights", "Manage-Inventory-Flights"],
				},
			],
		},
		// product builder
		// {
		// 	id: "sidenav-products-builder",
		// 	route: "/products-builder",
		// 	icon: <TripsManagementIcon />,
		// 	text: `${dashboard.messages.tripsManagement}`,
		// 	ring: false,
		// 	permission: [
		// 		"View-Trip-Management-Module",
		// 		"Manage-Trip-Management-Module",
		// 	],
		// 	subNavs: [
		// 		{
		// 			id: 111,
		// 			route: "/products-builder/products",
		// 			text: productsBuilder.productBuilder,
		// 			icon: <MyBookingIcon />,
		// 			permission: [
		// 				"View-Trip-Management-Module",
		// 				"Manage-Trip-Management-Module",
		// 			],
		// 		},
		// 		{
		// 			id: 112,
		// 			route: "/products-builder/requests",
		// 			text: payment.messages.requests,
		// 			icon: <MyBookingIcon />,
		// 			permission: [
		// 				"View-Trip-Management-Module",
		// 				"Manage-Trip-Management-Module",
		// 			],
		// 		},
		// 	],
		// },
		// safa offline
		// {
		// 	id: "sidenav-safaOffline",
		// 	route: "/visa",
		// 	icon: <SafaOfflineIcon />,
		// 	text: `${dashboard.messages.SafaOffline}`,
		// 	ring: false,
		// 	permission: ["View-Safa-Offline", "Manage-Safa-Offline"],
		// },
		// myteam
		{
			id: "sidenav-myteam",
			route: "/team-managementlist",
			icon: <MyTeamIcon />,
			text: `${dashboard.messages.MyTeam}`,
			ring: false,
			permission: ["View-My-Team", "Manage-My-Team"],
			subNavs: [
				{
					id: "sidenav-myteam-member",
					route: "/team-managementlist/member",
					text: teamManagement.manageMember,
					icon: <TeamMemberIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
				{
					id: "sidenav-myteam-team",
					route: "/team-managementlist/team",
					text: teamManagement.manageTeam,
					icon: <TeamGroupsIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
				{
					id: "sidenav-myteam-branch",
					route: "/team-managementlist/branch",
					text: teamManagement.manageBranch,
					icon: <TeamBranchesIcon />,
					permission: ["View-My-Team", "Manage-My-Team"],
				},
			],
		},
		// wallet
		{
			id: "sidenav-wallet",
			route: "/wallet",
			icon: <WalletIcon />,
			text: `${dashboard.messages.myWallet}`,
			ring: true,
			permission: ["View-Wallets", "Manage-Wallets"],
			subNavs: [
				{
					id: "sidenav-wallet-transactions",
					route: "/wallet/transactions",
					text: payment.messages.transactions,
					icon: <WalletTransactionsIcon />,
					permission: ["View-Wallets", "Manage-Wallets"],
				},
				{
					id: "sidenav-wallet-requests",
					route: "/wallet/requests",
					text: payment.messages.requests,
					icon: <WalletRequestsIcon />,
					permission: ["View-Wallets", "Manage-Wallets"],
				},
			],
		},
		// activity log
		{
			id: "sidenav-activity-log",
			route: "/activity-logs",
			icon: <ActivityLogIcon />,
			text: `${dashboard.messages.activityLogs}`,
			ring: true,
			permission: ["View-Activity-Logs"],
		},
		// statistics
		{
			id: "sidenav-statistics",
			route: "/statistics",
			icon: <StatisticsIcon />,
			text: `${dashboard.messages.statistics}`,
			ring: true,
			permission: [],
		},
		// {
		// 	id: "sidenav-about-us",
		// 	route: "/about-us",
		// 	icon: <AboutUsIcon />,
		// 	text: `${newLand.aboutUs}`,
		// 	ring: true,
		// 	permission: [],
		// },
		// {
		// 	id: "sidenav-contact-us",
		// 	route: "/contact-us",
		// 	icon: <ContactUsIcon />,
		// 	text: `${newLand.contactUs}`,
		// 	ring: true,
		// 	permission: [],
		// },
	]);

	useEffect(() => {
		const subNavShow = dashboardRoutes?.map((dashboard) => {
			if (dashboard?.subNavs && dashboard?.subNavs.length > 0) {
				return {
					...dashboard,
					show:
						dashboard?.subNavs?.filter(
							(subRoute) => subRoute?.route === location.pathname
						).length > 0,
				};
			}
			return {
				...dashboard,
				show: false,
			};
		});
		setDashboardRoutes(subNavShow);
	}, [location.pathname]);

	const dashboardList =
		dashboardRoutes &&
		dashboardRoutes?.map((dashboard, index) => {
			const routeHasSubNav =
				dashboard?.subNavs && dashboard?.subNavs.length > 0 ? true : false;
			return (
				<li className={`sidenav_item ${dashboard?.class}`} key={`nav-${dashboard?.id}-${index}`}>
					<ShowForPermission permission={dashboard?.permission}>
						<NavLink
							id={dashboard?.id}
							className={`sidenav_link  ` }
							activeClassName="active"
							to={dashboard?.route || ""}
							onClick={(e) => {
								routeHasSubNav && e.preventDefault();
								if (show) {
									setDashboardRoutes(
										dashboardRoutes.map((route) => {
											return {
												...route,
												show:
													route.id === dashboard.id ? !dashboard.show : false,
											};
										})
									);
								}
								if(!routeHasSubNav && show) {
									setShow(false);
								}
							}}
						>
							<span className="sidenav_icon">{dashboard?.icon}</span>
							<span
								className={`sidenav_arrow ${dashboard.show && routeHasSubNav ? "toggled" : ""
									}`}
							>
								<Arrow />
							</span>
							<p className="dash-text">{dashboard?.text}</p>
						</NavLink>
					</ShowForPermission>

					{/* dropdown */}
					{routeHasSubNav ? (
						<ul className="sidenav_dropdown">
							{show ? (
								<Collapse isOpen={dashboard.show}>
									{dashboard?.subNavs?.map((subNav, idx) => {
										return (
											<ShowForPermission
												permission={subNav?.permission}
												key={`subnav-${subNav?.id}-${idx}`}
											>
												<li className="dropdown_item">
													<NavLink
														to={subNav?.route || ""}
														value={dashboard?.id}
														className="dropdown_link"
														activeClassName="active"
														onClick={() =>
															pathname !== "/" || show ? setShow(false) : false
														}
													>
														<span className="">{subNav?.icon}</span>
														<span className="mx-2">{subNav?.text}</span>
													</NavLink>
												</li>
											</ShowForPermission>
										);
									})}
								</Collapse>
							) : (
								dashboard?.subNavs?.map((subNav, idx) => {
									return (
										<ShowForPermission
											permission={subNav?.permission}
											key={`subnav-${subNav?.id}-${idx}`}
										>
											<li
												className="dropdown_item"
												key={`subnav-${subNav?.id}-${idx}`}
											>
												<NavLink
													to={subNav?.route || ""}
													value={dashboard?.id}
													className="dropdown_link"
													activeClassName="active"
													onClick={() =>
														pathname !== "/" || show ? setShow(false) : false
													}
												>
													<span className="">{subNav?.icon}</span>
													<span className="mx-1">{subNav?.text}</span>
												</NavLink>
											</li>
										</ShowForPermission>
									);
								})
							)}
						</ul>
					) : null}
				</li>
			);
		});

	return (
		<nav id="sideNav" className={show ? "opend" : "closed"}>
			<Link
				className={`navbar-brand ${show ? "w-100 m-0" : "m-0"} mt-3`}
				onClick={() => {
					show && setShow(false);
					history.push(marketPlacePrmission ? "/market-search" : "/statistics");
				}}
			>
				<span className={`navbar-brand-icon`}>
					<img
						className={show ? "img-fluid-logo" : "open-logo w-100"}
						/* src={show ? SafaLogo : safaLogoMinimal} */ src={newlogo}
						alt="Logo"
					/>
				</span>
			</Link>
			{/* nav list */}
			<ul className="sidenav_list">
				<h1 className={`sidenav_header ${locale === "en" ? "" : "mr-7"}`}>
					{dashboard.messages.menu}
				</h1>
				{dashboardList}
			</ul>
		</nav>
	);
}
