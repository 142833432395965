import React, { useEffect, useMemo } from 'react'
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import Locale from 'translations';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { deleteTourFromCart } from 'services/tours';


export default function ToursCartModal({ cartModalOpen, handleCartModalOpen }) {
  const { inventory, marketPlace, onlineVisa } = Locale;
  const { toursCart } = useMarketplaceState();
  const dispatch = useMarketplaceDispatch();
  const isCartEmpty = toursCart?.length === 0 || toursCart?.cartItems?.length === 0;
  const history = useHistory();

  // calc total price
  const calcTotalCartPrice = useMemo(() => {
    let total = 0;
    let currency = toursCart?.cartItems?.[0]?.details?.currency;
    toursCart?.cartItems?.forEach(cartItem => {
      cartItem = cartItem?.details;
      const modalities = cartItem?.modalities[0];
      total = total + modalities?.rates[0]?.rateDetails[0]?.totalAmount?.amount;
    })
    return { total: total?.toFixed(2), currency };
  }, [toursCart?.cartItems])


  // check if cart is expired
  useEffect(() => {
    if (toursCart?.expires_after <= 0) {
      dispatch({
        type: "toursCart",
        payload: [],
      })
    }
  }, [toursCart?.expires_after])

  async function deleteItemFromCart(itemId) {
    const cartId = toursCart?.id;
    const deleteItemRes = await deleteTourFromCart({ cartId, itemId });
    if (deleteItemRes?.status >= 200 && deleteItemRes?.status < 300) {
      const cartItems = deleteItemRes?.data?.cart?.cartItems
      dispatch({
        type: "toursCart",
        payload:
          typeof cartItems === "object" ?
            { ...deleteItemRes?.data?.cart, cartItems: Object.keys(cartItems).map((key) => cartItems[key]) } :
            deleteItemRes?.data?.cart
      });
    } else if (deleteItemRes?.status === 400 && deleteItemRes?.data?.custom_error_code === "TOUR-CRT-001") {
      dispatch({
        type: "toursCart",
        payload: [],
      })
    }
    else {
      handleCartModalOpen();
    }
  }

  return (
    <Modal isOpen={cartModalOpen} size="lg">
      <ModalHeader toggle={handleCartModalOpen}>
        {marketPlace.yourCart}
      </ModalHeader>
      <ModalBody>
        {/* cart items */}
        <div className='tours-cart-container'>
          {!isCartEmpty ?
            <>
              <div className='tours-cart-items'>
                {toursCart?.cartItems?.map((cartItem, index) => {
                  const cartItemDetails = cartItem?.details;
                  const modalities = cartItemDetails?.modalities[0];
                  return (
                    <div key={`${cartItemDetails?.code} - ${index}`}>
                      <div className='tours-cart-item w-100 d-flex flex-wrap flex-column flex-md-row justify-content-between '>
                        {/* left side */}
                        <div className='d-flex flex-md-nowrap flex-wrap col-md-9 col-12'>
                          <div className='tour-card-img'>
                            <img src={cartItemDetails?.images[0]} alt={cartItemDetails?.name} />
                          </div>
                          <div className='mx-md-2'>
                            <h3 className='text-dark-blue font-weight-bold h6'>{cartItemDetails?.name}</h3>
                            <div className='my-1'>
                              <i className="fas fa-map-marker-alt text-yellow"></i>
                              <span className='mx-2'>
                                {cartItemDetails?.destinations?.map((city) => {
                                  return <span key={city?.code}>{city?.name} ({city?.code}), </span>
                                })}
                              </span>
                            </div>
                            <div className=' my-1'>
                              <i className="far fa-calendar-alt text-yellow"></i>
                              <span className='mx-2'>{moment(cartItem?.date_from).format("DD-MM-YYYY")} ({moment(cartItem?.date_from).format("dddd")})</span>
                            </div>
                            <div className=' my-1'>
                              <i className="far fa-user text-yellow"></i>
                              <span className='mx-2'>
                                {cartItem?.noOfAdults} {" "}
                                {inventory.messages.adults}
                              </span>
                              {cartItem?.children?.length > 0 ?
                                <span>
                                  {cartItem?.children?.length} {inventory.messages.children} {" "}
                                  ({cartItem?.children?.map((age, index) => {
                                    return (
                                      <span key={`age-${index}-${age}`}>{age}, </span>
                                    )
                                  })}
                                  {marketPlace.yearsOld})
                                </span>
                                :
                                null
                              }

                            </div>
                          </div>
                        </div>

                        {/* right side */}
                        <div className='d-flex flex-wrap flex-column flex-md-row justify-content-between align-items-md-center'>
                          <div className='text-success align-self-md-baseline font-weight-bold mx-md-2'>
                            ({(modalities?.rates[0]?.rateDetails[0]?.totalAmount?.amount?.toFixed(2))} {cartItemDetails?.currency})
                          </div>
                          <button className='btn tours-cart-item-remove-btn'
                            onClick={() => deleteItemFromCart(cartItem?.id)}
                          >
                            <i class="fas fa-trash"></i>
                          </button>
                        </div>
                      </div>
                      {index === toursCart?.length - 1 ? "" : <hr />}
                    </div>
                  )
                })}
              </div>
              <div className='tours-cart-item-total d-flex justify-content-between my-3 p-2'>
                <span>{inventory.messages.ReservationTotalCost}</span>
                <span className='font-weight-bold text-issued h5 m-0'>({calcTotalCartPrice.total} {calcTotalCartPrice.currency})</span>
              </div>
            </>
            :
            <p>{marketPlace.yourCartEmpty}</p>
          }

        </div>
      </ModalBody>

      <ModalFooter className='border-0 pt-0'>
        <div className='row m-0 mt-3 justify-content-between w-100'>
          <div className="col-md-5 col-12 text-capitalize">
            <button className="btn bg-nxt w-100 continue-btn text-capitalize"
              onClick={() => {
                handleCartModalOpen();
                history.push('/tours-result');
              }}

            >
              {marketPlace?.messages?.continueSearch}
            </button>
          </div>
          <div className="col-md-7 col-12 mt-md-0 mt-2">
            <button className="btn bg-nxt w-100"
              disabled={isCartEmpty}
              onClick={() => {
                handleCartModalOpen();
                history.push('/tour-summary');
              }}
            >
              {onlineVisa.PROCEEDTOCHECKOUT}
            </button>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  )
}
