
import ShowForPermission from 'helpers/showForPermission'
import React, { useRef } from 'react'
import { Nav, NavItem, NavLink, TabContent } from 'reactstrap'
import Locale from 'translations';
import ToursSearchForm from './ToursSearchForm';
import trnasferTourBgSrc from "assets/images/stationery-pins.png";
import toursImg from "assets/images/inventory/tours.svg";




export default function ToursSearch() {
  const { marketPlace } = Locale;
  const divHigth = useRef({});
  let clientHeight = divHigth?.current?.clientHeight;

  return (
    <div className="market-search transport-market-search mb-4">
      <div className="main-tabs w-100 p-0" ref={divHigth}>
        <Nav tabs className="align-items-center main-nav">
          <ShowForPermission
            permission={[
              "View-Marketplace-Tours",
              "Manage-Marketplace-Tours",
            ]}
          >
            <NavItem>
              <NavLink className="active">
                <img
                  className="px-1"
                  src={toursImg}
                  alt={marketPlace.messages.transfer}
                  srcSet=""
                  width="30px"
                />
                {marketPlace.messages.tours}
              </NavLink>
            </NavItem>

          </ShowForPermission>
        </Nav>

        <div className="heading col-md-10 py-3 m-auto">
          <h5 className=" mt-3 search-title terms-color text-uppercase">
            {marketPlace.searchAndBook}
          </h5>
          <h3 className=" mb-2 ">{marketPlace.messages.NewDeals}</h3>
        </div>

        <TabContent>
          <ToursSearchForm />
        </TabContent>

      </div>

      <div className="search-banner"
        style={{
          backgroundImage: `url(${trnasferTourBgSrc})`,
          height: `${clientHeight}px`,
        }}
      ></div>
    </div>
  )
}
