import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";
import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import validate, { isFormValid } from "helpers/validate";
import {
	addPackageAvailabilities,
	editPackageAvailabilities,
} from "services/productbuilder";

function GuestsBox({
	guests,
	setGuests,
	activeTap,
	reservationDetails,
	setTotalPrice,
	getViewReservation,
	allLookupsBuilder
}) {
	const { inventory, marketPlace } = Locale;
	const [isEditing, setIsEditing] = useState(false);
	const [savedGuests, setSavedGuests] = useState([...guests]);
	const [pricesModal, setPricesModal] = useState(false);
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	const [roomTypesLookup, setRoomTypesLookup] = useState({});

	const reservationPaid = reservationDetails?.reservation_status === "paid";
	const reservationOffline = reservationDetails?.reservation_from === "offline";
	const roomAvailabilities = reservationDetails?.roomAvailabilities;


	function togglePricesModal() {
		setPricesModal(!pricesModal);
	}

	useEffect(() => {
		let roomsLookup = {}
		if (allLookupsBuilder && Object.keys(roomTypesLookup).length === 0) {
			allLookupsBuilder?.room_types?.length > 0 && allLookupsBuilder?.room_types?.forEach(roomType => {
				roomsLookup[roomType?.id] = roomType?.name
			});
			setRoomTypesLookup(roomsLookup)
		}
	}, [allLookupsBuilder, roomTypesLookup])

	const types = [
		{ value: 1, name: "Adult", id: 1 },
		{ value: 2, name: "Child", id: 2 },
	];
	const childPrice = reservationDetails?.product_price?.price_child;

	const getRoomAvailabilitiesList = useCallback(
		(guestType) => {
			let roomTypesList = [];
			const adultGuestsSelectedRooms = [
				...new Map(
					savedGuests
						?.filter(
							(guest) =>
								guest?.type?.name === "Adult" && guest?.room_type !== ""
						)
						?.map((item) => [item["room_type"], item])
				).values(),
			];
			if (guestType === "Adult") {
				roomTypesList = reservationDetails?.product_room_price
					?.filter((roomPrice) => roomPrice?.room_remaining_availability > 0)
					.map((room) => {
						return {
							...room,
							id: room?.id,
							name: roomTypesLookup[room?.room_type],
							value: room?.room_type,
							label: roomTypesLookup[room?.room_type],
						};
					});
			} else if (guestType === "Child") {
				roomTypesList = adultGuestsSelectedRooms?.map((room) => {
					return {
						...room,
						id: room,
						name: roomTypesLookup[room?.room_type],
						value: room?.room_type,
						label: roomTypesLookup[room?.room_type],
					};
				});
			}
			return roomTypesList;
		},
		[reservationDetails?.product_room_price, roomTypesLookup, savedGuests]
	);

	function addNewGeust() {
		setSavedGuests([
			...savedGuests,
			{
				type: "",
				room_type: "",
				roomType: "",
				price: "0.00",
			},
		]);
	}

	function removeGuest(index) {
		const allGuests = [...savedGuests];
		allGuests.splice(index, 1);
		setSavedGuests(allGuests);
	}

	function editGuestsType(value, index, guestType) {
		const allGuests = [...savedGuests];
		let currentGuest = allGuests[index];
		currentGuest = {
			...currentGuest,
			type: value,
			roomType: "",
			room_type: "",
			price: guestType === "Adult" ? "0.00" : childPrice,
		};
		allGuests[index] = currentGuest;
		setSavedGuests(allGuests);
	}

	function editGuestsRoomType(value, index) {
		const allGuests = [...savedGuests];
		let currentGuest = allGuests[index];
		currentGuest = {
			...currentGuest,
			roomType: value,
			room_type: value?.room_type,
			price: value?.adult_price || childPrice,
		};
		allGuests[index] = currentGuest;
		setSavedGuests(allGuests);
	}

	// check errors
	function checkFormErrors() {
		let submitError = {};
		const validationKeys = ["type", "room_type"];
		savedGuests?.forEach((guest, guestIndex) => {
			validationKeys.forEach((key) => {
				submitError = {
					...submitError,
					...validate(
						{ name: [key + guestIndex], value: guest[key] },
						{ required: true }
					),
				};
			});
		});
		setErrors(() => submitError);
	}

	function checkGuestsError() {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	}

	function formatGuests(guestsArray) {
		let adults = [];
		let children = [];
		const guestsList = guestsArray?.map((room) => {
			children = [...Array(+room?.children_count).keys()].map(() => {
				return {
					...room,
					type: { value: 2, name: "Child", id: 2 },
					price: room?.children_price,
				};
			});

			adults = [...Array(+room?.adults_count).keys()].map(() => {
				return {
					...room,
					type: { value: 1, name: "Adult", id: 1 },
					price: room?.adults_price,
				};
			});
			return [...adults, ...children];
		});
		return guestsList?.flat();
	}

	useEffect(() => {
		async function savedGuestsChanges() {
			const childGuests = savedGuests?.filter(
				(guest) => guest?.type?.name === "Child"
			);
			const rooms_availability = [];
			savedGuests?.forEach((guest) => {
				if (guest?.type?.name === "Adult") {
					rooms_availability.push({
						id: guest?.id,
						room_type: guest?.room_type?.room_type || guest?.room_type,
						adults_count: 1,
						children_count: childGuests?.filter(
							(child) => child?.room_type === guest?.room_type
						).length,
						infant_count: 0,
					});
				}
			});
			const data = {
				reservation_num: reservationDetails?.reservation_num,
				rooms_availability: rooms_availability,
			};

			// check if add new guest or edit exist guest
			if (reservationOffline) {
				// add guests
				const addPackageAvailabilitiesRes = await addPackageAvailabilities(
					data
				);
				if (
					addPackageAvailabilitiesRes?.status >= 200 &&
					addPackageAvailabilitiesRes?.status < 300
				) {
					const guestsList = formatGuests(
						addPackageAvailabilitiesRes?.data?.data?.roomAvailabilities
					);
					setGuests(guestsList);
					setIsEditing(false);
					// call getViewReservation to get the new reservation data
					getViewReservation();
				} else {
					setSavedGuests(guests);
				}
			}
			// edit exist guest
			else {
				const editPackageAvailabilitiesRes = await editPackageAvailabilities(
					data
				);
				if (
					editPackageAvailabilitiesRes?.status >= 200 &&
					editPackageAvailabilitiesRes?.status < 300
				) {
					const guestsList = formatGuests(
						editPackageAvailabilitiesRes?.data?.data?.roomAvailabilities
					);
					setGuests(guestsList);
					// call getViewReservation to get the new reservation data
					getViewReservation();
					setIsEditing(false);
				} else {
					setSavedGuests(guests);
				}
			}
		}
		if (isFormValid(errors)) {
			savedGuestsChanges();
		}
	}, [isErrorLoaded]);

	// update total price
	useEffect(() => {
		// let price = +reservationDetails?.total_price;

		const calcTotalPrice = savedGuests?.reduce(
			(acc, guest) => acc + (+guest?.price || 0),
			0
		);
		setTotalPrice(+calcTotalPrice?.toFixed(2));
	}, [savedGuests]);

	return (
		<>
			{roomTypesLookup ?
				<>
					<div className="guests">
						<div className="header">
							<h2 className="title">{inventory.messages.guests}</h2>
							<div>
								{!isEditing &&
									activeTap === 1 &&
									!reservationPaid && 
									reservationDetails?.reservation_status !== "rejected" && (
										<button
											className="btn actions"
											onClick={() => {
												setIsEditing(!isEditing);
												if (savedGuests?.length === 0) {
													addNewGeust();
												}
											}}
											// disabled
										>
											<i className="far fa-edit"></i> {inventory.messages.edit}
										</button>
									)}

								<button className="btn actions" onClick={togglePricesModal}>
									<i className="far fa-eye"></i> {inventory.messages.ViewRoomsPrice}
								</button>
							</div>
						</div>
						<div className="body-g">
							{isEditing && activeTap === 1 ? (
								<>
									{savedGuests?.map((guest, index) => {
										const isNewGuest = guests.length < index + 1;
										console.log(guests);
										return (
											<div
												className="row-g edit-row my-2"
												key={`edit-guest-${index}`}
											>
												<p className="label">
													{inventory.messages.guest} {index + 1}
												</p>
												{/* guest type */}
												<div className="our-filed me-2">
													<SelectField
														label={
															<>
																<i className="fas fa-user-alt me-1"></i>
																{inventory.messages.type}
															</>
														}
														value={guest?.type?.name}
														options={
															index === 0
																? types.filter((type) => type.id === 1)
																: types
														}
														placeholder={"Select"}
														onChange={(e) => {
															editGuestsType(e, index, e?.name);
															setErrors({
																...errors,
																...validate(
																	{ name: [`type${index}`], value: e },
																	{ required: true }
																),
															});
														}}
														disabled={!isNewGuest}
														color={errors[`type${index}`]?.required ? "danger" : ""}
													/>
												</div>
												{/* room type */}
												<div className="our-filed  me-2">
													<SelectField
														label={
															<>
																<i className="fas fa-bed me-1"></i>
																{inventory.messages.RoomType}
															</>
														}
														value={
															guest?.room_type?.label || roomTypesLookup[guest?.room_type]
														}
														placeholder={"Select"}
														options={getRoomAvailabilitiesList(guest?.type?.name)}
														onChange={(e) => {
															editGuestsRoomType(e, index);
															setErrors({
																...errors,
																...validate(
																	{ name: [`room_type${index}`], value: e },
																	{ required: true }
																),
															});
														}}
														color={
															errors[`room_type${index}`]?.required ? "danger" : ""
														}
													/>
												</div>
												{/* price */}
												<div>
													<p>{inventory.messages.Price} </p>
													<strong style={{ color: "#10A711", fontWeight: "bold" }}>
														{guest?.price || "0.00"} {reservationDetails?.currency}
													</strong>
												</div>
												{isNewGuest ? (
													<div
														className="mx-3 pointer ml-auto mr-auto"
														onClick={() => removeGuest(index)}
													>
														<i
															className="fas fa-trash"
															style={{ color: "#C30101" }}
														></i>
													</div>
												) : null}
											</div>
										);
									})}

									<div className="d-flex justify-content-between align-items-center mt-4">
										{reservationOffline ? (
											<button
												className="btn"
												style={{ color: "#027B30" }}
												onClick={() => addNewGeust()}
												//disabled
											>
												<i className="fas fa-user-plus"></i>
												<span className="mx-1">{inventory.messages.AddGuests}</span>
											</button>
										) : (
											<div></div>
										)}
										<div>
											{/* cancel edit button */}
											<button
												className="btn btn-guests me-2"
												onClick={() => {
													setIsEditing(!isEditing);
													setSavedGuests(guests);
												}}
												//disabled
											>
												{inventory.messages.cancel}
											</button>

											{/* save changes button */}
											<button
												style={{ color: "#fff", backgroundColor: "#027B30" }}
												className="btn btn-guests"
												onClick={() => {
													checkGuestsError();
												}}
												disabled={savedGuests?.length === 0}
												//disabled
											>
												{inventory.messages.savechanges}
											</button>
										</div>
									</div>
								</>
							) : (
								// if not editing
								roomAvailabilities?.map((room, index) => {
									return (
										<div className="row-g  my-2" key={`guest-${room.id}`}>
											<p className="label">
												<span className="mx-1">
													{marketPlace.messages.room} {index + 1}
												</span>
											</p>
											<div className="our-filed me-1">
												<h5 className="label-type">
													<i className="fas fa-bed me-1"></i>
													{inventory.messages.RoomType}
												</h5>
												<p style={{ color: "#0C3B5C" }}>
													{roomTypesLookup[room?.room_type]}
												</p>
											</div>
											<div className="our-filed me-1">
												<h5 className="label-type">
													Number Of Adualt
												</h5>
												<p style={{ color: "#0C3B5C" }}>
													{room.adults_count}
												</p>
											</div>
										
											<div className="our-filed me-1">
												<h5 className="label-type">
													{inventory.messages.Price}
												</h5>
												<strong style={{ color: "#10A711", fontWeight: "bold" }}>
													{room?.adults_price}
													{/* {guest?.type?.name === "Adult" ? guest?.adults_price : guest?.children_price} */}{" "}
													{reservationDetails?.currency}
												</strong>
											</div>

										
										</div>
									);
								})
							)}
						</div>
					</div>
					{/* prices modal */}
					<Modal isOpen={pricesModal} size="lg" centered={true}>
						<ModalHeader className="font-weight-bold" toggle={togglePricesModal}>
							{marketPlace.messages.roomPrices}
						</ModalHeader>
						<ModalBody>
							<table
								className="table table-striped room-prices m-auto"
								cellspacing="12px"
							>
								<thead>
									<tr>
										<th scope="col" rowSpan={1}></th>
										<td align="center" className="adult">
											{marketPlace.messages.adult}
										</td>
										<td align="center" className="child">
											{inventory.messages.Child}
										</td>
										<td align="center" className="infant">
											{marketPlace.infant}
										</td>
									</tr>
								</thead>
								<tbody>
									{reservationDetails?.product_room_price?.map((room, index) => {
										return room?.room_remaining_availability ? (
											<tr key={`prices-${index}`}>
												<td>
													{roomTypesLookup[room?.room_type]}{" "}
													<span className="text-primary">
														({room?.room_remaining_availability}x{" "}
														{marketPlace.messages.adult}
														{`${room?.childs_count > 0
															? ` / ${room?.children_count}x ${inventory.messages.Child}`
															: ""
															}`}
														{`${room?.infant_count > 0
															? ` / ${room?.infant_count}x ${inventory.messages.infant}`
															: ""
															}`}
														)
													</span>
												</td>
												<td align="center">
													{room?.adult_price} {reservationDetails?.currency}
												</td>
												<td align="center">
													{childPrice || "0.00"} {reservationDetails?.currency}
												</td>
												<td align="center">
													{reservationDetails?.product_price?.price_infant ||
														"0.00"}{" "}
													{reservationDetails?.currency}
												</td>
											</tr>
										) : null;
									})}
								</tbody>
							</table>
						</ModalBody>
					</Modal>
				</>
				:
				null
			}
		</>
	);
}

export default GuestsBox;
