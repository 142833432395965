import { AuthProvider } from "context/auth";
import { useSBSState } from "context/global";
import { Redirect, Route } from "react-router-dom";

// React Component
export default function AuthRoute({ component: Component, ...props }) {
	const { isAuth } = useSBSState();

	if (!isAuth) {
		return (
			<Route
				{...props}
				render={(matchProps) => (
					<AuthProvider>
						<Component {...matchProps} />
					</AuthProvider>
				)}
			/>
		);
	} else {
		return <Redirect to="/" />;
	}
}
