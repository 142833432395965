import { useSBSState } from 'context/global';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import React, { useEffect, useRef, useState } from 'react';
import Locale from 'translations';
import { getCities } from "services/marketplace";
import { searchTours } from 'services/tours'
import { useHistory } from 'react-router-dom'
import { store } from 'react-notifications-component'
import AutoCompleteField from 'components/Form/AutoCompleteField/AutoCompleteField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField'
import SelectField from 'components/Form/SelectField/SelectField'
import TextField from 'components/Form/TextField/TextField'
import validate, { isFormValid } from 'helpers/validate'
import moment from 'moment'

export default function ToursSearchForm({ modify, toggleModifyModal }) {
  const { messages, marketPlace, inventory, visa } = Locale;
  const dispatch = useMarketplaceDispatch();
  const { allCountries } = useSBSState();
  const { toursSearch } = useMarketplaceState()
  const countries = allCountries;
  const maxChildAge = 12;
  const maxAdultsCount = 20;
  let goingToPrevValue = useRef(null);
  const [toursData, setToursData] = useState({
    country: null,
    destination: null,
    date_from: null,
    date_to: null,
    nights: 0,
    adults: 0,
    childs: { id: 0, value: 0, label: "0", name: "0" },
    childs_ages: []
  });
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [destinationList, setDestinationList] = useState([]);
  const history = useHistory();

  const adultsChildsLookup = [...Array(maxAdultsCount + 1).keys()].map(count => {
    return {
      id: count,
      value: count,
      label: count.toString(),
      name: count.toString()
    }
  })

  const childsAgesLookup = [...Array(maxChildAge + 1).keys()].map(age => {
    return {
      id: age,
      value: age,
      label: age.toString(),
      name: age.toString()
    }
  });

  function handleChildAge(e, ageIndex) {
    const ages = [...toursData?.childs_ages];
    ages[ageIndex] = e;
    setToursData({ ...toursData, childs_ages: ages })
  }

  function handleChildsCount(e) {
    const currentChildsCount = toursData?.childs_ages.length;
    let newChildsAges = toursData.childs_ages;
    let addedRemovedChildsCount = 0;
    // check if we increase
    if (e.value > currentChildsCount) {
      addedRemovedChildsCount = e.value - currentChildsCount;
      newChildsAges = [...toursData.childs_ages, ...[...Array(+addedRemovedChildsCount).keys()].map(() => {
        return {
          id: 0,
          value: 0,
          label: "0",
          name: "0"
        }
      })];
    } else if (e.value < currentChildsCount) {
      addedRemovedChildsCount = -(currentChildsCount - e.value)
      newChildsAges = newChildsAges.slice(0, addedRemovedChildsCount)
    }
    setToursData({
      ...toursData,
      childs: e,
      childs_ages: newChildsAges,
    });
  }


  function checkFormErrors() {
    let SubmitError = {};
    Object.keys(toursData).forEach((key) => {
      SubmitError = {
        ...SubmitError,
        ...validate(
          { name: key, value: toursData[key], },
          { required: key === "nights" || key === "childs_ages" ? false : true }
        ),
      };
    });
    setErrors(() => SubmitError);
  };

  // get tour results and store search cretria in localstorage
  async function getToursResults(data) {
    const toursRes = await searchTours(data);
    if (toursRes?.status >= 200 && toursRes?.status < 300) {
      if (toursRes?.data?.data?.length > 0) {
        dispatch({
          type: "toursSearchResults",
          payload: toursRes?.data
        });
        dispatch({
          type: "saveToursSearch",
          payload: { ...toursData, cacheKey: toursRes?.data?.cacheKey }
        });
        if (modify) {
          toggleModifyModal()
        }
        history.push('/tours-result')
      } else {
        store.addNotification({
          title: messages.noResults,
          message: messages.noSearchResults,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
        });
      }
    } else {
      store.addNotification({
        title: messages.noResults,
        message: messages.noSearchResults,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: { duration: 3000, onScreen: true, pauseOnHover: true },
      });
    }
  }

  // search call get result function
  function search() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  };

  useEffect(() => {
    if (isFormValid(errors)) {
      const data = {
        date_from: moment(toursData?.date_from).format("YYYY-MM-DD"),
        date_to: moment(toursData?.date_to).format("YYYY-MM-DD"),
        city_code: toursData?.destination?.id,
        noOfAdults: toursData?.adults?.value,
        children: toursData?.childs_ages?.map(age => age?.value)
      }
      getToursResults(data);
    }
  }, [isErrorLoaded]);

  // in modify mode set the tour data with cached data from localstorge
  useEffect(() => {
    if (modify) {
      setToursData({
        ...toursSearch,
        date_from: moment(toursSearch?.date_from),
        date_to: moment(toursSearch?.date_to),
      })
    }
  }, []);

  function clearSearchText(e, key) {
    goingToPrevValue.current = toursData[key];
    const value = e.target.value;
    if (value.length > 0) {
      setToursData({ ...toursData, [key]: null });
    }
  }

  // auto comapelete
  const getDestinationList = async (inputValue) => {
    if (inputValue.length > 2) {
      const citiesRes = await getCities(inputValue, toursData?.country?.id);
      if (citiesRes?.status >= 200 && citiesRes?.status < 300) {
        const formatCities = citiesRes?.data?.data?.map(city => {
          return {
            id: city?.id,
            label: city?.name,
            value: city?.id,
            name: city?.name,
            country_id: city?.country_id
          }
        });
        setDestinationList(formatCities)
      }
    }
  };




  return (
    <div className={`col-md-10 HotelSearch m-auto pb-3 ${modify ? "marketsearch-tours-modify" : ""}`}>
      <div className="row px-2">
        {/* country */}
        <div className="col-md-12">
          <SelectField
            label={visa.country}
            hasLabel={true}
            id="country"
            name="country"
            options={countries}
            value={toursData?.country?.label}
            onChange={(e) => {
              setToursData({
                ...toursData,
                country: e,
                destination: null
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "country", value: e.id },
                  { required: true, }
                ),
              });
            }}
            errors={errors?.country}
            color={errors?.country?.required ? "danger" : ""}
          />




        </div>
        {/* destination */}
        <div className="col-md-12">
          <AutoCompleteField
            hasLabel={true}
            id="destination"
            name="destination"
            label={marketPlace.messages.destination}
            isSearchable={true}
            placeholder={marketPlace.messages.destination}
            listAuto={destinationList}
            setListAuto={setDestinationList}
            getListAuto={getDestinationList}
            value={toursData?.destination?.label || ""}
            onFocus={(e) => clearSearchText(e, "destination")}
            onBlur={() => setToursData({ ...toursData, destination: goingToPrevValue.current })}
            onChange={(e) => {
              setToursData({ ...toursData, destination: { label: e } })
            }}
            onSelectValue={(e) => {
              setToursData({ ...toursData, destination: e });
              setErrors({
                ...errors,
                ...validate(
                  { name: "destination", value: e },
                  { required: true }
                ),
              });
            }}
            disabled={!toursData?.country && !modify}
            errors={errors?.destination}
            color={errors?.destination?.required ? "danger" : ""}
          />
        </div>

        {/* date from */}
        <div className="col-md-4">
          <DatePickerField
            label={marketPlace.From}
            id="date_from"
            name="date_from"
            placeholder="DD/MM/YYYY"
            date={toursData?.date_from}
            onChangeDate={(date) => {
              setToursData({
                ...toursData,
                date_from: date,
                date_to: moment(date) >= moment(toursData?.date_to) ? null : toursData?.date_to,
                nights: moment(date) >= moment(toursData?.date_to) ? 0 : toursData?.date_to?.diff(date, "days") || 0
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "date_from", value: date },
                  { required: true, }
                ),
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(new Date()).add(0, "d"), "day")
            }
            errors={errors?.date_from}
            color={errors?.date_from?.required ? "danger" : ""}
          />
        </div>
        {/* date to */}
        <div className="col-md-4">
          <DatePickerField
            label={marketPlace.To}
            id="date_to"
            name="date_to"
            placeholder="DD/MM/YYYY"
            date={toursData?.date_to}
            initialVisibleMonth={() => moment(toursData.date_from) || null}
            onChangeDate={(date) => {
              setToursData({
                ...toursData,
                date_to: date,
                date_from: moment(date) <= moment(toursData?.date_from) ? null : toursData?.date_from,
                nights: date?.diff(toursData?.date_from, "days") || 0
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "date_to", value: date },
                  { required: true, }
                ),
              });
            }}
            isOutsideRange={(day) =>
              !day.isAfter(moment(toursData?.date_from).add(0, "d"), "day")
            }
            disabled={!toursData?.date_from}
            errors={errors?.date_to}
            color={errors?.date_to?.required ? "danger" : ""}
          />
        </div>
        {/* nights */}
        {!modify ?
          <div className="col-md-4">
            <TextField
              label={marketPlace.Nights}
              id="nights"
              name="nights"
              hasLabel={true}
              value={toursData?.nights}
              readOnly
              disabled
            />
          </div>
          :
          null
        }

        {/* adults */}
        <div className="col-md-6 adults">
          <SelectField
            label={inventory.messages.adults}
            id="adults"
            name="adults"
            hasLabel={true}
            options={adultsChildsLookup}
            value={toursData?.adults?.label}
            onChange={(e) => {
              setToursData({
                ...toursData,
                adults: e,
                childs: e.value === 0 ? { id: 0, value: 0, label: "0", name: "0" } : toursData?.childs,
                childs_ages: e.value === 0 ? [] : toursData?.childs_ages
              });
              setErrors({
                ...errors,
                ...validate(
                  { name: "adults", value: e.id },
                  { required: true }
                ),
              });
            }}
            errors={errors?.adults}
            color={errors?.adults?.required ? "danger" : ""}
          />
        </div>
        {/* childs */}
        <div className="col-md-6 chidlren">
          <div className='d-flex align-items-center'>
            <label htmlFor="children" className='m-0'>{inventory.messages.children}</label>
            <i className="fas fa-info-circle new-color mx-1" title={marketPlace.messages.childrenAge} />
          </div>
          <SelectField
            id="children"
            name="children"
            hasLabel={false}
            options={adultsChildsLookup}
            value={toursData?.childs?.label}
            disabled={!toursData?.adults || toursData?.adults?.value <= 0}
            onChange={(e) => {
              handleChildsCount(e);
              setErrors({
                ...errors,
                ...validate(
                  { name: "children", value: e.id },
                  { required: true }
                ),
              });
            }}
          />
        </div>
        {/* children ages */}
        <div className='col-12 children_ages'>
          {toursData?.childs_ages?.length ? <label htmlFor="" className='d-block w-100'>{marketPlace.ChildrenAges}</label> : null}
          <div className='d-flex flex-wrap'>
            {toursData?.childs_ages?.map((age, index) => {
              return (
                <div className="col-md-2 col-3" key={index}>
                  <SelectField
                    hasLabel={false}
                    options={childsAgesLookup}
                    value={age?.label}
                    onChange={(e) => handleChildAge(e, index)}
                  />
                </div>
              )
            })}
          </div>
        </div>

        <button
          onClick={search}
          className="btn w-100 bg-nxt mb-3 mt-3"
          type="button"
        >
          {marketPlace.messages.search}
        </button>
      </div>
    </div>
  )
}
