export const DefinitVoucher = `
<style>
			.w-25 {
				width: 25% !important;
			}

			.w-50 {
				width: 50% !important;
			}

			.w-75 {
				width: 75% !important;
			}

			.w-100 {
				width: 100% !important;
			}

			.mr-3,
			.mx-3 {
				margin-right: 1rem !important;
			}
      .mr-4 {
				margin-right: 2.5rem !important;
			}
			.ml-3,
			.mx-3 {
				margin-left: 1rem !important;
			}

			html,
			body,
			div,
			dl,
			dt,
			dd,
			ul,
			ol,
			li,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			pre,
			code,
			form,
			fieldset,
			legend,
			input,
			textarea,
			p,
			blockquote,
			th,
			td {
				margin: 0;
				padding: 0;
			}

			.py-4 {
				padding-bottom: 1.5rem !important;
				padding-top: 1.5rem !important;
			}
			.py-5 {
				padding-bottom: 2.5rem !important;
				padding-top: 2.5rem !important;
			}
			.mx-3 {
				margin-left: 1rem !important;
				margin-right: 1rem !important;
			}
			.mt-2 {
				margin-top: 0.5rem !important;
			}
			.mt-3 {
				margin-top: 1rem !important;
			}
			.bg-gray {
				background: #e8e8e8 !important;
			}
			.p-4 {
				padding: 1.5rem !important;
			}
			.align-items-center {
				align-items: center !important;
			}
			.justify-content-around {
				justify-content: space-around !important;
			}
			.font-weight-bold {
				font-weight: 700 !important;
			}

			.mb-1 {
				margin-bottom: 0.25rem !important;
			}
			.my-1 {
				margin-bottom: 0.25rem !important;
				margin-top: 0.25rem !important;
			}
			.my-3 {
				margin-bottom: 0.75rem !important;
				margin-top: 0.75rem !important;
			}
			.mb-3,
			.my-3 {
				margin-bottom: 1rem !important;
			}

			* {
				font-family: Helvetica;
			}

			.bg-gray-100 {
				background: #f4f4f4 !important;
			}

			.container {
				max-width: 1140px;
			}

			.ml-5,
			.mx-5 {
				margin-left: 3rem !important;
			}

			.pt-2,
			.py-2 {
				padding-top: 0.5rem !important;
			}
			.pt-1,
			.py-1 {
				padding-top: 0.2rem !important;
			}
			.d-flex {
				display: flex !important;
			}

			.text-center {
				text-align: center !important;
			}

			.justify-content-between {
				justify-content: space-between !important;
			}

			.justify-content-around {
				justify-content: space-around !important;
			}

			.flex-column {
				flex-direction: column;
			}

			.align-items-center {
				align-items: center;
			}

			.nav {
				background-color: #ebebeb;
				border-bottom: 1px solid #a7a7a7;
				padding: 1rem 0 1rem 1rem;
			}

			.hero-section {
				padding: 0.5rem 1rem;
				justify-content: space-around;
				align-items: center;
				flex-wrap: wrap;
        margin:150px 0
			}

			.v-label {
				transform: rotate(342deg);
				/* color: #d38a27; */
				font-weight: 700;
			}

			.hero-section ul li:first-child {
				padding-bottom: 10px;
			}

			.border-bottom {
				border-bottom: 1px solid #0c3b5c;
			}
			.border-bottom-bold {
				border-bottom: 2px solid #0c3b5c;
			}
			.border-bold {
				border: 2px solid #0c3b5c;
			}
			.sm-font {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: 600;
			}

			.sm-font-light {
				font-size: 13px;
				color: #2d2d2d;
				font-weight: lighter;
			}

			table {
				width: 100%;
			}

			.table,
			.th,
			.td {
				border: 1px solid #0c3b5c;
				border-collapse: collapse;
			}

			.th {
				color: #2d2d2d;
				font-weight: bold;
				padding: 10px;
			}

			.td {
				color: #2d2d2d;
				font-weight: lighter;
				font-size: 13px;
				padding: 9px;
				text-align: center;
			}

			ul {
				list-style: none;
			}

			.footer {
        margin-top: 100px;
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 0.2rem;
				text-align: center;
				font-size: 14px;
				position: relative;
				bottom: -50px;
				border-top: 2px solid #0c3b5c;
			}

			.main-color {
				color: #0c3b5c;
			}
			.nav1 {
				justify-content: center;
				padding: 12px 0;
        border-bottom: 2px solid #0c3b5c;
			}

			.p-0 {
				padding: 0 !important;
			}
      .font-bold{
				font-weight: 800 !important;
			}
      .font-light-bold{
				font-weight: 600 !important;

      }
			@media print {
				.definite_voucher{
					padding:20px;

				}
			}
		</style>
    <div
    class="container definite_voucher"
    style="
      background: #fff;
      width: 900px !important;
      margin: 12px auto !important;
    "
  >
    <nav class="nav1 d-flex border-bottom-bold">
      <img
        src=[SafaLogo]
        alt="safa_logo"
        style="width: 187.93px; height: 42.45px"
      />
    </nav>

    <div class="hero-section border-bold d-flex p-0">
      <div style="    display: flex;
      align-items: end;
      flex-direction: column;
      width: 90%;
      padding:10px 0
      ">
        <p class="font-light-bold">Voucher No</p>
        <p>[confirmation_number]</p>
      </div>
      <table class="table" style="width: 100%">
        <thead>
          <tr>
            <th class="th font-light-bold">Hotel Name</th>
            <th class="th">[hotel_name]</th>
            <th class="th font-light-bold"></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class=" td font-bold">Type Of Room</td>
            <td class="td">[allRooms]</td>
            <td class="td font-light-bold">Issue Date: [isuee_date]</td>
          </tr>
          <tr>
            <td class="td font-bold">Hotel Rsr#</td>
            <td class="td">[hotel_rsr]</td>
            <td class="td font-bold">Staff Name: [staff_name]</td>
          </tr>

          <tr>
            <td class="td font-bold">Check-in</td>
            <td class="td"><span class="mr-4">[check_in] </span>      <span class="font-bold">check-out:</span>  [check_out]</td>
            <td rowspan="3" style="vertical-align: bottom;
            text-align: start;" class="td font-bold">Stamp and Signature</td>
          </tr>
          <tr>
            <td class="td font-bold">Guest Name</td>
            <td class="td">[guest_name]</td>
          </tr>

          <tr>
            <td class="td font-bold">Remarks</td>
            <td class="td">[remarks]</td>
          </tr>
        </tbody>
      </table>
      <p class="my-3 font-light-bold" style="text-align: start">
        Any amount in excess of the value of this order to be collected
        directly from the guest
      </p>
    </div>
    <table class="footer">
      <tr>
        <td>
          <span class="sm-font-light"
            >Prince sultanRd,3th floor,Sabah Center, Saudi Arabia</span
          >
        </td>
      </tr>
      <tr>
        <td>
          <span class="sm-font-light">Website:</span
          ><span class="sm-font">https://sbs.safasoft.com</span> /
          <span class="sm-font-light">E-mail:</span
          ><span class="sm-font">res@safasoft.com</span>
        </td>
      </tr>
      <tr>
        <td>
          <span class="sm-font-light">Mob:</span><span class="sm-font">+966 53 073 3802</span>
        </td>
      </tr>
    </table>
  </div>
`;
