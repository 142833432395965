import carColored from "assets/images/inventory/carColored.svg";
import backgroundUMRAH from "assets/images/search-banner2.jpg";
import classnames from "classnames";
import moment from "moment";
import { useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import Locale from "translations";
import TransportationSearch from './TransportationSearch';


export default function Transportation() {
  const { marketPlace } = Locale;
  const [activeTab, setActiveTab] = useState("1");
  const [transportationData, setTransportationData] = useState({
    validTo: moment(new Date()).add(1, "d"),
    goingTo: null,
    country: null,
    busType: null,
    busClass: null,
    busCount: 1,
    peopleNumber: 1,
  })
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
    // activeTab === "1"
    //   ? setHotels(hotelInitial)
    //   : setHotels(transportationInitial);
  };
  const handleChange = (e, flag = false) => {
    const name = e.target?.name;
    const value = e.target?.value;
    if (flag) {
      setTransportationData({
        ...transportationData,
        country: e["value"], // countries[value],
        goingTo: e.value, //countries[value]?.code,
      });
    } else setTransportationData({ ...transportationData, [name]: value });
  };




  return (
    <>
      <div className="market-search  mb-4">
        <div className="main-tabs w-100 p-0">
          <Nav tabs className="align-items-center main-nav">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  toggle("1");
                }}
              >
                {/* <i className="fas fa-hotel fa-fw fa-lg mx-1"></i> */}
                <img className="px-1" src={carColored} alt={marketPlace.messages.transportation} srcSet="" />
                {marketPlace.messages.transportation}
              </NavLink>
            </NavItem>
          </Nav>

          <div className="heading col-md-10 py-3 m-auto">
            <h5 className=" mt-3 search-title terms-color text-uppercase">
              {marketPlace.messages.transportationRequest}
            </h5>
            <h3 className=" mb-2 ">
              {" "}
              {marketPlace.messages.compeleteTransportationForm}
            </h3>
          </div>

          {/*****************Start Container Tab********************** */}

          {/*****************End Container Tab********************** */}

          <TabContent activeTab="1">
            <TransportationSearch
              transportation={transportationData}
              setTransportation={setTransportationData}
              handleChange={handleChange}
            />
          </TabContent>
        </div>
        <div
          className="search-banner"
          style={{
            backgroundImage: `url(${backgroundUMRAH})`,
            // height: `${clientHeight}px`,
          }}
        ></div>
      </div>
    </>
  );
}
