import applyIcon from "assets/images/inventory/apply.svg";
import clearIcon from "assets/images/inventory/clear.svg";

import DateRangeField from "components/Form/DateRangeField/DateRangeField";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import useLookups from "modules/products-builder/hooks/useLookups";
import moment from "moment";
import { useEffect, useState } from "react";
import { cleanEmpty } from "services/general";
import Locale from "../../../translations";

export default function ProductFilter({ fetchProducts }) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { productsBuilder } = Locale;
	const dispatch = useProductsBuilderDispatch();
	const [lookups] = useLookups();

	const [filters, setFilters] = useState({
		searchQuery: null,
		productType: { id: null, name: "" },
		productValidFrom: undefined,
		productValidTo: undefined,
		productPricing: { id: null, name: "" },
		productStatus: { id: null, name: "" },
		productClass: { id: null, name: "" },
		productDeparture: undefined,
		productReturn: undefined,
	});

	useEffect(() => {
			let data = {
				product_type_id: filters.productType.id,
				product_valid_from: filters.productValidFrom,
				departure_date_at: filters.productDeparture,
				is_has_price: filters.productPricing.id,
				product_classification_id: filters.productClass.id,
				product_status_id: filters.productStatus.id,
				return_date_at: filters.productReturn,
				search: filters.searchQuery,
				validation_date_from_at: filters.productValidFrom,
				validation_date_to_at: filters.productValidTo,
			};

			fetchProducts(cleanEmpty(data));
	}, [filters]);

	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	// Reset Filter
	const resetFilter = () => {
		setFilters({
			searchQuery: null,
			productType: { id: null, name: "" },
			productValidFrom: undefined,
			productValidTo: undefined,
			productPricing: { id: null, name: "" },
			productStatus: { id: null, name: "" },
			productClass: { id: null, name: "" },
			productDeparture: undefined,
			productReturn: undefined,
		});
	};

	// ----------------------------------------------------------------------------------------------------
	// Main Component Body UI
	// ----------------------------------------------------------------------------------------------------
	return (
		<div className="main-filter mb-1">
			<div className="row align-items-center w-100 no-gutter m-0 filter-row mx-md-5 mx-2">
				{/* Search */}
				<div className="col-md-2 col-min-3">
					<div className="main-label">
						<TextField
							type="text"
							// placeholder={"Type prodect Name ..."}
 							placeholder={productsBuilder.productNamePlaceholde}
							 value={filters.searchQuery}
							onChange={(e) =>
								setFilters({ ...filters, searchQuery: e.target.value })
							}
							label={productsBuilder.productName}
						/>
					</div>
				</div>

				{/* Type */}
				<div className="col-md-2 col-min-2">
					<SelectField
						value={filters.productType.name}
						onChange={(e) =>
							setFilters({
								...filters,
								productType: { id: e.value, name: e.label },
							})
						}
						placeholder={productsBuilder.select}
						/* 						placeholder={productsBuilder.productTypePlaceholder}
						 */ options={lookups.product_types}
						label={productsBuilder.productType}
					/>
				</div>

				{/* Valid From */}
				<div className="col-md-3 col-min-3">
					<DateRangeField
						label={productsBuilder.validation}
						startDate={filters.productValidFrom}
						endDate={filters.productValidTo}
						startDatePlaceholderText={productsBuilder.dateFormatPlaceholder}
						endDatePlaceholderText={productsBuilder.dateFormatPlaceholder}
						onDatesChange={({ startDate, endDate }) => {
							setFilters({
								...filters,
								productValidFrom: moment(startDate).format("YYYY-MM-DD"),
								productValidTo: endDate
									? moment(endDate).format("YYYY-MM-DD")
									: moment(startDate).add(1, "d").format("YYYY-MM-DD"),
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>
				{filters.productType.id > 0 && filters.productType.id <= 2 ? (
					<>
						{/* Pricing */}
						<div className="col-md-2 col-min-2">
							<SelectField
								value={filters.productPricing.name}
								onChange={(e) =>
									setFilters({
										...filters,
										productPricing: { id: e.value, name: e.label },
									})
								}
								options={lookups.product_price_types}
								placeholder={productsBuilder.pricingPlaceholder}
								label={productsBuilder.pricing}
							/>
						</div>

						{/* Status */}
						<div className="col-md-1 col-min-1">
							<SelectField
								value={filters.productStatus.name}
								onChange={(e) =>
									setFilters({
										...filters,
										productStatus: { id: e.value, name: e.label },
									})
								}
								options={lookups.product_statuses}
								placeholder={productsBuilder.statusPlaceholder}
								label={productsBuilder.productStatus}
							/>
						</div>

						{/* Class */}
						<div className="col-md-2 col-min-1">
							<SelectField
								value={filters.productClass.name}
								onChange={(e) =>
									setFilters({
										...filters,
										productClass: { id: e.value, name: e.label },
									})
								}
								options={lookups.product_classifications}
								placeholder={productsBuilder.classPlaceholder}
								label={productsBuilder.productClass}
							/>
						</div>
					</>
				) : null}

				{/* Departure */}
				<div className="col-md-3 col-min-2">
					<DateRangeField
						label={productsBuilder.tripDate}
						startDate={filters.productDeparture}
						endDate={filters.productReturn}
						startDatePlaceholderText={productsBuilder.dateFormatPlaceholder}
						endDatePlaceholderText={productsBuilder.dateFormatPlaceholder}
						onDatesChange={({ startDate, endDate }) => {
							setFilters({
								...filters,
								productDeparture: moment(startDate).format("YYYY-MM-DD"),
								productReturn: endDate
									? moment(endDate).format("YYYY-MM-DD")
									: moment(startDate).add(1, "d").format("YYYY-MM-DD"),
							});
						}}
						isOutsideRange={() => false}
					/>
				</div>

				{/* Reset Filter */}
				<div className="col-md-2 col-min-1 d-flex mt-4 ">
					{/* <div className="mx-2 d-flex  align-items-center">
						<button className="btn-apply">
							<img src={applyIcon} className="px-1" alt="" srcset="" />
							
							{productsBuilder.apply}

						</button>
					</div> */}

					{/* <div className="mx-2">
						<Link
							className="btn bg-nxt "
							to="/products-builder/create"
							onClick={() => {
								// when make create should empty status
								localStorage.setItem("uuid", "undefined");
								dispatch({
									type: "deleteProduct",
									payload: { isEditMode: false },
								});
							}}
						>
							{productsBuilder.newProduct}
						</Link>
					</div> */}

					<div
						className="d-flex align-items-center  justify-content-center text-secondary pointer btn-reset-filter"
						onClick={resetFilter}
					>
						<img src={clearIcon} className="px-1" alt="" srcset="" />
						{/* 						<i class="fas fa-undo px-1 "></i>
						 */}{" "}
						{productsBuilder.resetFilter}
					</div>
				</div>
			</div>
		</div>
	);
}
