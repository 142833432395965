// import { Collapse, Icon, IconButton, Rating, Typography } from "@mui/material";
import moment from "moment/moment";
// import Image from "next/image";
import React, { useState } from "react";
// import cloudImg from "../../../../assets/images/nights.svg";
// import { useTranslation } from "../../../../hooks/useTranslation";
// import ItinareyHotelIcon from "../../../../Icons/ItinareyHotelIcon";
// import ItinirareyPlaneIcon from "../../../../Icons/ItinirareyPlaneIcon";
//import styles from "../../../../styles/packages/modals/packagedetails.module.css";
import { Collapse } from "reactstrap";
import { ItinareyHotelIcon, ItinirareyPlaneIcon } from '../Details/shared/PackageIcon';

function Hotel({ type, isMobile, element }) {
  //const t = useTranslation();
  const [opened, setopened] = useState(false);
  const handelcollapse = () => {
    setopened(!opened);
  };

  return (
    <>
      <div 
			className={"boxcollapse"}
			>
        <div 
				className={"closecollapse"}
				>
          <div
            className={`collapseIcon ${opened && "opencoll"}`}
          >
          
                <ItinareyHotelIcon />
           
          </div>
          {isMobile && (
            <p
              style={{
                color: "##0F3E5E",
                fontWeight: "bold",
                marginRight: "auto",
                paddingLeft: "5px",
              }}
p            >
              {"Hotels"}
            </p>
          )}
          {!isMobile && (
            <>
              <div 
							className={"info"}
							>
                <div className="d-flex wrap flex-column">
                  <p style={{ color: "#0F3E5E" }} >
                    {element.itemable?.hotel?.name}
                  </p>
                  {/* <Rating
                    size="small"
                    value={+element.itemable?.hotel?.rating}
                    readOnly
                  /> */}

									{+element.itemable?.hotel?.rating > 0 ? (
											<div className="hotel-star-rate d-inline">
												{[...Array(+element.itemable?.hotel?.rating).keys()].map((i) => (
													<i key={i} className="fa fa-star" aria-hidden="true"></i>
												))}
											</div>
										) :(
											<div className="hotel-star-rate d-inline">
												{[...Array(5)].map((i) => (
													<i key={i} className="far fa-star" aria-hidden="true"></i>
												))}
											</div>
										) }
                </div>
                <span style={{ color: "#2D2D2D" }}>
                  {element.itemable?.hotel?.country}
                </span>
                <span style={{ color: "##2D2D2D" }}>
                  {moment(element.itemable?.check_in_at).format("DD/MM/YYYY")}
                </span>
              </div>
            </>
          )}
          {type == "hotel" && (
            <div 
						className={"movebox"}
						>
              <div 
							className={"nightbox"}
							>
                <i class="fas fa-cloud-moon"></i>
                <span>
                  {element?.itemable?.night_count} {"nights"}
                </span>
              </div>
            </div>
          )}
          {isMobile && (
            <div>
              <button onClick={handelcollapse} className='btn'>
              <i class="fas fa-chevron-down fa-1x text-grey"></i>
              </button>
            </div>
          )}
        </div>

        {type == "hotel" && isMobile ? (
          <Collapse isOpen={opened}>
            <div 
						className={"opendcollapse"}
						>
              <div 
							className={"info"}
							>
                <div>
                  <p style={{ color: "#0F3E5E" }} >
                    El Nouzha Airport{" "}
                  </p>
                  {type == "hotel" && (
                    // <Rating size="small" value={+element.itemable?.hotel?.rating} readOnly />
										+element.itemable?.hotel?.rating > 0 ? (
											<div className="hotel-star-rate d-inline mt-3 px-2">
												{[...Array(+element.itemable?.hotel?.rating).keys()].map((i) => (
													<i key={i} className="fa fa-star" aria-hidden="true"></i>
												))}
											</div>
										) :(
											<div className="hotel-star-rate d-inline mt-3 px-2">
												{[...Array(5)].map((i) => (
													<i key={i} className="far fa-star" aria-hidden="true"></i>
												))}
											</div>
										) 
                  )}
                </div>
                <span style={{ color: "#2D2D2D" }}>
                  Egypt
                </span>
                <span style={{ color: "##2D2D2D" }}>
                  17/06/2022
                </span>
              </div>
            </div>
          </Collapse>
        ) : null}
      </div>
    </>
  );
}

export default Hotel;
