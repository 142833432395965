import React, { useEffect, useState } from "react";
import Filterrequestes from "./Filterrequestes";
import Locale from "translations";
import ListRequestes from './listRequestes';
import SelectField from 'components/Form/SelectField/SelectField';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from "helpers/validate";
import NumberField from "components/Form/NumberField/NumberField";
import { addApplication, productsListForApplication, requestsListReservations } from 'services/productbuilder';
import moment from 'moment';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import ShowForPermission from 'helpers/showForPermission';
import { useSBSState } from 'context/global';



function Requestes() {
	const { payment, inventory } = Locale;
	const { allCountries } = useSBSState();
	const countries = allCountries;
	const history = useHistory();
	const [errors, setErrors] = useState();
	const [requestsList, setRequestsList] = useState();
	const [applicationProducts, setApplicationProducts] = useState([]);
	const [filters, setFilters] = useState({
		trip_name: "",
		trip_type: "",
		departure_date: "",
		status: "",
	});
	const [meta, setMeta] = useState();
	// const [total, setTotal] = useState([]);
	const [newApplicationData, setNewApplicationData] = useState({
		tripname: "",
		ownername: "",
		owneremail: "",
		phoneCode: "",
		phoneNumber: ""
	});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);

	const [createApplicationIsOpen, setCreateApplicationIsOpen] = useState(false);
	const toggleCreateApplicationModal = () => {
		setCreateApplicationIsOpen(!createApplicationIsOpen);
	};
	const checkFormErrors = () => {
		let submitError = {};
		Object.keys(newApplicationData).forEach(key => {
			submitError = {
				...submitError,
				...validate(
					{ name: key, value: newApplicationData[key], },
					{ required: true }
				)
			}
		})
		setErrors(() => submitError);
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};

	async function getRequestsList(filters) {
		const params = {
			...filters,
			trip_type: filters?.trip_type?.id,
			departure_date:
				filters.departure_date !== ""
					? moment(filters.departure_date).format("YYYY-MM-DD")
					: "",
			status: filters?.status?.title,
		};
		const requestsListRes = await requestsListReservations(params);
		if (requestsListRes?.status >= 200 && requestsListRes?.status < 300) {
			setRequestsList(requestsListRes?.data?.data);
			setMeta(requestsListRes?.data?.meta);
			// setTotal();
		}
	}

	useEffect(() => {
		getRequestsList(filters);
	}, [filters]);

	const goTo = (page = 1) => {
		setFilters({ ...filters, page: page });
	};

	// add new offline application
	async function createNewApplication() {
		const data = {
			product_uuid: newApplicationData?.tripname?.product_uuid,
			contact_info: {
				full_name: newApplicationData?.ownername,
				email: newApplicationData?.owneremail,
				phone: newApplicationData?.phoneNumber,
				phone_code: newApplicationData?.phoneCode?.id
			}

		}
		const createApplicationRes = await addApplication(data)
		if (createApplicationRes?.status >= 200 && createApplicationRes?.status < 300) {
			history.push(`/products-builder/viewrequests/${createApplicationRes?.data?.data?.id}`)
		}
	}

	useEffect(() => {
		if (isFormValid(errors)) {
			createNewApplication();
		} else {
			console.log('not vaild ')
		}
	}, [isErrorLoaded]);

	// get products list for new application
	useEffect(() => {
		async function getProductListForNewApplication() {
			const productListRes = await productsListForApplication();
			if (productListRes?.status >= 200 && productListRes?.status < 300) {
				setApplicationProducts(productListRes?.data?.data);
			}
		}
		getProductListForNewApplication();
	}, [])


	return (
		<>
			<div className="container">
				<div className="d-flex justify-content-between align-items-center py-2 px-2 border bg-white">
					<p style={{ fontWeight: "bold" }} className="text-dark">{payment.messages.requests}({meta?.total})</p>
					<ShowForPermission permission="Manage-Trip-Management-Module">
						<button
							className=" btn px-md-5 py-1"
							style={{
								backgroundColor: "#D29D4D",
								color: "#fff",
								fontSize: "18px",
							}}
							onClick={toggleCreateApplicationModal}
						>
							{payment.messages.addappliction}
						</button>
					</ShowForPermission>
				</div>

				<Filterrequestes
					filters={filters}
					setFilters={setFilters}
					getRequestsList={getRequestsList}
				/>

				<ListRequestes requestsList={requestsList} meta={meta} goTo={goTo} />


				<Modal
					isOpen={createApplicationIsOpen}
					size="md"
					centered={true}
					hasFooter={false}
				>
					<ModalHeader toggle={toggleCreateApplicationModal}>
						{payment.messages.addappliction}
					</ModalHeader>
					<ModalBody>
						<div className="container p-2 pb-4 ">
							<div>
								<SelectField
									label={`${inventory.messages.tripname} *`}
									placeholder={inventory.messages.select}
									name={"tripname"}
									id="tripname"
									value={newApplicationData.tripname?.label}
									onChange={(e) => {
										setNewApplicationData({ ...newApplicationData, tripname: e })
										setErrors({
											...errors,
											...validate(
												{ name: "tripname", value: e },
												{ required: true }
											),
										});
									}}
									options={applicationProducts}
									color={errors?.tripname?.required ? 'danger' : ''}
									errors={errors?.tripname}
								/>
							</div>
							<div>
								<TextField
									value={newApplicationData.ownername}
									label={`${inventory.messages.ownername} *`}
									type="text"
									name="ownername"
									id="ownername"
									placeholder={inventory.messages.ownername}
									onChange={(e) => {
										setNewApplicationData({ ...newApplicationData, ownername: e.target.value })
										setErrors({
											...errors,
											...validate(
												{ name: "ownername", value: e.target.value },
												{ required: true }
											),
										});
									}}
									color={errors?.ownername?.required ? 'danger' : ''}
									errors={errors?.ownername}
								/>
							</div>
							<div>
								<TextField
									label={`${inventory.messages.owneremail} *`}
									type="email"
									name="owneremail"
									id="owneremail"
									value={newApplicationData.owneremail}
									placeholder={inventory.messages.owneremail}
									min={3}
									onChange={(e) => {
										setNewApplicationData({ ...newApplicationData, owneremail: e.target.value })
										setErrors({
											...errors,
											...validate(
												{ name: "owneremail", value: e.target.value },
												{ required: true, email: true }
											),
										});
									}}
									color={errors?.owneremail?.required ? 'danger' : ''}
									errors={errors?.owneremail}
								/>
							</div>
							<div>
								<SelectField
									label={`${inventory.messages.phoneCode} *`}
									placeholder={inventory.messages.select}
									name={"tripname"}
									id="tripname"
									value={newApplicationData.phoneCode?.label}
									onChange={(e) => {
										setNewApplicationData({ ...newApplicationData, phoneCode: e })
										setErrors({
											...errors,
											...validate(
												{ name: "phoneCode", value: e },
												{ required: true }
											),
										});
									}}
									options={countries?.map(country=> {
										return { id: country?.phone_code, label: country?.phone_code, name: country?.phone_code }
									})}
									color={errors?.phoneCode?.required ? 'danger' : ''}
									errors={errors?.phoneCode}
								/>
							</div>
							<div>
								<NumberField
									type={"phone"}
									name="phoneNumber"
									id="phoneNumber"
									label={`${inventory.messages.phoneNumber} *`}
									readOnly={true}
									removeArrow={true}
									value={newApplicationData.phoneNumber}
									placeholder={inventory.messages.phoneNumber}
									min={3}
									onChange={(e) => {
										setNewApplicationData({ ...newApplicationData, phoneNumber: e.target.value })
										setErrors({
											...errors,
											...validate(
												{ name: "phoneNumber", value: e.target.value },
												{ required: true }
											),
										});
									}}
									color={errors?.phoneNumber?.required || errors?.phoneNumber?.min ? 'danger' : ''}
									errors={errors?.phoneNumber}
								/>
								{/* {errors?.phoneNumber?.required && <><span className='text-danger'>{`${inventory.messages.phoneNumber} ${commons.isRequired}`}</span></>} */}
							</div>
							<button
								className="btn mt-3  w-100 "
								onClick={checkFormErrors}
								style={{
									backgroundColor: "#DAA249",
									fontSize: "18px",
									color: "#FFFFFF",
								}}
							>
								{inventory.messages.create}
							</button>
						</div>
					</ModalBody>
				</Modal>
			</div>
		</>
	);
}

export default Requestes;
