import axios from "axios";
import { useSBSDispatch } from 'context/global';
import { useEffect, useState } from "react";

export default function useFetchCountries() {
	const [isLoaded, setIsLoaded] = useState(false);
	const [countries, setCountries] = useState([]);
	const [lookupsBuilder, setLookupsBuilder] = useState([]);
	const dispatch = useSBSDispatch();
	useEffect(() => {
		async function get() {
			await axios
				.get(`${process.env.REACT_APP_LOOKUPS_ENDPOINT}/geolocation/countries`)
				.then((res) => {
					setCountries(res.data.data);
					setIsLoaded(true);
					dispatch({
						type: "getCountries",
						payload: res.data.data,
					})
				});

			 await axios
						.get(`${process.env.REACT_APP_PRODUCTS_BUILDER}/lookups_common_product_builder`)
						.then((res) => {
							setLookupsBuilder(res.data.data);
							dispatch({
								type: "getLookupsBuilder",
								payload: res.data.data,
							})
						})
						.catch((err) => err.response);
				

		}
		get();
	}, []);

	return [countries, isLoaded];
}
