import React from 'react';
import correctIcon from "assets/images/wallet/correctIcon.svg";
import clearIcon from "assets/images/wallet/clearIcon.svg";
import Locale from 'translations';
import TextField from 'components/Form/TextField/TextField';
import DatePickerField from 'components/Form/DatePickerField/DatePickerField';
import SelectField from 'components/Form/SelectField/SelectField';
import { useSBSState } from 'context/global';


export default function PackagesFilter({ filters, handleChangeFilters, handleResetFilters, search }) {
  const { payment, inventory } = Locale;
  const {allLookupsBuilder} = useSBSState();
  const status = [
    { id: 0, name: `${payment.messages.Pending}`, title: "pending" },
    { id: 1, name: `${payment.messages.Reject}`, title: "rejected" },
    { id: 2, name: `${inventory.messages.approved}`, title: "approved" },
    { id: 3, name: `${inventory.messages.Canceled}`, title: "canceled" },
    { id: 4, name: `${inventory.messages.paid}`, title: "paid" },
    { id: 5, name: `${inventory.messages.confirmed}`, title: "confirmed" },
    { id: 6, name: `${payment.messages.Expired}`, title: "expired" },
  ];
  const tripTypeLookups = allLookupsBuilder?.product_types    

  

  return (
    <div className="filter-update bg-filter-gray">
      <div className="product-buttons col-md-11">
        <div className="main-filter-payment d-flex justify-contain-between flex-wrap">
          {/* search text */}
          <div className="main-label controls-field col-md-2 col-6">
            <TextField
              label={payment.messages.search}
              id="package-filter-search"
              name="package-filter-search"
              type="text"
              placeholder={payment.messages.search}
              onChange={(e) => handleChangeFilters(e.target.value, 'search')}
              value={filters.search}
            />
          </div>
          {/* trip type */}
          <div className="main-label controls-field  col-md-2 col-6">
            <SelectField
              hasLabel={true}
              label={inventory.messages.tripType}
              options={tripTypeLookups}
              id="package-filter-trip-type"
              name="package-filter-trip-type"
              onChange={(e) => handleChangeFilters(e, 'trip_type')}
              value={filters.trip_type?.label}
            />
          </div>
          {/* booking date */}
          <div className="main-label controls-field  col-md-2 col-6">
            <DatePickerField
              label={inventory.messages.date}
              placeholder="DD/MM/YYY"
              id="package-filter-date"
              name="package-filter-date"
              date={filters.departure_date}
              onChangeDate={(e) => handleChangeFilters(e, 'departure_date')}
              isOutsideRange={(day) => {
                return false;
              }}
            />
          </div>
          {/* status */}
          <div className="main-label controls-field col-md-2 col-6">
            <SelectField
              label={payment.messages.status}
              placeholder={payment.messages.status}
              options={status}
              value={filters?.status?.name}
              onChange = {(e) => handleChangeFilters(e, 'status')}
            />
          </div>
          {/* fiter buttons */}
          <div className="filter-btn col-md-4 col-6 d-flex align-items-center justify-content-start">
            <div className="apply pointer">
              <button className="btn btn-light px-3" onClick={search}>
                <img src={correctIcon} alt=" " />
                {payment.messages.apply}
              </button>
            </div>
            <div
              className="clear text-secondary pointer"
              onClick={handleResetFilters}
            >
              <button className="btn btn-light px-3 mx-2">
                <img src={clearIcon} alt=" " />
                {payment.messages.clear}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
