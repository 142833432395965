import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSDispatch, useSBSState } from "context/global";
import bg from "../../../assets/images/customRegister/Group 3787.png";
import bgAR from "../../../assets/images/customRegister/Group 3787AR.png";
import safaLogo from "../../../assets/images/safa_logo.png";
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Locale from 'translations';
import TextField from 'components/Form/TextField/TextField';
import validate, { isFormValid } from 'helpers/validate';
import PasswordField from 'components/Form/PasswordField/PasswordField';
import { connectSafaWithSbsAccount } from 'services/auth';

export default function LinkSafaAccount() {
  const { emailConfirmation, login, marketPlace, newLand } = Locale;
  const { locale } = useSBSState();
  const dispatch = useSBSDispatch();
  const history = useHistory();
  const userDontHaveSBSAccountCode = 'AUTH-010'

  const [haveSbsAccount, setHaveSbsAccount] = useState(null);
  const [errors, setErrors] = useState({});
  const [isErrorLoaded, setIsErrorLoaded] = useState(false);
  const [loginData, setLoginData] = useState({
    sbsEmail: "",
    sbsPassword: "",
    safaKey: "",
    safaEmail: "",
    safaPassword: "",
  });
  const [spin, setSpin] = useState(false);
  const isMobile = window.innerWidth < 426 ? true : false;


  function handleChange(e, stateKey, stateValue) {
    setErrors({
      ...errors,
      ...validate(e.target, {
        required: true,
        email: stateKey === "sbsEmail" || stateKey === "safaEmail",
        password: stateKey === "sbsPassword",
        min: stateKey === "sbsPassword" ? 8 : "",
      }),
    });
    setLoginData({
      ...loginData,
      [stateKey]: stateValue,
    });
  }

  function checkFormErrors() {
    let submitError = {}
    Object.keys(loginData).forEach(key => {
      submitError = {
        ...submitError,
        ...validate(
          { name: key, value: loginData[key] },
          {
            required: true,
            email: key === "sbsEmail" || key === "safaEmail",
            password: key === "sbsPassword",
            min: key === "sbsPassword" ? 8 : "",
          }
        ),
      }
    });
    setErrors(() => submitError)
  }

  function submit() {
    checkFormErrors();
    if (!isErrorLoaded) {
      setIsErrorLoaded(true);
    } else {
      setIsErrorLoaded(false);
    }
  }

  async function handleConnectSafaWithSbsAccount() {

    setSpin(true);
    const data = {
      safa_key: loginData?.safaKey,
      safa_email: loginData?.safaEmail,
      safa_password: loginData?.safaPassword,
      sbs_email: loginData?.sbsEmail,
      sbs_password: loginData?.sbsPassword,
    }
    const response = await connectSafaWithSbsAccount(data);
    if (response?.status === 200) {
      dispatch({
        type: "login",
        isAuth: true,
        token: response.data.data.token,
        userInfo: { ...response.data.data.user, is_first_login: isMobile ? 0 : response?.data?.data?.user?.is_first_login },
        companyInfo: response.data.data.company,
      });
      history.push("/market-search");
    } else if (response?.status === 400 && response?.data?.err_code === userDontHaveSBSAccountCode) {
      history.push('/auth/register_safa');
    }
    setSpin(false);
  }

  useEffect(() => {
    if (isFormValid(errors)) {
      handleConnectSafaWithSbsAccount();
    }
    return () => { }
  }, [isErrorLoaded])


  return (
    <div className="bg-layout register-page">
      <div className="d-flex container justify-content-between pt-1">
        <figure className="col-5 pt-3 m-0 register-page-img">
          <img src={locale === "en" ? bg : bgAR} alt="" className="img-fluid" />
        </figure>

        <div className="col-md-5 col-sm-9 d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Link to="/contact-us" className="text-dark">
              {newLand.contactUs}
            </Link>
            <Link to="/about-us" className="text-dark">
              {newLand.aboutUs}
            </Link>
            <LanguageSwitcher />
          </div>
          <img
            src={safaLogo}
            alt=""
            className="safa_logo d-none"
          />
          <div className="w-100 mt-4">
            <h1 className='register-type-head px-2'>
              <span className='d-block col-md-8 col-12'> {login.doYouHaveAccountOnSbsSystem} </span>
            </h1>

            <div className="col-md-6 col-9 d-flex align-items-center justify-content-between mt-4 px-3">
              {/* have account */}
              <div className="d-flex align-items-center">
                <input
                  className="radio-input"
                  type="radio"
                  name={'yes'}
                  id={'yes'}
                  value={'yes'}
                  checked={haveSbsAccount === 'yes' ? true : false}
                  onChange={(e) => {
                    setHaveSbsAccount('yes');
                  }}
                  autoComplete="off"
                />
                <label className="form-check-label px-1 pointer" htmlFor={'yes'}>
                  {marketPlace.Yes}
                </label>
              </div>
              {/* doesnot have account  */}
              <div className="d-flex align-items-center">
                <input
                  className="radio-input"
                  type="radio"
                  name={'no'}
                  id={'no'}
                  value={'no'}
                  checked={haveSbsAccount === 'no' ? true : false}
                  onChange={(e) => {
                    setHaveSbsAccount('no');
                  }}
                  autoComplete="off"
                />
                <label className="form-check-label px-1 pointer" htmlFor={'no'}>
                  {marketPlace.No}
                </label>
              </div>
            </div>


            {haveSbsAccount && haveSbsAccount === 'yes' ?
              <>
                <LoginWithSafaAndSBS loginData={loginData} errors={errors} handleChange={handleChange} />
                <button
                  className="btn bg-nxt w-100 mt-4 mb-2 py-2 text-uppercase"
                  disabled={spin}
                  onClick={submit}
                >
                  {login.loginTitle}
                </button>
              </>
              :
              null
            }
            {haveSbsAccount && haveSbsAccount === 'no' ?
              <Link to="/auth/register_safa" className="btn bg-nxt w-100 mt-4 mb-2 py-2 text-uppercase">
                {emailConfirmation.register}
              </Link>
              :
              null
            }
          </div>
          <div></div>
        </div>
      </div>
    </div>
  )
}


export function LoginWithSafaAndSBS({ loginData, errors, handleChange }) {
  const { login } = Locale;
  return (
    <div className='mt-4 login-with-safa-sbs'>
      {/* sbs account login */}
      <div className='mb-3'>
        <h2 className='font-weight-bold h5 m-0'>{login.sbsAccountDetails}</h2>
        <hr className='mt-1' />
        <TextField
          placeholder={login.emailPlaceholder}
          label={login.emailAddress}
          name="sbsEmail"
          id="sbsEmail"
          value={loginData.sbsEmail}
          onChange={(e) => {
            handleChange(e, 'sbsEmail', e.target.value)
          }}
          color={errors?.sbsEmail?.required || errors?.sbsEmail?.email ? "danger" : ""}
          errors={errors?.sbsEmail}
        />

        <PasswordField
          hasLabel={true}
          placeholder={login.passwordPlaceholder}
          label={login.password}
          name="sbsPassword"
          id="sbsPassword"
          value={loginData.sbsPassword}
          onChange={(e) => {
            handleChange(e, 'sbsPassword', e.target.value)
          }}
          min={8}
          color={
            errors?.sbsPassword?.required ||
              errors?.sbsPassword?.password ||
              errors?.sbsPassword?.min
              ? "danger"
              : ""
          }
          errors={errors?.sbsPassword}
        />
      </div>

      {/* safa visa login */}
      <div>
        <h2 className='font-weight-bold h5 m-0'>{login.safaVisaAccountDetails}</h2>
        <hr className='mt-1' />
        <div className='row m-0'>
          <div className="col-md-6 col-12">
            <TextField
              label={login.safa_key}
              placeholder={login.safaPlaceholder}
              name="safaKey"
              id="safaKey"
              value={loginData.safaKey}
              onChange={(e) => {
                handleChange(e, 'safaKey', e.target.value)
              }}
              color={errors?.safaKey?.required ? "danger" : ""}
              errors={errors?.safaKey}
            />
          </div>
          <div className="col-md-6 col-12">
            <TextField
              placeholder={login.usernamePlaceholder}
              label={login.username}
              value={loginData.email}
              name="safaEmail"
              id="safaEmail"
              onChange={(e) => {
                handleChange(e, 'safaEmail', e.target.value)
              }}
              color={
                errors?.safaEmail?.required || errors?.safaEmail?.email
                  ? "danger"
                  : ""
              }
              errors={errors?.safaEmail}
            />
          </div>
          <div className="col-12">
            <PasswordField
              placeholder={login.passwordPlaceholder}
              hasLabel={true}
              label={login.password}
              name="safaPassword"
              id="safaPassword"
              value={loginData.safaPassword}
              onChange={(e) => {
                handleChange(e, 'safaPassword', e.target.value)
              }}
              color={errors?.safaPassword?.required ? "danger" : ""}
              errors={errors?.safaPassword}
            />
          </div>

        </div>
      </div>

    </div>
  )
}