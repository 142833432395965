import phone from "assets/images/phone.png";
import safaLogo from "assets/images/safaLogo.svg";
import { toursVoucher } from "assets/toursVoucher";
import ShowForPermission from 'helpers/showForPermission';
import moment from "moment";
import { getModifyAvailable, getVoucher, preCancelTourItem } from "services/tours";
import Locale from "translations";
import CancelModel from './CancelModel';
import { useState } from 'react';
import ModifyTourModal from './ModifyTourModal';
import { useMarketplaceDispatch, useMarketplaceState } from 'context/marketplace';
import { useSBSState } from 'context/global';

export default function TourBookingDetailsCard({ reservationItem, paymentStatus, bookingStatus, setBookingDetails, toggleReloadData }) {
	const { inventory, marketPlace } = Locale;
	const { toursSearch } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();
	const { locale } = useSBSState();

	const expiredBooking = bookingStatus === "expired";
	const expiredItem = reservationItem?.status === "expired" || moment().isAfter(moment(reservationItem.start_date));
	const canDownloadOrModify = paymentStatus !== "pending" && !expiredItem && !expiredBooking && reservationItem?.status !== "canceled";
	const canCancelTour = bookingStatus === "confirmed" && reservationItem?.status === "confirmed" && moment().isBefore(moment(reservationItem.start_date));
	const amount = { totalPrice: reservationItem?.price, currency: reservationItem?.currency };
	const reservationItemVoucher = reservationItem?.details?.vouchers;

	const [modifiedResults, setModifiedResults] = useState();
	const [modalCancel, setModalCancel] = useState(false);
	const [preCancel, setPreCancel] = useState();
	const [modifyTourModalOpen, setModifyTourModalOpen] = useState(false);

	const toggleModifyTourModal = () => setModifyTourModalOpen(!modifyTourModalOpen);
	const toggleModalCancel = () => setModalCancel(!modalCancel);

	async function getPreCancelTourItem() {
		const preCancelRes = await preCancelTourItem(reservationItem?.id);
		if (preCancelRes.status >= 200 && preCancelRes.status < 300) {
			setPreCancel(preCancelRes?.data?.data);
			setModalCancel(true);
		}
	}

	const printVoucher = async () => {
		const res = await getVoucher(reservationItem?.booking_id, reservationItem?.id);
		if (res.status !== 200) return;
		const data = res?.data?.vouchers?.[0];

		let childrenString = "";
		data?.children?.forEach((age, index) => {
			childrenString = childrenString.concat(
				`<span >
					${age}
					${index !== data?.children?.length - 1 ? ", " : " "}
				</span>`
			);
		});
		let remarkString = data?.remarks?.concat(`<p>${data?.remarks}</p>`);
		let destinations = data?.destinations?.map(destination => destination?.name).join(', ');

		const keyValuePair = {
			"[reference_number_key]": "Reference Number",
			"[reference_number_value]": data?.reference,
			"[safa_reference_number_key]": "Safa Reference Number",
			"[safa_reference_number_value]": data?.safa_reference,
			"[name]": data?.name,
			"[passenger_name_key]": "Passenger Name",
			"[passenger_name_value]": data?.passenger_name,
			"[booking_date_key]": "Booking Date",
			"[booking_date_value]": moment(data?.booking_date).format(
				"YYYY-MM-DD"
			),
			"[agency_Reference_key]": "Agency Reference",
			"[agency_Reference_value]": data?.agency_reference,
			"[from_key]": "From",
			"[from_value]": moment(data?.from_date).format("YYYY-MM-DD"),
			"[to_key]": "To",
			"[to_value]": moment(data?.from_date).format("YYYY-MM-DD"),
			"[ticket_type_key]": "Ticket Type",
			"[ticket_type_vaue]": data?.ticket_type,
			"[destination_key]": "Destination",
			"[destination_key_value]": destinations,
			// "[service_time_key]": "Service Time",
			// "[service_time_value]": "--",
			"[adult_key]": "Adult",
			"[adult_value]": data?.pax?.adults,
			"[children_key]": "Children",
			"[children_value]": data?.pax?.children,
			"[children_ages_key]": "Children Ages",
			"[children_ages_value]":
				data?.pax?.children_ages?.length > 0 ? data?.pax?.children_ages : "--",
			"[remarks_key]": "Remarks",
			"[remarks_value]": remarkString,
			"[provider_name_value]": data?.provider?.name,
			"[provider_ref_value]": data?.provider?.reference ? data?.provider?.reference : "--",
			"[supplier_name_value]": data?.supplier?.name,
			"[supplier_vat_value]": data?.supplier?.vatNumber,
		};

		let printVar = toursVoucher;
		for (const key in keyValuePair) {
			printVar = printVar.replace(key, keyValuePair[key]);
		}

		printVar = printVar.replace(
			"[logo]",
			`<img width={200} src="${safaLogo}" alt="safa logo" />`
		);
		printVar = printVar.replace(
			"[supplier_img]",
			`<img src="" alt="supplier img" class="img-fluid" width={200} />`
		);
		printVar = printVar.replace(
			"[phone_img]",
			`<img src="${phone}" alt="phone" />`
		);

		let popupWin = window.open("", " ", "top=0,left=0,height=80%,width=auto");
		popupWin.document.open();
		popupWin.document.write(`
        <html>
        <head>
        <title> print Voucher</title>
        <style>

        </style>
        </head>
        <body onLoad="{()=>window.print()}">${printVar}</body>
        </html>`);
		popupWin.document.close();
		setTimeout(() => {
			popupWin.print();
		}, 100);
	};

	async function getTourModifyAvailability(modifySearchData) {
		setModifiedResults(null);
		const data = {
			date_from: moment(modifySearchData?.date_from).format("YYYY-MM-DD"),
			date_to: moment(modifySearchData?.date_to).format("YYYY-MM-DD"),
			noOfAdults: modifySearchData?.adults?.value,
			children: modifySearchData?.children || modifySearchData?.childs_ages?.map((age) => age?.value),
		}
		const modifyTourRes = await getModifyAvailable(reservationItem?.id, data);
		if (modifyTourRes?.status >= 200 && modifyTourRes?.status < 300) {
			setModifyTourModalOpen(true);
			setModifiedResults(modifyTourRes?.data?.tour);
			dispatch({
				type: "saveToursSearch",
				payload: { ...toursSearch, ...modifySearchData }
			});
		}
	}



	function printProviderVoucher() {
		// let providerHtmlVoucher = reservationItemVoucher[0]?.url;
		let providerPdfVoucher = reservationItemVoucher[1]?.url;
		window.open(providerPdfVoucher, '_blank');
	}

	return (
		<>
			<div className="transfer-booking-item">
				<div className="d-flex flex-md-nowrap flex-wrap">
					<div className="tour-card-img">
						<img
							src={reservationItem?.details?.images?.[0]}
							alt="tours"
							className="img-fluid transfer-booking-item-img"
						/>
					</div>
					<div className="d-flex flex-column mx-md-2">
						<h2 className="mb-2">
							<span className="font-weight-bold text-dark-blue h5">
								{reservationItem?.details?.name}
							</span>
						</h2>
						<i className="fal fa-sort-alt"></i>

						<div className="transfer-booking-item-info">
							<i className="fas fa-map-marker-alt"></i>
							<p className="mx-1">
								{reservationItem?.details?.destinations?.map((city) => {
									return (
										<span key={city?.code}>
											{city?.name} ({city?.code}),{" "}
										</span>
									);
								})}
							</p>
						</div>

						<div className="transfer-booking-item-info">
							<i className="far fa-calendar-alt"></i>
							<span className="mx-1 font-weight-bold">
								{moment(reservationItem?.start_date).format("DD/MM/YYYY")} (
								{moment(reservationItem?.start_date).format("dddd")})
							</span>
						</div>

						<div className="transfer-booking-item-info">
							<i className="far fa-user"></i>
							<span className="mx-1 font-weight-bold">
								{reservationItem?.adults} {inventory.messages.adults}
							</span>
							{reservationItem?.children?.length > 0 ? (
								<span>
									{reservationItem?.children?.length} {inventory.messages.children}{" "}
									(
									{reservationItem?.children?.map((age, index) => {
										return (
											<span key={`age-${index}-${age}`}>
												{age}
												{index !== reservationItem?.children?.length - 1
													? ", "
													: " "}
											</span>
										);
									})}
									{marketPlace.yearsOld} )
								</span>
							) : null}
						</div>
					</div>
				</div>

				<h3 className="font-weight-bold text-dark-blue mt-2">
					{inventory.messages.remarks}
				</h3>
				{reservationItem?.details?.comments?.map((remark, remarkIdx) => {
					return <p key={`${remark?.type}-${remarkIdx}`}>{remark?.text}</p>;
				})}

				<p className="font-weight-bold text-dark-blue my-2 pt-2 border-top-gray">
					<span>
						{reservationItem?.adults} {reservationItem?.adults > 1 ? inventory.messages.adults : inventory.messages.Adult}
					</span>
					{reservationItem?.children?.length > 0 ? (
						<span>
							{reservationItem?.children?.length} {inventory.messages.children} (
							{reservationItem?.children?.map((age, index) => {
								return (
									<span key={`age-${index}-${age}`}>
										{age}
										{index !== reservationItem?.children?.length - 1 ? ", " : " "}
									</span>
								);
							})}
							{marketPlace.yearsOld})
						</span>
					) : null}{" "}
					({reservationItem?.details?.modality?.name})
				</p>

				{/* questions */}
				{reservationItem?.details?.questions?.length > 0 &&
					<div className='border-top-gray'>
						<h3 className="font-weight-bold text-dark-blue mt-2">
							{inventory.messages.questionsAndAnswers}
						</h3>
						{reservationItem?.details?.questions?.map((question, index)=> {
							return(
								<div className='border my-2 rounded p-2' key={`question-${question?.question?.code}`}>
									<p>{question?.question?.text}</p>
									<p className='mt-2 font-weight-bold'>{question?.answer}</p>
								</div>
							)
						})}
					</div>
				}

				{/* cancelations info */}
				{reservationItem?.details?.cancellationPolicies?.length > 0 ? (
					<div className="mt-3 transfer-booking-item-cancelation">
						<div className="d-flex align-items-center flex-wrap py-3">
							<div className="col-md-6">
								<div className="">
									<div className="d-flex justify-content-between ">
										<p className="font-weight-bold h6">
											{inventory.messages.cancellationFees}
										</p>
									</div>
									{reservationItem?.details?.cancellationPolicies?.map(
										(policy, index) => {
											return (
												<div
													className="d-flex justify-content-between"
													key={index}
												>
													<p className="transfer-booking-item-blue-dark">
														{marketPlace.From}{" "}
														{moment(policy?.dateFrom).format("hh:mm A")}{" "}
														{moment(policy?.dateFrom).format("DD/MM/YYYY")}
													</p>
													<span className="text-light-danger font-weight-bold h6 text-14">
														{policy?.amount} {reservationItem?.currency}
													</span>
												</div>
											);
										}
									)}
								</div>
							</div>
							<div className="col-md-6 text-muted">
								{marketPlace.cancelationWarning}
							</div>
						</div>
					</div>
				) : null}

				<div className="d-flex justify-content-between py-2 tour-booking-item-action">
					<div className='w-100 d-flex align-items-center justify-content-between'>
						{canDownloadOrModify ?
							<div className="text-light-warning"
								onClick={() => {
									if (reservationItemVoucher?.length > 0) {
										printProviderVoucher();
									} else {
										printVoucher();
									}
								}}
							>
								<i className="fas fa-download"></i>
								<span className="mx-1">{inventory.messages.downloadVoucher}</span>
							</div>
							:
							null
						}
						<div>
							{canCancelTour && paymentStatus !== "pending" ?
								<ShowForPermission permission="Manage-Inventory-Tours">
									<button className="btn text-light-danger p-0 d-flex align-items-center" onClick={getPreCancelTourItem}>
										<i className="fas fa-ban text-light-danger"></i>
										<span className="mx-1">{inventory.messages.cancelTour}</span>
									</button>
								</ShowForPermission>
								:
								null
							}
							{/* modify */}
							{canDownloadOrModify ?
								<></>
								// <button className='btn'
								// 	onClick={() => {
								// 		getTourModifyAvailability({
								// 			date_from: reservationItem?.start_date,
								// 			date_to: reservationItem?.end_date,
								// 			adults: { id: reservationItem?.adults, value: reservationItem?.adults, label: reservationItem?.adults },
								// 			children: reservationItem?.children,
								// 			childs: { id: reservationItem?.children?.length, value: reservationItem?.children?.length, label: reservationItem?.children?.length },
								// 			childs_ages: reservationItem?.children?.map(childAge => {
								// 				return { id: childAge, value: childAge, label: childAge }
								// 			})
								// 		})
								// 	}}
								// >
								// 	Modify
								// </button>
								:
								null
							}
						</div>
					</div>

					{reservationItem?.status === "canceled" ?
						<p className='text-light-danger font-weight-bold'>{inventory.messages.cancelled}</p>
						:
						null
					}
					{expiredBooking ?
						<p className='text-light-danger font-weight-bold'>{inventory.messages.expired}</p>
						:
						null
					}

				</div>
			</div>

			{canCancelTour && modalCancel ?
				<CancelModel
					model={modalCancel}
					toggleModel={toggleModalCancel}
					details={reservationItem}
					cancelType={"single-item"}
					preCancel={preCancel}
					amount={amount}
					setBookingDetails={setBookingDetails}
					toggleReloadData={toggleReloadData}
				/>
				:
				null
			}

			{canDownloadOrModify && modifyTourModalOpen ?
				<ModifyTourModal
					isOpen={modifyTourModalOpen}
					toggle={toggleModifyTourModal}
					reservationItem={reservationItem}
					getTourModifyAvailability={getTourModifyAvailability}
					modifiedResults={modifiedResults}
				/>
				:
				null}

		</>
	);
}
