import ImagePlaceholder from "assets/images/image_placeholder.png";
import { useSBSState } from "context/global";
import { useVisaState } from "context/visa";
import Barcode from "react-barcode";

export default function VisaData({ data, index }) {
	const { locale } = useSBSState();
	const { mehremName } = useVisaState();

	return (
		<>
			<div className="visa-print-content">
				<header>{/* <img src={footerImage1} alt="footer" /> */}</header>

				<div className="container-custom visa-content ">
					<div className="box1">
						<div className="row-1 ">
							<div className="col1-md-3">
								<div className="img img-print-visa mt-1">
									<img
										src={data?.photo ? data?.photo : ImagePlaceholder}
										width="240"
										height="260"
										alt=""
										className="img-fluide"
									/>
								</div>
							</div>

							<div className="col1-md-9">
								<div className="row1 text-center">
									<div className="col1-md-3 px-3 py-1 bg-gray-print ">
										<p>Visa No.</p>
									</div>
									<div className="col1-md-5  px-3 py-2 bg-gray ">
										<p>{data.visa}</p>
									</div>
									<div className="col1-md-3  px-3 py-1 bg-gray-print ">
										رقم التأشيرة
									</div>
								</div>

								<div className="row1 text-center ">
									<div className="col1-md-3 px-3 py-1 bg-gray-print ">
										<p>Valid from</p>
									</div>
									<div className="col1-md-5  px-3 py-2 bg-gray ">
										<p>{data?.visa_date_validity_from}</p>
									</div>
									<div className="col1-md-3  px-3 py-1 bg-gray-print ">
										صالحة اعتبارا من{" "}
									</div>
								</div>
								<div className="row1 text-center">
									<div className="col1-md-3 px-3 py-1 bg-gray-print ">
										<p>Valid until</p>
									</div>
									<div className="col1-md-5  px-3 py-2 bg-gray ">
										<p>{data?.validity_to}</p>
									</div>
									<div className="col1-md-3  px-3 py-1 bg-gray-print ">
										<p>صالحة لغاية</p>
									</div>
								</div>

								<div className="row1 text-center ">
									<div className="col1-md-3 px-3 py-1 bg-gray-print ">
										<p>Duration of Stay</p>
									</div>
									<div className="col1-md-5  px-3 py-2 bg-gray ">
										<p>{data?.duration} day </p>
									</div>
									<div className="col1-md-3  px-3 py-1 bg-gray-print ">
										<p>مدة الإقامة</p>
									</div>
								</div>
								<div className="row1 text-center">
									<div className="col1-md-3 px-3 py-1 bg-gray-print ">
										<p>Passport No.</p>
									</div>
									<div className="col1-md-5  px-3 py-2 bg-gray ">
										<p>{data?.ppno}</p>
									</div>
									<div className="col1-md-3  px-3 py-1 bg-gray-print ">
										<p> رقم جواز السفر</p>
									</div>
								</div>
							</div>
						</div>
					</div>

					{/* <div className="box1">
						<div className="row w-100">
							<div className="col-md-2">
								<div className="img">
									<img
										src={
											data?.profile_image
												? data?.profile_image
												: ImagePlaceholder
										}
										width="320"
										height="300"
										alt=""
									/>
								</div>
							</div>
							<div className="col-md-9">
								<div className="row text-center">
									<div className="col-md-2 px-3 py-3 bg-gray-print ">
										<p>Visa No.</p>
									</div>
									<div className="col-md-5  px-3 py-3 bg-gray mx-2">
										<p>{data.visa}</p>
									</div>
									<div className="col-md-2  px-3 py-3 bg-gray-print ">
										رقم التأشيرة
									</div>
								</div>

								<div className="row text-center my-1">
									<div className="col-md-2 px-3 py-3 bg-gray-print ">
										<p>Valid from</p>
									</div>
									<div className="col-md-5  px-3 py-3 bg-gray mx-2">
										<p>{data?.visa_date_validity_from}</p>
									</div>
									<div className="col-md-2  px-3 py-3 bg-gray-print ">
										صالحة اعتبارا من{" "}
									</div>
								</div>
								<div className="row text-center">
									<div className="col-md-2 px-3 py-3 bg-gray-print ">
										<p>Valid until</p>
									</div>
									<div className="col-md-5  px-3 py-3 bg-gray mx-2">
										<p>{data?.validity_to}</p>
									</div>
									<div className="col-md-2  px-3 py-3 bg-gray-print ">
										<p>صالحة لغاية</p>
									</div>
								</div>

								<div className="row text-center my-1">
									<div className="col-md-2 px-3 py-3 bg-gray-print ">
										<p>Duration of Stay</p>
									</div>
									<div className="col-md-5  px-3 py-3 bg-gray mx-2">
										<p>{data?.duration} day </p>
									</div>
									<div className="col-md-2  px-3 py-3 bg-gray-print ">
										<p>مدة الإقامة</p>
									</div>
								</div>
								<div className="row text-center">
									<div className="col-md-2 px-3 py-3 bg-gray-print ">
										<p>Passport No.</p>
									</div>
									<div className="col-md-5  px-3 py-3 bg-gray mx-2">
										<p>{data?.ppno}</p>
									</div>
									<div className="col-md-2  px-3 py-3 bg-gray-print ">
										<p> رقم جواز السفر</p>
									</div>
								</div>
							</div>
						</div>
					</div> */}

					<div className="box2 mb-3">
						<div className="col-md-12">
							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p>Place of issue</p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.embassy_issue}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">
									مصدر التأشيرة{" "}
								</div>
							</div>

							<div className="row my-2 text-center">
								<div className="col-md-2  py-1 bg-gray-print ">
									<p> Name</p>
								</div>
								<div className="col-md-7  py-2 bg-gray	mx-3">
									<p>{data?.name}</p>
								</div>
								<div className="col-md-2  py-1 bg-gray-print ">الاسم</div>
							</div>
							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p> Nationality </p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.nationality_name.name[locale]}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">الجنسية</div>
							</div>

							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p> Type Of Visa </p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.visa_type}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">
									نوع التأشيرة
								</div>
							</div>
							{/* {data?.mahramName != "" ? (
								<div className="row my-2 text-center">
									<div className="col-md-2  py-2 bg-gray-print ">
										<p> Mahram Name </p>
									</div>
									<div className="col-md-7   py-2 bg-gray	mx-3">
										<p>{data?.mahramName}</p>
									</div>
									<div className="col-md-2   py-2 bg-gray-print ">
										اسم المحرم
									</div>
								</div>
							) : null} */}

							{mehremName?.length > 0 && mehremName[index] != "" ? (
								<div className="row my-2 text-center">
									<div className="col-md-2  py-2 bg-gray-print ">
										<p> Mahram Name </p>
									</div>
									<div className="col-md-7   py-2 bg-gray	mx-3">
										<p>{mehremName[index]}</p>
									</div>
									<div className="col-md-2   py-2 bg-gray-print ">
										اسم المحرم
									</div>
								</div>
							) : null}
						</div>
					</div>
					<div className="brd"></div>

					<div className="box3 my-3">
						<div className="col-md-12">
							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p>Umrah Operator</p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.umrah_operator_UO}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">
									مكتب العمرة
								</div>
							</div>

							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p> External Agent</p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.external_agent_EA}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">
									الوكيل الخارجي
								</div>
							</div>
							<div className="row my-2 text-center">
								<div className="col-md-2  py-2 bg-gray-print ">
									<p> Border No. </p>
								</div>
								<div className="col-md-7   py-2 bg-gray	mx-3">
									<p>{data?.border_num}</p>
								</div>
								<div className="col-md-2   py-2 bg-gray-print ">رقم الحدود</div>
							</div>
						</div>
					</div>

					<div className="brd"></div>

					<div className="box3 my-3">
						<div className="col-md-12">
							<div className="row  text-center">
								<div className="col-md-2  bg-gray-print-barcode  ">
									<p>Visa No. </p>
								</div>
								<div className="col-md-7   	mx-3 barcode-item">
									{/* <p className="barcode">*{data?.visa}*</p> */}
									<p className="barcode-item">
										<Barcode
											value={data?.visa}
											format="CODE39"
											displayValue={true}
											// width={7.1}
											// height={60}
											width={2.9}
											height={55}
										/>
									</p>
								</div>
								<div className="col-md-2    bg-gray-print-barcode ">
									<p> رقم التأشير</p>
								</div>
								{/* <p className="text-center mb-2">{data?.visa}</p> */}
							</div>

							<div className="row  text-center">
								<div className="col-md-2   bg-gray-print-barcode  applicationNo">
									<p> Application No.</p>
								</div>
								<div className="col-md-7    	mx-3">
									{/* <p className="barcode">*{data?.enumber}*</p> */}
									<p>
										<Barcode
											value={data?.enumber}
											format="CODE39"
											displayValue={true}
											// width={5.7}
											// height={60}
											width={2.9}
											height={55}
										/>
									</p>
								</div>
								<div className="col-md-2    bg-gray-print-barcode ">
									<p>رقم الطلب </p>
								</div>
								{/* <p className="text-center mb-2">{data?.enumber}</p> */}
							</div>
						</div>
					</div>
				</div>

				<footer></footer>
			</div>
		</>
	);
}
