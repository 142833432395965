import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import bg from "../../../assets/images/customRegister/Group 3787.png";
import bgAR from "../../../assets/images/customRegister/Group 3787AR.png";
import { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Locale from 'translations';
import safaLogo from "assets/images/safa_logo.png";


export default function SelectRegisterType() {
  const { locale } = useSBSState();
  const { userDetails, login, companyDetails, newLand } = Locale;
  const history = useHistory()
  const [registerType, setRegisterType] = useState();
  const normalRegister = 'normal_register'
  const safaRegister = 'safa_register'

  function redirect() {
    if (registerType === normalRegister) {
      history.push('/auth/register');
    } else if (registerType === safaRegister) {
      history.push('/auth/register_safa');
    }
  }

  return (
    <div className="bg-layout  register-page ">
      <div className="d-flex container justify-content-between pt-1">
        <figure className="col-5 pt-3 m-0 register-page-img">
          <img src={locale === "en" ? bg : bgAR} alt="" className="img-fluid" />
        </figure>

        <div className="col-md-5 col-sm-9 d-flex flex-column justify-content-between">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Link to="/contact-us" className="text-dark">
              {newLand.contactUs}
            </Link>
            <Link to="/about-us" className="text-dark">
              {newLand.aboutUs}
            </Link>
            <LanguageSwitcher />
          </div>
          <img
            src={safaLogo}
            alt=""
            className="safa_logo d-none"
          />


          <div className="w-100 select-register-type">
            <h1 className='register-type-head px-2'>
              <span className='d-block col-md-8 col-12'> {login.pleaseSelectRegisterMethod}</span>
            </h1>
            {/* select register type */}
            <div className="d-flex align-items-center justify-content-between mt-4 px-3">
              {/* normal register */}
              <div className="d-flex align-items-center">
                <input
                  class="radio-input"
                  type="radio"
                  name={normalRegister}
                  id={normalRegister}
                  value={normalRegister}
                  checked={registerType === normalRegister}
                  onChange={(e) => {
                    setRegisterType(normalRegister)
                  }}
                  autocomplete="off"
                />
                <label className="form-check-label px-1 pointer" for={normalRegister}>
                  {login.normalSingup}
                </label>
              </div>
              {/* safa register */}
              <div className="d-flex align-items-center">
                <input
                  class="radio-input"
                  type="radio"
                  name={safaRegister}
                  id={safaRegister}
                  value={safaRegister}
                  checked={registerType === safaRegister}
                  onChange={(e) => {
                    setRegisterType(safaRegister)
                  }}
                  autocomplete="off"
                />
                <label className="form-check-label px-1 pointer" for={safaRegister}>
                  {login.signupWithSafaKey}
                </label>
              </div>
            </div>

            <button
              className="btn bg-nxt w-100 mt-4 mb-2 py-2 text-uppercase"
              disabled={!registerType}
              onClick={redirect}
            >
              {companyDetails.next}
            </button>

            <Link to="/auth/login" className="mt-2 bg-back text-capitalize">
              <span className="icx icx-left-solid-arrow text-10"></span>{" "}
              {userDetails.backBtn}
            </Link>
          </div>

          <div></div>
        </div>


      </div>
    </div>
  )
}
