import { useSBSDispatch, useSBSState } from "context/global";
import { useState } from "react";
import { TabPane } from "reactstrap";
import { fetchCitiesSearch } from "services/marketplace";
import Locale from "translations";
import SuccessModal from "../../../HotelResponse/Summary/successModal";
import Groups from "./Groups";
import Individual from "./Individual";
import { useSetpsList } from 'layouts/useSetpsList';
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import Model from "components/model";
import ShowForPermission from 'helpers/showForPermission';


function HotelSearch({
	hotel,
	setHotel,
	allCurrenciesData,
	allCountries,
	mealPlanLookups,
	roomTypesLookups,
	roomViewsLookups,
}) {
	const { locale } = useSBSState();
	const dispatch = useSBSDispatch();
	const { marketPlace, guide } = Locale;
	const [activeTab, setActiveTab] = useState("1");
	const [modalsuccess, setModalsuccess] = useState(false);
	const togglesuccessModal = () => setModalsuccess(!modalsuccess);


	const [modalGroup, setModalGroup] = useState(false);
	const toggleModalGroup = () => {
		setModalGroup(!modalGroup);
		dispatch({ type: "guide", payload: { enabled: false, initialStep: 0, steps:[] } })
	};

	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};
	const CurrenciesData = allCurrenciesData;
	const [msgResponse, setMsgResponse] = useState(null);
	const { menuSteps, hotelsTabs, vacationsIndividualSteps, vacationsGroupsSteps } = useSetpsList();
	// AutoCompleteField
	const [listAuto, setListAuto] = useState([]);
	const [modal, setmodal] = useState({
		isOpen: false,
		title: marketPlace.groups,
	});
	const togglemodel = () => {
		setmodal({...modal,isOpen:!modal.isOpen})
	}
	const getListAuto = async (inputValue) => {
		if (inputValue.length > 2) {
			const countries = await fetchCitiesSearch("is_vacation", inputValue);

			let result = countries.map((item) => {
				return {
					...item,
					id: item.id,
					name: item.name + " - " + item.country.name[locale],
					value: item.id,
					label: item.name + " - " + item.country.name[locale],
				};
			});
			setListAuto(result);
		}
	};

	return (
		<div className="">
			<button className='btn market-search-guide-btn' onClick={() => {
				dispatch({
					type: "guide",
					payload: {
						initialStep: 0,
						enabled: true,
						steps: activeTab === "1" ? [...menuSteps, ...hotelsTabs, ...vacationsIndividualSteps] : [...vacationsGroupsSteps]
					}
				})
			}}>
				<i class="fas fa-question-circle"></i>
				<span>{guide.pageGuide}</span>
			</button>
			<TabPane tabId="1">
				{/* <div className="vacation-content sub-tabs-vacation col-md-10 HotelSearch m-auto ">
					<div className="d-flex sub-tabs-content" >
						<div
							id='hotel-vacations-individual'
							className={`${activeTab === "1" ? "active" : ""} TAPactive`}
							onClick={() => toggle("1")}
						>
							{marketPlace.INDIVIDUAL}
						</div>
						<div
							id='hotel-vacations-groups'
							className={`${activeTab === "2" ? "active" : ""} TAPactive`}
							onClick={() => toggle("2")}
						>
							{marketPlace.groups}
						</div>
					</div>
				</div> */}
				{activeTab == 1 ? (
					<Individual
						hotel={hotel}
						setHotel={setHotel}
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						CurrenciesData={CurrenciesData}
					/>
				) : (
					<Groups
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						CurrenciesData={CurrenciesData}
						modalsuccess={modalsuccess}
						setModalsuccess={setModalsuccess}
						mealPlanLookups={mealPlanLookups}
						roomTypesLookups={roomTypesLookups}
						roomViewsLookups={roomViewsLookups}
						msgResponse={msgResponse}
						setMsgResponse={setMsgResponse}
					/>
				)}
			</TabPane>

			<Model
				isOpen={modal.isOpen}
				title={modal.title}
				size="lg"
				toggle={togglemodel}
				hasFooter={false}
			>
				<Groups
					listAuto={listAuto}
					setListAuto={setListAuto}
					getListAuto={getListAuto}
					allCountries={allCountries}
					CurrenciesData={CurrenciesData}
					modalsuccess={modalsuccess}
					setModalsuccess={setModalsuccess}
					mealPlanLookups={mealPlanLookups}
					roomTypesLookups={roomTypesLookups}
					roomViewsLookups={roomViewsLookups}
					msgResponse={msgResponse}
					setMsgResponse={setMsgResponse}
				/>
			</Model>
			<SuccessModal
				modalsuccess={modalsuccess}
				togglesuccessModal={togglesuccessModal}
				msg={msgResponse}
				title={"Group Request"}
			/>

<ShowForPermission  permission="Manage-Marketplace-Group-Reservations" >

<a
				onClick={() => toggleModalGroup()} 
				href={() => false}
				className="link text-center w-100 text-underline d-block font-weight-bold"
				style={{fontSize: "1.1rem"}}
				id="hotel-vacations-groups"
				>
				{marketPlace.moreRooms}
			</a>
				</ShowForPermission>


			<Modal
				size="lg"
				isOpen={modalGroup}
				toggle={toggleModalGroup}
				className="ModalGroup-Modal"
			>
				<ModalHeader
					className="align-items-center py-1  border-none"
					toggle={toggleModalGroup}
				>
					{marketPlace.groups}
				</ModalHeader>
				<ModalBody>

					<Groups
						listAuto={listAuto}
						setListAuto={setListAuto}
						getListAuto={getListAuto}
						allCountries={allCountries}
						CurrenciesData={CurrenciesData}
						modalsuccess={modalsuccess}
						setModalsuccess={setModalsuccess}
						mealPlanLookups={mealPlanLookups}
						roomTypesLookups={roomTypesLookups}
						roomViewsLookups={roomViewsLookups}
						msgResponse={msgResponse}
						setMsgResponse={setMsgResponse}
					/>

				</ModalBody>
			</Modal>
		</div>
	);
}

export default HotelSearch;
