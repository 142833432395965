import dircte from " ../../assets/images/icons/Dircte.svg";
import externalGold from " ../../assets/images/icons/External-gold.svg";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import generateUniqueID from "helpers/generateUniqueID";
import moment from "moment";

import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Locale from "translations";

export default function ExternalTransportationsPreview(props) {
	const [externalTransportations, setExternalTransportations] = useState({});
	const { productElements, productsBuilder } = Locale;
	const history = useHistory();
	const dispatch = useProductsBuilderDispatch();
	useEffect(() => {
		setExternalTransportations(props.element);
	}, []);
	console.log(externalTransportations, "externalTransportations");
	return (
		<div className="product-build__product-collpase itenary-box d-flex justify-content-center align-items-center flex-row">
			<div className="img-circle-wrapper  icon-pro-Itinerary ">
				<img src={externalGold} alt="" srcset="" />
				{/* 							<span className="fas fa-plane"></span>
				 */}{" "}
			</div>
			<div
				className="button-collapse"
				style={{
					border: externalTransportations.fail ? "2px solid #C30101" : "",
				}}
			>
				<div className="title-style-container">
					<div className="right-items-container">
						{externalTransportations.fail ? (
							<>
								<div
									className="product-content-items px-4 p-2"
									style={{ minHeight: "80px" }}
								>
									<p
										style={{
											fontSize: "18px",
											fontWeight: "700",
											color: "#C30101",
											width: "100%",
										}}
									>
										{externalTransportations.title}

										{!props.isShow && (
											<button
												className="btn float-right btn-lg text-"
												style={{
													fontSize: "14px",
													background: "#C30101",
													color: "#fff",
													textTransform: "capitalize",
												}}
												onClick={() => {
													dispatch({
														type: "appendItem",
														payload: {
															id: generateUniqueID(),
															item: { name: "external", id: 1 },
														},
													});
													history.push("/products-builder/create", {
														tab: "1",
													});
												}}
											>
												{productsBuilder.add} +
											</button>
										)}
									</p>
								</div>
							</>
						) : (
							<div className="product-content-items px-4 p-2">
								<div className="product-country-from">
									<h6 className="blue-txt-item">
										<span>
											{externalTransportations.itemable?.from_hall?.name}
										</span>
										{externalTransportations.itemable?.from_port.name}
									</h6>

									<h6 className="htel-date">
										{externalTransportations.itemable?.from_country.name}
									</h6>
									<p className="htel-date">
										{moment(
											externalTransportations.itemable?.departure_at
										).format("YYYY-MM-DD")}
									</p>
								</div>
								<div className="product-arrival">
									<i className="fa fa-circle " aria-hidden="true"></i>
									<span className="transitons-count">
										{externalTransportations.itemable?.transits &&
										externalTransportations.itemable?.transits.length > 0 ? (
											<>
												{externalTransportations.itemable?.transits.length}{" "}
												{productElements.stop}
											</>
										) : (
											<>{productElements.direct}</>
										)}
									</span>
									<img src={dircte} alt="" srcset="" />
								</div>
								<div className="product-country-to">
									<h6 className="blue-txt-item">
										<span className="htel-date">
											{externalTransportations.itemable?.to_hall?.name}
										</span>
										{externalTransportations.itemable?.to_port.name}
									</h6>
									<h6 className="htel-date">
										{externalTransportations.itemable?.to_country.name}
									</h6>
									<p className="htel-date">
										{moment(
											externalTransportations.itemable?.arrival_at
										).format("YYYY-MM-DD")}
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
