import AuthRoute from "components/Routes/AuthRoute";
import LandPage from 'modules/LandPage';
import SafaRegistration from 'modules/auth/pages/SafaRegistration';
import Activation from "modules/auth/pages/Activation";
import PasswordChangeSucsses from "modules/auth/pages/PasswordChangeSucsses";
import SelectRegisterType from 'modules/auth/pages/SelectRegisterType';
import NewLanding from "modules/newLanding";
import { lazy } from "react";
import { Route, Switch } from "react-router-dom";
import LinkSafaAccount from 'modules/auth/pages/LinkSafaAccount';
import SafaRegisterationSucceed from 'modules/auth/pages/SafaRegistration/SafaRegisterationSucceed';
import Otp from 'modules/auth/pages/Otp';
import NewLandingApps from 'modules/apps/LandingPageApp';
import PrivacyPolicy from 'modules/auth/pages/PrivacyPolicy';
import ContactUsPage from 'modules/ContactUs';
// import AboutUs from 'modules/AboutUs';
import AboutUs from 'modules/about-us';
import SinglePromo from 'modules/Promo/Hotels/Components/SinglePromo';
import BestDealsPage from 'modules/Promo/Hotels/BestDeals/main';


// Lazy Components
const Error404 = lazy(() => import("./components/Errors/Error404/Error404"));
const Login = lazy(() => import("./modules/auth/pages/Login"));
const LoginWithSafa = lazy(() => import("./modules/auth/pages/LoginWithSafa"));
const ForgotPassword = lazy(() =>
	import("./modules/auth/pages/ForgotPassword")
);
const ForgotPasswordSubmitted = lazy(() =>
	import("./modules/auth/pages/ForgotPasswordSubmitted")
);
const ResetPassword = lazy(() => import("./modules/auth/pages/ResetPassword"));
const RegistrationSucceed = lazy(() =>
	import("modules/auth/pages/RegistrationSucceed")
);
const Register = lazy(() => import("modules/auth/pages/Register"));

// Application Routes
const preLoginRoutes = (
	<Switch>
		{/* ****************** Start Auth Route ******************** */}

		{/* Login Module */}
		<AuthRoute path="/" exact component={Login} />
		<AuthRoute path="/promo" exact component={SinglePromo} />
		<AuthRoute path="/about-us" exact component={AboutUs} />
		<AuthRoute path="/PrivacyPolicy" exact component={PrivacyPolicy} />
		<AuthRoute path="/contact-us" exact component={ContactUsPage} />
		<AuthRoute path="/about-us" exact component={AboutUs} />

		<AuthRoute path="/auth/login" exact component={Login} />
		<AuthRoute path="/auth/otp" exact component={Otp} />
		<AuthRoute path="/auth/login_safa" exact component={LoginWithSafa} />

		<AuthRoute
			path="/auth/select-register-type"
			exact
			component={SelectRegisterType}
		/>
		<AuthRoute path="/auth/register" exact component={Register} />
		<AuthRoute path="/auth/register_safa" exact component={SafaRegistration} />
		<AuthRoute
			path="/auth/link_safa_account"
			exact
			component={LinkSafaAccount}
		/>
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		<AuthRoute
			path="/auth/register_safa/success"
			exact
			component={SafaRegisterationSucceed}
		/>
		<AuthRoute path="/auth/forgot_password" exact component={ForgotPassword} />
		<AuthRoute
			path="/auth/forgot_password_submitted"
			exact
			component={ForgotPasswordSubmitted}
		/>
		<AuthRoute
			path="/auth/reset_password/:token/:email"
			exact
			component={ResetPassword}
		/>
		<AuthRoute path="/sbs/welcome" exact component={LandPage} />
		<AuthRoute path="/new-landing" exact component={NewLanding} />
		<AuthRoute path="/landing-apps" exact component={NewLandingApps} />
		<AuthRoute path="/promo/:type" exact component={BestDealsPage} />

		<AuthRoute
			path="/auth/passwordChanged/successfully"
			exact
			component={PasswordChangeSucsses}
		/>
		<Route path="/auth/activation/:token/:email" component={Activation} />
		<AuthRoute path="/auth/activation/resend" component={RegistrationSucceed} />
		<AuthRoute
			path="/auth/register/success"
			exact
			component={RegistrationSucceed}
		/>
		{/* ****************** End Auth Route ******************** */}

		{/* UI Static Routes */}

		<Route path="*" component={Error404} />
	</Switch>
);

export default preLoginRoutes;
