import Flight from "assets/images/smFlight.png";
import background from "assets/images/bannerFlight.png";
import classnames from "classnames";

import { useRef, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";

import Locale from "translations";

import SearchFilghtForm from "./SearchFilghtForm";

export default function Flights() {
	//Locale
	const divHigth = useRef({});
	let clientHeight = divHigth.current.clientHeight;
	const { marketPlace } = Locale;
	const [activeTab, setActiveTab] = useState("1");

	const toggle = (tab) => {
		setActiveTab(tab);
	};

	return (
		<>
			<div className="market-search  mb-4">
				<div className="main-tabs w-100 p-0" ref={divHigth}>
					<Nav tabs className="align-items-center main-nav text-capitalize">
						<NavItem>
							<NavLink
								id="hotel-vacations"
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<img
									className="px-1"
									src={Flight}
									alt=""
									srcset=""
									width="40px"
								/>
								{marketPlace.messages.flights}
							</NavLink>
						</NavItem>
					</Nav>
					<div className="heading col-md-10 m-auto pt-2 pd-1">
						<h5 className=" mt-3 search-title terms-color text-uppercase ">
							{marketPlace.messages.search} & {marketPlace.messages.book}
						</h5>
						<h3 className=" mb-2 "> {marketPlace.messages.NewDeals}</h3>
					</div>

					{/*****************Start Container Tab********************** */}
					<SearchFilghtForm />
				</div>
				<div
					className="search-banner"
					style={{
						backgroundImage: `url(${background})`,
						height: `${clientHeight}px`,
					}}
				></div>
			</div>
		</>
	);
}
