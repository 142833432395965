import dircte from " ../../assets/images/icons/Dircte.svg";
import domestaic from " ../../assets/images/icons/Domestic-gold.svg";
import moment from "moment";
import { useEffect, useState } from "react";
import Locale from 'translations';

export default function DomesticFlightPreview(props) {
	const { productsBuilder } = Locale;

	const [domesticFlightPreview, setDomesticFlightPreview] = useState({});

	useEffect(() => {
		setDomesticFlightPreview(props.element);
	}, []);

	return (
		<div className=" product-build__product-collpase itenary-box d-flex justify-content-center align-items-center flex-row">
			<div className="img-circle-wrapper icon-pro-Itinerary ">
				<img src={domestaic} alt="" srcset="" />
				{/* 							<img src={travelIcon} alt="Travel Icon" title="Icon" />
				 */}{" "}
			</div>

			<div className="button-collapse">
				<div className="title-style-container">
					<div className="right-items-container">
						<div className="product-content-items px-4 p-2">
							<div className="product-country-from">
								<h6 className="blue-txt-item">
									[
									<span>{domesticFlightPreview.itemable?.from_hall?.name}</span>
									] {domesticFlightPreview.itemable?.from_port.name}
								</h6>
								<h6 className="htel-date">
									{domesticFlightPreview.itemable?.country.name}
								</h6>
								<p className="htel-date">
									{moment(domesticFlightPreview.itemable?.departure_at).format(
										"YYYY-MM-DD"
									)}
								</p>
							</div>
							<div className="product-arrival">
								<i className="fa fa-circle " aria-hidden="true"></i>
								<span className="transitons-count">{productsBuilder.Direct}</span>
								<img src={dircte} alt="" srcset="" />
								{/* 								<i className="fa fa-plane" aria-hidden="true"></i>
								 */}{" "}
							</div>
							<div className="product-country-to">
								<h6 className="blue-txt-item">
									[<span>{domesticFlightPreview.itemable?.to_hall?.name}</span>]{" "}
									{domesticFlightPreview.itemable?.to_port.name}
								</h6>
								<h6 className="htel-date">
									{" "}
									{domesticFlightPreview.itemable?.country.name}
								</h6>
								<p className="htel-date">
									{" "}
									{moment(domesticFlightPreview.itemable?.arrival_at).format(
										"YYYY-MM-DD"
									)}
								</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
