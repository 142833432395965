
import moment from "moment";
import React, { useEffect, useState } from "react";

import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import { useSBSState } from "context/global";
import Locale from "translations";
import { useHistory } from "react-router-dom";
import ContactInformation from "./ContactInformation";
import Travelers from "./TravelersDetails";
import validate, { isFormValid } from "helpers/validate";
import ItinareyPackage from "./ItinareyPackage";
import {
	PackageCheckAvailability,
	PackagesBooking,
} from "services/marketplace";
import { store } from "react-notifications-component";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import NewPaymentAction from "components/NewPaymentAction";

export default function CheckoutPackage() {
	const { packages: t, inventory } = Locale;
	//const t = useTranslation();
	// const windowWidth = useWindowSize();
	// const isMobile = windowWidth < 776;
	let history = useHistory();
	const searchDispatch = useMarketplaceDispatch();
	const { priceRoomsPackages, PackagesGuests } = useMarketplaceState();
	const PackagesGuestsLocalStorage=JSON.parse( localStorage.getItem("PackagesGuests"))
	const { userInfo, allCountries ,allDataLogin} = useSBSState();
	const details = useMarketplaceState();
	const { uuid } = useParams();
console.log("allDataLoginallDataLogin",PackagesGuestsLocalStorage);
	const userInfoExist = allDataLogin && Object.keys(allDataLogin).length > 0;
	// get number of adult
	const noOfAdultTravalers = PackagesGuests?PackagesGuests?.guests.reduce(
		(total, obj) => +obj.room_numbers + total,
		0
	):PackagesGuestsLocalStorage?.guests.reduce(
		(total, obj) => +obj.room_numbers + total,
		0
	);

	// get number of children travelers
	const noOfChildTravalers = details?.Count?.child?.length;

	const [travelersAdultsList, setTravelersAdultsList] = useState([]);
	const [travelersChildList, setTravelersChildList] = useState([]);
	const [countries, setCountries] = useState([]);
	const [phoneCode, setPhoneCode] = useState(
		userInfo?.phone_code ||
			details.packageSearchData?.departure_from?.phone_code
	);

	// convert add
	useEffect(() => {
		const adultsArray =
			+noOfAdultTravalers > 0 &&
			[...Array(noOfAdultTravalers && +noOfAdultTravalers).keys()]?.map(() => {
				return {
					firstName: "",
					lastName: "",
					gender: "",
					passportExpiryDate: "",
					passportNumber: "",
					birthDate: "",
					nationality: details?.packageSearchData?.nationality || "",
					country: details?.packageSearchData?.departure_from || "",
				};
			});
		const childsArray =
			+noOfChildTravalers > 0
				? [...Array(noOfChildTravalers && +noOfChildTravalers).keys()]?.map(
						() => {
							return {
								firstName: "",
								lastName: "",
								gender: "",
								passportExpiryDate: "",
								passportNumber: "",
								birthDate: "",
								nationality: details?.packageSearchData?.nationality || "",
								country: details?.packageSearchData?.departure_from || "",
							};
						}
				  )
				: [];
		setTravelersAdultsList(adultsArray);
		setTravelersChildList(childsArray);
	}, [details.packageSearchData, noOfAdultTravalers, noOfChildTravalers]);
	// ariports lookups
	const [errors, setErrors] = useState({});
	const [isErrorLoaded, setIsErrorLoaded] = useState(false);
	function innerObject(arr) {
		let all = {};
		arr.map((item, index) => {
			all = { ...all, ...item };
		});
		return all;
	}
	const checkFormErrors = () => {
		let Error = [];
		travelersAdultsList.map((res, index) => {
			Object.entries(res).forEach(([key, value]) => {
				let newError = {
					...validate(
						{
							name: `${key}${index}`,
							value: `${value}`,
						},
						{
							required: true,
						}
					),
				};
				Error.push(newError);
			});
		});

		travelersChildList.map((res, index) => {
			Object.entries(res).forEach(([key, value]) => {
				let newError = {
					...validate(
						{
							name: `${key}${index}`,
							value: `${value}`,
						},
						{
							required: true,
						}
					),
				};
				Error.push(newError);
			});
		});
		setErrors({
			...errors,

			...innerObject(Error),
		});
	};
	// countries lookups
	useEffect(() => {
		async function countriesLookups() {
			const format = await allCountries?.map((country) => ({
				id: country.id,
				label: country.name,
				name: country.name,
				code: country.country_code,
				phone_code: country.phone_code,
			}));
			setCountries(format);
		}
		countriesLookups();
	}, [allCountries]);

	// reset body overflow when is coming back from failed payment
	useEffect(() => {
		document.body.style.overflowY = "auto";
	}, []);

	let typeRooms = {
		1: "Single Room",
		2: "Double Room",
		3: "Treble Room",
		4: "Quad Room",
		5: "Quintuple Room",
		6: "Hexagonal Room",
		7: "Seven Room",
		8: "Eight Room",
	};

	// submit data to the backend
	// const submit = async (requestBody) => {
	// 	const bookRes = await PackagesBooking(requestBody);

	// 	if (bookRes?.status >= 200 && bookRes?.status < 300) {
	// 		store.addNotification({
	// 			title: "info!",
	// 			message: bookRes.data.message?? " Booking Successfully",
	// 			type: "success",
	// 			insert: "top",
	// 			container: "top-right",
	// 			animationIn: ["animated", "fadeIn"],
	// 			animationOut: ["animated", "fadeOut"],
	// 			dismiss: {
	// 				duration: 1100,
	// 				onScreen: true,
	// 				pauseOnHover: true,
	// 			},
	// 			onRemoval: () => {
	// 				history.push("/inventory/packages")
	// 			}

	// 		});
	// 	}
	// };
	async function bookOnPayment(payment_method, currency) {
		let data = {
			_debug: true,
			product_uuid: uuid,
			payment_method: payment_method,
			currency: currency,
			children: PackagesGuests?.children != "" ? +PackagesGuests?.children : 0,
			rooms: PackagesGuests?.guests.map((item) => ({
				type: +item.room_value,
				adults_count: +item.room_numbers,
			})),

			travelers: travelersAdultsList.map((item) => ({
				first_name: item?.firstName,
				last_name: item?.lastName,
				gender: item?.gender == "male" ? "M" : "F",
				birth_date: moment(item?.birthDate).format("YYYY-MM-DD"),
				nationality_id: item.nationality.id,
				country_id: item.country.id,
				passport_expiry: moment(item?.passportExpiryDate).format("YYYY-MM-DD"),
				passport_number: item?.passportNumber,
			})),
		};
		const bookRes = await PackagesBooking(data);
		if(packageAvailability?.booking_type=="auto"){
			if (bookRes?.status >= 200 && bookRes?.status < 300) {
				store.addNotification({
					title: "info!",
					message: bookRes.data.message ?? "Booking Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/inventory/packages");
					},
				});
			}
		}else{
			if (bookRes?.status >= 200 && bookRes?.status < 300) {
				store.addNotification({
					title: "info!",
					message: "Send Request Successfully",
					type: "success",
					insert: "top",
					container: "top-right",
					animationIn: ["animated", "fadeIn"],
					animationOut: ["animated", "fadeOut"],
					dismiss: {
						duration: 1100,
						onScreen: true,
						pauseOnHover: true,
					},
					onRemoval: () => {
						history.push("/inventory/packages");
					},
				});
			}
		}
		
	}
	useEffect(() => {
		if (isFormValid(errors)) {
			CheckAvailability();
		}
	}, [isErrorLoaded]);

	const [packageAvailability, setPackageAvailability] = useState(null);

	const [actionType, setActionType] = useState("");
	const [formData, setFormData] = useState({});
	const [paymentModalIsOpen, setPaymentModalIsOpen] = useState(false);
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK
		? JSON.parse(process.env.REACT_APP_OTP_CHECK)
		: false;

	const togglePaymentModal = () => {
		setActionType("");
		setFormData({});
		setPaymentModalIsOpen(!paymentModalIsOpen);
	};

	// check Availability
	async function CheckAvailability() {
		const data = {
			product_uuid: uuid,
			children: PackagesGuests?.children != "" ? +PackagesGuests?.children : 0,

			rooms: PackagesGuests?.guests.map((item) => ({
				type: +item.room_value,
				adults_count: +item.room_numbers,
			})),
		};
		
		const checkFlightAbaliabilityRes = await PackageCheckAvailability(data);
		
		console.log(checkFlightAbaliabilityRes);
		if (checkFlightAbaliabilityRes.status === 200) {
			setPackageAvailability(checkFlightAbaliabilityRes?.data);
			if(checkFlightAbaliabilityRes?.data?.booking_type=="auto"){
				togglePaymentModal()
			}else{
				bookOnPayment("debit",PackagesGuests?.currency)
			}
		} else if (checkFlightAbaliabilityRes?.status === 202) {
			setActionType(checkFlightAbaliabilityRes?.data?.actionType);
			setFormData(checkFlightAbaliabilityRes?.data?.data?.formData);
		}
	}

	const submitValidation = () => {
		checkFormErrors();
		if (!isErrorLoaded) {
			setIsErrorLoaded(true);
		} else {
			setIsErrorLoaded(false);
		}
	};
	let basePrice = 0;
	priceRoomsPackages?.adult?.map((item) => {
		basePrice += +item.price;
	});
	if (priceRoomsPackages?.child?.length > 0) {
		priceRoomsPackages?.child?.map(() => {
			basePrice += +details?.PackagesDetails?.product_price?.price_child;
		});
	}

	const currencyCode = details?.PackagesDetails?.product_price?.currency_code;
	const productDiscounts = details?.PackagesDetails?.product_price?.discounts;

	function calcDiscountPrices(price) {
		const productDiscountTypeId = productDiscounts?.[0]?.discount_type?.id;
		const productDiscountAmount = productDiscounts?.[0]?.discount;
		const percentageDiscountId = 1;
		const fixedDiscount = 2;
		let priceAfterDiscount;
		let discount = 0;
		if (productDiscountTypeId === percentageDiscountId) {
			priceAfterDiscount = price - +price * (+productDiscountAmount / 100);
			discount = +price * (+productDiscountAmount / 100);
		} else if (productDiscountTypeId === fixedDiscount) {
			priceAfterDiscount = price - +productDiscountAmount;
			discount = productDiscountAmount;
		} else {
			priceAfterDiscount = price;
			discount = 0;
		}
		return { priceAfterDiscount: +priceAfterDiscount, discount: +discount };
	}
	const handleTravelers = (value, index, name, valueTraveler) => {
		let clone = valueTraveler;
		clone[index][name] = value;
		if (valueTraveler === travelersAdultsList) {
			setTravelersAdultsList([...clone]);
		} else {
			setTravelersChildList([...clone]);
		}
	};
	return (
		<div className="container">
			<div className="col-md-12 mt-4">
				<div className="row mt-3">
					<div className="col-md-9">
						<p className="Review_title ">{t.contactInformation}</p>
						<ContactInformation
							phoneCode={phoneCode}
							setPhoneCode={setPhoneCode}
							countries={countries}
							userInfoExist={userInfoExist}
							userInfo={allDataLogin}
						/>

						<p className="Review_title mt-3">{t.travelersDetails}</p>
						<div className="ContactInfo">
							<div className="col-md-12 px-0">
								<>
									{travelersAdultsList?.length > 0
										? travelersAdultsList.map((adult, index) => {
												return (
													<Travelers
														key={index + "key"}
														index={index}
														title={t.adults}
														type="adults"
														setErrors={setErrors}
														errors={errors}
														handleTravelers={handleTravelers}
														valueTraveler={travelersAdultsList}
														countries={countries}
													/>
												);
										  })
										: null}
								</>

								{/* <>
									{travelersChildList?.length > 0
										? travelersChildList.map((child, index) => {
												return (
													<Travelers
														key={index + "key"}
														index={index}
														title={t.children}
														type="childs"
														setErrors={setErrors}
														errors={errors}
														handleTravelers={handleTravelers}
														valueTraveler={travelersChildList}
													/>
												);
										  })
										: null}
								</> */}
							</div>
						</div>

						<ItinareyPackage details={details.PackagesDetails} />

						<div className="d-flex w-100 justify-content-end">
							<button
								color="success"
								variant="contained"
								className="btn col-12 col-6 mt-2 mb-4 text-light"
								type="submit"
								style={{ background: "#10A711" }}
								onClick={submitValidation}
							>
								{t.bookNow}
							</button>
						</div>
					</div>
					<div className="col-md-3">
						{/***Summary */}
						<div
							style={{
								color: "#0F3E5E",
								fontWeight: "800",
								fontSize: "15px",
								marginY: "7px",
								marginX: "10px",
							}}
						>
							{t.summary}
						</div>
						<div className={`package_summary p-3`}>
							{PackagesGuestsLocalStorage?.guests.map((item, index) => (
								<div key={index} className="d-flex justify-content-between">
										<>
										<span key={index}>
										{item?.room_numbers}{`X ${inventory.messages.Adult} `}
										<span style={{ fontSize: "12px", color: "#0F3E5E" }}>
											({item?.room_type})
										</span>
									</span>
									<span>
									{+item?.price?.toFixed(2)} {PackagesGuestsLocalStorage?.currency}
								</span>
								</>
									
									{/* <span>
										{item?.room_value}{" "}
										<span style={{ fontSize: "12px", color: "#0F3E5E" }}>
											{item?.room_type}
										</span>
									</span>
									<span>
										{item?.price} {PackagesGuests?.currency}
									</span> */}
								</div>
							))}
						</div>
						{/***Price Summary */}
						<div
							style={{
								color: "#0F3E5E",
								fontWeight: "800",
								fontSize: "15px",
								marginY: "7px",
								marginX: "10px",
							}}
						>
							{t.priceSummary}
						</div>
						<div className={`package_summary p-3`}>
							<div className="d-flex justify-content-between my-1">
								<span>{t.basePrice}</span>
								<span>
								
										{PackagesGuests?PackagesGuests?.guests.reduce(
										(total, obj) => +obj.price + total,
										0
									):PackagesGuestsLocalStorage?.guests.reduce(
										(total, obj) => +obj.price + total,
										0
									)}{PackagesGuestsLocalStorage?.currency}
								</span>
							</div>

							{/* {productDiscounts?.length > 0 ? (
								<div className="d-flex justify-content-between my-1">
									<span>{t.discount}</span>
									<span>
										-{calcDiscountPrices(+basePrice)?.discount?.toFixed(2)}{" "}
										{currencyCode}
									</span>
								</div>
							) : null} */}
							<hr
								style={{ borderColor: "rgba(0, 0, 0, 0.5)" }}
								className="my-1"
							/>
							<div
								className="d-flex justify-content-between my-1"
								style={{ color: "#0F3E5E", fontWeight: "700" }}
							>
								<span>{t.totalPackagePrice}</span>
								<span>
									{PackagesGuests?PackagesGuests?.guests.reduce(
										(total, obj) => +obj.price + total,
										0
									):PackagesGuestsLocalStorage?.guests.reduce(
										(total, obj) => +obj.price + total,
										0
									)}
								
									{PackagesGuestsLocalStorage?.currency}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
			{paymentModalIsOpen && (
				<NewPaymentAction
					isOpen={paymentModalIsOpen}
					toggleModal={togglePaymentModal}
					canPayLater={packageAvailability?.canPayLater}
					formData={formData}
					actionType={actionType}
					Price={+packageAvailability?.provider_total_price}
					currency={packageAvailability?.provider_currency}
					secondCurrency={PackagesGuests?.currency}
					OTP={OTP_CHECK}
					onPayment={bookOnPayment}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
		</div>
	);
}
