
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Locale from "translations";
import trnasferTourBgSrc from "assets/images/stationery-pins.png";
import ShowForPermission from 'helpers/showForPermission';
import carColored from "assets/images/new-transportation-colored.svg";
import TransferSearchForm from './TransferSearchForm';
import { useRef, useState } from 'react';
import classNames from 'classnames';
import TransportationSearch from '../Transportation/TransportationSearch';


export default function TransferSearch() {
	const { marketPlace } = Locale;
	const divHigth = useRef({});
	let clientHeight = divHigth?.current?.clientHeight;
	const [activeTab, setActiveTab] = useState("1");
	const toggleTap = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};


	return (
		<div className="market-search transport-market-search mb-4">
			<div className="main-tabs w-100 p-0" ref={divHigth}>
				<Nav tabs className="align-items-center main-nav">
					<ShowForPermission
						permission={[
							"View-Marketplace-Transfer",
							"Manage-Marketplace-Transfer",
						]}
					>
						<NavItem>
							<NavLink
								className={classNames({ active: activeTab === "1" })}
								onClick={() => {
									toggleTap("1");
								}}
							>
								<img
									className="px-1"
									src={carColored}
									alt={marketPlace.messages.transfer}
									srcSet=""
									width="30px"
								/>
								{marketPlace.messages.globalTransfer}
							</NavLink>
						</NavItem>
						{/* <NavItem>
							<NavLink
								className={`${classNames({ active: activeTab === "2" })} m-0 text-center rounded-0`}
								onClick={() => {
									toggleTap("2");
								}}
							>
								<img
									className="px-1"
									src={carColored}
									alt={marketPlace.messages.transportation}
									srcSet=""
									width="30px"
								/>
								{marketPlace.messages.umrahTransfer}
							</NavLink>
						</NavItem> */}

					</ShowForPermission>
				</Nav>

				<div className="heading col-md-10 py-3 m-auto">
					<h5 className=" mt-3 search-title terms-color text-uppercase">
						{marketPlace.searchAndBook}
					</h5>
					<h3 className=" mb-2 ">{marketPlace.messages.NewDeals}</h3>
				</div>

				<TabContent activeTab={activeTab}>

					<TabPane tabId="1">
						<TransferSearchForm />
					</TabPane>
					<TabPane tabId="2">
						<TransportationSearch />
					</TabPane>
				</TabContent>

			</div>

			<div className="search-banner"
				style={{
					backgroundImage: `url(${trnasferTourBgSrc})`,
					height: `${clientHeight}px`,
				}}
			></div>
		</div>
	);
}
