import arFLag from "assets/images/ar-flag.svg";
import enFLag from "assets/images/en-flag.svg";
import { useSBSDispatch, useSBSState } from "context/global";
import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown
} from "reactstrap";
import Locale from "./LanguageSwitcher.locale";
export default function LanguageSwitcher() {
	const { locale } = useSBSState();
	Locale.setLanguage(locale);
	const dispatch = useSBSDispatch();
	return (
		<UncontrolledDropdown className="lang-dropdown p-0">
			<DropdownToggle caret className="btn-light p-0">
				<img
					className="img-lang px-2"
					src={locale === "ar" ? arFLag : enFLag}
					width="40"
					alt="lang"
				/>
				{Locale[locale]}
			</DropdownToggle>

			<DropdownMenu className="lang-dropdown-item">
				{locale === "ar" ? (
					<DropdownItem
						onClick={() => {
							if (locale === "ar") {
								dispatch({ type: "setLocale", payload: "en" });
								dispatch({ type: "setLoading", payload: true });
							}
						}}
					>
						<img className="img-lang px-2" src={enFLag} width="40" alt="lang" />
						<span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
							English
						</span>
					</DropdownItem>
				) : (
					<DropdownItem
						onClick={() => {
							if (locale === "en") {
								dispatch({ type: "setLocale", payload: "ar" });
								dispatch({ type: "setLoading", payload: true });
							}
						}}
					>
						<img className="img-lang px-2" src={arFLag} width="40" alt="lang" />
						<span className={`${locale === "en" ? "ml-2" : "mr-2"}`}>
							العربية
						</span>
					</DropdownItem>
				)}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
}
