import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import axios from "axios";
import WalletCurrenciesPagination from "./WalletCurrenciesPagination";
import WalletCurrenciesFilter from "./WalletCurrenciesFilter";

export default function WalletCurrenciesTable(props) {
	const [TeamManageList, setTeamManageList] = useState([]);

	const [searchValue, setSearchValue] = useState("");

	useEffect(() => {
		axios.get("../walletCurrenciesAdmin.json").then((res) => {
			setTeamManageList(res.data.teamManageList);
		});
	}, []);

	const removeItem = (index) => {
		setTeamManageList(TeamManageList.filter((k, i) => i !== index));
	};

	const memberStatus = (status) => {
		if (status === "Active") {
			return "Active";
		} else {
			return "Deactive";
		}
	};

	const memberCheck = (status) => {
		if (status === 0) {
			return "fa-stop text-danger";
		} else {
			return "fa-play text-success";
		}
	};

	//Edit New Member
	// const { classNameEdit } = props;
	const [modalEdit, setModalEdit] = useState(false);
	const toggleEdit = () => setModalEdit(!modalEdit);

	//Filter Currency Name
	const filterCurrencyName = ({ currencyName }) => {
		return currencyName.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1;
	};

	// Reset Filter
	const resetFilter = () => {
		setSearchValue("");
	};

	const AllProducts = TeamManageList.filter(filterCurrencyName).map(
		(teamManageList, index) => {
			return (
				<tr key={teamManageList.id}>
					<th scope="row">{teamManageList.id}</th>
					<td>{teamManageList.currencyName}</td>
					<td>{teamManageList.currencyCode}</td>
					<td>{teamManageList.selectedCountries}</td>
					<td>{teamManageList.selectedCompanies}</td>
					<td>
						<p
							className={`${memberStatus(teamManageList.status)} member-status`}
						>
							{memberStatus(teamManageList.status)}
						</p>
					</td>
					<td className="actions">
						<i
							className="fas fa-edit fa-fw text-primary"
							onClick={toggleEdit}
						></i>
						<i
							className={`fas fa-fw ${memberCheck(teamManageList.checkICon)}`}
						></i>
						<i
							className="fas fa-trash-alt fa-fw text-danger"
							onClick={() => removeItem(index)}
						></i>
					</td>
				</tr>
			);
		}
	);

	return (
		<>
			<WalletCurrenciesFilter
				onSearch={setSearchValue}
				value={searchValue}
				onReset={resetFilter}
			/>
			<div className="product-builder-list">
				<div className="product-header-list p-0">
					<h6 className="font-weight-bold dark-blue-color">
						Wallet Currencies
					</h6>
					<div className="product-buttons">
						<span>Show</span>
						<select className="py-1 px-3 mx-2">
							<option>10</option>
							<option>15</option>
							<option>25</option>
						</select>
						<span>Entries</span>
					</div>
				</div>

				<Table striped className="custom-table">
					<thead>
						<tr>
							<th>#</th>
							<th>Currency Name</th>
							<th>Currency Code</th>
							<th>Selected Countries</th>
							<th>Selected Companies</th>
							<th>Status</th>
							<th width="11%">Tools</th>
						</tr>
					</thead>
					<tbody>{AllProducts}</tbody>
				</Table>

				<WalletCurrenciesPagination />
			</div>
		</>
	);
}
