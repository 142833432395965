import React, { useState } from "react";
import walletImgSrc from "assets/images/inventory/walletSm.svg";
import walletImgWhiteSrc from "assets/images/inventory/walletSmWhite.png";
import Locale from "translations";
import ShowForPermission from "helpers/showForPermission";
import CancelModel from "./CancelModel";
import TransferPayment from "modules/market-place/TransferResponse/Modals/TransferPayment";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { transferPaylater } from "services/inventory";
import { store } from "react-notifications-component";
import moment from "moment";
import NewPaymentAction from 'components/NewPaymentAction';

export default function TransferBookingSummary({
	Details,
	setTransferDetails,
}) {
	console.log(Details,"Details");
	const { marketPlace, inventory } = Locale;
	const [modalCancel, setModalCancel] = useState(false);
	const [paymentModal, setPaymentModal] = useState(false);

	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});
	const { id } = useParams();
	const toggleModalCancel = () => setModalCancel((prev) => !prev);
	const handelpaymentModal = () => {
		setActionType('')
		setFormData({})
		setPaymentModal((prev) => !prev);
	};
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const pay = async (paymentMethod, currency,otp) => {
		let data = { payment_method: paymentMethod, currency,otp:otp };
		const response = await transferPaylater(id, data);
		if (response.status === 200) {
			setPaymentModal(false);
			setTransferDetails(response.data.data);
			store.addNotification({
				title: "Payment Done",
				message: "Payment Done successfully",
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 3000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}else if (response?.status === 202){
      setActionType(response?.data?.actionType)
      setFormData(response?.data?.data?.formData)

    }
	};
	const confirmedStatus = Details?.status === "CONFIRMED";

	const items = Details?.items ?? Details?.cartItems;

	const cancelDate =
		items &&
		items.every((item) => {
			return moment().isBefore(moment(item.pickup_time));
		});

	const canCancel = confirmedStatus &&  cancelDate;

	const confirmPickupUrl = items && items.find((i) => i.confirm_pickup_url);
	console.log("Details",Details);
	return (
		<div className="col-md-4 mt-2 mt-md-0">
			<div className="transfer-booking-details-header">
				<h2>{marketPlace.messages.bookingSummary}</h2>
			</div>
			{/* cart items */}
			<div className="p-2 mt-3 border-1-gray bg-white">
				{items && items?.length > 0
					? items.map((item, index) => {
							return item.status !== "CANCELED" ? (
								<>
									<div className="d-flex transfer-booking-summary-item">
										<div className="col-md-8">
											<p className="font-weight-bold">
												{item?.type} {item?.category} {item?.vehicle}
											</p>
											<div className="text-muted">
												<p>
													{marketPlace.From}: {item?.from_name}
												</p>
												<p>
													{marketPlace.To}: {item?.to_name}
												</p>
											</div>
										</div>
										<div>
										<p className="text-body">{marketPlace.paidprice}:</p>
											<span className="col-md-4 font-weight-bold text-center">
												{item?.price} {item?.currency}
											</span>

											{/* {item?.currency !== item?.provider_currency && (
												<>
													<p className="text-body">{marketPlace.providerprice}:</p>
													<p className="col font-weight-bold text-14">
														{(+item?.provider_total_price).toFixed(2)}{" "}
														{item?.provider_currency}
													</p>
												</>
											)} */}
										</div>
									</div>
									{index !== items?.length - 1 ? <hr /> : null}
								</>
							) : null;
					  })
					: null}
			</div>


			<ShowForPermission permission="Manage-Inventory-Transfer">
				{canCancel && (
					<div
						className="text-light-danger tour-booking-action mt-2"
						onClick={toggleModalCancel}
					>
						<i className="fas fa-ban text-light-danger"></i>
						<span className="mx-1">{inventory.messages.cancelAllTransfer}</span>
					</div>
				)}
			</ShowForPermission>

			{/* total price  */}
			<div
				className={`transfer-booking-totalprice mt-2`}
			>
				<div className="d-flex">
					<img src={walletImgSrc} alt="wallet" className="img-fluid" />
					<p className="d-flex flex-column mx-2 ">
						<span className="text-dark-blue">
							{inventory.messages.ReservationTotalCost}{" "}
						</span>

						<span className="text-success font-weight-bold h5">
							{Details?.currency == Details?.provider_currency ? (
								<span>
									{Details?.total_amount?.toFixed(2)} {Details?.currency}{" "}
								</span>
							) : (
								<>
									<span>
										{Details?.total_amount?.toFixed(2)} {Details?.currency}
									</span>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
										{marketPlace.providerprice} :
									</span>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
										{Details?.provider_total_price} {Details?.provider_currency}
									</span>
								</>
							)}
						</span>
					</p>
				</div>

				<ShowForPermission permission="Manage-Inventory-Transfer">
					{ Details.status === "PENDING" && (
						<button className="btn" onClick={handelpaymentModal}>
							<img src={walletImgWhiteSrc} alt="wallet white" />
							<span className="mx-2">{inventory.messages.payNow}</span>
						</button>
					)}
				</ShowForPermission>
				<ShowForPermission permission="Manage-Inventory-Transfer">
					{
						Details.status === "CONFIRMED" &&
						confirmPickupUrl && (
							// eslint-disable-next-line jsx-a11y/no-redundant-roles
							<a
								className="btn bg-danger px-1"
								rel="noreferrer"
								role="link"
								href={`${confirmPickupUrl.confirm_pickup_url}`}
								target="_blank"
							>
								<i className="far fa-calendar-check h5 m-0"></i>
								<span className="mx-1 text-nowrap ">{inventory.messages.confirmPickup}</span>
							</a>
						)}
				</ShowForPermission>
			</div>

			<CancelModel
				details={Details}
				model={modalCancel}
				toggleModel={toggleModalCancel}
				setTransferDetails={setTransferDetails}
			/>

			{paymentModal && (
				<NewPaymentAction
					actionType={actionType}
					formData={formData}
					isOpen={paymentModal}
					toggleModal={handelpaymentModal}
					onPayment={pay}
					canPayLater={false}
					Price= {Details?.provider_total_price}
					currency= {Details?.provider_currency}

					secondPrice= {Details?.total_amount}
					secondCurrency= {Details?.currency}
					OTP={OTP_CHECK}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}
		</div>
	);
}
