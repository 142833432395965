import React, { useState } from "react";
import walletImgSrc from "assets/images/inventory/walletSm.svg";
import walletImgWhiteSrc from "assets/images/inventory/walletSmWhite.png";
import Locale from "translations";
import moment from "moment";
import CancelModel from "./CancelModel";
import ShowForPermission from "helpers/showForPermission";
import { getPreCancelTour, payLaterTour } from "services/tours";
import { useParams } from "react-router-dom";
import ToursPaymentModal from "modules/market-place/ToursResponse/Summary/ToursPaymentModal";
import NewPaymentAction from 'components/NewPaymentAction';

export default function ToursBookingSummary({
	payNow,
	bookingDetails,
	setBookingDetails,
}) {
	const { marketPlace, inventory } = Locale;
	const { id } = useParams();
	const [modalCancel, setModalCancel] = useState(false);
	const [isOpenpaymentModal, setIsOpenPaymentModal] = useState(false);
	const [preCancel, setPreCancel] = useState();
	const toggleModalCancel = () => setModalCancel(!modalCancel);
	const OTP_CHECK = process.env.REACT_APP_OTP_CHECK? JSON.parse(process.env.REACT_APP_OTP_CHECK):false

	const togglePaymentModal = () => {
		setActionType('')
		setFormData({})
		setIsOpenPaymentModal(!isOpenpaymentModal)
	};
	const [actionType, setActionType] = useState('');
  const [formData, setFormData] = useState({});
	const canceledStatus = bookingDetails.status === "canceled";
	const expiredStatus = bookingDetails.status === "expired";
	const cancelDate =
		bookingDetails.reservations &&
		bookingDetails.reservations.every((item) => {
			return moment().isBefore(moment(item.start_date));
		});
	const canCancel =
		cancelDate &&
		!canceledStatus &&
		!expiredStatus &&
		bookingDetails?.payment_status !== "pending";
	payNow =
		bookingDetails?.payment_status === "pending" &&
		!canceledStatus &&
		!expiredStatus &&
		moment().isBefore(moment(bookingDetails?.auto_cancel_date));
	const amountDetails = {
		totalPrice: bookingDetails?.total_price,
		currency: bookingDetails?.currency,
	};
	const confirmedReservations = bookingDetails?.reservations?.filter(
		(reservation) => reservation?.status === "confirmed"
	);

	async function getPreCancel() {
		const res = await getPreCancelTour(id);
		if (res.status === 200) {
			setModalCancel(true);
			setPreCancel(res?.data?.data);
		} else {
			setModalCancel(false);
		}
	}

	async function handlePayLater(payment_method, currency,otp) {
		
		const payRes = await payLaterTour(id, {
			paymentMethod: payment_method,
			currency,
			otp:otp
		});
		if (payRes?.status == 200||payRes?.status == 201 ) {
			setBookingDetails(payRes?.data?.tour);
			togglePaymentModal();
		}else if (payRes?.status === 202){
			
      setActionType(payRes?.data?.actionType)
      setFormData(payRes?.data?.data?.formData)

    }
	}

	return (
		<div className="col-md-4 mt-2 mt-md-0">
			<div className="transfer-booking-details-header">
				<h2>{marketPlace.messages.bookingSummary}</h2>
			</div>
			{/* cart items */}
			{confirmedReservations?.length > 0 ? (
				<div className="p-2 mt-3 border-1-gray bg-white">
					{confirmedReservations?.map((reservation, index) => (
						<div key={`summary-${reservation?.details?.id}`}>
							<div className="d-flex justify-content-between transfer-booking-summary-item">
								<div className="col-md-8">
									<p className="font-weight-bold text-14">
										{reservation?.details?.name}
									</p>
									<div className="transfer-booking-item-info flex-wrap">
										<i className="fas fa-map-marker-alt"></i>
										<p className="mx-1">
											{reservation?.details?.destinations?.map((city) => {
												return (
													<span key={city?.code}>
														{city?.name} ({city?.code}),{" "}
													</span>
												);
											})}
										</p>
									</div>

									<div className="transfer-booking-item-info flex-wrap">
										<i className="far fa-calendar-alt"></i>
										<span className="mx-1">
											{moment(reservation?.start_date).format("DD/MM/YYYY")} (
											{moment(reservation?.start_date).format("dddd")})
										</span>
									</div>

									<div className="transfer-booking-item-info flex-wrap">
										<i className="far fa-user"></i>
										<span className="mx-1">
											{reservation?.adults} {reservation?.adults > 1 ? inventory.messages.adults : inventory.messages.Adult}
										</span>
										{reservation?.children?.length > 0 ? (
											<span>
												{reservation?.children?.length}{" "}
												{inventory.messages.children} (
												{reservation?.children?.map((age, index) => {
													return (
														<span key={`age-${index}-${age}`}>
															{age}
															{index !== reservation?.children?.length - 1
																? ", "
																: " "}
														</span>
													);
												})}
												{marketPlace.yearsOld} )
											</span>
										) : null}
									</div>
								</div>
								<div>
								<p className="text-body">{marketPlace.paidprice}</p>
									<p className="font-weight-bold text-14">
										{(+reservation?.price).toFixed(2)} {reservation?.currency}
									</p>
									{/* {reservation?.currency !== reservation?.provider_currency && (
										<>
											<p className="text-body">{marketPlace.providerprice}</p>
											<p className="font-weight-bold text-14">
												{(+reservation?.provider_total_price).toFixed(2)}{" "}
												{reservation?.provider_currency}
											</p>
										</>
									)} */}
								</div>
							</div>

							{index !== confirmedReservations?.length - 1 && <hr />}
						</div>
					))}
				</div>
			) : null}

			<ShowForPermission permission="Manage-Inventory-Tours">
				{canCancel && (
					<div
						className="text-light-danger tour-booking-action mt-2"
						onClick={getPreCancel}
					>
						<i className="fas fa-ban text-light-danger"></i>
						<span className="mx-1">{inventory.messages.cancelAllTours}</span>
					</div>
				)}
			</ShowForPermission>
			{/* total price  */}
			<div
				className={`transfer-booking-totalprice mt-2 ${
					payNow ? "" : "justify-content-end"
				}`}
			>
				<div className="d-flex">
					<img src={walletImgSrc} alt="wallet" className="img-fluid" />
					<p className="d-flex flex-column mx-2 ">
						<span className="text-dark-blue text-14">
							{inventory.messages.ReservationTotalCost}{" "}
						</span>
						<span className="text-success font-weight-bold h5">
							{bookingDetails?.currency === bookingDetails?.provider_currency ? (
								<span>
									{bookingDetails?.total_price?.toFixed(2)}{" "}
									{bookingDetails?.currency}{" "}
								</span>
							) : (
								<>
									<span>
										{bookingDetails?.total_price?.toFixed(2)}{" "}
										{bookingDetails?.currency}
									</span>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
											{inventory.messages.providerTotalAmount}:
									</span>
									<span
										style={{
											fontSize: "14px",
											display: "block",
											textTransform: "capitalize",
											color: "gray",
										}}
									>
										{bookingDetails?.provider_total_price}{" "}
										{bookingDetails?.provider_currency}
									</span>
								</>
							)}
						</span>
					</p>
				</div>
				<ShowForPermission permission="Manage-Inventory-Tours">
					{payNow && (
						<button className="btn" onClick={togglePaymentModal}>
							<img src={walletImgWhiteSrc} alt="wallet white" />
							<span className="mx-2">{inventory.messages.payNow}</span>
						</button>
					)}
				</ShowForPermission>
			</div>
			{isOpenpaymentModal && (
				<NewPaymentAction
					isOpen={isOpenpaymentModal}
					toggleModal={togglePaymentModal}
					canPayLater={false}
					formData={formData} 
					OTP={OTP_CHECK}
					actionType={actionType}
					Price={bookingDetails?.total_price}
					currency={bookingDetails?.currency}
					onPayment={handlePayLater}
					setActionType={setActionType}
					setFormData={setFormData}
				/>
			)}

			{!canceledStatus && modalCancel && (
				<CancelModel
					model={modalCancel}
					toggleModel={toggleModalCancel}
					details={confirmedReservations}
					cancelType={"all-items"}
					preCancel={preCancel}
					amount={amountDetails}
					setBookingDetails={setBookingDetails}
				/>
			)}
		</div>
	);
}
