import hotelGold from " ../../assets/images/icons/hotel-gold.svg";
import nights from " ../../assets/images/icons/nights.svg";
import { useProductsBuilderDispatch } from "context/productsBuilder";
import generateUniqueID from "helpers/generateUniqueID";
import moment from "moment";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Locale from "../../../../translations";

export default function HotelPreview(props) {
	const [hotelPreview, setExternalHotelPreview] = useState({});
	const { productElements, productsBuilder } = Locale;
	const history = useHistory();
	const dispatch = useProductsBuilderDispatch();
	useEffect(() => {
		setExternalHotelPreview(props.element);
	}, []);

	return (
		<div className=" product-build__product-collpase itenary-box d-flex justify-content-center align-items-center flex-row">
			<div className="img-circle-wrapper icon-pro-Itinerary">
				<img src={hotelGold} alt="" srcset="" />
				{/* 							<span className="icx icx-hotel"></span>
				 */}{" "}
			</div>
			<div
				className="button-collapse"
				style={{ border: hotelPreview.fail ? "2px solid #C30101" : "" }}
			>
				<div className="title-style-container">
					<div className="right-items-container">
						{hotelPreview.fail ? (
							<>
								<div
									className="product-content-items px-4 p-2"
									style={{ minHeight: "80px" }}
								>
									<p
										className="w-100"
										style={{
											fontSize: "18px",
											fontWeight: "700",
											color: "#C30101",
										}}
									>
										{hotelPreview.title}

										{!props.isShow && (
											<button
												className="btn float-right btn-lg text-"
												style={{
													fontSize: "14px",
													background: "#C30101",
													color: "#fff",
													textTransform: "capitalize !important",
												}}
												onClick={() => {
													dispatch({
														type: "appendItem",
														payload: {
															id: generateUniqueID(),
															item: { name: "hotel", id: 6 },
														},
													});
													history.push("/products-builder/create", {
														tab: "1",
													});
												}}
											>
												{productsBuilder.add} +
											</button>
										)}
									</p>
								</div>
							</>
						) : (
							<div className="product-content-items px-4 p-2">
								<div className="product-country-from">
									<h6 className="blue-txt-item">
										{/* {hotelPreview.itemable?.city?.name} - */}
										{hotelPreview.itemable?.hotel?.name}{" "}
										{[1, 2, 3, 4, 5].map((_, i) => {
											return +hotelPreview.itemable?.hotel?.classification <=
												i ? (
												<i key={i} className="far fa-star"></i>
											) : (
												<i key={i} className="fas fa-star"></i>
											);
										})}
									</h6>
									<p className="htel-date">
										{hotelPreview.itemable?.hotel?.country} /{" "}
										{hotelPreview.itemable?.hotel?.city}
									</p>
									<p className="htel-date">
										{moment(hotelPreview.itemable?.check_in_at).format(
											"YYYY-MM-DD"
										)}
									</p>
								</div>
								<div className="days-box days_preview  nights-icon ">
									<div className="icon">
										<img src={nights} alt="" srcset="" />
										{/* 									<i className="fas fa-moon fa-lg"></i>
										 */}{" "}
									</div>
									{/* 								<div className="vertical"></div>
									 */}{" "}
									<div className="nights  nights-box">
										<span className="text p-1">
											{" "}
											{hotelPreview.itemable?.night_count}{" "}
										</span>
										<span className="text">{productElements.nights}</span>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
