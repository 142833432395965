import React from 'react';

function TitlePackage({ details }) {
  return (
    <div className="cardtitle col-md-8 col-12">
      <div className="titlehead w-100">
        <p className='font-weight-bold'>{details?.trip_name}</p>
        {+details?.product_classification?.id > 0 ? (
          <div className="hotel-star-rate d-inline px-2">
            {[...Array(+details?.product_classification?.id).keys()].map((i) => (
              <i key={i} className="fas fa-star" aria-hidden="true"></i>
            ))}
          </div>
        ) : (
          <div className="hotel-star-rate d-inline px-2">
            {[...Array(5)].map((i) => (
              <i key={i} className="fas fa-star" aria-hidden="true"></i>
            ))}
          </div>
        )}
      </div>
    </div>
  )
}

export default TitlePackage