import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import { fetchHotelRecent } from "services/statistics";
import Locale from "translations";

export default function HotelTable(props) {
	const { inventory, productsBuilder, statistics } = Locale;
	const [ProductList, setProductList] = useState([]);

	const fetchData = async (limit = 5) => {
		const { data } = await fetchHotelRecent(limit);
		setProductList(data.data);
	};

	useEffect(() => {
		fetchData();
	}, []);

	const AllProducts = ProductList.map((productItem, index) => {
		return (
			<tr key={productItem.id}>
				<td>{productItem.brn}</td>
				<td width="30%" title={productItem.hotel_name}>
					{productItem.hotel_name.substring(0, 22)}
				</td>

				{/* <td>{productItem.room_type}</td> */}
				<td>{productItem.rooms_number}</td> 
				 
				<td>{productItem.created_at}</td>
				<td>
					{productItem.total_price} {inventory.messages.sar}
				</td>
				<td>
				{+productItem?.gds != 4 ? (
						<Link
							className="text-yellow"
							to={{
								pathname: `/Hotels/inventory/${productItem?.reservation_num}`,
								state: {
									tab: "hotel",
									hotel: productItem?.hotel_name,
									promo: null,
									voucher: null,
									productItems: productItem,
								},
							}}
						>
							<i className="fas fa-eye "></i>{inventory.messages.view}
							{/* 							<img src={openproduct} alt="" srcset="" />
							 */}{" "}
						</Link>
					) : (
						<Link
							className="text-yellow"
							to={`/Hotels/inventory/${productItem?.reservation_num}`}
						>
							{/* <i class="fas fa-angle-right"></i> */}
								<i className="fas fa-eye "></i>{inventory.messages.view}
							{/* 							<img src={openproduct} alt="" srcset="" />
							 */}{" "}
						</Link>
					)}
				</td>
			</tr>
		);
	});

	return (
		<div className="product-builder-list">
			{ProductList.length > 0 ? (
				<div className="product-header-list p-3 bg-white">
					<div>
						<h6 className="font-weight-bold dark-blue-color">
							{statistics.recentHotel}
						</h6>
						<p>5 {statistics.bookingMonth}</p>
					</div>
					<ShowForPermission
						permission={[
							"View-Inventory-Hotels",
							"Manage-Inventory-Hotels",
							"View-Inventory-Transfer",
							"Manage-Inventory-Transfer",
							"View-Inventory-Tours",
							"Manage-Inventory-Tours",
							"Manage-Inventory-Tours",
							"View-Inventory-Flights",
							"View-Inventory-Visa",
							"Manage-Inventory-Visa",
						]}
					>
						<div className="product-buttons">
							<Link
								className="btn btn-link text-yellow "
								to={{
									pathname: `/inventory`,
									state: { tab: "1" },
								}}
							>
								{statistics.viewBooking} <i class="fas fa-angle-right"></i>
							</Link>
						</div>
					</ShowForPermission>
				</div>
			) : null}
			<div className="table-container">
				<Table striped className="table-update  bg-white ">
					<thead>
						<tr>
							<th>{inventory.messages.brn}</th>
							<th width="30%">{inventory.messages.hotelName}</th>

							{/* <th>{inventory.messages.roomType}</th> */}
							<th>{inventory.messages.noOfRooms}</th>
							<th>
								<i class="fas fa-sort"></i>
								{inventory.messages.bookingDate}
							</th>
							<th>
								<i class="fas fa-sort"></i>
								{inventory.messages.totalPrice}
							</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{ProductList.length > 0 ? (
							AllProducts
						) : (
							<tr>
								<td colSpan="11">
									<div className="product-build__product-no-data">
										<i className="fas fa-info-circle fa-lg"></i>{" "}
										<h4>{productsBuilder.noResult}</h4>
									</div>
								</td>
							</tr>
						)}
					</tbody>
				</Table>
			</div>
		</div>
	);
}
