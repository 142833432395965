import React, { useEffect, useState } from "react";
import { useMarketplaceState } from "context/marketplace";
import SelectField from "components/Form/SelectField/SelectField";
import TextField from "components/Form/TextField/TextField";
import Locale from 'translations';

function Guests({
	edit,
	setedit,
	adultState,
	setAdultState,
	obj,
	setObj,
	setRoooomOpt,
	setIsSaved,
	// calcDiscountPrices,
	currencyCode,
	//productDiscounts,
	setGuestsState,
	GuestsState,
	details,
	setGuestsChildrenState,
	GuestsChildrenState,
}) {
	// const details = useMarketplaceState();
	const {inventory, marketPlace, packages} = Locale;

	const [adRoomType, setAdRoomType] = useState();
	const [chRoomType, setChRoomType] = useState();
	const handeledit = () => {
		setedit(!edit);
	};
	let typeRooms = {
		1: "Single Room",
		2: "Double Room",
		3: "Triple Room",
		4: "Quad Room",
		5: "Quintuple Room",
		6: "Hexagonal Room",
		7: "Seven Room",
		8: "Eight Room",
	};

	let sumRoom = 0;
	const countRoom = details?.PackagesDetails?.roomsAvailabilities?.map(
		(item) => (sumRoom += item?.room_remaining_availability)
	);
	console.log(details);
	let numberOfRoom = [...Array(+sumRoom + 1).keys()].map((item) => {
		return item != 0 ? item : "";
	});
	let numberOfRoomChild = [...Array(+sumRoom).keys()].map((item) => {
		return item;
	});

	let roomTypeOption = details?.roomsAvailabilities?.map((item) => {
		if (item?.room_remaining_availability !== 0) {
			return {
				name: typeRooms[item?.room_type],
				price: item.total_rooms_price,
				total_price: item.adult_price,
				price_room_service: item?.total_items_price_without_rooms,
				availability: item?.room_remaining_availability,
				value: item?.room_type,
				id: item?.room_type,
			};
		}
	}).filter((item)=> item);

	useEffect(() => {
		let opt = details?.PackagesDetails?.roomsAvailabilities?.map((item) => {
			if (item?.room_remaining_availability !== 0) {
				return {
					name: typeRooms[item?.room_type],
					//price: item.adult_price,
					price: item.original_price,
					availability: item?.room_remaining_availability,
					value: typeRooms[item?.room_type],
				};
			}
		});
		setRoooomOpt(opt);
	}, [obj, adultState]);

	let adultOPtion = numberOfRoom?.map((item, index) => {
		return { value: item, name: item };
	});
	let childOption = numberOfRoomChild?.map((item, index) => {
		return { value: item, name: item };
	});
	const handleGuest = (room_type) => {
		let clone = [...GuestsState];
		let condition = clone.filter((item) => item.room_type == room_type?.name);
		if (condition.length == 0) {
			clone.push({
				room_type: room_type?.name,
				room_value: room_type?.value,
				room_numbers: "",
				room_availability: room_type?.availability,
				price_room: room_type?.price,
				price_room_with_service: room_type?.price_room_service,
				price: 0,
			});
			setGuestsState([...clone]);
		}
	};
	const removeRoom = (index) => {
		let clone = [...GuestsState];
		clone.splice(index, 1);
		setGuestsState([...clone]);
	};

	const isSaveDisabled = GuestsState.filter((item) => item.room_numbers == "");
	return (
		<>
			<div className={"guestbox"}>
				<div className={"guesthead"}>
					<p style={{ color: "#0F3E5E", fontWeight: "bold" }}>{inventory.messages.guests}</p>
					{edit ? (
						<>
							{/* <p className={"guestnum"}>
								{adultState?.adult} x {"adults"}
							</p> */}
							{
								<p className={"guestnum"}>
									{GuestsChildrenState || 0} x {packages.child}
								</p>
							}
							<button
								onClick={() => {
									handeledit();
									setIsSaved(false);
								}}
								style={{
									fontSize: "15px",
									justifyContent: "center !important",
								}}
								className="btn btn-light text-light-success mt-1"
							>
								<i className="fas fa-pen mx-2"></i>
								{packages.editGuests}
							</button>
						</>
					) : (
						<>
							{/********Number Of Adults********** */}
							<div className="col-md-2">
								<SelectField
									hasLabel={true}
									label={marketPlace.addRoomType}
									// value={adultState?.adult}
									name="adults"
									options={roomTypeOption}
									onChange={(e) => {
										handleGuest(e);
									}}
								/>
							</div>

							{/********Number Of child********** */}

							<div className="col-md-2">
								<TextField
									hasLabel={true}
									label={marketPlace.children}
									placeholder={packages.numberOfChildren}
									value={GuestsChildrenState}
									onChange={(e) => {
										setGuestsChildrenState(e.target.value);
									}}
									disabled={edit}
								/>
							</div>

							<button
								style={{
									color: "#10A711",
									fontSize: "15px",
									justifyContent: "center !important",
								}}
								onClick={() => {
									handeledit();
									setIsSaved(true);
								}}
								className="btn btn-light text-light-success mt-3"
								disabled={
									isSaveDisabled?.length > 0 || GuestsState?.length == 0
								}
							>
								<i className="fas fa-save mx-2" />
								{inventory.messages.save}
							</button>
							{/* <Button
                sx={{ color: "#CE0012", fontSize: "10px" }}
                onClick={handeledit}
                startIcon={
                  <Icon className="material-symbols-outlined">block</Icon>
                }
              >
                {t.cancel}
              </Button> */}
						</>
					)}
				</div>
				<div className={"guestbody"}>
					{/********Room Type  Of Adults********** */}
					{GuestsState?.map((item, index) => (
						<div className={`guestrow flex-wrap`} key={index}>
							<p className={"guestlabel"}>
								{marketPlace.room} {index + 1}
							</p>
							<div className="search_field search_field_half">
								<span
									style={{
										fontSize: "12px",
										position: "absolute",
										top: "10px",
										padding: "0 16px",
										fontWeight: "700",
									}}
								>
									{marketPlace.RoomType}
								</span>
								<SelectField
									class="w-100"
									value={GuestsState[index].room_type}
									placeholder={"select"}
									options={roomTypeOption}
									disabled={true}
								/>
							</div>
							<div className='d-flex flex-column' >
								<div
									className={`search_field search_field_half ${
										GuestsState[index].room_availability <
										GuestsState[index].room_numbers
											? "border-danger"
											: ""
									}`}
									style={{maxWidth:"100%"}}
								>
									<span
										style={{
											fontSize: "12px",
											position: "absolute",
											top: "10px",
											padding: "0 16px",
											fontWeight: "700",
											zIndex: 1500000,
										}}
									>
										{packages.numberOfAdults}
									</span>
									<TextField
										hasLabel={false}
										value={GuestsState[index].room_numbers}
										placeholder={"Enter"}
										onChange={(e) => {
											let clone = [...GuestsState];
											clone[index] = {
												...clone[index],
												room_numbers: e.target.value,
												price:
													Math.ceil(
														+e.target.value / +clone[index].room_value
													) *
														clone[index].price_room *
														+clone[index].room_value +
													+clone[index].price_room_with_service *
														+e.target.value,
											};
											setGuestsState(clone);
										}}
										color={
											GuestsState[index].room_availability <
											GuestsState[index].room_numbers
												? "danger"
												: ""
										}
										errors={
											GuestsState[index].room_availability <
											GuestsState[index].room_numbers
										}
										disabled={edit}
									/>
								</div>
								{GuestsState[index].room_availability <
								GuestsState[index].room_numbers ? (
									<small className="text-danger">{`${packages.adultNumberShouldNotExceed} (${GuestsState[index].room_availability})`}</small>
								) : null}
							</div>
							<div className="search_field search_field_half">
								<div>
									<label htmlFor="departurefrom" className="text-uppercase">
										<span className="mx-2">{inventory.messages.Price}</span>
									</label>
									<p
										style={{
											color: "#10A711",
											marginTop: "5px",
											marginLeft: "10px",
										}}
									>
										{+item?.price?.toFixed(2)}
										<span style={{ fontSize: "10px", mx: "3px" }}>
											{details.currency}
										</span>
									</p>
								</div>
							</div>
							<button className="btn" onClick={() => removeRoom(index)}>
								<i class="fas fa-trash-alt text-danger" />
							</button>
							{GuestsState[index].room_numbers ? (
								<div className="flex-fill p-2">
									{packages.priceIncludesPriceOf} (
									{`${Math.ceil(
										GuestsState[index].room_numbers /
											GuestsState[index].room_value
									)} ${
										Math.ceil(
											GuestsState[index].room_numbers /
												GuestsState[index].room_value
										) > 1
											? packages.rooms
											: packages.room
									} `}
									)
								</div>
							) : null}
						</div>
					))}
				</div>
			</div>
		</>
	);
}

export default Guests;
