import UMRAH from "assets/images/new-umrah.svg";
import VACATIONS from "assets/images/new-vactions.svg";
import background from "assets/images/search-banner.jpg";
import backgroundUMRAH from "assets/images/search-banner2.jpg";
import classnames from "classnames";
import { useSBSState } from "context/global";
import { useMarketplaceDispatch } from "context/marketplace";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import {
	fetchMealPlanLookups,
	fetchRoomTypeLookups,
	fetchRoomViewLookups,
} from "services/marketplace";
import Locale from "translations";
import UmrahTab from "./Umrah";
import Vacations from "./Vacations";
import { fetchCitiesSearch } from "services/marketplace";
import { fetchLookups } from "services/lookups";


export default function Hotels({
	allCountries,
	allCurrenciesData,
}) {
	//Locale
	const divHigth = useRef({});
	let clientHeight = divHigth.current.clientHeight;

	const { marketPlace } = Locale;
	const { permissions, role } = useSBSState();
	const dispatch = useMarketplaceDispatch();
	const hotelInitial = {
		checkIn: moment(new Date()).add(1, "d"),
		checkOut: moment(new Date()).add(2, "d"),
		goingTo: null,
		adult: 1,
		child: 0,
		roomType: null,
		rooms: [{ number_of_adult: null, BoxChild: false }],
		RadioOptions: "WithoutMaqam",
	};

	const transportationInitial = {
		validTo: moment(new Date()).add(1, "d"),
		goingTo: null,
		country: null,
		busType: null,
		busClass: null,
		busCount: 1,
		peopleNumber: 1,
	};
	const [activeTab, setActiveTab] = useState("1");
	const [activeContainerTab, setActiveContainerTab] = useState("1");
	const [mealPlanLookups, setMealPlanLookups] = useState(null);
	const [roomTypesLookups, setRoomTypeLookps] = useState(null);
	const [roomViewsLookups, setRoomViewsLookups] = useState(null);
	const [allCitiesVacation, setAllCitiesVacation] = useState();
	const [allLookups, setAllLookups] = useState([]);

	// const umrah =
	// 	permissions?.includes(
	// 		...["View-Marketplace-Umrah", "Manage-Marketplace-Umrah"]
	// 	) || role === "super-admin";
	const vacation =
		permissions?.includes(
			...["View-Marketplace-Hotels", "Manage-Marketplace-Hotels"]
		) || role === "super-admin";
	// const [transCountries, setTransCountries] = useState(null);
	const [transRoutes, setTransRoutes] = useState(null);
	const [hotels, setHotels] = useState(
		hotelInitial

		// 	{
		// 	validTo: moment(new Date()).add(1, "d")._d,
		// 	goingTo: null,
		// 	country: null,
		// 	busType: null,
		// 	busClass: null,
		// 	busCount: 1,
		// 	peopleNumber: 1,
		// 	checkIn: new Date(),
		// 	checkOut: null,
		// 	adult: 1,
		// 	child: 0,
		// 	roomType: 1,
		// }
	);

	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			setActiveTab("1");
		}
	}, [permissions, role]);
	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
		activeTab === "1"
			? setHotels(hotelInitial)
			: setHotels(transportationInitial);
	};
	// const toggleContainer = (tab) => {
	// 	if (activeContainerTab !== tab) setActiveContainerTab(tab);
	// 	// activeContainerTab === "1"
	// 	// 	? setHotels(hotelInitial)
	// 	// 	: setHotels(transportationInitial);
	// };

	const hotelCountries = [
		{
			code: "SA1",
			name: marketPlace.messages.makkah,
		},
		{
			code: "SA4",
			name: marketPlace.messages.medina,
		},
	];

	// const handleChange = (e, flag) => {
	// 	const name = e.target?.name;
	// 	const value = e.target?.value;
	// 	if (flag) {
	// 		setHotels({
	// 			...hotels,
	// 			country: e["value"], // countries[value],
	// 			goingTo: e.value, //countries[value]?.code,
	// 		});
	// 	} else setHotels({ ...hotels, [name]: value });
	// };

	useEffect(() => {
		activeTab === "1"
			? setHotels(hotelInitial)
			: setHotels(transportationInitial);
		dispatch({
			type: "fetchCountries",
			payload: activeTab === "1" ? hotelCountries : transRoutes,
		});
	}, [activeTab]);

	// fetch lookups
	useEffect(() => {
		async function fetchLookups() {
			const [meals, roomTypes, roomViews] = await Promise.all([
				fetchMealPlanLookups(),
				fetchRoomTypeLookups(),
				fetchRoomViewLookups(),
			]);
			const formatMealPlanLookup = meals?.data?.data?.map((mealPlan) => {
				return { ...mealPlan, label: mealPlan?.name, value: mealPlan?.id };
			});
			const formatRoomTypeLookup = roomTypes?.data?.data?.map((roomType) => {
				return { ...roomType, label: roomType?.name, value: roomType?.id };
			});
			const formatRoomViewLookup = roomViews?.data?.data?.map((roomView) => {
				return { ...roomView, label: roomView?.name, value: roomView?.id };
			});
			setMealPlanLookups(formatMealPlanLookup);
			setRoomTypeLookps(formatRoomTypeLookup);
			setRoomViewsLookups(formatRoomViewLookup);
		}
		fetchLookups();
	}, []);

	useEffect(() => {
		async function fetchCitiesVacation() {
			const countries = await fetchCitiesSearch("is_vacation");
			// const Currencies = await fetchAllCurrencies();
			// let x = Currencies?.map((res) => {
			// 	return { name: res.currency_code, id: res.currency_code };
			// });
			// setAllCurrenciesData(x);
			setAllCitiesVacation(countries);
		}
		fetchCitiesVacation();
	}, []);

	

	// TODO remove it
	useEffect(() => {
		async function fetchData() {
			const response = await fetchLookups();
			setAllLookups(response?.room_types);
		}
		fetchData();
	}, []);

	return (
		<>
			<div className="market-search  mb-4">
				<div className="main-tabs w-100 p-0" ref={divHigth}>
					<Nav tabs className="align-items-center main-nav text-capitalize">
						{/* <ShowForPermission permission={["hotel-vacation"]}> */}
						<NavItem>
							<NavLink
								id="hotel-vacations"
								className={classnames({ active: activeTab === "1" })}
								onClick={() => {
									toggle("1");
								}}
							>
								<img
									className="px-1"
									src={VACATIONS}
									alt=""
									srcset=""
									width="40px"
								/>
								{marketPlace.messages.VACATIONS}
							</NavLink>
						</NavItem>
						{/* </ShowForPermission> */}
						{/* <ShowForPermission permission={["hotel-umrah"]}> */}
						{/* <NavItem>
								<NavLink
									id='hotel-umrah'
									className={classnames({ active: activeTab === "2" })}
									onClick={() => {
										toggle("2");
									}}
								>
									<img
										className="px-1"
										src={UMRAH}
										alt=""
										srcset=""
										width="40px"
									/>
									{marketPlace.messages.UMRAH}
								</NavLink>
							</NavItem> */}
						{/* </ShowForPermission> */}
						{/* <NavItem>
							<NavLink
								className={classnames({ active: activeTab === "2" })}
								onClick={() => {
									toggle("2");
								}}
								disabled
							>
								<span className="Soon-span">{marketPlace.Soon}!</span>
								<img
									className="px-1"
									src={TransportationSvg}
									alt=""
									srcset=""
								/>
								{marketPlace.messages.transportation}
							</NavLink>
						</NavItem> */}
					</Nav>
					<div className="heading col-md-10 m-auto pt-2 pd-1">
						<h5 className=" mt-3 search-title terms-color text-uppercase ">
							{marketPlace.messages.search} & {marketPlace.messages.book}
						</h5>
						<h3 className=" mb-2 "> {marketPlace.messages.NewDeals}</h3>
					</div>

					{/*****************Start Container Tab********************** */}

					{/*****************End Container Tab********************** */}

					<TabContent activeTab={activeTab}>
						{activeTab === "1" ? (
							<Vacations
								allCountries={allCountries}
								allCitiesVacation={allCitiesVacation}
								allCurrenciesData={allCurrenciesData}
								allLookups={allLookups}
								mealPlanLookups={mealPlanLookups}
								roomTypesLookups={roomTypesLookups}
								roomViewsLookups={roomViewsLookups}
							/>
						) : activeTab === "2" ? (
							<UmrahTab
								allCountries={allCountries}
								allCitiesVacation={allCitiesVacation}
								allCurrenciesData={allCurrenciesData}
								allLookups={allLookups}
								mealPlanLookups={mealPlanLookups}
								roomTypesLookups={roomTypesLookups}
								roomViewsLookups={roomViewsLookups}
							/>
						) : null}
					</TabContent>
				</div>
				{activeTab === "1" && (
					<div
						className="search-banner"
						style={{
							backgroundImage: `url(${background})`,
							height: `${clientHeight}px`,
						}}
					></div>
				)}
				{activeTab === "2" && (
					<div
						className="search-banner"
						style={{
							backgroundImage: `url(${backgroundUMRAH})`,

							height: `${clientHeight}px`,
						}}
					></div>
				)}
			</div>
		</>
	);
}
