import React, { useEffect, useMemo, useState } from "react";
import TourDetailsInfo from "./TourInfo";
import Carousel from "react-spring-3d-carousel";
import { useHistory, useParams } from "react-router-dom";
import TourSearchAvailability from "./SearchAvailability";
import TourSelectDates from "./SelectDates";
import TourSelectCategory from "./SelectCategory";
import { ShoppingCartIcon } from "../TourIcons";
import ToursCartModal from "../CartModal";
import { addTourToCart, getCart, showSingleTourDetails } from "services/tours";
import {
	useMarketplaceDispatch,
	useMarketplaceState,
} from "context/marketplace";
import moment from "moment";
import placeHolederImgSrc from "assets/images/placeHoleder.png";
import Locale from "translations";
import TourDetailsSummary from "./Summary";
import ShowForPermission from "helpers/showForPermission";
import { calcCategoryPrice } from '../helpers/calcSelectedCategory';
import { store } from 'react-notifications-component';
import ToursErrorModal from '../ToursErrorModal';
import { useSBSDispatch } from 'context/global';

export default function TourDetails() {
	const { marketPlace, commons } = Locale;
	const { toursSearch, toursCart } = useMarketplaceState();
	const dispatch = useMarketplaceDispatch();
	const sbsDispatch = useSBSDispatch();

	const history = useHistory();
	const { id } = useParams();
	const [cartModalOpen, setCartModalOpen] = useState(false);
	const [tourData, setTourData] = useState();
	const [tourSlides, setTourSlides] = useState([]);
	const [activeSlide, setActiveSlide] = useState(0);
	const [selectedCategory, setSelectedCategory] = useState({
		category: null,
		language: null,
	});
	const [selectedDate, setSelectedDate] = useState(null);
	const [errorModal, setErrorModal] = useState({message: "", open: false});

	// const [canAddToCart, setCanAdd]

	function handleCartModalOpen() {
		setCartModalOpen(!cartModalOpen);
	}

	function toggleErrorModal() {
    setErrorModal({...errorModal, open: !errorModal.open})
  }

	// get tour details impacts all sub componenets
	async function getTourDetails() {
		const data = {
			cacheKey: toursSearch?.cacheKey,
			code: id,
			date_from: moment(toursSearch?.date_from).format("YYYY-MM-DD"),
			date_to: moment(toursSearch?.date_to).format("YYYY-MM-DD"),
			city_code: toursSearch?.destination?.id,
			noOfAdults: toursSearch?.adults?.value,
			children: toursSearch?.childs_ages?.map((age) => age?.value),
		};
		const tourDetailsRes = await showSingleTourDetails(data);
		
		if (tourDetailsRes?.status >= 200 && tourDetailsRes?.status < 300) {
			const slides = tourDetailsRes?.data?.tour?.images?.map((image, index) => {
				return {
					key: "slide-" + index,
					content: (
						<img
							className="bg-white"
							src={image}
							alt={tourDetailsRes?.data?.tour?.name + index}
							onError={(e) => {
								e.target.onerror = null;
								e.target.src = placeHolederImgSrc;
							}}
						/>
					),
					onClick: () => setActiveSlide(index),
				};
			});
			setTourData(tourDetailsRes?.data?.tour);
			setTourSlides(slides);
		} else {
			setErrorModal({open: true, message: tourDetailsRes?.data?.message});
			// failed to get tour
			setTimeout(() => {
				history.push("/tours-result");
			}, 2000);
		}
	}

	// add tour to cart
	async function addToCart() {
		const isCartEmpty = !toursCart?.cartItems?.length;
		const tourCountryCode = tourData?.country?.code;
		const firstCartItemCountryCode = toursCart?.cartItems?.length > 0 ? toursCart?.cartItems?.[0]?.details?.country?.code : null;
		const canAddToCart = isCartEmpty || (!isCartEmpty && tourCountryCode === firstCartItemCountryCode);

		if (canAddToCart) {
			const data = {
				cacheKey: toursSearch?.cacheKey,
				cartId: toursCart?.id ? toursCart?.id : null,
				tourId: tourData?.id,
				modalityCode: selectedCategory?.category?.code,
				rateKey: selectedCategory?.rateKey,
				date_from: moment(selectedDate).format("YYYY-MM-DD"),
				date_to: moment(selectedDate).format("YYYY-MM-DD"),
				noOfAdults: toursSearch?.adults?.value,
				children: toursSearch?.childs_ages?.map((age) => age?.value),
			};
			const addToCartRes = await addTourToCart(data);
			if (addToCartRes?.status >= 200 && addToCartRes?.status < 300) {
				dispatch({
					type: "toursCart",
					payload: addToCartRes?.data?.cart,
				});
				setCartModalOpen(true);
			} else {
				setCartModalOpen(false);
				dispatch({
					type: "toursCart",
					payload: null,
				});
			}
		} else {
			store.addNotification({
				title: '',
				message: 'all Items in the Tours cart should be on the same country.',
				type: "danger",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 5000,
					onScreen: true,
					pauseOnHover: true,
				},
			});
		}
	}

	// calc selected category price for adults and childs passed as prop in summary and dates
	const tourSummary = useMemo(() => {
		const childrenAges = toursSearch?.childs_ages?.map(age => age?.value);
		return calcCategoryPrice(toursSearch?.adults?.value, childrenAges, selectedCategory);
	}, [toursSearch, selectedCategory]);

	// console.log("calcSelectedCategoryPrice", categorySummary);
	// get tour details after new search
	useEffect(() => {
		getTourDetails();
	}, [toursSearch]);

	// get cart 
	async function getUpdatedCart() {
		const tourCartRes = await getCart(toursCart?.id);
		if (tourCartRes?.status >= 200 && tourCartRes?.status < 300) {
			dispatch({
				type: "toursCart",
				payload: tourCartRes?.data?.cart,
			});
		} else {
			dispatch({
				type: "toursCart",
				payload: null,
			})
		}
	}
	useEffect(() => {
		if (toursCart?.id) {
			getUpdatedCart();
		}
	}, []);

	return (
		<>
			{tourData ?
				<div className="container bg-white mt-3">
					{/* top bar */}
					<div className="tour-details-topbar d-flex flex-wrap justify-content-between py-3">
						<div className="d-flex flex-column">
							<h1 className="text-dark-blue font-weight-bold">{tourData?.name}</h1>

							<div className="text-yellow">
								<i className="fas fa-map-marker-alt"></i>
								<span className="mx-1">
									{tourData?.destinations?.map((city) => {
										return (
											<span key={city?.code}>
												{city?.name} ({city?.code}),{" "}
											</span>
										);
									})}
									{tourData?.country?.name}
								</span>
							</div>
						</div>
						<button
							className="mx-1 btn btn-white-border-gray"
							onClick={() => history.push("/tours-result")}
						>
							<i className="far fa-clock mx-1"></i>
							{marketPlace.BackSearch}
						</button>
					</div>

					{/* tour slides */}
					<div className="tour-slides">
						{tourSlides && tourSlides.length > 0 ? (
							<div
								style={{
									height: "360px",
									width: "100%",
									overflow: "hidden",
								}}
								className="py-4"
							>
								<Carousel
									slides={tourSlides}
									offsetRadius={2}
									goToSlide={activeSlide}
								/>
							</div>
						) : null}
					</div>

					{/* tour detalis */}
					<div className="d-flex flex-wrap tour-details mt-3">
						<div className="col-md-6 col-12">
							<TourDetailsInfo
								tourData={tourData}
								selectedDate={selectedDate}
								selectedCategory={selectedCategory}
							/>
						</div>

						<div className="col-md-6 col-12 pb-5">
							<TourSearchAvailability searchData={toursSearch} />
							{tourData && tourData?.modalities?.length > 0 ? (
								<>
									<div className="tour-details-selections">
										{/* select dates */}
										<TourSelectDates
											tourData={tourData}
											selectedDate={selectedDate}
											setSelectedDate={setSelectedDate}
											tourSummary={tourSummary}
											selectedCategory={selectedCategory}
										/>
										{/* select category */}
										<TourSelectCategory
											tourData={tourData}
											selectedCategory={selectedCategory}
											setSelectedCategory={setSelectedCategory}
										/>
									</div>
									{/* summary */}
									<div className="tour-details-summary mt-2">
										<TourDetailsSummary
											tourData={tourData}
											tourSummary={tourSummary}
											handleCartModalOpen={handleCartModalOpen}
											selectedCategory={selectedCategory}
											tourCode={id}
										/>
										{/* add to cart */}
										<ShowForPermission permission={["Manage-Marketplace-Tours"]}>
											<button
												className="btn w-100 bg-nxt py-2 mt-2"
												onClick={() => {
													addToCart();
												}}
											>
												{marketPlace.addToCart}
											</button>
										</ShowForPermission>
									</div>
								</>
							) : (
								<span>{marketPlace.noAvailabilityForDates}</span>
							)}
						</div>
					</div>
					<button className="btn tours-cart-btn" onClick={handleCartModalOpen}>
						<ShoppingCartIcon />
						{toursCart?.cartItems?.length > 0 ? (
							<span className="tours-cart-btn-indicator">
								{toursCart?.cartItems?.length}
							</span>
						) : null}
					</button>
					<ToursCartModal
						handleCartModalOpen={handleCartModalOpen}
						cartModalOpen={cartModalOpen}
					/>

				</div>
				: null
			}

			{/* error modal */}
			<ToursErrorModal
				isOpen={errorModal.open}
				toggleModal={toggleErrorModal}
				message={errorModal.message}
				withButton={false}
			/>

		</>
	);
}
