import datePickerIcon from "assets/images/datePicker.svg";
import { useState } from "react";
// import { isInclusivelyAfterDay, isInclusivelyBeforeDay } from "react-dates";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import "react-dates/lib/css/_datepicker.css";
import Locale from "translations";

export default function DateTimePickerField(props) {
	const [open, setOpen] = useState(false);
	const { commons } = Locale;

	const generateErrorsMsgs = (errors) => {
		let message = [];
		if (errors) {
			for (const [k, v] of Object.entries(errors)) {
				if (k === "required" && v) {
					message.push(commons.isRequired);
				} else if (k === "min" && v) {
					message.push(` should be at least ${props.min} chars`);
				} else if (k === "email" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "phone" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "number" && v) {
					message.push(commons.shouldBeValid);
				} else if (k === "date" && v) {
					message.push(commons.shouldBeValid);
				}
			}
			if (message.length === 1) {
				return `${props.label} ${message[0]}`;
			} else if (message.length > 1) {
				return `${props.label} ${message.join(" & ")}`;
			} else {
				return;
			}
		}
	};

	

	const handleOpen = () => setOpen((prev) => !prev);
	return (
		<>
			{/* Label */}
			{props.hasLabel ? (
				<label
					className={`control-field__label ${
						props.color ? " control-field__label--" + props.color : ""
					}`}
				>
					{props.label}
				</label>
			) : null}
			<div
				className={`my-1 control-field + ${
					props.color ? " control-field--" + props.color : ""
				} ${props.disabled ? "isDisabled" : ""}`}
			>
				{/* Date Range Body */}
				<div
					className={`control-field__body newFixPadding position-relative ${
						props.disabled && props.readOnly ? "isDisabled" : ""
					}`}
				>
					{/* Date Inputs */}
					<div className="no-gutters"  onClick={()=>{setOpen(true)}}>
						<DatePicker
							className="border-0 w-100"
							// numberOfMonths={1}
							// onOutsideClick={true}
							open={open}
							onClickOutside={handleOpen}
							onSelect={handleOpen}
							selected={props.date}
							// noBorder={true}
							disabled={props.disabled}
							timeCaption="time"
							timeInputLabel="Time:"
							dateFormat="dd/MM/yyyy h:mm aa"
							showTimeInput
							minDate={props.minDate}
							maxDate={props.maxDate}
							onChange={props.onChangeDate}
							placeholderText={props.placeholder}
							customArrowIcon={true}
							{...props}
							
							//peekNextMonth
							//showMonthDropdown
							showYearDropdown
							dropdownMode="select"
      				
						/>
						{/* <i className="fas fa-calendar-alt date-icon p-2"></i> */}
						<img
							className="date-icon p-2"
							src={datePickerIcon}
							alt=""
							onClick={(e) => {
								e.stopPropagation();
								handleOpen();
							}}
							style={{zIndex:0}}
						/>
					</div>
				</div>
			</div>

			{/* Feedback */}
			{/* <small
				className={`control-field__feedback control-field__feedback--${
					props.color
				} ${props.isInvalid ? "d-block" : "d-none"}`}
			>
				{props.feedbackMessage}
			</small> */}
			{/* Error Message */}
			<small
				className={`control-field__feedback control-field__feedback--${props.color} d-block error-message`}
			>
				{generateErrorsMsgs(props.errors)}
			</small>
		</>
	);
}

DateTimePickerField.defaultProps = {
	hasLabel: true,
};
