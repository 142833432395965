import AttractionGold from " ../../assets/images/icons/Attraction-gold.svg";
import { useSBSState } from "context/global";
import { useEffect, useState } from "react";

export default function AttractionLandmarkPreview(props) {
	const [attraction, setAttraction] = useState({});

	const { locale } = useSBSState();

	useEffect(() => {
		setAttraction(props.element);
	}, []);
	return (
		<div className=" product-build__product-collpase itenary-box d-flex justify-content-center align-items-center flex-row">
			<div className="img-circle-wrapper  icon-pro-Itinerary ">
				<img src={AttractionGold} alt="" srcset="" />
				{/* 							<span className="icx icx-camera-location"></span>
				 */}{" "}
			</div>
			<div className="button-collapse">
				<div className="title-style-container">
					<div className="right-items-container ">
						<div className="product-content-items px-4 p-2">
							<div className="product-country-from">
								<h6 className="blue-txt-item">
									{attraction.itemable?.name[locale]}
								</h6>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
