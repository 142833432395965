import { useAuthDispatch, useAuthState } from "context/auth";
import { Spinner } from "reactstrap";
import Locale from "translations";
import emailImg from "../assets/images/customRegister/mailbox.png";

export default function EmailConfirmation(props) {
	// ----------------------------------------------------------------------------------------------------
	// Component States / Variables / Constants
	// ----------------------------------------------------------------------------------------------------
	const { emailConfirmation } = Locale;
	const userEmail = useAuthState();
	const dispatch = useAuthDispatch();
	const normalRegister = props.registerType === "normal"

	// Back Button
	const back = () => {
		dispatch({ type: "Progress", progress: normalRegister ? 66 : 50, step: normalRegister ? 3 : 2 });
	};
	// ----------------------------------------------------------------------------------------------------
	// Component Functions
	// ----------------------------------------------------------------------------------------------------
	return (
		<>
			<div className="O-Email-box">
				<h1 className="text-title text-center text-orange m-0 mt-3">
					{emailConfirmation.mainTitle}
				</h1>

				<div className="row mt-5 register-email-confirmation">
					<div className=" mt-4">
						<img
							src={emailImg}
							alt="email-verification"
							title="email-verification"
						/>
					</div>
					<div>
						<h1 className="text-gray mt-1 text-center  col-sm-12">
							{emailConfirmation.emailVerificationTitle}
						</h1>
						<h1 className="text-black font-weight-bold m-0 text-center col-sm-12 mb-4">
							{normalRegister ? userEmail.userDetails?.email : userEmail?.safaUserDetails?.email}
						</h1>
					</div>

					{/* <div className="custom-control custom-checkbox">
					<input
						className="custom-control-input permChecks "
						id="checkbox-1"
						name="news"
						type="checkbox"
						value="1"
					/>
					<label className="custom-control-label pointer" htmlFor="checkbox-1">
						{backOffice.news}
					</label>
				</div> */}
				</div>
			</div>
			<div className="row justify-content-center my-2 mt-5 m-0 p-0 register-controls">
				<div className="col-4 p-0">
					<button
						className="btn bg-bk w-95"
						onClick={back}
						disabled={props.isDisabled}
					>
						{emailConfirmation.back}
					</button>
				</div>

				<div className="col-6 p-0 ">
					<button
						className="btn bg-nxt w-100 d-flex align-items-center justify-content-center"
						onClick={props.mainAction}
						disabled={props.isDisabled}
					>
						{props.isDisabled ? <Spinner color="light" size="sm" className='mx-2' /> : null}
						{emailConfirmation.register}
					</button>
				</div>
			</div>
		</>
	);
}
