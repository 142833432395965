import TextField from "components/Form/TextField/TextField";
import moment from "moment";
import { useEffect, useState } from "react";
import { store } from "react-notifications-component";
import { deletePassanger, getPassengers } from "services/productbuilder";
import Locale from "translations";

export default function Listpax({
	togglepass,
	setReload,
	Reload,
	reservationDetails,
}) {
	const { payment, inventory } = Locale;
	const [passangerList, setPassangerList] = useState(
		reservationDetails?.travelers
	);
	//to get all passanger
	useEffect(() => {
		const fetchAllPassanger = async () => {
			const res = await getPassengers(reservationDetails?.reservation_num);
			setPassangerList(res?.data?.data);
		};
		fetchAllPassanger();
	}, [Reload]);
	//to delete passanger
	const deleteItem = async (id) => {
		const res = await deletePassanger(id);
		if (res.status === 200 || res.status === 201) {
			store.addNotification({
				title: "info!",
				message: res.data.message,
				type: "success",
				insert: "top",
				container: "top-right",
				animationIn: ["animated", "fadeIn"],
				animationOut: ["animated", "fadeOut"],
				dismiss: {
					duration: 1500,
					onScreen: true,
					pauseOnHover: true,
				},
				onRemoval: () => {
					setReload((prev) => !prev);
				},
			});
		}
	};
	return (
		<>
			<div className="d-flex  align-items-center py-2 px-2 border bg-white">
				<p style={{ fontWeight: "bold" }} className="text-dark">
					{payment.messages.paxdetails}
				</p>
			</div>
			<div className="filter-update bg-filter-gray border">
				<div className="product-buttons col-md-12">
					<div className="main-filter-payment d-flex justify-content-between">
						<div className="main-label controls-field col-6 col-md-3">
							<TextField
								label={payment.messages.search}
								type="text"
								placeholder={payment.messages.search}
							/>
						</div>

						<div className="filter-btn col-6 col-md-3 d-flex align-items-center justify-content-end">
							<div className="add-pass pointer">
								<button
									className="btn text-white  px-3"
									disabled={
										reservationDetails?.reservation_status === "rejected"
									}
									// disabled
									onClick={() => togglepass("add")}
								>
									{inventory.messages.AddPassport}
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="border table-responsive">
				<table
					striped
					className="table-update p-2 bg-white m-0 table table-striped"
				>
					<thead>
						<tr>
							<th>{inventory.messages.name}</th>
							<th>{inventory.messages.type}</th>
							<th>{inventory.messages.birthday}</th>
							<th>{inventory.messages.nationality}</th>
							<th>{inventory.messages.country}</th>
							<th>{inventory.messages.passport}</th>
							{/* <th>{"Passport Expiry"}</th> */}

							<th>{inventory.messages.visa}</th>
							<th>{inventory.messages.eNumber}</th>
							<th>{inventory.messages.Actions}</th>
						</tr>
					</thead>
					<tbody>
						{passangerList && passangerList.length > 0 ? (
							passangerList.map((passanger, index) => (
								<tr key={index}>
									<td>{passanger.first_name + " " + passanger.last_name}</td>
									<td>
										{moment().diff(passanger?.birth_date, "years", false) >= 18
											? "Adult"
											: "Child"}
									</td>
									<td>{passanger?.birth_date}</td>
									<td>{passanger.nationality?.name}</td>
									<td>{passanger.country?.name}</td>
									<td>{passanger.passport_number}</td>
									{/* <td>{passanger.passport_expiry}</td> */}
									<td>{passanger?.visa_number}</td>
									<td>{passanger.e_number}</td>
									<td>
										{reservationDetails?.reservation_status !== "rejected" && (
											<>
												<i
													style={{ color: "#CB9A51" }}
													className="fas fa-edit me-2 pointer"
													onClick={() => togglepass("edit", passanger)}
													
												></i>
												<i
													className="fas fa-trash-alt fa-fw text-danger pointer"
													onClick={() => deleteItem(passanger?.id)}
													
												></i>
											</>
										)}
									</td>
								</tr>
							))
						) : (
							<td colSpan="9">
								<div className="product-build__product-no-data fullHeight">
									<h4 className="no-data-color">{"No Data Found"}</h4>
								</div>
							</td>
						)}
					</tbody>
				</table>
			</div>
		</>
	);
}
