import Pagination from "components/Pagination";
import ShowForPermission from "helpers/showForPermission";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import Locale from "translations";

function ListRequestes({ requestsList, meta, goTo }) {
	const { inventory, backOffice } = Locale;
	const statusClasses = (status) => {
		switch (status) {
			case "pending":
				return "bg-yellow-request";
			case "rejected":
			case "expired":
			case "canceled":
				return "bg-danger";
			default:
				return "bg-green";
		}
	};
	console.log("test");
	return (
		<div className="border table-responsive">
			<table
				striped
				className="table-update p-2 bg-white m-0 table table-striped"
			>
				<thead>
					<tr>
						<th>{inventory.messages.refNumber}</th>
						<th>{inventory.messages.tripname}</th>
						<th>{inventory.messages.tripType}</th>
						<th>{inventory.messages.owner}</th>
						<th>{inventory.messages.departure}</th>
						<th>{inventory.messages.nights}</th>
						<th>{inventory.messages.pax}</th>
						<th>{inventory.messages.TotalPrice}</th>
						<th>{inventory.messages.totalpaid}</th>
						<th>{inventory.messages.requestDate}</th> <th>{"Source"}</th>{" "}
						<th>{inventory.messages.paymentStatus}</th>
						<th>{inventory.messages.applicationStatus}</th>
					</tr>
				</thead>
				<tbody>
					{requestsList?.length > 0 ? (
						requestsList?.map((request, index) => {
							return (
								<tr key={request?.reservation_num}>
									<td>{request?.reference_num}</td>
									<td>{request?.trip_name}</td>
									<td>{request?.trip_type}</td>
									<td>{request?.owner?.full_name}</td>
									<td>
										{moment(request?.departure_date).format("DD-MM-YYYY")}
									</td>
									<td>{request?.nights}</td>
									<td>{request?.pax}</td>
									<td>
										{request?.total_price} {request?.currency}
									</td>
									<td>
										{request?.reservation_from === "online" &&
										request?.reservation_type === "auto" &&
										request?.reservation_status === "paid"
											? request?.total_paid
											:request?.payments_total_paid }
										{request?.currency}
										{/* {request?.payments_total_paid} {request?.currency} */}
									</td>
									<td>
										{" "}
										{moment(request?.created_at).format("DD-MM-YYYY | hh:mm A")}
									</td>
									<td>{request?.reservation_from}</td>
									<td>
										<span
											className={`px-2 text-white rounded text-capitalize ${
												request?.payment_status === "pending"
													? "bg-yellow-request"
													: "bg-green"
											}`}
										>
											{request?.payment_status === "pending"
												? inventory.messages.unPaid
												: inventory.messages.paid}
										</span>
									</td>

									<td>
										<span
											className={`px-2 text-white rounded text-capitalize ${statusClasses(
												request?.reservation_status
											)}`}
										>
											{request?.reservation_status === "paid"
												? inventory.messages.confirmed
												: request?.reservation_status}
										</span>
									</td>
									<ShowForPermission permission="Manage-Trip-Management-Module">
										<td>
											<Link
												to={`/b2b-requests/${request?.id}`}
											>
												<i className="fas fa-chevron-right pointer"></i>
											</Link>
										</td>
									</ShowForPermission>
								</tr>
							);
						})
					) : (
						<tr>
							<td colSpan="12">
								<div className="product-build__product-no-data fullHeight">
									<i className="icx icx-eye-slash" aria-hidden="true"></i>
									<h4>{backOffice.noResult}</h4>
								</div>
							</td>
						</tr>
					)}
				</tbody>
			</table>
			{requestsList?.length > 0 ? <Pagination info={meta} goTo={goTo} /> : null}
		</div>
	);
}

export default ListRequestes;
