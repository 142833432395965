import React, { createContext, useContext, useReducer } from "react";
const ProductsBuilderContext = createContext();
const ProductsBuilderDispatchContext = createContext();
const initialState = { product_items: [] };

function productsBuilderReducer(state = initialState, action) {
	switch (action.type) {
		case "saveBasicDetails": {
			
			
			return { ...state, isEditMode: false, ...action.payload };
		}

		case "deleteProduct": {
			return { isEditMode: false, product_items: [], ...action.payload };
		}

		case "appendItem": {
			return {
				...state,
				isEditing: true,
				product_items: [...state.product_items, action.payload],
			};
		}

		case "deleteItem": {
			const items = [...state.product_items].filter(
				(item) => item.id !== action.payload.index
			);
			// const index = items.findIndex((item) => item.id === action.payload.index);
			// items.splice(index, 1);
			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "deleteItemHotel": {
			
			const items = [...state.product_items].filter(
				(item) =>
				{
					
					return item.item.name=="hotel"&& item.id < action.payload.index
				}
			);
			// const index = items.findIndex((item) => item.id === action.payload.index);
			// items.splice(index, 1);
			console.log("hotels",items);
			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "appendInfo": {
			const items = [...state.product_items];
	
			
			state.product_items.map((productItem, index) => {
			
				if (
					productItem.item?.id == action.payload?.item?.id &&
					!productItem.hasOwnProperty("itemable") &&
					!state.product_items.filter(res=>res.id==action.payload.id).length > 0
				) {
					items[index] = { ...items[index], ...action.payload };
				} 
				
				if (
					productItem?.item?.id == action?.payload?.item?.id &&
					productItem.hasOwnProperty("itemable") &&
					productItem.id == action.payload.id
				) {
					items[index] = { ...items[index], ...action.payload };
				}
			});

			return {
				...state,
				isEditing: false,
				product_items: items,
			};
		}

		case "price": {
			return { ...state, price: action.payload, product_price: action.payload };
		}
		default: {
			throw new Error(`Unhandled action type: ${action.type}`);
		}
	}
}

function useProductsBuilderState() {
	const context = useContext(ProductsBuilderContext);
	if (context === undefined) {
		throw new Error(
			"useProductsBuilder State must be used within a ProductsBuilder Provider"
		);
	}
	return context;
}

function useProductsBuilderDispatch() {
	const context = useContext(ProductsBuilderDispatchContext);
	if (context === undefined) {
		throw new Error(
			"useProductsBuilderDispatch must be used within a ProductsBuilderProvider"
		);
	}
	return context;
}

function ProductsBuilderProvider({ children }) {
	const [state, dispatch] = useReducer(productsBuilderReducer, initialState);
	return (
		<ProductsBuilderContext.Provider value={state}>
			<ProductsBuilderDispatchContext.Provider value={dispatch}>
				{children}
			</ProductsBuilderDispatchContext.Provider>
		</ProductsBuilderContext.Provider>
	);
}

export {
	ProductsBuilderProvider,
	useProductsBuilderState,
	useProductsBuilderDispatch,
};
