import {
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	UncontrolledDropdown,
} from "reactstrap";
import Translation from "translations";
import iconNotification from "../../assets/images/customHeader/wallet.svg";

import { useSBSState } from "context/global";
import { useEffect, useRef, useState } from "react";
import { useHistory, Link } from "react-router-dom";
export default function WalletHeader() {
	const { dashboard } = Translation;
	const { myBalance } = useSBSState();
	const history = useHistory();
	const [toggle, setToggle] = useState(false);
	const ref = useRef(null);

	const el = document.getElementById("item");
	const [showClass, setShowClass] = useState(false);
	useEffect(() => {
		setShowClass(el?.className.includes("show"));
	}, [toggle]);

	return (
		<div
			className={`custom-notification wallet-icon ${
				toggle ? "bg-wallets" : ""
			}`}
		>
			<div className="bell-notifaction mx-2">
				<div className="custom-notification">
					<Link to="/wallet/transactions">
						<img src={iconNotification} alt="" />
					</Link>
				</div>
			</div>
			{/* <UncontrolledDropdown
				setActiveFromChild
				className="notification"
				ref={ref}
				id="item"
				onClick={() => setToggle(!toggle)}
			>
				<DropdownToggle className="bell-notifaction mx-2">
					<div className="custom-notification">
						<img src={iconNotification} alt="" />
					</div>
				</DropdownToggle> */}

				{/* <DropdownMenu right className="bg-notify no-padding notify-items  wallet-icons-header"> */}
					{/**************************************** */}
					{/* <DropdownItem
						tag="button"
						className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem font-weight-bold m- `}
					>
						<div className="d-flex">
							<img src={iconNotification} alt="" width={35} height={35} />
							<div className="mx-3">
								<h3 className="text-yellow">{dashboard.messages.safaWallet}</h3>
								<span>
									{" "}
									{safaBalance?.amount} {safaBalance?.currency}
								</span>
							</div>
						</div>
					</DropdownItem> */}





					{/* <DropdownItem
						tag="button"
						className={`d-flex justify-content-start flex-wrap py-2 border-top DropdownItem font-weight-bold m- `}
					>
						<div className="d-flex">
							<img src={iconNotification} alt="" width={35} height={35} />
							<div className="mx-3">
								<h3 className="text-yellow">{dashboard.messages.myWallet}</h3>
								<span>
									{" "}
									{myBalance?.amount} {myBalance?.currency}
								</span>
							</div>
						</div>
					</DropdownItem> */}
					{/**************************************** */}
					{/* <DropdownItem
						onClick={() => {
							history.push("/wallet");
						}}
						className="d-flex justify-content-center w-100 notify-show-more btn-wallet-icon py-2"
					>
						<h6 className="text-yellow">{dashboard.messages.manageWallet}</h6>
					</DropdownItem>
				</DropdownMenu>
			</UncontrolledDropdown> */}
		</div>
	);
}
