import Locale from 'translations';
import HotelTab from './HotelTab';

export function Hotels({ details}) {
  const {packages} = Locale;
  // const t = useTranslation();
  const sxResponsive = {
    ".box-container-hotel": {
      "@media(min-width: 320px )and (max-width: 480px)": {},
    },
    ".box-container-hotel .MuiCardMedia-img": {
      "@media(min-width: 320px )and (max-width: 480px)": {
        width: "80px !important",
        height: "80px",
      },
    },
  };
  const data = [];
  return (
    data && (
      <div>
        <p
            style={{
              color: "#0F3E5E",
              fontWeight: "800",
              fontSize:"17px",
              marginY: "7px",
            }}
          >
           {packages.hotel}
          </p>
        <div class="card" key={"1"} style={{ mb: 2,  ...sxResponsive }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              padding: ".8rem",
            }}
            className="box-container-hotel"
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                
                style={{ width: 180, height: 130}}
                src={ details?.itemable?.hotel?.image}
                alt="Live from space album cover"
              />
              
            </div>

            <div class="card-body"
              style={{
                display: "flex",
                flexDirection: "column",
                padding:0,
                margin:"0 12px"

              }}
            >
              <div
                className={"hotelName"}
              >
                {details?.itemable?.hotel?.name}
              </div>
              {/* <Rating value={+details?.itemable?.hotel?.rating} readOnly size="small" /> */}
              {+details?.itemable?.hotel?.rating > 0 ? (
							<div className="hotel-star-rate d-inline px-2">
								{[...Array(+details?.itemable?.hotel?.rating).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>
						) :(
							<div className="hotel-star-rate d-inline px-2">
								{[...Array(5)].map((i) => (
									<i key={i} className="far fa-star" aria-hidden="true"></i>
								))}
							</div>
						) }
              <p
                
                 style={{color:"#222"}}
              >
                <i class="fas fa-map-marker-alt"  style={{ verticalAlign: "middle",fontSize:"15px"}}></i>{" "}

                {details?.itemable?.hotel?.city},{details?.itemable?.hotel?.country} | <span  
                style={{color:"#0F3E5E",fontWeight:"600 !important"}}
                >
                
                </span> 
                 {/* Distance from Haram: 500 M */}
              </p>
              <div
                style={{ color:"#0C3B5C",fontSize:"14px"}}
              >
                {/* <Icon
                  sx={{
                    verticalAlign: "middle",
                    marginInlineEnd: ".3rem",
                    fontSize: "14px !important",
                   
                  }}
                  className="material-symbols-outlined"
                >
                  BedtimeOutlined
                </Icon> */}
                <i class="far fa-moon" style={{
                    verticalAlign: "middle",
                    marginInlineEnd: ".3rem",
                    fontSize: "14px !important",
                   
                  }}></i>
                You will stay here {details?.itemable?.night_count} nights
              </div> 
            </div>    
          </div>
          <HotelTab details={details}/>
        </div>
      
      </div>
    )
  );
}
