import moment from "moment";
import Locale from "translations";
export default function SummaryTab({ reservationDetails }) {
  const { payment, inventory } = Locale;
  const ownerName = reservationDetails?.owner?.full_name
    ?.substring(0, 3)
    .concat("*****");
  const ownerPhone = `${reservationDetails?.owner?.phone_code
    } ${reservationDetails?.owner?.phone?.substring(0, 4).concat("*****")}`;
  const ownerEmail = reservationDetails?.owner?.email
    ?.substring(0, 4)
    .concat("*****com");
  // const outstanding =
  // 	+reservationDetails?.total_price - +reservationDetails?.total_paid;
  const isApplicationPaidConfirmed =
    reservationDetails?.reservation_status !== "pending";
  const isApplicationRejected =
    reservationDetails?.reservation_status === "rejected";
  const isApplicationPending =
    reservationDetails?.reservation_status === "pending";

  const isOnlineReservation = reservationDetails?.reservation_from === "online" && reservationDetails?.reservation_type === "auto";
  const isManualOrOffline = reservationDetails?.reservation_from === "offline" || reservationDetails?.reservation_type === "manual";

  const totalPaid =
    isOnlineReservation ? reservationDetails?.total_paid :
      isManualOrOffline ? reservationDetails?.payments_total_paid :
        null;

  const totalOutstanding =
    isOnlineReservation ? (+reservationDetails?.total_price - +reservationDetails?.total_paid) :
      isManualOrOffline ? reservationDetails?.payments_outstanding :
        null;

  return (
    <div className="summarybox">
      <h4 className="summaryhead"> {payment.messages.summardetails}</h4>
      <div className="info-box row">
        <div className="col-6">
          <div className="info-row">
            <p className="label">{payment.messages.RefNo}</p>
            <p className="info">{reservationDetails?.reference_num}</p>
          </div>
          <div className="info-row">
            <p className="label">{inventory.messages.owner}</p>
            <p className="info">{isApplicationPaidConfirmed ? reservationDetails?.owner?.full_name : ownerName}</p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.phone}</p>
            <p className="info">
              {isApplicationPaidConfirmed ? `${reservationDetails?.owner?.phone_code} ${reservationDetails?.owner?.phone}` : ownerPhone}
            </p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.Email}</p>
            <p className="info">{isApplicationPaidConfirmed ? reservationDetails?.owner?.email : ownerEmail}</p>
          </div>
          <div className="info-row">
            <p className="label">{inventory.messages.departure}</p>
            <p className="info">{moment(reservationDetails?.departure_date).format("DD-MMM-YY")}</p>
          </div>
          <div className="info-row">
            <p className="label">{inventory.messages.nights}</p>
            <p className="info">{reservationDetails?.nights}</p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.Package}</p>
            <p className="info">{reservationDetails?.trip_name}</p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.pax}</p>
            <p className="info">{reservationDetails?.pax ? reservationDetails?.pax : "-"}</p>
          </div>
        </div>
        <div className="col-6">
          <div className="info-row">
            <p className="label">{"From"}</p>
            <p className="info text-capitalize ">
              {reservationDetails?.reservation_from}
            </p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.status}</p>
            <p className="info text-capitalize " style={{ color: isApplicationPending ? "#DE8D0A" : isApplicationRejected ? "#f04129" : "#709a47" }}>
              {reservationDetails?.reservation_status === "paid"
                ? inventory.messages.confirmed
                : reservationDetails?.reservation_status}
              {/* {reservationDetails?.reservation_status === "pending" ? inventory.messages.waitingApproval : reservationDetails?.reservation_status} */}
            </p>
          </div>
          <div className="info-row">
            <p className="label">{payment.messages.total}</p>
            <p className="info">{reservationDetails?.total_price} {reservationDetails?.currency}</p>
          </div>
          {/* total paid */}
          <div className="info-row">
            <p className="label">{payment.messages.paid}</p>
            <p className="info" style={{ color: "#00B545" }}>
              {totalPaid} {reservationDetails?.currency}
            </p>
          </div>

          <div className="info-row">
            <p className="label">{payment.messages.Outstanding}</p>
            <p className="info" style={{ color: "#B17A00" }}>
              {totalOutstanding} {reservationDetails?.currency}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
