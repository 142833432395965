import React, { useEffect, useState } from "react";
import banner2 from "assets/images/packages_bg.png";

import SelectField from "components/Form/SelectField/SelectField";
import Locale from "translations";
import useLookupsApi from "modules/products-builder/hooks/useLookups";
import PackageMonth from "./PackageMonth";
import filterReset from "assets/images/filterReset.svg";
import TextField from "components/Form/TextField/TextField";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { fetchCities, fetchPackagesMonth } from "services/lookups";
import moment from 'moment';

export default function SearchBarPackages({
	handleFilters,
	filters,
	getPackages,
	resetForm,
}) {
	const { marketPlace, backOffice } = Locale;
	const [lookupsState, setLookupsState] = useState({packageMonth:[],cities:[]});
	const [lookups] = useLookupsApi();

	const paxNightsLookup = Array(30)
		.fill(1)
		.map((i, index) => ({
			name: i + index,
			id: i + index,
		}));
	console.log(filters);
	const itemsLookup = [
		{ name: "hotel", id: "hotel" },
		{ name: "External Transportation", id: "external_transportation" },
		{ name: "Internal Transportation", id: "internal_transportation" },
		{ name: "Internal Transportation", id: "internal_transportation" },
		{ name: "visa", id: "visa" },
		{ name: "sightseeing", id: "sightseeing" },
	];

	const { id } = useParams();

	const fetchLookupList = async () => {
		const res = await Promise.all([fetchCities(id), fetchPackagesMonth(id)]);

		const dates = res?.[1]?.dates
		const packageMonth = Object.keys(dates).map((month)=> {
			const days = [...new Set(dates[month].map((day)=> +moment(day).format("DD"))).values()]
			return{
				month ,
				days
			}
		})
		setLookupsState({
			cities: res?.[0],
			packageMonth,
		});
	};

	useEffect(() => {
		fetchLookupList();
		return () => {};
	}, []);

	return (
		<div
			className="mybooking-search-wrapper search-bar"
			style={{
				backgroundImage: `url(${banner2})`,
				backgroundSize:"cover"
			}}
		>
			<div className="p-5">
				<div
					className="mybooking-grid-container p-4"
					style={{ backgroundColor: "#ffffffe8" }}
				>
					<div className="col-md-12 HotelSearch">
						<div className="row ">
							<div className="col-md-12 " style={{ padding: "0 15px" }}>
								<div className="row">
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											label={marketPlace.PackageType}
											placeholder={marketPlace.select}
											onChange={(e) => {
												handleFilters({
													name: "packageType",
													value: e,
												});
											}}
											value={filters.packageType?.name}
											options={lookups.product_types}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<PackageMonth
											label={marketPlace.PackageMonth}
											placeholder={marketPlace.select}
											onChange={(date) =>
												handleFilters({
													name: "packageMonth",
													value: date,
												})
											}
											avilableDates={lookupsState?.packageMonth}
											value={filters?.packageMonth}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											label={marketPlace.city}
											placeholder={marketPlace.select}
											onChange={(e) => {
												handleFilters({
													name: "city",
													value: e,
												});
											}}
											value={filters.city?.name}
											options={lookupsState.cities}
										/>
									</div>
									<div className="col-md-1 px-2 pr-3">
										<SelectField
											label={marketPlace.messages.pax}
											placeholder={marketPlace.select}
											onChange={(e) => {
												handleFilters({
													name: "pax",
													value: e,
												});
											}}
											value={filters.pax?.name}
											options={paxNightsLookup}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											multi
											placeholder={
												filters.tripItems?.length > 0
													? filters.tripItems
													: marketPlace.select
											}
											getOptionLabel={(x) => x.name}
											getOptionValue={(x) => x.id}
											onChange={(values, { action }) => {
												if (action === "remove-value" && !values) {
													handleFilters({
														name: "tripItems",
														value: [],
													});

													return;
												}
												handleFilters({
													name: "tripItems",
													value: values,
												});
											}}
											label={marketPlace.TripItems}
											options={itemsLookup}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											multi
											label={marketPlace.messages.roomType}
											placeholder={
												filters.roomTypes?.length > 0
													? filters.roomTypes
													: marketPlace.select
											}
											// onChange={(e) => {
											// 	handleFilters({
											// 		name: "roomTypes",
											// 		value: e,
											// 	});
											// }}
											onChange={(values, { action }) => {
												if (action === "remove-value" && !values) {
													handleFilters({
														name: "roomTypes",
														value: [],
													});

													return;
												}
												handleFilters({
													name: "roomTypes",
													value: values,
												});
											}}
											// value={filters.roomTypes?.name}
											options={lookups.room_types}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											label={marketPlace.noOfNights}
											placeholder={marketPlace.select}
											onChange={(e) => {
												handleFilters({
													name: "noNights",
													value: e,
												});
											}}
											value={filters.noNights?.name}
											options={paxNightsLookup}
										/>
									</div>
									<div className="col-md-2 px-2 pr-3">
										<SelectField
											label={backOffice.stars}
											placeholder={marketPlace.select}
											onChange={(e) => {
												handleFilters({
													name: "stars",
													value: e,
												});
											}}
											value={filters.stars?.name}
											options={lookups.product_classifications}
										/>
									</div>
									<div className="col-md-4 px-2 pr-3 d-flex">
										<div className="flex-fill">
											<TextField
												type="number"
												label={marketPlace.packagePrice}
												placeholder={"from"}
												value={filters.priceFrom || ""}
												onChange={(e) => {
													const value = +e.target.value;
													const priceFrom = value;
													const priceTo =
														value > filters.priceTo
															? value + 1
															: filters.priceTo;

													handleFilters({
														multipleValues: {
															priceFrom,
															priceTo,
														},
													});
												}}
											/>
										</div>
										<div className="flex-fill">
											<TextField
												type="number"
												label=" "
												placeholder={"to"}
												value={filters.priceTo || ""}
												onChange={(e) => {
													const value = +e.target.value;
													const priceTo = value;
													const priceFrom =
														value < filters.priceFrom
															? value - 1
															: filters.priceFrom;

													handleFilters({
														multipleValues: {
															priceFrom,
															priceTo,
														},
													});
												}}
											/>
										</div>
									</div>

									<div className="col-md-3 px-2 pr-3">
										<div className="full-input search-button d-flex">
											<button
												className="btn bg-nxt mb-3 w-50"
												style={{ marginTop: "30px" }}
												type="button"
												onClick={getPackages}
											>
												{marketPlace.messages.search}
											</button>
											<button
												className="btn bg-white clear-filter px-3 mx-2 mb-3 w-50"
												style={{ marginTop: "30px" }}
												type="button"
												onClick={resetForm}
											>
												<img src={filterReset} alt="" className="mx-2" />

												{marketPlace.messages.resetFilter}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
