import visaoff from "assets/images/2Online.svg";
import flightsIcon from "assets/images/flights.svg";
import HotelsIcon from "assets/images/new-hotels-colored.svg";
import HotelGray from "assets/images/new-hotels.svg";
import transportationColored from "assets/images/new-transportation-colored.svg";
import car from "assets/images/new-transportation.svg";
import visa from "assets/images/new-visa-colored.svg";
import classnames from "classnames";
import { useSBSState } from "context/global";
import ShowForPermission from "helpers/showForPermission";
import { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent } from "reactstrap";
import {  getAdsList } from "services/marketplace";
import Locale from "translations";
import Flights from "./FlightsForm";
import Hotels from "./Hotels";
import OnlineVisa from "./OnlineVisa";
import OfferBar from "./specialOfferBar";
import useShowPermission from "hooks/useShowPermission";
import TransferSearch from './Transfer';
import ToursSearch from './Tours';
import toursImg from "assets/images/inventory/tours.svg";
import packageImg from "assets/images/packages.svg";

import {  useHistory, useParams } from 'react-router-dom';
import Transportation from './Transportation';

function SearchContainer() {
	const { marketPlace, inventory } = Locale;
	const { permissions, role, allCountries } = useSBSState();
	const history = useHistory();
	const { tab } = useParams();

	const hotalsPermission = useShowPermission({
		permission: ["View-Marketplace-Hotels", "Manage-Marketplace-Hotels"],
	});
	const visasPermission = useShowPermission({
		permission: ["Manage-Marketplace-Visa"],
	});
	const transferPermission = useShowPermission({
		permission: [
			"View-Marketplace-Transfer",
			"Manage-Marketplace-Transfer",
		],
	});
	const toursPermission = useShowPermission({
		permission: [
			"View-Marketplace-Tours",
			"Manage-Marketplace-Tours",
		],
	});
	const flightPermission = useShowPermission({
		permission: ["View-Marketplace-Flights", "Manage-Marketplace-Flights"],
	});
	// const groundPermission = useShowPermission({
	// 	permission: [
	// 		"View-Marketplace-Ground-Service",
	// 		"Manage-Marketplace-Ground-Service",
	// 	],
	// });
	// const scrollToContent = () => {
	// 	let tabContent = document.getElementById("tabContentRef");
	// 	tabContent.scrollIntoView({ block: "start", behavior: "smooth" });
	// };

	const [activeTab, setActiveTab] = useState("");
	// const [allCurrenciesData, setAllCurrenciesData] = useState();


	const toggle = (tab) => {
		if (activeTab !== tab) setActiveTab(tab);
	};

	const [adsList, setAdsList] = useState([]);

	useEffect(() => {
		async function fetchAdsList() {
			const res = await getAdsList();
			setAdsList(res?.data);
		}
		fetchAdsList();
	}, []);

	useEffect(() => {
		switch (tab) {
			case "hotels":
				setActiveTab("1");
				break;
			case "visa":
				setActiveTab("2");
				break;
			case "transportaion":
				setActiveTab("3");
				break;
			case "tour":
				setActiveTab("4");
				break;
			case "flights":
				setActiveTab("5");
				break;
			default:
				setActiveTab("1");
				history.push("/market-search/hotels");
				break;
		}
	}, [tab, activeTab]);


	const redirect = () =>
		history.push(
			hotalsPermission
				? "/market-search/hotels"
				: transferPermission
				? "/market-search/transportaion"
				: visasPermission
				? "/market-search/visa"
				: toursPermission
				? "/market-search/tour"
				: flightPermission
				? "/market-search/flights"
				: null
	);

	useEffect(() => {
		if ((permissions && permissions.length > 0) || role) {
			if (!hotalsPermission && tab === "hotels") {
				redirect();
				return;
			}
			if (!transferPermission && tab === "transportaion") {
				redirect();
				return;
			}
			if (!visasPermission && tab === "visa") {
				redirect();
				return;
			}
			if (!toursPermission && tab === "tour") {
				redirect();
				return;
			}
			if (!flightPermission && tab === "flights") {
				redirect();
				return;
			}
		}
	}, [permissions, role]);



	return (
		<>
			<div className="market-search-conatainer k-market-search-container container pt-3">
				<div className="main-tabs1 ">
					<div className="market-search-tabs-container">
						<Nav tabs className="align-items-center main-nav1 w-100">
							{/**request-hotels */}
							<ShowForPermission
								permission={[
									"View-Marketplace-Hotels",
									"Manage-Marketplace-Hotels",
								]}
							>
								<NavItem>
									<NavLink
										className={classnames({ active: activeTab === "1" })}
										onClick={() => {
											// scrollToContent();
											toggle("1");
											history.push("/market-search/hotels");
										}}
									>
										{/* <i class="fas fa-kaaba fa-lg mx-1"></i> */}
										<img
											src={activeTab === "1" ? HotelsIcon : HotelGray}
											alt=""
											srcset=""
											width="50px"
										/>
										<p>{marketPlace.messages.hotels}</p>
										<i className="fas fa-caret-right"></i>
									</NavLink>
								</NavItem>
							</ShowForPermission>
							{/* flights */}
							<ShowForPermission
								permission={[
									"View-Marketplace-Flights",
									"Manage-Marketplace-Flights",
								]}
							>
								<NavItem>
									<NavLink
										style={{ marginTop: "10px", fontSize: "15px" }}
										className={`${classnames({ active: activeTab === "5" })}`}
										onClick={() => {
											// scrollToContent();
											toggle("5");
											history.push("/market-search/flights");
										}}
									>
										<img
											style={{ margin: "auto" }}
											src={activeTab === "5" ? flightsIcon : flightsIcon}
											alt=""
											srcset=""
										/>
										{/* <span className="soon">{inventory.messages.soon}</span> */}

										<p>{marketPlace.messages.flights}</p>
										<i className="fas fa-caret-right"></i>
									</NavLink>
								</NavItem>
							
							</ShowForPermission>
							{/* <ShowForPermission permission={["request-transportations"]}> */}


							{/* transportation */}
							{/* <NavItem>
								<NavLink
									style={{ marginTop: "10px" }}
									className={classnames({ active: activeTab === "6" })}
									onClick={() => {
										toggle("6");
										history.push("/market-search/transportation");
									}}
								>
									<img
										style={{ margin: "auto", width: "50px" }}
										src={activeTab === "6" ? transportationColored : car}
										alt=""
										srcset=""
									/>
									<span className="soon">{inventory.messages.soon}</span>

									<p>{marketPlace.messages.Transportation}</p>
									<i class="fas fa-caret-right"></i>
								</NavLink>
							</NavItem> */}

							{/* </ShowForPermission> */}

							{/* visa */}
							<ShowForPermission
								permission={[
									"View-Marketplace-Visa",
									"Manage-Marketplace-Visa",
								]}
							>
								<NavItem>
									<NavLink
										style={{ marginTop: "10px" }}
										className={classnames({ active: activeTab === "2" })}
										onClick={() => {
											// scrollToContent();
											toggle("2");
											history.push("/market-search/visa");
										}}
									>
										<img
											style={{ margin: "auto" }}
											src={activeTab === "2" ? visa : visaoff}
											alt=""
											srcset=""
										/>
										<p>{marketPlace.messages.ONLINEVISA}</p>
										<i class="fas fa-caret-right"></i>
									</NavLink>
								</NavItem>
							</ShowForPermission>
							{/* transfer */}
							<ShowForPermission
								permission={[
									"View-Marketplace-Transfer",
									"Manage-Marketplace-Transfer",
								]}
							>
								<NavItem>
									<NavLink
										style={{ marginTop: "10px" }}
										className={classnames({ active: activeTab === "3" })}
										onClick={() => {
											// scrollToContent();
											toggle("3");
											history.push("/market-search/transportaion");
										}}
									>
										<img
											style={{ margin: "auto", width: "50px" }}
											src={activeTab === "3" ? transportationColored : car}
											alt=""
											srcset=""
										/>
										{/* <span className="soon">{inventory.messages.soon}</span> */}

										<p>{marketPlace.messages.transfer}</p>
										<i className="fas fa-caret-right"></i>
									</NavLink>
								</NavItem>
							</ShowForPermission>
							{/* tours */}
							<ShowForPermission
								permission={[
									"View-Marketplace-Tours",
									"Manage-Marketplace-Tours",
								]}
							>
								<NavItem>
									<NavLink
										style={{ marginTop: "10px" }}
										className={classnames({ active: activeTab === "4" })}
										onClick={() => {
											// scrollToContent();
											toggle("4");
											history.push("/market-search/tour");
										}}
									>
										<img
											style={{ margin: "auto", width: "40px" }}
											src={toursImg}
											alt=""
											srcset=""
										/>
										{/* <span className="soon">{inventory.messages.soon}</span> */}

										<p>{marketPlace.messages.tours}</p>
										<i className="fas fa-caret-right"></i>
									</NavLink>
								</NavItem>
							</ShowForPermission>


									<NavItem>
										{/* <NavLink
											style={{ marginTop: "10px" }}
											onClick={() => {
												history.push("/packages")
											}}
										>
											<img
												style={{ margin: "auto", width: "40px" }}
												src={packageImg}
												alt=""
												srcset=""
											/>
											<p>{inventory.messages.packages}</p>
											<i className="fas fa-caret-right"></i>
										</NavLink> */}
									</NavItem>

							{/* <ShowForPermission permission={["request-ground-work"]}>
							<NavItem>
								<NavLink
									style={{ marginTop: "10px", fontSize: "15px" }}
									className={classnames({ active: activeTab === "4" })}
									onClick={() => {
										scrollToContent()
										toggle("4");
									}}
									disabled
								>
									<img
										style={{ margin: "auto", width: "45px" }}
										src={activeTab === "4" ? groundServiceAcive : groundService}
										alt=""
										srcset=""
									/>
									<span className="soon">{inventory.messages.soon}</span>

									<p>{marketPlace.messages.groundService}</p>
									<i className="fas fa-caret-right"></i>
								</NavLink>
							</NavItem>
						</ShowForPermission> */}
						</Nav>
					</div>
					<TabContent
						activeTab={activeTab}
						className="w-100 px-md-0 px-3"
						id="tabContentRef"
					>
						<div className="main-nav-contant ">
							{activeTab === "1" ? (
								<Hotels allCountries={allCountries} />
							) : activeTab === "2" ? (
								//null
								 <OnlineVisa allCountries={allCountries} />
							) : activeTab === "3" ? (
								<TransferSearch />
							) : activeTab === "4" ? (
								<ToursSearch />
							) : activeTab === "5" ? (
								<Flights />
							) : activeTab === "6" ? (
								<Transportation />
							) : null}
						</div>
					</TabContent>
				</div>
			</div>
			<OfferBar
				className="OfferBar"
				adsList={adsList}
				setAdsList={setAdsList}
			/>
		</>
	);
}

export default SearchContainer;
