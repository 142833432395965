import React from 'react'
import { Table } from 'reactstrap'
import Locale from 'translations'

export default function PackageReservationTravelers({ details }) {
  const {packages, marketPlace, inventory} = Locale;

  return (
    <div className='table-container'>
      <Table striped className="table-update p-2 bg-white table table-striped">
        <thead>
          <tr>
            <td>{inventory.messages.PassportNo}.</td>
            <td>{marketPlace.messages.name}</td>
            <td>{packages.gender}</td>
            <td>{packages.birthDate}</td>
            <td>{packages.nationality}</td>
            <td>{packages.passportExpireDate}</td>
          </tr>
        </thead>
        <tbody>
          {details?.travelers?.length > 0 ?
            details?.travelers?.map((traveler) => {
              return (
                <tr key={traveler?.id}>
                  <td>{traveler?.passport_number}</td>
                  <td>{traveler?.first_name} {traveler?.last_name} </td>
                  <td>{traveler?.gender}</td>
                  <td>{traveler?.birth_date}</td>
                  <td>{traveler?.nationality?.name}</td>
                  <td>{traveler?.passport_expiry}</td>
                </tr>
              )
            })
            :
            <tr>
              No Travelers
            </tr>
          }
        </tbody>
      </Table>
    </div>
  )
}
