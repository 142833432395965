import LanguageSwitcher from "components/LanguageSwitcher/LanguageSwitcher";
import { useSBSState } from "context/global";
import bg from "../../../assets/images/loginLogo.png";
import bgAR from "../../../assets/images/loginlogoAr.png";
import safaLogo from "../../../assets/images/safa_logo.png";
import RegistrationProgress from "../components/RegistrationProgress";
import Locale from 'translations';
import { Link } from 'react-router-dom';


const RegistrationLayout = (props) => {
	const { locale } = useSBSState();
	const { newLand } = Locale;


	return (
		<div className="bg-layout  register-page ">
			<div className="d-flex container justify-content-between pt-1">
				<figure className="col-5 pt-3 m-0 register-page-img">
					<img src={locale === "en" ? bg : bgAR} alt="" className="img-fluid" />
				</figure>

				<div className="col-lg-6 col-md-5 col-sm-9">
					<div className=" d-flex  flex-column  justify-content-end align-items-end">
						<div className="d-flex justify-content-between align-items-center w-100">
							<Link to="/contact-us" className="text-dark">
								{newLand.contactUs}
							</Link>
							<Link to="/about-us" className="text-dark">
								{newLand.aboutUs}
							</Link>
							<LanguageSwitcher />
						</div>
						<img
							src={safaLogo}
							alt=""
							className="safa_logo d-none"
						/>
						<div className="col-12">
							<div className="">
								<RegistrationProgress registerType={props.registerType} />

								<div className="mt-3">{props.children}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegistrationLayout;
