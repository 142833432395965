import React, { useState } from 'react'
import RegistrationLayout from '../../layout/RegistrationLayout';
import EmailConfirmation from 'modules/EmailConfirmation';
import { useAuthState } from 'context/auth';
import SafaAccountDetails from './SafaAccountDetails';
import SafaUserDetails from './SafaUserDetails';
import { useHistory } from 'react-router-dom';
import { useSBSState } from 'context/global';
import { registerSafaVisaAccount } from 'services/auth';

export default function SafaRegistration() {
  const { locale } = useSBSState();
  const registerData = useAuthState();
  const history = useHistory();
  const [safaCompanyDetails, setSafaCompanyDetails] = useState({
    companyCountry: null,
    companyName: null,
    companyPhone: null
  });
  const [apiCalled, setApiCalled] = useState(false);
  const [spin, setSpin] = useState(false);



  async function registerSafaAccount() {
    const data = {
      user_email: registerData?.safaUserDetails?.email,
      company_name: registerData?.safaUserDetails?.companyName,
      company_phone: registerData?.safaUserDetails?.companyPhone,
      user_password: registerData?.safaUserDetails?.password,
      user_password_confirmation: registerData?.safaUserDetails?.passwordConfirmation,
      lang: locale,
      user_full_name: registerData?.safaUserDetails?.name,
      company_country_id: registerData?.safaUserDetails?.country?.id?.toString(),
      company_city_id: registerData?.safaUserDetails?.city?.id?.toString(),
      company_avatar: registerData?.safaUserDetails?.compnayLogo,
      safa_key: registerData?.safaAccountDetails?.safa_key,
      safa_email: registerData?.safaAccountDetails?.email,
      safa_password: registerData?.safaAccountDetails?.password
    }

    setApiCalled(true);
    // Register New Account
    const response = await registerSafaVisaAccount(data);
    if (response.status === 200) {
      setApiCalled(false);
      history.push("/auth/register_safa/success");
    } else {
      setApiCalled(false);
    }

  };

  const currentStepView = () => {
    switch (registerData.step) {
      case 1:
        return (
          <SafaAccountDetails
            spin={spin}
            setSpin={setSpin}
            setSafaCompanyDetails={setSafaCompanyDetails}
          />
        );
      case 2:
        return (
          <SafaUserDetails
            spin={spin}
            setSpin={setSpin}
            safaCompanyDetails={safaCompanyDetails}
          />
        );
      case 3:
        return (
          <EmailConfirmation
            mainAction={registerSafaAccount}
            isDisabled={apiCalled}
            registerType={'safa'}
          />
        );
      default:
        return (
          <SafaAccountDetails
            spin={spin}
            setSpin={setSpin}
          />
        );
    }
  };

  return (
    <RegistrationLayout registerType="safa">
      {currentStepView()}
    </RegistrationLayout>
  )
}
