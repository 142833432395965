import moment from "moment";
import React, { useState } from "react";
import Locale from "translations";
import SelectField from "components/Form/SelectField/SelectField";
import { Collapse } from "reactstrap";
import TextField from "components/Form/TextField/TextField";
import DatePickerField from "components/Form/DatePickerField/DatePickerField";
import validate from 'helpers/validate';

export default function Travelers({
	index,
	errors,
	title,
	type,
  setErrors,
  handleTravelers,
  valueTraveler,
  countries
}) {
	const { packages, commons } = Locale;
	// const hasError = touched?.[type]?.[index] && errors?.[type]?.[index] !== undefined;
	const [travelerCollapse, setTravelerCollapse] = useState(
		index === 0 && type === "adults" ? true : false
	);
	
	function adultMinMaxBirthDate() {
		let minMaxBirthDate = { min: "", max: "" };
		if (index === 0) {
			minMaxBirthDate = {
				min: moment(new Date()).subtract(100, "years"),
				max: moment(new Date()).subtract(19, "years"),
			};
		} else if (index > 0) {
			minMaxBirthDate = {
				min: moment(new Date()).subtract(100, "years"),
				max: moment(new Date()).subtract(12, "years"),
			};
		}
		return minMaxBirthDate;
	}
console.log("errorserrors",errors);

	return (
		<>
			<div
				className="d-flex align-items-center justify-content-between px-3 py-1 mb-2 rounded"
				style={{
					backgroundColor: "#F3F3F3",
					cursor: "pointer",
					border: "1px solid #EDEDED",
				}} //borderColor: hasError ? "#ff1744" : "#EDEDED"
				onClick={() => setTravelerCollapse(!travelerCollapse)}
			>
				<span color="secondary.main">
					{title} {index + 1}
				</span>
				<button className="btn ">
					<i class="fas fa-chevron-down fa-1x text-grey"></i>
				</button>
			</div>

			<Collapse isOpen={travelerCollapse}>
				<div className="row px-3">
					{/* gender */}
					<div className="col-md-4">
						<SelectField
							hasLabel={true}
							placeholder={commons.select}
							options={[
								{ name: packages.male, id: 1 },
								{ name: packages.female, id: 2 },
							]}
              label={packages.gender}
              name={`gender${index}`}
              value={valueTraveler[index]["gender"].name}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `gender${index}`, value: e.value },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e, index, "gender",valueTraveler);

							}}
              color={errors[`gender${index}`]?.required ? "danger" : ""}
              errors={errors[`gender${index}`]}
              
						/>
					</div>
					{/* first name */}
					<div className="col-md-4">
					
						<TextField
							hasLabel={true}
							type="text"
							id="firstName"
							placeholder={packages.firstName}
							label={packages.firstName}
              name={`firstName${index}`}
							value={valueTraveler[index]["firstName"]}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `firstName${index}`, value: e.target.value },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e.target.value, index, "firstName",valueTraveler);

							}}
              color={errors[`firstName${index}`]?.required ? "danger" : ""}
              errors={errors[`firstName${index}`]}
						/>
					</div>
					{/* last name */}
					<div className="col-md-4">
						
						<TextField
							hasLabel={true}
							type="text"
							placeholder={packages.lastName}
							label={packages.lastName}
              name={`lastName${index}`}
							value={valueTraveler[index]["lastName"]}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `lastName${index}`, value: e.target.value },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e.target.value, index, "lastName",valueTraveler);

							}}
              color={errors[`lastName${index}`]?.required ? "danger" : ""}
              errors={errors[`lastName${index}`]}
						/>
					</div>
					{/* birth data */}
					<div className="col-md-4">
					
						<DatePickerField
							hasLabel={true}
              hasYears={true}
							placeholder="DD/MM/YYYY"
							maxDate={
								type === "adults"
									? adultMinMaxBirthDate().max
									: moment(new Date()).subtract(2, "years")
							}
							minDate={
								type === "adults"
									? adultMinMaxBirthDate().min
									: moment(new Date()).subtract(12, "years")
							}
							label={packages.birthDate}
              name={`birthDate${index}`}
							 date={valueTraveler[index]["birthDate"]?valueTraveler[index]["birthDate"]: moment(new Date()).subtract(50, "years")}
							onChangeDate={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `birthDate${index}`, value: e },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e, index, "birthDate",valueTraveler);

							}}
              color={errors[`birthDate${index}`]?.required ? "danger" : ""}
              errors={errors[`birthDate${index}`]}
						/>
						
					</div>
					{/* nationality */}
					<div className="col-md-4">
					

						<SelectField
							hasLabel={true}
							label={packages.nationality}
							placeholder={commons.select}
							options={countries}
							name={`nationality${index}`}
              value={valueTraveler[index]["nationality"]?.name}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `nationality${index}`, value: e.value},
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e, index, "nationality",valueTraveler);

							}}
              color={errors[`nationality${index}`]?.required ? "danger" : ""}
              errors={errors[`nationality${index}`]}
							
						/>
					</div>
					{/* country */}
					<div className="col-md-4">
						<SelectField
							hasLabel={true}
							label={packages.country}
							placeholder={commons.select}
							options={countries}
							name={`country${index}`}
              value={valueTraveler[index]["country"]?.name}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `country${index}`, value: e.value},
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e, index, "country",valueTraveler);

							}}
              color={errors[`country${index}`]?.required ? "danger" : ""}
              errors={errors[`country${index}`]}
						/>
					</div>

					{/* passport expiry date */}
					<div className="col-md-4">
						
						<DatePickerField
							hasLabel={true}
							label={packages.passportExpiryDate}
              hasYears={true}
							placeholder="DD/MM/YYYY"
							name={`passportExpiryDate${index}`}
							minDate={moment(new Date()).add(6, "months")}
							maxDate={moment(new Date()).add(10, "years")}
              date={valueTraveler[index]["passportExpiryDate"]?valueTraveler[index]["passportExpiryDate"]: moment(new Date()).add(6, "months")}             
							onChangeDate={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `passportExpiryDate${index}`, value: e },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e, index, "passportExpiryDate",valueTraveler);

							}}
              color={errors[`passportExpiryDate${index}`]?.required ? "danger" : ""}
              errors={errors[`passportExpiryDate${index}`]}
						/>
					</div>
					{/* passport number */}
					<div className="col-md-4">
						{/* <label htmlFor={`[${type}][${index}].passportNumber`}>
							{t.passportNumber}*
						</label> */}
						{/* <TextField
                size='small'
                fullWidth
                type="text"
                id={`[${type}][${index}].passportNumber`}
                name={`[${type}][${index}].passportNumber`}
                value={values?.[type]?.[index]?.passportNumber}
                placeholder={t.passportNumber}
                onChange={handleChange}
                error={
                  touched?.[type]?.[index]?.passportNumber && Boolean(errors?.[type]?.[index]?.passportNumber)
                }
                helperText={touched?.[type]?.[index]?.passportNumber && errors?.[type]?.[index]?.passportNumber}
              /> */}
						<TextField
							hasLabel={true}
							label={packages.passportNumber}
							type="text"
	
							placeholder={packages.passportNumber}
							
              name={`passportNumber${index}`}
							value={valueTraveler[index]["passportNumber"]}
							onChange={(e) => {
                setErrors({
                  ...errors,
                  ...validate(
                    { name: `passportNumber${index}`, value: e.target.value },
                    {
                      required: true,
                    }
                  ),
                });

                handleTravelers(e.target.value, index, "passportNumber",valueTraveler);

							}}
              color={errors[`passportNumber${index}`]?.required ? "danger" : ""}
              errors={errors[`passportNumber${index}`]}
						/>
					</div>
				</div>
			</Collapse>
		</>
	);
}
