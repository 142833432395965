import React, { useState } from "react";
import hourGlassSrc from "assets/images/icons/hour-glass.png";
import { ReactComponent as Users } from "assets/images/icons/users.svg";

import Locale from "translations";
import { useSBSState } from "context/global";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

export default function VisaSearchTypes({
	destinations,
	selectedDestination,
	setSelectedDestination,
	selectVisa,
	lowestPackagePrice,
}) {
	// set first package plan
	const { locale } = useSBSState();
	const [filter, setFilter] = useState([]);
	const [noteModal, setNoteModal] = useState({
		isOpen: false,
		note: "",
	});

	const [someNationalities, setSomeNationalities] = useState({
		isOpen: false,
		nationality: [],
	});

	function toggleSomeNationalities(data) {
		console.log(data);
		setSomeNationalities((prev) => ({ isOpen: !prev.isOpen, nationality: data }));
	}

	const toggleNote = (note) => {
		setNoteModal((prev) => ({
			isOpen: !prev.isOpen,
			note,
		}));
	};

	const { marketPlace } = Locale;
	const visaTypes = selectedDestination?.visa_types;

	const onChangeFilter = (e, checked) => {
		const value = e.target.value;
		let newFilter = [...filter];
		if (checked) {
			newFilter = newFilter.filter((types) => types !== value);
		} else {
			newFilter = [...newFilter, value];
		}
		setFilter(newFilter);
	};

	return (
		<>
			<div className="p-3">
				<h4 className="mb-2 terms-color h5">
					{marketPlace.selectVisaServices}
				</h4>

				<div className="visa-selected-container row mx-0">
					{destinations?.map((destination) => {
						const countryImageURL = `${process.env.REACT_APP_FILEUPLOAD_URL}/fetch/${destination?.country_photo}`;
						return (
							<div className='col-md-4 col-12 mb-1 mb-md-0'  key={`destination-${destination?.country_code}`} >
								<div className={`visa-destination-card pointer ${destination?.country_code === selectedDestination?.country_code? '':"disabled-background"} `}
									style={{ backgroundImage: `linear-gradient(180deg, #00000000, #000000e3), url(${countryImageURL})`, backgroundRepeat: 'no-repeat', backgroundSize: "100% 100%" }}
									onClick={() => {
										setSelectedDestination({
											...destination,
											country_photo: countryImageURL,
										});
										setFilter([]);
									}}
								>
									<div className="d-flex justify-content-between">
										<h5
											className={`visa-card-header ${
												destination?.country_code ===
												selectedDestination?.country_code
													? ""
													: "opacity5"
											}`}
										>
											{destination?.country}
										</h5>

										<div
											className={`visa-radio-input ${
												destination?.country_code ===
												selectedDestination?.country_code
													? "active"
													: ""
											}`}
										></div>
									</div>

									<div
										className={`visa-card-content mb-1 ${
											destination?.country_code ===
											selectedDestination?.country_code
												? ""
												: "opacity5"
										}`}
									>
										<span className="line-hi-1 ">
											{marketPlace.startingFrom}
										</span>
										<p>
											<span className="visa-card-start-price line-hi-1 ">
												{lowestPackagePrice?.[destination?.country_code]?.price}{" "}
												{
													lowestPackagePrice?.[destination?.country_code]
														?.currency
												}
											</span>
											<span> / {marketPlace.perVisa}</span>
										</p>
									</div>
								</div>
							</div>
						);
					})}
				</div>
			</div>

			<h5 className="mb-1 terms-color h5 px-3">
				{locale === "en"
					? `${selectedDestination?.country} ${marketPlace.visas}`
					: `${marketPlace.visas} ${selectedDestination?.country}`}
			</h5>
			<div className="bg-light-darker w-100 d-flex p-3 align-items-center">
				<i class="fas fa-filter me-1"></i>
				<h5 className="text-body font-weight-bold me-2">{marketPlace.messages.filter}</h5>

		

				{visaTypes?.map((i, index) => {
					const visaType = i.name;
					const checked = filter.includes(visaType);
					
					return (
						<div className="custom-control custom-checkbox filter-check-box" key={`${visaType}-${index}`}>
							<input
								className="custom-control-input"
								id={`${visaType}-id`}
								type="checkbox"
								value={visaType}
								checked={checked}
								onChange={(e) => onChangeFilter(e, checked)}
							/>
							<label
								className="custom-control-label custom-control-labeler pointer"
								htmlFor={`${visaType}-id`}
							>
								{visaType} ( {i.residences?.[0].package_plans.length} )
							</label>
						</div>
					);
				})}
			</div>

			<div className="p-3">
				{/* visa types list */}
				<div className="visa-types-container">
					{visaTypes?.map((visa, index) =>
						visa?.residences.map((residence,ind) =>
							residence?.package_plans?.map((plan,IN) => {
								const show = filter.length === 0 || filter.includes(visa.name);
								return show ? (
									<VisaTypeCard
									key={`visa-${visa?.id}-${index}${ind}${IN}`}
									currency={residence?.provider_currency}
										visa={visa}
										residence={residence}
										packagePlan={plan}
										index={index}
										selectVisa={selectVisa}
										destination={selectedDestination}
										toggleNote={toggleNote}
										toggleSomeNationalities={toggleSomeNationalities}
									/>
								) : null;
							})
						)
					)}
				</div>
			</div>

			<PackageNote
				isOpen={noteModal.isOpen}
				toggle={() => toggleNote("")}
				note={noteModal.note}
			/>
			<PackageNationalitiesModal
				isOpen={someNationalities.isOpen}
				toggle={() => toggleSomeNationalities([])}
				nationalities={someNationalities.nationality}
			/>
		</>
	);
}

export function VisaTypeCard({
	currency,
	visa,
	index,
	selectVisa,
	packagePlan,
	toggleNote,
	residence,
	toggleSomeNationalities,
}) {
	const { marketPlace, inventory } = Locale;

	return (
		<div className="visa-type-card border" key={`visa-${visa?.id}-${index}`}>
			<div className="visa-type-content">
				<h6 className="font-weight-bold orange-color">{visa?.name}</h6>
				{/* <p className='terms-color'>
          ({residences?.nationalities?.length > 0 ?
            residences?.nationalities?.map((nationality, index) => (
              <span key={nationality?.country_id}>
                {nationality?.name}
                {index !== residences?.nationalities?.length - 1 ? ", " : ""}
              </span>
            ))
            :
            `${inventory.messages.all}`
          })
        </p> */}
				{/* loop */}

				{/* processing time */}
				<div className="w-100 my-1  d-flex align-items-center justify-content-center">
					<img
						src={hourGlassSrc}
						alt="hour glass icon"
						width="14px"
						height="16px"
					/>
					<span className='mx-1'>{marketPlace.processingTime}</span>
					<span>{residence?.processing_time}</span>
				</div>

				

				{/* package plan */}
				<div className="visa-type-price">
					<div className="visa-type-pax">
						<Users />
						<span className="mx-1">{`${marketPlace.package} (${packagePlan.range_from} - ${packagePlan.range_to}) ${marketPlace.packagePax}`}</span>
					</div>
					<span className="text-white mt-2">{inventory.messages.Price}</span>
					<p className="text-white lineHeight1	">
						<span className="h5 font-weight-bold lineHeight1">
							{packagePlan?.package_provider_price} {" "}
						</span>
						<span className="lineHeight1">
							{currency} / {inventory.messages.visa}
						</span>
						{packagePlan?.notes ? (
							<i
								role="button"
								class="fas fa-info-circle orange-color ms-1"
								onClick={() => toggleNote(packagePlan?.notes)}
							></i>
						) : null}
					</p>
				</div>

				<ul className="w-100 px-2 my-2">
				<li className="w-100  d-flex align-items-center"
					style={{    marginBottom: "0.25em",lineHeight: 1}}
				>
					<i class="fas fa-check fa-xs"></i>
					{residence?.all_nationalities ? (
						<span className="mx-1">{marketPlace.allNationalities}</span>
					) : residence?.nationalities.length > 1 ? (
						<p className="d-flex align-items-center align-items-center">
							<span className="mx-1">{marketPlace.someNationalities}</span>
							<i

								class="fas fa-eye orange-color"
								onClick={() =>
									toggleSomeNationalities(residence?.nationalities)
								}
								role="button"
							></i>
						</p>
					) : (
						<span className="mx-2">{`${marketPlace.nationality} (${residence?.nationalities?.[0]?.name})`}</span>
					)}
				</li>
					{packagePlan?.visa_include?.map((i) => (
						<li key={i}>
							<i className="fas fa-check fa-xs "></i>
							<span className="mx-1">{i}</span>
						</li>
					))}
				</ul>

				{/* visa price */}
				<div className="w-100 px-2 mt-auto">
					<button
						className="btn select-visa-btn shadow-none"
						onClick={() => {
							selectVisa({
								visaType: { id: visa?.id, name: visa?.name },
								packagePlan: packagePlan,
								residence: residence,
							});
						}}
					>
						{marketPlace.start}
					</button>
				</div>
			</div>
		</div>
	);
}

const PackageNote = ({ isOpen, toggle, note }) => {
	const { inventory } = Locale;
	return (
		<Modal isOpen={isOpen} centered>
			<ModalHeader toggle={toggle}>
				{inventory.viewReservation.Notes}
			</ModalHeader>
			<ModalBody>{note}</ModalBody>
		</Modal>
	);
};
const PackageNationalitiesModal = ({ isOpen, toggle, nationalities }) => {
	const { marketPlace } = Locale;

	return (
		<Modal isOpen={isOpen} centered size="lg">
			<ModalHeader toggle={toggle}>
				{marketPlace.availableNationalities}
			</ModalHeader>
			<ModalBody className="row m-0">
				{nationalities?.map((item, index) => {
					return (
						<p
							className="d-flex align-items-center my-2 col-4"
							key={item.country_id}
						>
							<i class="fas fa-check fa-xs"></i>
							<span className="mx-2">{item.name}</span>
						</p>
					);
				})}
			</ModalBody>
		</Modal>
	);
};
