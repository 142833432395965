import axios from "axios";
import { fetchImages } from "./auth";
import { cleanEmpty } from "./general";
const baseURL = process.env.REACT_APP_API_URL;
const marketPlaceURL = process.env.REACT_APP_API_URL + "/v1/marketplace";
const lookupsURL = process.env.REACT_APP_API_URL + "/v1/lookups";
const marketPackagesURL = process.env.REACT_APP_API_URL + "/v1/market-packages";
export const sendOtp = async () => {
	return await axios
		.post(`${baseURL}/send-otp`)
		.then((res) => res);
};

export const fetchCountries = async () => {
	return await axios
		.get(`${marketPlaceURL}/lookups/get_all_countries`)
		.then((res) => res.data.countries);
};
export const fetchRoutes = async (lang) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/get_all_routes?lang=${lang}`)
		.then((res) => res.data.routes);
};

export const fetchCategories = async (lang) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/get_all_categories?lang=${lang}`)
		.then((res) => res.data.categories);
};

export const fetchVehicleTypes = async (lang) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/get_all_vehicle_types?lang=${lang}`)
		.then((res) => res.data.vehicleTypes);
};

export const fetchCompanies = async (lang) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/get_all_companies?lang=${lang}`)
		.then((res) => res.data.companies);
};

export const fetchHotels = async (data, page = 1) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/search?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchFlights = async (data, page = 1) => {
	return await axios
		.post(`${baseURL}/v1/flights/search?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getFlightsFiltersLookup = async (cacheKey, solutionKey) => {
	return await axios
		.get(
			`${baseURL}/v1/flights/side-filters/${cacheKey}${solutionKey ? "?solutionKey=" + solutionKey : ""
			}`
		)
		.then((res) => res)
		.catch((err) => err.response);
};
export const FlightCheckAvailability = async (data) => {
	return await axios
		.post(`${baseURL}/v1/flights/check-availability`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchFlightDetails = async (data) => {
	return await axios
		.post(`${baseURL}/v1/flights/view-details`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const bookFlight = async (data) => {
	return await axios
		.post(`${baseURL}/v1/flights/book`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchHotelsOffers = async (data, page = 1) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/search-offers?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const guestHotelsOffers = async (data, page = 1) => {
	return await axios
		.post(
			`${process.env.REACT_APP_API_URL}/v1/guest-marketplace/hotels/search-offers`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const guestHotels = async (data, page = 1) => {
	return await axios
		.post(
			`${process.env.REACT_APP_API_URL}/v1/guest-marketplace/hotels/search`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchHotelsGroup = async (data) => {
	return await axios
		.post(`${baseURL}/v1/group-reservations/requests`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchTransports = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/search`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchTransport = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/view_transporter`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchHotel = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/get_hotel_details`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchHotelGuest = async (data) => {
	return await axios
		.post(
			`${process.env.REACT_APP_API_URL}/v1/guest-marketplace/hotels/get_hotel_details`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const searchHotelFilters = async (filters) => {
	return await axios.post(`${marketPlaceURL}/hotels/search_filters`, filters);
};

export const searchOfferHotelFilters = async (filters) => {
	return await axios.post(
		`${process.env.REACT_APP_API_URL}/v1/guest-marketplace/hotels/search_filters`,
		filters
	);
};

export const searchTransFilters = async (filters) => {
	return await axios.post(
		`${marketPlaceURL}/transportaions/search_with_filters`,
		filters
	);
};

export const addHotelToCart = async (data) => {
	return await axios.post(`${marketPlaceURL}/hotels/add_to_cart`, data);
};

export const checkHotelAvailability = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/check_availability`, data)
		.catch((err) => err.response);
};

export const bookHotel = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/book`, data)
		.catch((err) => err.response);
};

export const cancelHotelBooking = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/cancel-book`, data)
		.catch((err) => err.response);
};

export const addTransporterToCart = async (data) => {
	return await axios.post(`${marketPlaceURL}/transportaions/add_to_cart`, data);
};

export const checkTransporterAvailability = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/check_availability`, data)
		.catch((err) => err.response);
};

export const bookTransporter = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/book`, data)
		.catch((err) => err.response);
};

export const updateBookingHotels = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/update-booking`, data)
		.catch((err) => err.response);
};
export const confirmBookingHotels = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/update-booking-confirm`, data)
		.catch((err) => err.response);
};

export const cancelRoom = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/cancel-room`, data)
		.catch((err) => err.response);
};

export const cancelTransporterBooking = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/cancel-book`, data)
		.catch((err) => err.response);
};

export const fetchHotelBrns = async (filter) => {
	return await axios.get(`${marketPlaceURL}/hotels/inventory/list_brns`, {
		params: cleanEmpty(filter),
	});
};
export const fetchGroupReservation = async (filter) => {
	return await axios.get(`${baseURL}/v1/group-reservations/requests`, {
		params: cleanEmpty(filter),
	});
};
////taha
export const getPromoVoucher = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/get-voucher`, data)
		.catch((err) => err.response);
};
//hotels/inventory/pay-brn-fees/11
export const payFees = async (id, data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/inventory/pay-brn-fees/${id}`, data)
		.catch((err) => err.response);
};
export const payGroupsFees = async (id, data) => {
	return await axios
		.post(`${baseURL}/v1/group-reservations/request-payments/${id}/pay`, data)
		.catch((err) => err.response);
};
export const fetchHotelBrn = async (number) => {
	return await axios.get(
		`${marketPlaceURL}/hotels/inventory/get_brn/${number}`
	);
};

export const fetchTransportaionsBrns = async (page) => {
	return await axios.get(
		`${marketPlaceURL}/transportaions/inventory/list_brns?page=${page}`
	);
};
export const getListRemark = async (id) => {
	return await axios.get(
		`${baseURL}/v1/group-reservations/requests/${id}/remarks`
	);
};
export const AddListRemark = async (id, data) => {
	return await axios
		.post(`${baseURL}/v1/group-reservations/requests/${id}/remarks`, data)
		.catch((err) => err.response);
};

export const fetchTransportaionsBrn = async (number) => {
	return await axios.get(
		`${marketPlaceURL}/transportaions/inventory/get_brn/${number}`
	);
};
export const viewGroupINventory = async (number) => {
	return await axios.get(`${baseURL}/v1/group-reservations/requests/${number}`);
};
export const getTransportationReceipt = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/get-receipt`, data, {
			responseType: "arraybuffer",
			headers: {
				Accept: "application/pdf",
			},
		})
		.catch((err) => err.response);
};

export const getTransportationVoucher = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transportaions/get-voucher`, data, {
			responseType: "arraybuffer",
			headers: {
				Accept: "application/pdf",
			},
		})
		.catch((err) => err.response);
};

export const getHotelReceipt = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/get-receipt`, data, {
			responseType: "arraybuffer",
			headers: {
				Accept: "application/pdf",
			},
		})
		.catch((err) => err.response);
};

export const getHotelVoucher = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/hotels/get-voucher`, data, {
			responseType: "arraybuffer",
			headers: {
				Accept: "application/pdf",
			},
		})
		.catch((err) => err.response);
};
//lookups/cities
export const fetchCitiesSearch = async (type, search) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/cities?${type}=1&search=${search}`)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};
export const fetchCities = async (type, filter) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/cities?${type}=1`, {
			params: cleanEmpty(filter),
		})
		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const fetchCitiesHotelsSearch = async (type, search, country_id) => {
	return await axios
		.get(
			`${marketPlaceURL}/lookups/cities-hotels?${type}=1&search=${search}&country_id=${country_id ?? ""}`
		)
		.then((res) => res.data.data)
		.catch((err) => err.response);
};

export const fetchAirlines = async (search) => {
	return await axios
		.get(`${lookupsURL}/airlines`, {
			params: cleanEmpty(search),
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchFlightClasses = async () => {
	return await axios
		.get(`${lookupsURL}/flight-classes`)
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchAirPorts = async (search) => {
	return await axios
		.get(`${lookupsURL}/air-ports`, {
			params: cleanEmpty(search),
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const fetchCitiesHotelsSearchData = async (filter) => {
	return await axios.get(`${marketPlaceURL}/lookups/cities-hotels`, {
		params: cleanEmpty(filter),
	});
};

export const fetchVoucher = async (brn, number) => {
	return await axios.get(
		`${marketPlaceURL}/hotels/inventory/get-voucher/${brn}/${number}`
	);
};

export const getInvoice = async (id) => {
	return await axios
		.get(`${marketPlaceURL}/hotels/inventory/get-invoice/${id}`)
		.then((res) => res.data.data)
		.then(async (invoice) => {
			return {
				...invoice,
				companyImage: await fetchImages(invoice.company?.avatar),
			};
		})
		.catch((err) => err.response);
};

export const fetchMealPlanLookups = async () => {
	return await axios
		.get(`${lookupsURL}/meal-plans`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchRoomViewLookups = async () => {
	return await axios
		.get(`${lookupsURL}/room-views`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchRoomTypeLookups = async () => {
	return await axios
		.get(`${lookupsURL}/room-types`)
		.then((res) => res)
		.catch((err) => err.response);
};

// list ads
export const getAdsList = async () => {
	return await axios
		.get(`${marketPlaceURL}/ads-list`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getCities = async (search, country_id) => {
	return await axios
		.get(`${marketPlaceURL}/lookups/cities?search=${search}&country_id=${country_id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchTransfer = async (data, page = 1) => {
	return await axios
		.post(`${marketPlaceURL}/transfers/search?page=${page}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const addToTransferCart = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transfers/add-to-cart`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deleteFromTransferCart = async (id) => {
	return await axios
		.post(`${marketPlaceURL}/transfers/remove-from-cart`, { id })
		.then((res) => res)
		.catch((err) => err.response);
};

export const cancelTransferItem = async (id, itemId) => {
	return await axios
		.post(`${baseURL}/v1/inventory/transfers/reservations/${id}/cancel-item`, {
			item_id: itemId,
		})
		.then((res) => res)
		.catch((err) => err.response);
};

export const cancelTransfers = async (id, itemId) => {
	return await axios
		.post(`${baseURL}/v1/inventory/transfers/reservations/${id}/cancel`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteAllTransferCart = async () => {
	return await axios
		.post(`${marketPlaceURL}/transfers/remove-all-cart`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const showTransferCart = async () => {
	return await axios
		.get(`${marketPlaceURL}/transfers/show-cart`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const transferBooking = async (data) => {
	return await axios
		.post(`${marketPlaceURL}/transfers/book`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchAccommodationsLookup = async () => {
	return await axios
		.get(`${marketPlaceURL}/lookups/accommodations`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchBoardBasesLookup = async () => {
	return await axios
		.get(`${marketPlaceURL}/lookups/board-bases`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchMarketPackagesCountries = async () => {
	return await axios
		.get(`${baseURL}/v1/market-packages/countries`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const fetchMarketPackagesSearch = async (data) => {
	return await axios
		.get(`${baseURL}/v1/market-packages/search`,{
			params:cleanEmpty(data)
		})
		.then((res) => res)
		.catch((err) => err.response);
};


export const fetchMarketPackagesDetails = async (uuid) => {
	return await axios
		.get(`${marketPackagesURL}/details/${uuid}`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const PackagesBooking = async (data) => {
	return await axios
		.post(`${marketPackagesURL}/book`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchGroupReservationPDF = async (id) => {
	return await axios
		.get(`${baseURL}/v1/group-reservations/requests/${id}/pdf`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const fetchGroupReservationVoucher = async (id) => {
	return await axios
		.get(`${baseURL}/v1/group-reservations/requests/${id}/voucher`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const PackageCheckAvailability = async (data) => {
	return await axios
		.post(`${baseURL}/v1/market-packages/check-availability`, data)
		.then((res) => res)
		.catch((err) => err.response);
};