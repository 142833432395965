import axios from "axios";
import { cleanEmpty } from "./general";
const productBuilderURL =
	process.env.REACT_APP_PRODUCT_BUILDER_BREAKDOWN + "/products";
const baseURL = process.env.REACT_APP_API_URL;

export const createProduct = async (data) => {
	return await axios
		.post(`${productBuilderURL}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const getAllProducts = async (data, page = 1) => {
	return await axios
		.post(
			`${process.env.REACT_APP_PRODUCTS_BUILDER}/products_list?page=${page}`,
			data
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getSingleProduct = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getIteneraryProduct = async (id) => {
	return await axios
		.get(`${productBuilderURL}/${id}/itenerary`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteProduct = async (id) => {
	return await axios
		.delete(`${productBuilderURL}/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const unpublishedProduct = async (id) => {
	return await axios
		.post(`${productBuilderURL}/${id}/unpublish`)
		.then((res) => res)
		.catch((err) => err.response);
};


export const cloneProduct = async (id) => {
	return await axios
		.post(`${productBuilderURL}/${id}/clone`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateProduct = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const addProductsPrice = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/price`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const deleteItem = async (uuid, id) => {
	return await axios
		.delete(`${productBuilderURL}/${uuid}/items/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createHotel = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateHotel = async (id, hotel_id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/hotels/${hotel_id}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const createExternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/external-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateExternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/update-external-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createDomestic = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateDomestic = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/domestics/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createInternal = async (id, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const updateInternal = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/internal-transportations/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const excludeInternalTransportations = async (id) => {
	return await axios
		.get(
			`${productBuilderURL}/${id}/exclude-internal-transportations`,
		
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const createLandmark = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateLandmark = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/sightseeings/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createVisa = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateVisa = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/visa/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createInsurance = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateInsurance = async (id, compId, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/insurance/${compId}`, cleanEmpty(data))
		.then((res) => res)
		.catch((err) => err.response);
};

export const createOther = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/other-services`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const updateOther = async (id, compId, data) => {
	return await axios
		.post(
			`${productBuilderURL}/${id}/other-services/${compId}`,
			cleanEmpty(data)
		)
		.then((res) => res)
		.catch((err) => err.response);
};

export const getHotelMapToolHotels = async (param) => {

	return await axios
		.get(`${baseURL}/trip-management/hotels`, { params: cleanEmpty(param) })
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getInventoryHotels = async (param) => {
	return await axios
		.get(`${baseURL}/trip-management/inventory_hotels`, {
			params: cleanEmpty(param),
		})
		.then((res) => res.data)
		.catch((err) => err.response);
};

export const getAllCurrencies = async () => {

	return await axios
		.get(`${baseURL}/trip-management/get-all-currencies`)
		.then((res) => res.data)
		.catch((err) => err.response);
};


export const paymentActionDone = async (param) => {
	return await axios
		.post(`${baseURL}/online-payment/webhook/hyperpay`, cleanEmpty(param))
		.then((res) => res.data)
		.catch((err) => err.response);
};
export const sharePackage = async (id, data) => {
	return await axios
		.post(`${productBuilderURL}/${id}/publish`, data)
		.then((res) => res)
		.catch((err) => err.response);
};

export const productsListForApplication = async()=> {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-products-for-application`)
		.then((res) => res)
		.catch((err) => err.response);
}

export const getApplicationHistory = async(id)=> {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/applications/`+id+"/history")
		.then((res) => res)
		.catch((err) => err.response);
}


export const addApplication = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-application`, data)
		.then((res) => res)
		.catch((err) => err.response);
}


export const requestsListReservationsB2b = async (param) => {
	return await axios
		.get(`${baseURL}/v1/b2b/packages/list-reservations`, { params: cleanEmpty(param) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const requestViewReservationB2b = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2b/packages/view-reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
}

export const requestsListReservations = async (param) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-reservations`, { params: cleanEmpty(param) })
		.then((res) => res)
		.catch((err) => err.response);
};
export const getPassengers = async (reservation_num) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/list-travelers/${reservation_num}`)
		.then((res) => res)
		.catch((err) => err.response);
};
export const addPassanger = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-traveler`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const requestViewReservation = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/view-reservations/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
}

export const changeRequestStatus = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/accept-reject-request`,data)
		.then((res) => res)
		.catch((err) => err.response);
}

export const addCallLog = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-call-log`,data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const addPackageAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-package-availabilities`,data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const editPackageAvailabilities = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/edit-package-availabilities`,data)
		.then((res) => res)
		.catch((err) => err.response);
}
export const addPayments = async (data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/add-payment`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deletePayment = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/delete-payment/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
}
export const editPassanger = async (id,data) => {
	return await axios
		.post(`${baseURL}/v1/b2c/packages/edit-traveler/${id}`, data)
		.then((res) => res)
		.catch((err) => err.response);
};
export const deletePassanger = async (id) => {
	return await axios
		.get(`${baseURL}/v1/b2c/packages/delete-traveler/${id}`)
		.then((res) => res)
		.catch((err) => err.response);
}

export const getCompanies = async (data) => {
	return await axios
		.get(`${baseURL}/v1/lookups/companies`,{
			params: data,
		})
		.then((res) => res)
		.catch((err) => err.response);
}