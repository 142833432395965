import React from 'react';
import { useSBSState } from 'context/global';
import Locale from 'translations';

function TitlePackage({ details }) {
    const { locale } = useSBSState();
    const {packages} = Locale;
    //const roomsPrices = details?.roomsAvailabilities?.map(room => +room?.adult_price);
    //const minRoomPrice = Math.min(...roomsPrices);

    return (
        <div className="cardtitle" 
        >
            <div  className="titlehead"
            >
                <p style={{ fontWeight: "bold" }}>{details?.name?.[locale]}</p>
                {+details?.product_classification?.id > 0 ? (
							<div className="hotel-star-rate d-inline mb-1 px-2">
								{[...Array(+details?.product_classification?.id).keys()].map((i) => (
									<i key={i} className="fa fa-star" aria-hidden="true"></i>
								))}
							</div>
						) :(
							<div className="hotel-star-rate d-inline mt-3 px-2">
								{[...Array(5)].map((i) => (
									<i key={i} className="far fa-star" aria-hidden="true"></i>
								))}
							</div>
						) }
            </div>
            <div className="titlebody"
            >
                <span >{packages.priceStartFrom}</span>
                <div>
                    <p style={{ fontWeight: "bolder" }}>
                    {details?.starting_from} {details?.currency}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TitlePackage